import { HoldingType } from '../../holdings/enums/HoldingType';
import { HoldingTypeGroup } from '../../holdings/enums/HoldingTypeGroup';
import { WalletHolding } from '../../../portfolio/models/WalletHolding';
import { Holding } from '../../../portfolio/models/Holding';

export function getHoldingType(assetType: string): HoldingType {
  switch (assetType) {
    case 'nft':
      return HoldingType.Nft;
    case 'metals':
      return HoldingType.Metals;
    case 'real_estate':
      return HoldingType.RealEstate;
    case 'private_equity':
      return HoldingType.PrivateEquity;
    case 'collectibles':
      return HoldingType.Collectible;
    case 'others':
      return HoldingType.Other;
    default:
      return HoldingType.Other;
  }
}

export function getHoldingTypeGroup(assetTypeGroup: string): HoldingTypeGroup {
  switch (assetTypeGroup) {
    case 'crypto':
      return HoldingTypeGroup.crypto;
    case 'defi':
      return HoldingTypeGroup.defi;
    case 'stocks':
      return HoldingTypeGroup.stocks;
    case 'options':
      return HoldingTypeGroup.options;
    case 'cash':
      return HoldingTypeGroup.cash;
    case 'nft':
      return HoldingTypeGroup.nft;
    case 'commodity':
      return HoldingTypeGroup.commodity;
    case 'real_estate':
      return HoldingTypeGroup.realEstate;
    case 'private_equity':
      return HoldingTypeGroup.privateEquity;
    case 'collectibles':
      return HoldingTypeGroup.collectibles;
    case 'other':
      return HoldingTypeGroup.other;
    default:
      return HoldingTypeGroup.other;
  }
}

export const getQuantityDenomination = (
  walletHolding: WalletHolding | Holding,
) => {
  if ((walletHolding.asset?.assetTypeGroup ?? '') === 'nft') {
    if (walletHolding.quantity === 1) {
      return 'NFT';
    } else return 'NFTs';
  }
  if ((walletHolding.asset?.assetTypeGroup ?? '') === 'stocks') {
    if (walletHolding.quantity === 1) {
      return 'share';
    } else return 'shares';
  }
  if ((walletHolding.asset?.assetTypeGroup ?? '') === 'crypto') {
    if (walletHolding.quantity === 1) {
      return 'token';
    } else return 'tokens';
  }
  if ((walletHolding.asset?.assetTypeGroup ?? '') === 'options') {
    if ((walletHolding.quantity ?? 0) <= 1) {
      return 'contract';
    } else return 'contracts';
  }
  if ((walletHolding.asset?.assetTypeGroup ?? '') === 'commodity') {
    return 'oz';
  }
  if (
    ((walletHolding as WalletHolding)?.manualAsset?.assetTypeGroup ?? '') ===
    'private_equity'
  ) {
    if (walletHolding.quantity === 1) {
      return 'share';
    } else return 'shares';
  }

  return '';
};
