// vendor libraries
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

// hooks
import { useAuth } from '@/hooks/useAuth';

export const CurrencyFormatButton = () => {
  const { user, currencies, updateUserCurrency } = useAuth();

  const { currency } = user || {};

  return (
    <Menu as="div" className="initial inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-full border border-solid border-border-bg hover:bg-primary-gray dark:hover:bg-deals-card hover:border-primary-gray  dark:border-primary-dark bg-transparent px-4 py-2 text-sm font-medium text-price-green ">
          {currency?.currency_code}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-1/10 z-40 mt-2 h-40 overflow-y-scroll scrollbar w-56 origin-top-right rounded-md bg-white dark:bg-deals-card-top shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {currencies?.map(curr => {
              return (
                <Menu.Item key={curr.id}>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        updateUserCurrency(curr);
                      }}
                      className={`
                        block px-4 py-2 text-sm hover:bg-primary-gray dark:hover:bg-deals-card hover:border-primary-gray  dark:border-primary-dark cursor-pointer
                        ${
                          active
                            ? ' text-black dark:text-white'
                            : ' text-price-green '
                        }`}
                    >
                      {curr.name}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
