import { useEffect, useState } from 'react';

// components
import { Header } from './Header';
import { Footer } from './Footer';
import { BottomNav } from './BottomNav';
import PartyLottie from '../../common/PartyLottie';

// hooks
import { useAuth } from '../../../hooks/useAuth';
import { usePortfolio } from '../../../hooks/usePortfolio';
import { useConfetti } from '../../../hooks/useConfetti';
import { useMember } from '../../../hooks/useMember';

// recoil
import { useTokenState } from '../../../recoil/token';
import Sidebar from './Sidebar';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    isLoggedIn,
    fetchAllCurrencies,
    fetchUserProfile,
    updateDevicePWAData,
    user,
  } = useAuth();
  const { load, backgroundLoadAssetPrices, backgroundLoadNFTChainPrices } =
    usePortfolio();
  const { showConfetti, setShowConfetti } = useConfetti();

  const [token] = useTokenState();
  const { fetchMemberCenterData } = useMember();

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (token) {
      fetchUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      updateDevicePWAData();
      fetchMemberCenterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfetti]);

  useEffect(() => {
    let assetPriceId: ReturnType<typeof setInterval> | null = null;
    let nftChainPriceId: ReturnType<typeof setInterval> | null = null;

    if (isLoggedIn) {
      load({ showLoading: true, checkCache: false });
      fetchAllCurrencies();

      assetPriceId = setInterval(() => {
        backgroundLoadAssetPrices();
      }, 30000);
      nftChainPriceId = setInterval(() => {
        backgroundLoadNFTChainPrices();
      }, 50000);
    } else {
      if (assetPriceId) {
        clearInterval(assetPriceId);
      }
      if (nftChainPriceId) {
        clearInterval(nftChainPriceId);
      }
    }

    return () => {
      if (assetPriceId) {
        clearInterval(assetPriceId);
      }
      if (nftChainPriceId) {
        clearInterval(nftChainPriceId);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div
      className={`screen scroller min-h-screen ${
        isSidebarOpen ? 'overflow-hidden' : ''
      }`}
    >
      {user.status !== 'onboard' ? (
        <>
          <Header isOpen={isSidebarOpen} />
          <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} />
          <div className="flex flex-col flex-grow">
            <main
              className={`${
                isSidebarOpen ? 'shift-content' : 'closed'
              } flex-grow pb-32 md:pb-28 pt-16`}
            >
              {children}
            </main>
            <Footer />
          </div>
          <BottomNav />
          {showConfetti && <PartyLottie />}
        </>
      ) : (
        children
      )}
    </div>
  );
};
