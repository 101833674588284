import { Holding } from '../../models/Holding';
import { HoldingTypeGroup } from '../enums/HoldingTypeGroup';

export class HoldingCategory {
  holdings: Holding[];
  readonly type: HoldingTypeGroup;
  allocation: number;
  dayReturn: number;

  constructor(
    type: HoldingTypeGroup,
    allocation: number,
    dayReturn: number,
    holdings: Holding[] = [],
  ) {
    this.type = type;
    this.allocation = allocation;
    this.dayReturn = dayReturn;
    this.holdings = holdings;
  }

  copyWithHoldings(holdings: Holding[]): HoldingCategory {
    return new HoldingCategory(
      this.type,
      this.allocation,
      this.dayReturn,
      holdings,
    );
  }

  get value(): number {
    if (this.holdings.length === 0) {
      return 0;
    }
    return this.holdings.reduce((sum, item) => sum + item.amount, 0);
  }

  get valueChange(): number {
    if (this.holdings.length === 0) {
      return 0;
    }
    return this.holdings.reduce((sum, item) => sum + item.valueChange, 0);
  }
}
