export const Footer: React.FC = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-white dark:bg-darkest-dark overflow-hidden z-10">
      <div className="max-w-screen-content mx-auto px-6 py-2 md:py-3">
        <div className="flex justify-end items-center">
          <div className="text-xs md:text-sm leading-5 dark:text-white">
            &copy; {year} AssetDash
          </div>
        </div>
      </div>
    </footer>
  );
};
