// TokenRewardPage.tsx
import React from 'react';
import { SuperSEO } from 'react-super-seo';
import { TokenRewardContainer } from '@/components/v2/rewards/TokenRewardContainer';

export const TokenRewardPage: React.FC = () => {
  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title="AssetDash | Token Rewards" lang="en_IE" />
      <TokenRewardContainer />
    </div>
  );
};
