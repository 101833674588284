// models
import { SkeletonLoader } from '@/components/common/Skeleton/SkeletonLoader';
import {
  AssetDetails,
  DeFiDetail,
  StocksDetail,
  OptionsDetail,
} from '@/features/portfolio/models/AssetDetail';
import { CryptoDetail } from '@/features/portfolio/models/CryptoDetail';
import { NFTDetail } from '@/features/portfolio/models/NFTDetail';
import { useAuth } from '@/hooks/useAuth';
import { formatNumber, formatPriceNumber } from '@/utils/number';

interface AssetStatsContentProps {
  assetDetail: AssetDetails;
}

export const AssetStatsContent: React.FC<AssetStatsContentProps> = ({
  assetDetail,
}) => {
  const { convertUSDCurrency } = useAuth();

  const formatCurrencyValue = (value: number) => {
    if (value < 0.001) {
      return value.toFixed(7);
    } else if (value < 1) {
      return value.toFixed(4);
    } else {
      return formatPriceNumber(value);
    }
  };

  if (!assetDetail) {
    return (
      <div className="card p-2">
        <SkeletonLoader height={316} />
      </div>
    );
  }

  if (assetDetail instanceof CryptoDetail) {
    const { priceUSD, marketCap, supply, volume24HUSD } = assetDetail;
    return (
      <div className="card flex flex-col space-y-2 p-2">
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Price</span>
          <span>{priceUSD ? formatCurrencyValue(priceUSD) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Market Cap</span>
          <span>{marketCap ? convertUSDCurrency(marketCap) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Supply</span>
          <span>{supply ? formatNumber(supply) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">24H Volume</span>
          <span>{volume24HUSD ? formatNumber(volume24HUSD) : 'N/A'}</span>
        </div>
      </div>
    );
  } else if (assetDetail instanceof NFTDetail) {
    const {
      floorPriceNative,
      topBidPriceNative,
      marketCapUSD,
      nftChain,
      owners,
      totalListed,
      totalSupply,
    } = assetDetail;

    return (
      <div className="card flex flex-col space-y-2 p-2">
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Floor Price</span>
          <span>
            {floorPriceNative
              ? `${formatPriceNumber(floorPriceNative)} ${nftChain}`
              : 'N/A'}
          </span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Top Bid Price</span>
          <span>
            {topBidPriceNative
              ? `${formatPriceNumber(topBidPriceNative)} ${nftChain}`
              : 'N/A'}
          </span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Owners</span>
          <span>{owners ?? 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Market Cap</span>
          <span>{marketCapUSD ? convertUSDCurrency(marketCapUSD) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Total Listed</span>
          <span>{totalListed ?? 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Total Supply</span>
          <span>{totalSupply ?? 'N/A'}</span>
        </div>
      </div>
    );
  } else if (assetDetail instanceof DeFiDetail) {
    const { totalValueLockedUsd, marketCapUsd } = assetDetail;
    return (
      <div className="card flex flex-col space-y-2 p-2">
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Total Value Locked</span>
          <span>
            {totalValueLockedUsd
              ? convertUSDCurrency(totalValueLockedUsd)
              : 'N/A'}
          </span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Market Cap</span>
          <span>{marketCapUsd ? convertUSDCurrency(marketCapUsd) : 'N/A'}</span>
        </div>
      </div>
    );
  } else if (assetDetail instanceof StocksDetail) {
    const { averageVolume, open, high, low, close, week52High, week52Low } =
      assetDetail;
    return (
      <div className="card flex flex-col space-y-2 p-2">
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Average Volume</span>
          <span>{averageVolume ? formatNumber(averageVolume) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Open</span>
          <span>{open ? convertUSDCurrency(open) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">High</span>
          <span>{high ? convertUSDCurrency(high) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Low</span>
          <span>{low ? convertUSDCurrency(low) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Close</span>
          <span>{close ? convertUSDCurrency(close) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">52 Week High</span>
          <span>{week52High ? convertUSDCurrency(week52High) : 'N/A'}</span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">52 Week Low</span>
          <span>{week52Low ? convertUSDCurrency(week52Low) : 'N/A'}</span>
        </div>
      </div>
    );
  } else if (assetDetail instanceof OptionsDetail) {
    const { totalValueLockedUsd, marketCapUsd } = assetDetail;
    return (
      <div className="card flex flex-col space-y-2 p-2">
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Total Value Locked</span>
          <span>
            {totalValueLockedUsd
              ? convertUSDCurrency(totalValueLockedUsd)
              : 'N/A'}
          </span>
        </div>
        <div className="text-lg card px-3 py-2 flex flex-row justify-between">
          <span className="font-bold">Market Cap</span>
          <span>{marketCapUsd ? convertUSDCurrency(marketCapUsd) : 'N/A'}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="card flex flex-col space-y-2 p-2">No stats available</div>
    );
  }
};
