export class AssetPricesSync {
  assets?: Assets[];

  constructor(assets?: Assets[]) {
    this.assets = assets;
  }

  static fromJSON(json: any): AssetPricesSync {
    if (json['assets']) {
      return new AssetPricesSync(
        json['assets'].map((v: any) => Assets.fromJSON(v)),
      );
    }
    return new AssetPricesSync();
  }

  toJSON(): any {
    const data: any = {};
    if (this.assets) {
      data['assets'] = this.assets.map(v => v.toJSON());
    }
    return data;
  }
}

export class Assets {
  id?: string;
  priceUsd?: number;
  priceNative?: number;

  constructor(id?: string, priceUsd?: number, priceNative?: number) {
    this.id = id;
    this.priceUsd = priceUsd;
    this.priceNative = priceNative;
  }

  static fromJSON(json: any): Assets {
    return new Assets(
      json['id'],
      json['price_usd'],
      json['price_native'] !== undefined ? json['price_native'] + 0.0 : 0.0,
    );
  }

  toJSON(): any {
    const data: any = {};
    data['id'] = this.id;
    data['price_usd'] = this.priceUsd;
    data['price_native'] = this.priceNative;
    return data;
  }
}
