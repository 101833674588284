export class PortfolioChartsResponse {
  all?: PortfolioChartItem[];
  stocks?: PortfolioChartItem[];
  options?: PortfolioChartItem[];
  cash?: PortfolioChartItem[];
  crypto?: PortfolioChartItem[];
  defi?: PortfolioChartItem[];
  nft?: PortfolioChartItem[];
  commodity?: PortfolioChartItem[];
  privateEquity?: PortfolioChartItem[];
  realEstate?: PortfolioChartItem[];
  collectibles?: PortfolioChartItem[];
  other?: PortfolioChartItem[];

  constructor(
    params: {
      all?: PortfolioChartItem[];
      stocks?: PortfolioChartItem[];
      options?: PortfolioChartItem[];
      cash?: PortfolioChartItem[];
      crypto?: PortfolioChartItem[];
      defi?: PortfolioChartItem[];
      nft?: PortfolioChartItem[];
      commodity?: PortfolioChartItem[];
      privateEquity?: PortfolioChartItem[];
      realEstate?: PortfolioChartItem[];
      collectibles?: PortfolioChartItem[];
      other?: PortfolioChartItem[];
    } = {},
  ) {
    Object.assign(this, params);
  }

  static fromJSON(json: any): PortfolioChartsResponse {
    const obj = new PortfolioChartsResponse();
    if (json.all)
      obj.all = json.all.map((v: any) => PortfolioChartItem.fromJSON(v));
    if (json.stocks)
      obj.stocks = json.stocks.map((v: any) => PortfolioChartItem.fromJSON(v));
    if (json.options)
      obj.options = json.options.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      );
    if (json.cash)
      obj.cash = json.cash.map((v: any) => PortfolioChartItem.fromJSON(v));
    if (json.crypto)
      obj.crypto = json.crypto.map((v: any) => PortfolioChartItem.fromJSON(v));
    if (json.defi)
      obj.defi = json.defi.map((v: any) => PortfolioChartItem.fromJSON(v));
    if (json.nft)
      obj.nft = json.nft.map((v: any) => PortfolioChartItem.fromJSON(v));
    if (json.commodity)
      obj.commodity = json.commodity.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      );
    if (json.private_equity)
      obj.privateEquity = json.private_equity.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      );
    if (json.real_estate)
      obj.realEstate = json.real_estate.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      );
    if (json.collectibles)
      obj.collectibles = json.collectibles.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      );
    if (json.other)
      obj.other = json.other.map((v: any) => PortfolioChartItem.fromJSON(v));
    return obj;
  }

  toJSON(): any {
    const data: any = {};
    if (this.all) data.all = this.all.map(v => v.toJSON());
    if (this.stocks) data.stocks = this.stocks.map(v => v.toJSON());
    if (this.options) data.options = this.options.map(v => v.toJSON());
    if (this.cash) data.cash = this.cash.map(v => v.toJSON());
    if (this.crypto) data.crypto = this.crypto.map(v => v.toJSON());
    if (this.defi) data.defi = this.defi.map(v => v.toJSON());
    if (this.nft) data.nft = this.nft.map(v => v.toJSON());
    if (this.commodity) data.commodity = this.commodity.map(v => v.toJSON());
    if (this.privateEquity)
      data.privateEquity = this.privateEquity.map(v => v.toJSON());
    if (this.realEstate) data.realEstate = this.realEstate.map(v => v.toJSON());
    if (this.collectibles)
      data.collectibles = this.collectibles.map(v => v.toJSON());
    if (this.other) data.other = this.other.map(v => v.toJSON());
    return data;
  }
}

export class PortfolioChartSeriesResponse {
  all?: PortfolioChartItem[];
  day1Items?: PortfolioChartItem[];
  week1Items?: PortfolioChartItem[];
  month1Items?: PortfolioChartItem[];
  month3Items?: PortfolioChartItem[];
  year1Items?: PortfolioChartItem[];

  constructor(
    params: {
      all?: PortfolioChartItem[];
      day1Items?: PortfolioChartItem[];
      week1Items?: PortfolioChartItem[];
      month1Items?: PortfolioChartItem[];
      month3Items?: PortfolioChartItem[];
      year1Items?: PortfolioChartItem[];
    } = {},
  ) {
    Object.assign(this, params);
  }

  static fromJSON(json: any): PortfolioChartSeriesResponse {
    return new PortfolioChartSeriesResponse({
      all: json.all?.map((v: any) => PortfolioChartItem.fromJSON(v)),
      day1Items: json.day_1?.map((v: any) => PortfolioChartItem.fromJSON(v)),
      week1Items: json.week_1?.map((v: any) => PortfolioChartItem.fromJSON(v)),
      month1Items: json.month_1?.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      ),
      month3Items: json.month_3?.map((v: any) =>
        PortfolioChartItem.fromJSON(v),
      ),
      year1Items: json.year_1?.map((v: any) => PortfolioChartItem.fromJSON(v)),
    });
  }

  toJSON(): any {
    return {
      all: this.all?.map(v => v.toJSON()),
      day_1: this.day1Items?.map(v => v.toJSON()),
      week_1: this.week1Items?.map(v => v.toJSON()),
      month_1: this.month1Items?.map(v => v.toJSON()),
      month_3: this.month3Items?.map(v => v.toJSON()),
      year_1: this.year1Items?.map(v => v.toJSON()),
    };
  }
}

export class PortfolioChartResponse {
  items?: PortfolioChartItem[];

  constructor(params: { items?: PortfolioChartItem[] } = {}) {
    Object.assign(this, params);
  }

  static fromJSON(json: any): PortfolioChartResponse {
    return new PortfolioChartResponse({
      items: json.items?.map((v: any) => PortfolioChartItem.fromJSON(v)),
    });
  }

  toJSON(): any {
    return {
      items: this.items?.map(v => v.toJSON()),
    };
  }
}

export class PortfolioChartItem {
  timestamp_datetime?: string;
  day?: string;
  series?: string;
  value?: number;

  constructor(
    params: {
      timestamp_datetime?: string;
      day?: string;
      series?: string;
      value?: number;
    } = {},
  ) {
    Object.assign(this, params);
  }

  static fromJSON(json: any): PortfolioChartItem {
    return new PortfolioChartItem({
      timestamp_datetime: json.timestamp_datetime,
      day: json.day,
      series: json.series,
      value: json.value,
    });
  }

  toJSON(): any {
    return {
      timestamp_datetime: this.timestamp_datetime,
      day: this.day,
      series: this.series,
      value: this.value,
    };
  }
}
