import { useNavigate, useLocation } from 'react-router-dom';
import { NavHomeIcon } from './NavIcons/NavHomeIcon';
import { NavGameIcon } from './NavIcons/NavGameIcon';
import { NavGiftIcon } from './NavIcons/NavGiftIcon';
import { NavWalletIcon } from './NavIcons/NavWalletIcon';
import { NavLearnIcon } from './NavIcons/NavLearnIcon';

import { useAuth } from '../../../hooks/useAuth';

const NavItem = ({ Icon, text, path }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`flex flex-col items-center bg-text-gray-text space-y-1 cursor-pointer ${
        location.pathname === path
          ? 'text-price-green'
          : 'text-black dark:text-white hover:text-price-green dark:hover:text-price-green'
      }`}
      onClick={() => {
        navigate(path);
      }}
    >
      <Icon className="rounded-full" />
      <span className="text-xs">{text}</span>
    </div>
  );
};

export const BottomNav = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) return null;

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white dark:bg-deals-card-top text-gray-text md:hidden z-20 pb-2 pt-1">
      <div className="flex justify-around items-center max-w-screen-content mx-auto p-1">
        <NavItem Icon={NavHomeIcon} text="Home" path="/" />
        <NavItem Icon={NavLearnIcon} text="Learn" path="/learn" />
        <NavItem Icon={NavGameIcon} text="Games" path="/games" />
        <NavItem Icon={NavGiftIcon} text="Market" path="/market" />
        <NavItem Icon={NavWalletIcon} text="Wallets" path="/wallets" />
      </div>
    </nav>
  );
};
