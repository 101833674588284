// vendor libraries
import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

import Onboard1 from '../components/onboard/Onboard1';
import Onboard2 from '../components/onboard/Onboard2';
import Onboard3 from '../components/onboard/Onboard3';
import Onboard4 from '../components/onboard/Onboard4';

export default function CoinCenterPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.status !== 'onboard') {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [onboardPage, setOnboardPage] = useState(1);

  const handleOnboard = () => {
    setTimeout(() => {
      setOnboardPage(onboardPage + 1);
    }, 500);
  };

  return (
    <div>
      <SuperSEO title={`AssetDash | Asset Details`} lang="en_IE" />
      <div
        className="min-h-screen bg-cover"
        style={{
          backgroundImage:
            "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
        }}
      >
        {onboardPage === 1 && <Onboard1 handleOnboard={handleOnboard} />}
        {onboardPage === 2 && <Onboard2 handleOnboard={handleOnboard} />}
        {onboardPage === 3 && <Onboard3 handleOnboard={handleOnboard} />}
        {onboardPage === 4 && <Onboard4 />}
      </div>
    </div>
  );
}
