interface AuctionBidJson {
  public_user_id: string;
  created: string;
  num_coins: number;
}

export class AuctionBid {
  publicUserId: string;
  created: Date;
  numCoins: number;

  constructor(publicUserId: string, created: Date, numCoins: number) {
    this.publicUserId = publicUserId;
    this.created = created;
    this.numCoins = numCoins;
  }

  static fromJson(json: AuctionBidJson): AuctionBid {
    return new AuctionBid(
      json.public_user_id,
      new Date(json.created),
      json.num_coins
    );
  }

  toJson(): AuctionBidJson {
    return {
      public_user_id: this.publicUserId,
      created: this.created.toISOString(),
      num_coins: this.numCoins
    };
  }
}

interface AuctionBidsResponseJson {
  auction_bids?: AuctionBidJson[];
}

export class AuctionBidsResponse {
  auctionBids?: AuctionBid[];

  constructor(auctionBids?: AuctionBid[]) {
    this.auctionBids = auctionBids;
  }

  static fromJson(json: AuctionBidsResponseJson): AuctionBidsResponse {
    const bids = json.auction_bids
      ? json.auction_bids.map(AuctionBid.fromJson)
      : undefined;
    return new AuctionBidsResponse(bids);
  }

  toJson(): AuctionBidsResponseJson {
    return {
      auction_bids: this.auctionBids?.map(bid => bid.toJson())
    };
  }
}
