import React, { ComponentType } from 'react';

type ReactComponent = ComponentType<any>;

interface CombineComponentsProps {
  children: React.ReactNode;
}

export const combineComponents = (
  ...components: ReactComponent[]
): React.FC<CombineComponentsProps> => {
  return components.reduce(
    (
      AccumulatedComponents: React.FC<CombineComponentsProps>,
      CurrentComponent: ReactComponent,
    ) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};
