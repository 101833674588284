// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { GamesTagContainer } from '../../components/games/partner-games/GamesTagContainer';

export default function GamesTagPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title={`AssetDash | Games`} lang="en_IE" />
      <GamesTagContainer />
    </div>
  );
}
