import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AssetLogo } from '../../common/Logo/AssetLogo';

import { Provider } from './AddWalletCard';

interface AddWalletProviderListProps {
  providers: Provider[];
  infiniteScroll?: boolean;
  loadMore?: () => any;
  filterString: string;
  title: string;
}

export const AddWalletProviderList: React.FC<AddWalletProviderListProps> = ({
  providers,
  infiniteScroll = false,
  loadMore = () => {},
  filterString,
  title,
}) => {
  const [filteredList, setFilteredList] = useState<Provider[]>([]);

  useEffect(() => {
    if (filterString) {
      setFilteredList(
        providers.filter(provider =>
          provider.name.toLowerCase().includes(filterString.toLowerCase()),
        ),
      );
    } else {
      setFilteredList(providers);
    }
  }, [filterString, providers]);

  const fetchProviderLink = (sel: string) => {
    const token = localStorage.getItem('token');
    window.open(
      `${process.env.REACT_APP_PROVIDER_LINK}?providerId=${sel}&token=${token}&theme=dark&source=web`,
      '_blank',
    );
  };

  return (
    <div>
      {filteredList && filteredList.length > 0 && (
        <InfiniteScroll
          dataLength={filteredList.length}
          hasMore={infiniteScroll}
          next={loadMore}
          loader={null}
        >
          <div className="space-y-2">
            <div>{title}</div>
            {filteredList.map((d, index) => (
              <div
                key={d.id}
                onClick={() => fetchProviderLink(d.id)}
                className="bg-primary-gray cursor-pointer dark:bg-blue-dark hover:bg-gray-text dark:hover:bg-deals-card rounded-3xl py-3 px-4"
              >
                <div className="flex items-center space-x-2">
                  <div className="w-8">
                    <AssetLogo
                      width={28}
                      height={28}
                      type={d.wallet_type}
                      logoUrl={d.logo_url}
                    />
                  </div>
                  <div className="flex justify-between w-full items-center">
                    <div className="capitalize">{d.display_name}</div>
                    {title === 'Top Cryptos' && index === 0 && (
                      <TopCryptosIcons />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

const TopCryptosIcons: React.FC = () => (
  <div className="flex space-x-2">
    <CryptoLogo src="/assets/icons/avalanche_logo.png" alt="avalanche logo" />
    <CryptoLogo src="/assets/icons/binance_logo.png" alt="binance logo" />
    <CryptoLogo src="/assets/icons/polygon_logo.png" alt="polygon logo" />
    <div className="font-bold text-gray-700 rounded-full bg-white flex items-center justify-center font-mono w-4 h-4 md:h-6 md:w-6 text-10 md:text-sm">
      +5
    </div>
  </div>
);

const CryptoLogo: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <img src={src} className="w-4 h-4 md:w-6 md:h-6 rounded-full" alt={alt} />
);
