import { PartnerExpedition } from './PartnerExpeditions';

export class PartnerExpeditionDetailResponse {
  partnerExpedition?: PartnerExpedition | null;
  partnerExpeditionStore?: PartnerExpeditionStore | null;
  partnerExpeditionUser?: PartnerExpeditionUser | null;

  constructor({
    partnerExpedition,
    partnerExpeditionStore,
    partnerExpeditionUser,
  }: {
    partnerExpedition?: PartnerExpedition | null;
    partnerExpeditionStore?: PartnerExpeditionStore | null;
    partnerExpeditionUser?: PartnerExpeditionUser | null;
  }) {
    this.partnerExpedition = partnerExpedition;
    this.partnerExpeditionStore = partnerExpeditionStore;
    this.partnerExpeditionUser = partnerExpeditionUser;
  }

  static fromJson(json: any): PartnerExpeditionDetailResponse {
    return new PartnerExpeditionDetailResponse({
      partnerExpedition: json['partner_expedition']
        ? PartnerExpedition.fromJson(json['partner_expedition'])
        : null,
      partnerExpeditionStore: json['partner_expedition_store']
        ? PartnerExpeditionStore.fromJson(json['partner_expedition_store'])
        : null,
      partnerExpeditionUser: json['partner_expedition_user']
        ? PartnerExpeditionUser.fromJson(json['partner_expedition_user'])
        : null,
    });
  }

  toJson(): any {
    return {
      partner_expedition: this.partnerExpedition
        ? this.partnerExpedition.toJson()
        : null,
      partner_expedition_store: this.partnerExpeditionStore
        ? this.partnerExpeditionStore.toJson()
        : null,
      partner_expedition_user: this.partnerExpeditionUser
        ? this.partnerExpeditionUser.toJson()
        : null,
    };
  }
}

export class PartnerExpeditionUser {
  id: string;
  earnedCoins: number;

  constructor({ id, earnedCoins }: { id: string; earnedCoins: number }) {
    this.id = id;
    this.earnedCoins = earnedCoins;
  }

  static fromJson(json: any): PartnerExpeditionUser {
    return new PartnerExpeditionUser({
      id: json['id'],
      earnedCoins: json['earned_coins'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      earned_coins: this.earnedCoins,
    };
  }
}

class PartnerExpeditionStore {
  id: string;
  name: string;
  imageUrl: string;
  bannerUrl: string;
  description: string;
  websiteUrl: string;
  twitterUrl?: string | null;
  discordUrl?: string | null;

  constructor({
    id,
    name,
    imageUrl,
    bannerUrl,
    description,
    websiteUrl,
    twitterUrl = null,
    discordUrl = null,
  }: {
    id: string;
    name: string;
    imageUrl: string;
    bannerUrl: string;
    description: string;
    websiteUrl: string;
    twitterUrl?: string | null;
    discordUrl?: string | null;
  }) {
    this.id = id;
    this.name = name;
    this.imageUrl = imageUrl;
    this.bannerUrl = bannerUrl;
    this.description = description;
    this.websiteUrl = websiteUrl;
    this.twitterUrl = twitterUrl;
    this.discordUrl = discordUrl;
  }

  static fromJson(json: any): PartnerExpeditionStore {
    return new PartnerExpeditionStore({
      id: json['id'],
      name: json['name'],
      imageUrl: json['image_url'],
      bannerUrl: json['banner_url'],
      description: json['description'],
      websiteUrl: json['website_url'],
      twitterUrl: json['twitter_url'] ?? null,
      discordUrl: json['discord_url'] ?? null,
    });
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      image_url: this.imageUrl,
      banner_url: this.bannerUrl,
      description: this.description,
      website_url: this.websiteUrl,
      twitter_url: this.twitterUrl,
      discord_url: this.discordUrl,
    };
  }
}
