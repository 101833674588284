// vendor libraries
import { useTable } from 'react-table';

// types
import { TableComponentProps } from './types';

// components
import { ArrowButton } from '../Button/ArrowButton';
import { Holding } from '@/features/portfolio/models/Holding';

// recoil
import { useThemeState } from '@/recoil/theme';

// types

export const SmallTable: React.FC<TableComponentProps<Holding>> = ({
  data,
  columns,
  handleSort,
  sortValue,
  sort,
  rowHandler,
}) => {
  const [theme] = useThemeState();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const activeHeaderTitleColor = theme === 'light' ? '#9B9DA0' : '#ffffff';
  const defaultThColor = theme === 'light' ? '#A8A8A8' : '#D8D8D8';
  const defaultIconColor = theme === 'light' ? '#1F3840' : '#A8A8A8';

  const onRowClick = (assetTypeGroup: string, id: string) => {
    rowHandler && rowHandler(assetTypeGroup, id);
  };

  return (
    <div className="bg-white dark:bg-dark-bg text-black dark:text-white overflow-hidden">
      <table
        {...getTableProps()}
        className="w-full flex flex-col border-collapse"
      >
        <thead
          style={{ cursor: 'initial' }}
          className="pr-4 md:pr-27 py-4 border-b border-learn-course-card"
        >
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="flex items-center"
            >
              {headerGroup.headers.map(column => {
                const { id } = column;

                return (
                  <th
                    {...column.getHeaderProps()}
                    onClick={() => {
                      !column.disableSortBy && handleSort(id);
                    }}
                    style={{
                      cursor: !column.disableSortBy ? 'pointer' : 'default',
                    }}
                    className="pl-2 md:pl-6 text-gray-text dark:text-border-bg align-middle text-xs flex p-0"
                  >
                    {(!column.disableSortBy && (
                      <ArrowButton
                        buttonStyle={{
                          color:
                            (id === sortValue && activeHeaderTitleColor) ||
                            defaultThColor,
                          fontSize: '12px',
                          fontWeight: id === sortValue ? '600' : '400',
                        }}
                        direction={
                          id === sortValue
                            ? sort === ':desc'
                              ? 'down'
                              : 'up'
                            : 'down'
                        }
                        iconStyle={{
                          color:
                            (id === sortValue && activeHeaderTitleColor) ||
                            defaultIconColor,
                          opacity: (id === sortValue && '1') || '0.2',
                        }}
                      >
                        {column.render('Header')}
                      </ArrowButton>
                    )) ||
                      column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="h-full w-full flex flex-col">
          {rows.map(row => {
            prepareRow(row);
            const holding = row.original;
            const { asset, isManual } = holding;
            const { assetTypeGroup, id } = asset;

            return (
              <tr
                {...row.getRowProps()}
                className={`${
                  isManual ? '' : 'cursor-pointer'
                } mb-2 bg-short-description dark:bg-dark hover:bg-primary-gray appear-front dark:hover:bg-deals-card linear w-full h-12 font-normal text-sm leading-17 flex items-center pl-2 md:pl-6 pr-2 md:pr-27`}
                onClick={() => {
                  if (!isManual) {
                    onRowClick(assetTypeGroup!, id!);
                  }
                }}
              >
                {row.cells.map(cell => {
                  const { id } = cell.column;

                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ fontWeight: id === sortValue ? '600' : '400' }}
                      className="p-0 align-middle text-sm leading-17 font-normal"
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
