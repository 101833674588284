// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import { ClockIcon, ForwardIcon } from '@heroicons/react/24/outline';
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

// recoil
import { useThemeState } from '@/recoil/theme';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';

interface ChooseVerificationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleNewModalSelected: (item: string) => void;
  wallet: Wallet;
}

export const ChooseVerificationModal: React.FC<
  ChooseVerificationModalProps
> = ({ open, setOpen, handleNewModalSelected, wallet }) => {
  const [theme] = useThemeState();

  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const handleClick = (item: string) => {
    handleNewModalSelected(item);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg  px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 ${background}`}
              >
                <div>
                  <div className="mt-3 text-center space-y-4 sm:mt-5 divide-gray-text divide-y-1 dark:divide-blue-dark">
                    <Dialog.Title
                      as="h3"
                      className={`text-4xl font-bold ${textColor}`}
                    >
                      How do you want to <br className="hidden md:block" />
                      verify your wallet?
                    </Dialog.Title>
                    <div
                      className="py-6 text-left flex space-x-4 cursor-pointer"
                      onClick={() => {
                        handleClick('connect-wallet');
                      }}
                    >
                      <div className="space-y-4">
                        <div
                          className={`${textColor} text-2xl flex space-x-2 items-center`}
                        >
                          <p>Connect Wallet</p>
                          <ForwardIcon className="h-8 w-8 text-gray-text" />
                          <p className="text-price-green text-lg">Instant</p>
                        </div>
                        <p className={`${textColor} text-lg`}>
                          Connect your wallet and prove ownership by signing a
                          message. We use standard open-source libraries for
                          verification.
                        </p>
                      </div>
                      <div className="m-auto">
                        <ArrowRightCircleIcon className="h-16 w-16 fill-gray-text " />
                      </div>
                    </div>
                    <div
                      className="py-6 text-left flex space-x-4 cursor-pointer"
                      onClick={() => {
                        handleClick('proof-of-payment');
                      }}
                    >
                      <div className="space-y-4">
                        <div
                          className={`${textColor} text-2xl flex space-x-2 items-center`}
                        >
                          <p>Proof of Payment</p>
                          <ClockIcon className="h-8 w-8 text-gray-text" />
                          <p className="text-price-green text-lg">
                            {wallet.walletType === 'solana'
                              ? '1 hour'
                              : '24 hours'}
                          </p>
                        </div>
                        <p className={`${textColor} text-lg`}>
                          Verify without connecting your wallet by sending a
                          micro-transaction to our address to prove you are the
                          owner of the address.
                        </p>
                      </div>
                      <div className="m-auto">
                        <ArrowRightCircleIcon className="h-16 w-16 fill-gray-text " />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
