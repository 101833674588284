// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// components
import { ComponentPlaceholder } from '../../common/Placeholder/ComponentPlaceholder';
import { PortfolioHoldingsPieChartContainer } from './PortfolioHoldingsPieChartContainer';
import { PortfolioHoldingCategoryList } from './PortfolioHoldingCategoryList';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

export const PortfolioHoldingsCard: React.FC = () => {
  const { isLoading, holdingCategories } = usePortfolio();

  return (
    <div className="flex flex-col md:flex-row justify-between h-auto md:h-276 w-full md:space-x-4 space-y-10 md:space-y-0 card px-2 py-3">
      {isLoading ? (
        <div className="flex-grow min-h-[276px]">
          <SkeletonLoader height="100%" />
        </div>
      ) : holdingCategories.length > 0 ? (
        <>
          <div className="md:max-w-240 w-full">
            <PortfolioHoldingsPieChartContainer
              holdingCategories={holdingCategories}
            />
          </div>
          <PortfolioHoldingCategoryList holdingCategories={holdingCategories} />
        </>
      ) : (
        <ComponentPlaceholder />
      )}
    </div>
  );
};
