import moment from 'moment';

import { CoinSpendingJson } from '../../../api/coin';

class CoinSpendingsResponse {
  coinSpendings?: CoinSpending[];

  constructor(coinSpendings?: CoinSpending[]) {
    this.coinSpendings = coinSpendings;
  }

  static fromJson(json: {
    coin_spendings: CoinSpendingJson[];
  }): CoinSpendingsResponse {
    const coinSpendings = json.coin_spendings.map(v =>
      CoinSpending.fromJson(v),
    );
    return new CoinSpendingsResponse(coinSpendings);
  }

  toJson(): { coin_spendings: CoinSpendingJson[] } {
    return {
      coin_spendings: this.coinSpendings
        ? this.coinSpendings.map(v => v.toJson())
        : [],
    };
  }
}

class CoinSpending {
  created: Date;
  spendingType: string;
  spendingDescription: string;
  coinsSpent: number;

  constructor(
    created: Date,
    spendingType: string,
    spendingDescription: string,
    coinsSpent: number,
  ) {
    this.created = created;
    this.spendingType = spendingType;
    this.spendingDescription = spendingDescription;
    this.coinsSpent = coinsSpent;
  }

  static fromJson(json: CoinSpendingJson): CoinSpending {
    return new CoinSpending(
      new Date(json.created),
      json.spending_type,
      json.spending_description,
      json.coins_spent ?? 0,
    );
  }

  toJson(): CoinSpendingJson {
    return {
      created: this.created.toISOString(),
      spending_type: this.spendingType,
      spending_description: this.spendingDescription,
      coins_spent: this.coinsSpent,
    };
  }

  getCreatedDay(): string {
    return moment(this.created).format('MMM DD, yyyy');
  }
}

export { CoinSpendingsResponse, CoinSpending };
