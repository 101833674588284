import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { usePortfolio } from '../../../hooks/usePortfolio';

export const AuthButton = () => {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useAuth();
  const { clearData } = usePortfolio();

  const buttonText = isLoggedIn ? 'Log Out' : 'Log In';

  const handleClick = () => {
    if (isLoggedIn) {
      clearData();
      logout();
    }
    navigate('/login');
  };

  return (
    <button
      className="w-max cursor-pointer font-bold text-sm leading-4 py-button-auth px-4 border border-solid border-border-bg dark:border-primary-dark rounded-xl text-price-green hover:border-primary-gray hover:bg-primary-gray dark:hover:bg-deals-card"
      onClick={handleClick}
    >
      {buttonText}
    </button>
  );
};
