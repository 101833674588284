import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useThemeState } from '@/recoil/theme';

interface SkeletonLoaderProps {
  count?: number;
  height?: number | string;
  width?: number | string;
  borderRadius?: number;
  className?: string;
  circle?: boolean;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = props => {
  const [theme] = useThemeState();

  const baseColor = theme === 'light' ? '#F2F2F2' : '#242739';

  return <Skeleton baseColor={baseColor} highlightColor="#21CE99" {...props} />;
};
