interface TabMapping {
  [key: string]: string;
}

const tabMapping: TabMapping = {
  '1': '1D',
  '7': '1W',
  '30': '1M',
  '90': '3M',
  '365': '1Y',
  '3650': 'All',
};

const _tabOrder = ['1', '7', '30', '90', '365', '3650'];

interface SeriesCardTabBarProps {
  selected: keyof typeof tabMapping;
  handleClick: (key: string) => void;
  hideAllOption?: boolean;
}

export const SeriesCardTabBar: React.FC<SeriesCardTabBarProps> = ({
  selected,
  handleClick,
  hideAllOption,
}) => {
  const tabOrder = hideAllOption ? _tabOrder.slice(0, -1) : _tabOrder;
  return (
    <div className="w-auto flex justify-between text-gray-text dark:border-bg bg-primary-gray dark:bg-darkest-dark rounded-3xl p-0.5 font-semibold mt-4 h-6">
      {tabOrder.map(key => (
        <div
          className={`${
            selected === key &&
            'bg-white dark:bg-deals-card shadow-tab-shadow text-price-green'
          }
          text-xs leading-15 rounded-xl cursor-pointer font-bold h-6 flex justify-center items-center w-16 md:w-84`}
          onClick={() => {
            handleClick(key);
          }}
          key={key}
        >
          {tabMapping[key]}
        </div>
      ))}
    </div>
  );
};
