import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import { ResetPasswordForm } from '../../components/authentication/ResetPasswordForm';

export default function ResetPasswordPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { id } = useParams();
  return (
    <>
      <SuperSEO title="AssetDash | Forgot Password" />
      <div
        className="py-22p min-h-screen"
        style={{
          backgroundImage:
            "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
        }}
      >
        <div className="text-32 leading-none text-center mt-8 mb-8">
          Reset Password
        </div>
        <ResetPasswordForm reset_password_token={id!} />
      </div>
    </>
  );
}
