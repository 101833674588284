// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// components
import { WalletHoldingsListItem } from './WalletHoldingsListItem';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

interface WalletHoldingsListProps {
  handleManualAssetSelect: (
    selectedAsset: string,
    holding?: WalletHolding,
  ) => void;
}

export const WalletHoldingsList: React.FC<WalletHoldingsListProps> = ({
  handleManualAssetSelect,
}) => {
  const { walletId } = useParams();
  const { walletHoldings, walletsMap, isLoading } = usePortfolio();
  const [wallet, setWallet] = useState<Wallet>();

  useEffect(() => {
    if (walletId && walletsMap && walletsMap.has(walletId)) {
      setWallet(walletsMap.get(walletId));
    }
  }, [walletId, walletsMap]);

  const currentWalletHolding = walletHoldings?.filter(
    h => h.userWalletId === wallet?.id,
  );

  if (isLoading) {
    return <SkeletonLoader height={400} />;
  }

  return (
    <div className="card divide-y divide-tabs-bg">
      <div className="px-6 py-6 rounded-tl-card-box rounded-tr-card-box bg-white dark:bg-search-bg text-lg font-bold">
        Holdings
      </div>
      <div className="divide-y divide-tabs-bg rounded-bl-card-box rounded-br-card-box">
        {wallet?.readyStatus !== 'loading' ? (
          currentWalletHolding.length > 0 ? (
            currentWalletHolding?.map((walletHolding, index) => {
              return (
                <div key={walletHolding.id}>
                  {walletHolding && (
                    <WalletHoldingsListItem
                      wallet={wallet!}
                      walletHolding={walletHolding}
                      handleManualAssetSelect={handleManualAssetSelect}
                      isLast={index === currentWalletHolding.length - 1}
                    />
                  )}
                </div>
              );
            })
          ) : (
            <div className="text-center">No Holdings</div>
          )
        ) : wallet?.readyStatus === 'loading' ? (
          <div className="h-40">
            <p className="px-20 md:px-32 mb-24 text-center">
              We are getting this account ready for portfolio. Your holdings
              will be visible here!
            </p>
            <div className="flex justify-center">
              <svg
                className="animate-spin  h-16 w-16 text-price-green ml-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx={12}
                  cy={12}
                  r={10}
                  stroke="currentColor"
                  strokeWidth={4}
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div>
            <SkeletonLoader />
          </div>
        )}
      </div>
    </div>
  );
};
