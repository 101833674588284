import { useLearn } from '@/hooks/useLearn';
import MyShareSection from './MyShareSection';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

export default function ShareLearn({ type }) {
  const { isLoading, myShares, myStats } = useLearn();

  const { total_completed, total_conversions, total_tickets } = myStats || '';

  return (
    <>
      <div className="space-y-4">
        <div className="flex flex-wrap divide-x-1 divide-gray-text">
          <div className="text-xs md:text-lg pr-2">
            <p>{total_completed} Completions</p>
          </div>
          <div className="text-xs md:text-lg px-2">
            <p>{total_conversions} Conversions</p>
          </div>
          <div className="text-xs md:text-lg px-2">
            <p>{total_tickets} Tickets</p>
          </div>
        </div>
      </div>
      {!isLoading && myShares && myShares.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4">
          {myShares.map(myShare => {
            return <MyShareSection myShare={myShare} />;
          })}
        </div>
      ) : isLoading ? (
        <div>
          <SkeletonLoader height={300} />
        </div>
      ) : (
        <div className="text-center pt-8">
          <img
            src="/assets/images/no-course-found.png"
            alt="no course found"
            className="mx-auto"
          />
          <p className="font-bold text-xl mt-8">
            No Share Rewards yet, check again soon!
          </p>
        </div>
      )}
    </>
  );
}
