export interface WalletActivityTransaction {
  nftUrl: string;
  nftName: string;
  nftTokenAddress: string;
  nftChain: string;
  nftChainLogo: string;
  activityType: string;
  timestamp: Date;
  fromAddress?: string;
  toAddress?: string;
  price?: number;
}

export class WalletActivityTransaction {
  constructor(data: WalletActivityTransaction) {
    Object.assign(this, data);
    this.timestamp = new Date(data.timestamp);
  }

  static fromJSON(json: any): WalletActivityTransaction {
    return new WalletActivityTransaction({
      timestamp: new Date(json['timestamp']),
      nftUrl: json['nft_url'],
      nftName: json['nft_name'],
      nftTokenAddress: json['nft_token_address'],
      nftChain: json['nft_chain'],
      nftChainLogo: json['nft_chain_logo'],
      activityType: json['activity_type'],
      price: json['price'],
      fromAddress: json['from_address'],
      toAddress: json['to_address'],
    });
  }
}

export class WalletActivityTransactionsResponse {
  transactions: WalletActivityTransaction[];

  constructor(transactions: WalletActivityTransaction[] = []) {
    this.transactions = transactions;
  }

  static fromJSON(json: any): WalletActivityTransactionsResponse {
    const txList = json['transactions'] as any[];
    return new WalletActivityTransactionsResponse(
      txList.map(transaction =>
        WalletActivityTransaction.fromJSON(transaction),
      ),
    );
  }
}
