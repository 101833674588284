import { useEffect } from 'react';
import { useLearn } from '@/hooks/useLearn';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

export default function CourseTag() {
  const {
    courseTags,
    fetchCourseTags,
    selectedCourse,
    setSelectedCourse,
    setPage,
    isActionLoading: isLoading,
  } = useLearn();

  useEffect(() => {
    fetchCourseTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = id => {
    const resp = selectedCourse?.find(item => item === id);
    analytics.track(`learn_tag_click`, {
      id: id,
    });
    if (resp) {
      setSelectedCourse(current => current.filter(curr => curr !== id));
    } else {
      // setSelectedCourse((prevState) => [...prevState, id]);
      setSelectedCourse([id]);
    }
    setPage(1);
  };

  return (
    <>
      {!isLoading && courseTags ? (
        <div className="flex flex-wrap justify-center md:justify-start lg:justify-around items-center">
          {courseTags.map(courseTag => {
            return (
              <div
                key={courseTag.id}
                onClick={() => {
                  handleSelect(courseTag.id);
                }}
                className={`cursor-pointer min-w-32 mt-4 lg:mt-0 h-max py-2 mr-4 font-bold border-1 rounded-full text-center ${
                  selectedCourse.indexOf(courseTag.id) > -1
                    ? 'bg-white text-black border-white'
                    : ' text-black dark:text-gray-learn border-gray-text'
                }`}
              >
                {courseTag.name}
              </div>
            );
          })}
        </div>
      ) : isLoading ? (
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 my-auto justify-start items-center">
          <SkeletonLoader height={300} />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
