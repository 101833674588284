import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

export const SyncButton = () => {
  const navigate = useNavigate();

  const user_sync_attempt = { sync_status: 'done' };

  return (
    <div className="space-x-1 md:space-x-2 flex items-center">
      <div
        onClick={() => navigate('/sync')}
        className="flex items-center justify-center w-10p h-10p bg-transparent bg-center bg-no-repeat cursor-pointer rounded-full border border-solid border-border-bg hover:bg-primary-gray dark:hover:bg-deals-card hover:border-primary-gray dark:border-primary-dark"
      >
        <ArrowPathIcon
          className={`w-4 h-4 text-price-green ${
            user_sync_attempt?.sync_status === 'in_progress'
              ? 'animate-spin-slow'
              : ''
          }`}
        />
      </div>
    </div>
  );
};
