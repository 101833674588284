import { useEffect, useState } from 'react';

import { useExpeditions } from '../../hooks/useExpeditions';

// components
import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';
import { ExpeditionCenterCard } from './ExpeditionCenterCard';
import { BannerCarousel } from '../common/Banner/BannerCarousel';
import { OptionsTab } from '../common/Tab/OptionsTab';
import { AvailableExpeditionsTab } from './AvailableExpeditionsTab';
import { CompletedExpeditionsTab } from './CompletedExpeditionsTab';

export const ExpeditionsContainer = () => {
  const {
    load,
    isLoading,
    expeditionCenter,
    banners,
    assetdashExpeditions,
    completedAssetDashExpeditions,
    partnerExpeditions,
    completedPartnerExpeditions,
    assetdashExpeditionTags,
    partnerExpeditionTags,
  } = useExpeditions();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = {
    available: 'Available',
    completed: 'Completed',
  };

  const [activeTab, setActiveTab] = useState('available');

  const onTabClick = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div>
      <h1 className="text-32 leading-none text-center mt-8 mb-8">
        Expeditions
      </h1>
      {isLoading ? (
        <SkeletonLoader height={300} />
      ) : (
        <div className="space-y-4">
          {expeditionCenter && (
            <ExpeditionCenterCard expeditionCenter={expeditionCenter} />
          )}
          {banners.length > 0 && <BannerCarousel banners={banners} />}
          <div className="py-2 sm:py-5 md:px-6 flex items-center justify-center">
            <OptionsTab
              tabs={tabs}
              activeTab={activeTab}
              onClick={onTabClick}
            />
          </div>
          {activeTab === 'available' && (
            <AvailableExpeditionsTab
              assetdashExpeditions={assetdashExpeditions}
              partnerExpeditions={partnerExpeditions}
              assetdashExpeditionTags={assetdashExpeditionTags}
              partnerExpeditionTags={partnerExpeditionTags}
            />
          )}
          {activeTab === 'completed' && (
            <CompletedExpeditionsTab
              completedAssetDashExpeditions={completedAssetDashExpeditions}
              completedPartnerExpeditions={completedPartnerExpeditions}
            />
          )}
        </div>
      )}
    </div>
  );
};
