// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { GameDetailsContainer } from '../../components/games/partner-games/GameDetailsContainer';

export default function GamesDetailsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SuperSEO title={`AssetDash | Game Details`} lang="en_IE" />
      <GameDetailsContainer />
    </div>
  );
}
