import { axiosAssetDashApi } from './api';

export const getUserHiddenAssets = async () => {
  return await axiosAssetDashApi.get('/api/api_v3/portfolio/hidden_assets');
};

export const hideUserAsset = (assetId: string) => {
  return axiosAssetDashApi.post('/api/api_v3/portfolio/create_hidden_asset', 
    {asset_id: assetId},
  );
};

export const unhideUserAsset = (assetId: string) => {
  return axiosAssetDashApi.post('/api/api_v3/portfolio/remove_hidden_asset', 
    {asset_id: assetId},
  );
};
