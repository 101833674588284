// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// hooks
import { useAuctions } from '@/hooks/useAuctions';

// components
import { SkeletonLoader } from '../../../common/Skeleton/SkeletonLoader';
import { OptionsTab } from '../../../common/Tab/OptionsTab';
import { AuctionDetailsTab } from './tabs/AuctionDetailsTab';
import { AuctionActivityTab } from './tabs/AuctionActivityTab';
import { AuctionResultsTab } from './tabs/AuctionResultsTab';
import { AuctionBidsTab } from './tabs/AuctionBidsTab';

// api
import { getUserCoinCenter } from '@/api/coin';

// models
import { CoinCenter } from '@/features/coins/models/CoinCenter';

export const AuctionDetailsContainer = () => {
  const { id } = useParams();
  const {
    loadAuction,
    auction,
    auctionMyDetails,
    auctionBids,
    auctionBidLeaderboards,
    auctionUserPrizes,
    clearAuctionDetail,
  } = useAuctions();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [coinCenter, setCoinCenter] = useState<CoinCenter>();

  useEffect(() => {
    if (id && id !== auction?.id) {
      clearAuctionDetail();
      loadAuctionInfo(id);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadAuctionInfo = async (actionId: string) => {
    try {
      await loadAuction(actionId, false);
      await fetchUserCoinCenter();
    } catch (err) {
      navigate('/market');
    }
    setIsLoading(false);
  };

  const fetchUserCoinCenter = async () => {
    const response = await getUserCoinCenter();
    setCoinCenter(CoinCenter.fromJson(response.data.coin_center));
  };

  const [activeTab, setActiveTab] = useState('details');
  const tabs: { [key: string]: string } = {
    details: 'Details',
    activity: 'Activity',
    bids: 'Bids',
    results: 'Results',
  };

  return (
    <div className="mx-auto main-content grid grid-cols-1 gap-y-3 md:gap-y-0 md:gap-6">
      <div className="flex w-full justify-center pb-0 md:pb-4">
        <OptionsTab
          tabs={tabs}
          activeTab={activeTab}
          onClick={(key: string) => setActiveTab(key)}
        />
      </div>
      {!isLoading && auction ? (
        <>
          {activeTab === 'details' && (
            <AuctionDetailsTab
              auction={auction}
              myAuctionBidLeaderboard={
                auctionMyDetails?.myAuctionBidLeaderboard
              }
              auctionBidLeaderboards={auctionBidLeaderboards}
              coinCenter={coinCenter!}
            />
          )}
          {activeTab === 'activity' && (
            <AuctionActivityTab auction={auction} auctionBids={auctionBids} />
          )}
          {activeTab === 'bids' && (
            <AuctionBidsTab
              auction={auction}
              myAuctionBidLeaderboard={
                auctionMyDetails?.myAuctionBidLeaderboard
              }
              auctionBidLeaderboards={auctionBidLeaderboards}
            />
          )}
          {activeTab === 'results' && (
            <AuctionResultsTab
              auction={auction}
              myAuctionUserPrizes={auctionMyDetails?.myAuctionUserPrizes}
              auctionUserPrizes={auctionUserPrizes}
            />
          )}
        </>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
    </div>
  );
};
