import { PortfolioBaseHoldingCard } from '../featuredHoldingCard/PortfolioBaseHoldingCard';

import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

export const PortfolioBiggestPositionCard: React.FC = () => {
  const { biggestPosition, isLoading } = usePortfolio();
  const { convertUSDCurrency } = useAuth();

  return (
    <PortfolioBaseHoldingCard
      title="Biggest Position"
      value={convertUSDCurrency(biggestPosition?.amount)}
      holding={biggestPosition}
      isLoading={isLoading}
    />
  );
};
