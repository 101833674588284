import { useThemeState } from '../../../recoil/theme';

export const OptionsTab = ({ tabs, activeTab, onClick }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-gray-200' : 'bg-tabs-bg';
  const backgroundTabs = theme === 'light' ? 'bg-white' : 'bg-black';
  const border = theme === 'light' ? '' : 'border-bg';

  const tabKeys = Object.keys(tabs);
  return (
    <div
      className={`flex w-fit p-0.5 space-x-4 dark:text-gray-text ${border} ${background} rounded-3xl font-semibold py-1 sm:py-2 px-4`}
    >
      {tabKeys.map(key => (
        <div
          className={`
              ${
                activeTab === key &&
                `${backgroundTabs} shadow-tab-shadow text-price-green`
              } text-2xsm sm:text-sm leading-15 rounded-xl cursor-pointer font-bold h-6 flex justify-center items-center p-4 whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
          onClick={() => onClick(key)}
          key={key}
        >
          {tabs[key]}
        </div>
      ))}
    </div>
  );
};
