type NotificationSettingsParams = {
  id?: string;
  portfolioUpdateMorning?: boolean;
  portfolioUpdateEvening?: boolean;
  portfolioUpdateTopGainer?: boolean;
  portfolioUpdateNFT?: boolean;
};

export class NotificationSettings {
  readonly id: string;
  readonly portfolioUpdateMorning: boolean;
  readonly portfolioUpdateEvening: boolean;
  readonly portfolioUpdateTopGainer: boolean;
  readonly portfolioUpdateNFT: boolean;

  constructor({
    id = '',
    portfolioUpdateMorning = true,
    portfolioUpdateEvening = true,
    portfolioUpdateTopGainer = true,
    portfolioUpdateNFT = true,
  }: NotificationSettingsParams = {}) {
    this.id = id;
    this.portfolioUpdateMorning = portfolioUpdateMorning;
    this.portfolioUpdateEvening = portfolioUpdateEvening;
    this.portfolioUpdateTopGainer = portfolioUpdateTopGainer;
    this.portfolioUpdateNFT = portfolioUpdateNFT;
  }

  static fromJSON(json: any): NotificationSettings {
    return new NotificationSettings({
      id: json['id'],
      portfolioUpdateMorning: json['is_getting_portfolio_update_morning'] ?? true,
      portfolioUpdateEvening: json['is_getting_portfolio_update_evening'] ?? true,
      portfolioUpdateTopGainer: json['is_getting_portfolio_update_top_gainer'] ?? true,
      portfolioUpdateNFT: json['is_getting_portfolio_update_nft'] ?? true,
    });
  }

  toJSON(): Record<string, any> {
    return {
      'id': this.id,
      'is_getting_portfolio_update_morning': this.portfolioUpdateMorning,
      'is_getting_portfolio_update_evening': this.portfolioUpdateEvening,
      'is_getting_portfolio_update_top_gainer': this.portfolioUpdateTopGainer,
      'is_getting_portfolio_update_nft': this.portfolioUpdateNFT,
    };
  }

  copyWith(params: NotificationSettingsParams): NotificationSettings {
    return new NotificationSettings({
      id: this.id,
      portfolioUpdateMorning: params.portfolioUpdateMorning ?? this.portfolioUpdateMorning,
      portfolioUpdateEvening: params.portfolioUpdateEvening ?? this.portfolioUpdateEvening,
      portfolioUpdateTopGainer: params.portfolioUpdateTopGainer ?? this.portfolioUpdateTopGainer,
      portfolioUpdateNFT: params.portfolioUpdateNFT ?? this.portfolioUpdateNFT,
    });
  }
}
