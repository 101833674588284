import { axiosAssetDashApi } from './api';

import {
  CardCenterResponse,
} from '../features/cards/models/CardCenter';
import {
  VantaHandsResponse,
} from '../features/cards/models/VantaHands';
import {
  VantaStakedNFTsResponse
} from '../features/cards/models/VantaStakedNFTs';

export const getUserCardCenter = async (): Promise<CardCenterResponse> => {
  const response = await axiosAssetDashApi.get(`/api/api_v4/users/card-center`);
  
  return CardCenterResponse.fromJson(response.data);
};


export const getUserVantaHands = async (): Promise<VantaHandsResponse> => {
  const response = await axiosAssetDashApi.get(`/api/api_v4/cards/vanta-hands`);

  return VantaHandsResponse.fromJson(response.data);
};


export const getUserVantaStakedNFTs = async (): Promise<VantaStakedNFTsResponse> => {
  const response =  await axiosAssetDashApi.get(`/api/api_v4/cards/vanta-staked-nfts`);

  return VantaStakedNFTsResponse.fromJson(response.data);
};
