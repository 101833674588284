import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

// components
import { AssetDashLogo } from '../common/Logo/AssetDashLogo';

// theme
import { useThemeState } from '@/recoil/theme';

// utils
import { getNumberColor } from '@/utils/number';

// api
import { editNFTDetails } from '@/api/portfolioSync';

interface NFTEditCostBasisModalProps {
  walletNFTItem?: WalletNFTItem;
  isModalOpen: boolean;
  isNative?: boolean;
  closeModal: () => void;
}

export const NFTEditCostBasisModal: React.FC<NFTEditCostBasisModalProps> = ({
  walletNFTItem,
  isModalOpen,
  isNative = false,
  closeModal,
}) => {
  const [theme] = useThemeState();
  const background =
    theme === 'light' ? 'bg-primary-gray' : 'bg-dark-background';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const { convertUSDCurrency } = useAuth();
  const { customNFTPricesMap, load } = usePortfolio();

  const [costBasisUsd, setCostBasisUsd] = useState<number | ''>('');
  const [costBasisNative, setCostBasisNative] = useState<number | ''>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (walletNFTItem) {
      const customNFTPrice = customNFTPricesMap.get(walletNFTItem?.nftItemId!);
      if (customNFTPrice) {
        if (customNFTPrice.costBasisUsd !== undefined) {
          setCostBasisUsd(customNFTPrice.costBasisUsd);
        }
        if (customNFTPrice.costBasisNative !== undefined) {
          setCostBasisNative(customNFTPrice.costBasisNative);
        }
      }
    }
  }, [customNFTPricesMap, walletNFTItem]);

  const handleCostBasisInput = (
    input: string,
    setCostBasis: React.Dispatch<React.SetStateAction<number | ''>>,
  ) => {
    if (!input) {
      setCostBasis('');
      return;
    }
    const decimals = input.split('.')[1];

    let num = parseFloat(input);
    if (decimals != null && decimals.length > 2) {
      num = parseFloat(parseFloat(input).toFixed(2));
    }

    if (num > 0 && num <= 1000000) {
      setCostBasis(num);
    }
  };

  const handleSaveCostBasis = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (isNative) {
      await editNFTDetails({
        userWalletCurrentNftItemId: walletNFTItem?.id!,
        costBasisNative: costBasisNative || 0,
      });
    } else {
      await editNFTDetails({
        userWalletCurrentNftItemId: walletNFTItem?.id!,
        costBasisUsd: costBasisUsd || 0,
      });
    }
    await load({ checkCache: false });
    setCostBasisUsd('');
    setCostBasisNative('');
    closeModal();
    setLoading(false);
  };

  const currentPrice = isNative
    ? walletNFTItem?.priceNative
    : walletNFTItem?.priceUsd;
  const costBasis = isNative ? costBasisNative : costBasisUsd;
  const convertCurrency = isNative
    ? (value: number | undefined) =>
        value !== undefined
          ? `${value.toFixed(2)} ${walletNFTItem?.chain}`
          : '--.--'
    : convertUSDCurrency;
  const priceLabel = isNative ? walletNFTItem?.chain : 'USD';

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`${background} ${textColor}`}>
                <div className="p-10 space-y-4 rounded-lg">
                  <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-semibold ">Set Up Price</h2>
                    <button
                      onClick={closeModal}
                      className="font-semibold text-sm text-price-red"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="mt-4 flex">
                      <span className="font-normal text-md leading-5 mt-14 text-center">
                        Is your NFT worth more than the floor price? Set a
                        higher price by using the{' '}
                        <AssetDashLogo size="medium" /> multiplier or set a
                        static price manually.
                      </span>
                    </div>
                    <div className="mt-4 space-y-4 flex flex-col items-center font-normal text-md">
                      <div>
                        Set a Multiplier of 2 if you believe your NFT is worth
                        double the floor.
                      </div>
                      <div className="p-4 rounded-lg border border-gray-text max-w-sm">
                        Floor Price x Multiplier = Final Price
                      </div>
                      <div className="flex flex-col p-4 w-1/2">
                        <div className="flex justify-between">
                          <div className="text-gray-text">Current Price</div>
                          <div>{convertCurrency(currentPrice!)}</div>
                        </div>
                        <div className="flex justify-between border-b border-gray-text pb-2">
                          <div className="text-gray-text">Purchase Price</div>
                          {costBasis ? (
                            <div className="text-price-green">
                              {convertCurrency(costBasis!)}
                            </div>
                          ) : (
                            <div className="text-gray-text">
                              {convertCurrency(0)}
                            </div>
                          )}
                        </div>
                        <div className="flex justify-between pt-2">
                          <div className="text-gray-text">P/L</div>
                          {costBasis ? (
                            <div
                              style={{
                                color: getNumberColor(
                                  currentPrice! - costBasis,
                                ),
                              }}
                            >
                              {convertCurrency(currentPrice! - costBasis)}
                            </div>
                          ) : (
                            <div>{convertCurrency(0)}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSaveCostBasis}>
                      <div>{`Set Up Purchase Price (${priceLabel})`}</div>
                      <input
                        type="number"
                        className="text-center w-48 bg-transparent border-1 border-border-bg dark:border-tabs-bg font-normal text-4xl leading-44 px-6  placeholder-gray-text"
                        placeholder={isNative ? walletNFTItem?.chain : '$0.00'}
                        step="any"
                        value={costBasis === '' ? '' : costBasis}
                        onChange={e =>
                          handleCostBasisInput(
                            e.target.value,
                            isNative ? setCostBasisNative : setCostBasisUsd,
                          )
                        }
                      ></input>
                      <div className="mt-12 flex justify-center">
                        <button
                          type="submit"
                          className={`px-4 py-2 ${
                            !loading
                              ? 'bg-price-green cursor-pointer'
                              : 'bg-white dark:bg-tabs-bg'
                          }`}
                          disabled={loading}
                        >
                          {loading ? (
                            <div className="loader w-full"></div>
                          ) : (
                            <div>Save</div>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
