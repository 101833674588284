class VantaHandsResponse {
  vantaHands: VantaHand[];

  constructor(vantaHands: VantaHand[]) {
    this.vantaHands = vantaHands;
  }

  static fromJson(json: { [key: string]: any }): VantaHandsResponse {
    let vantaHands: VantaHand[] = [];
    if (json['vanta_hands'] != null) {
      vantaHands = json['vanta_hands'].map((v: any) => VantaHand.fromJson(v));
    }
    return new VantaHandsResponse(vantaHands);
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.vantaHands) {
      data['vanta_hands'] = this.vantaHands.map((v) => v.toJson());
    }
    return data;
  }
}

class VantaHand {
  handType?: string;
  handName?: string;
  handIndex?: number;
  handStrength?: number;
  card1Type?: string;
  card2Type?: string;
  card3Type?: string;
  card4Type?: string;
  card5Type?: string;

  constructor({
    handType,
    handName,
    handIndex,
    handStrength,
    card1Type = "empty",
    card2Type = "empty",
    card3Type = "empty",
    card4Type = "empty",
    card5Type = "empty",
  }: {
    handType?: string;
    handName?: string;
    handIndex?: number;
    handStrength?: number;
    card1Type?: string;
    card2Type?: string;
    card3Type?: string;
    card4Type?: string;
    card5Type?: string;
  }) {
    this.handType = handType;
    this.handName = handName;
    this.handIndex = handIndex;
    this.handStrength = handStrength;
    this.card1Type = card1Type;
    this.card2Type = card2Type;
    this.card3Type = card3Type;
    this.card4Type = card4Type;
    this.card5Type = card5Type;
  }

  static fromJson(json: { [key: string]: any }): VantaHand {
    return new VantaHand({
      handType: json['hand_type'],
      handName: json['hand_name'],
      handIndex: json['hand_index'],
      handStrength: json['hand_strength'],
      card1Type: json['card_1_type'] ?? "empty",
      card2Type: json['card_2_type'] ?? "empty",
      card3Type: json['card_3_type'] ?? "empty",
      card4Type: json['card_4_type'] ?? "empty",
      card5Type: json['card_5_type'] ?? "empty",
    });
  }

  toJson(): { [key: string]: any } {
    return {
      hand_type: this.handType,
      hand_name: this.handName,
      hand_index: this.handIndex,
      hand_strength: this.handStrength,
      card_1_type: this.card1Type,
      card_2_type: this.card2Type,
      card_3_type: this.card3Type,
      card_4_type: this.card4Type,
      card_5_type: this.card5Type,
    };
  }
}

export { VantaHand, VantaHandsResponse };
