// vendor libraries
import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-hot-toast';

// recoil
import { useThemeState } from '@/recoil/theme';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// api
import { setPrizeWallet } from '@/api/wallets';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';

interface ConfirmSetPrizeWalletModalProps {
  isOpen: boolean;
  closeModal: () => void;
  wallet: Wallet;
}

export const ConfirmSetPrizeWalletModal: React.FC<
  ConfirmSetPrizeWalletModalProps
> = ({ isOpen, closeModal, wallet }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const { load } = usePortfolio();
  const [loading, setLoading] = useState(false);

  const setPrizeWalletClick = async () => {
    setLoading(true);
    try {
      await setPrizeWallet(wallet.walletType!, wallet.id!);
      await load({ checkCache: false });
      toast.success('Prize wallet set');
    } catch {
      toast.error('Something went wrong');
    }
    closeModal();
    setLoading(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${textColor} ${background}`}
              >
                <Dialog.Title
                  as="h3"
                  className={`text-xl text-left leading-6 font-semibold `}
                >
                  Set Prize Wallet
                </Dialog.Title>
                <div className="mt-2">
                  <p className={`text-sm text-left text-gray-text`}>
                    Are you sure you want to set this as your prize wallet?
                  </p>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium
                      focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm
                      ${
                        !loading
                          ? 'bg-price-green cursor-pointer'
                          : 'bg-white dark:bg-tabs-bg'
                      }`}
                    onClick={setPrizeWalletClick}
                  >
                    {loading ? (
                      <div className="loader w-full"></div>
                    ) : (
                      <div>OK</div>
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
