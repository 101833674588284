// vendor libraries
import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';
import moment from 'moment';

// hooks
import { usePortfolio } from '../../hooks/usePortfolio';

// components
import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';

export default function PortfolioActivityPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { fetchWalletTransactions, walletTransactions } = usePortfolio();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTransaction = async () => {
      setLoading(true);
      await fetchWalletTransactions(false);
      setLoading(false);
    };

    getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SuperSEO title="AssetDash | NFT Activity" lang="en_IE" />
      <div className="mx-auto main-content grid grid-cols-1 gap-y-6 md:gap-y-0 md:gap-6">
        <div className="space-y-10">
          <div className="flex justify-between flex-row space-y-2">
            <div className="text-3xl font-semibold">NFT Activity</div>
          </div>
          <div className="space-y-4">
            {loading ? (
              <SkeletonLoader height={600} />
            ) : (
              walletTransactions?.map((transaction, i) => {
                return (
                  <div
                    key={i}
                    className="bg-white dark:bg-deals-card-top p-4 rounded-xl space-y-2"
                  >
                    <div className="flex justify-start space-x-8">
                      <img
                        src={transaction.nftUrl}
                        className="w-32 min-w-32 h-32 mx-auto rounded-card-box object-cover"
                        alt="nft"
                      />
                      <div className="w-full flex flex-col justify-between">
                        <div className="flex justify-between items-start align-center space-y-1 flex-row">
                          <div className="flex space-x-2">
                            <div className="flex flex-col">
                              <div className="font-semibold text-gray-600 dark:text-gray-300 text-lg">
                                {transaction.nftName}
                              </div>
                            </div>
                          </div>
                          <img
                            src={transaction?.nftChainLogo}
                            alt="chain"
                            className="h-6 w-6 rounded-full object-scale-down border solid border-gray-text"
                          />
                        </div>
                        <div className="flex justify-between items-start space-y-1 flex-row">
                          <div className="text-sm text-black uppercase max-w-40 bg-price-green px-2 py-1 rounded-8 font-semibold">
                            {transaction.activityType}
                          </div>
                          <div className="text-gray-text">
                            {moment(transaction.timestamp).fromNow()}
                          </div>
                        </div>
                        <div className="flex space-x-2 items-center">
                          <div className=" bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-gray-600 border-1 px-4 rounded-xl text-sm">
                            {transaction.fromAddress !== null
                              ? `${transaction.fromAddress?.slice(0, 6)}...`
                              : 'Unknown'}
                          </div>
                          <img
                            src="/assets/icons/arrow_right.png"
                            alt="arrow-right"
                            className="h-4"
                          />
                          <div className="bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-gray-600 border-1 px-4 rounded-xl text-sm">
                            {transaction.toAddress !== null
                              ? `${transaction.toAddress?.slice(0, 6)}...`
                              : 'Unknown'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}
