import { createContext, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import {
  fetchCompetition,
  getClaimedTickets,
  getCommunityPastWaitlist,
  getDealAssets,
  getDealDetails,
  getDeals,
  getDealsCenter,
  getDealsTag,
  getFortuneCookie,
  getGreatestGiveaway,
  getMyOrders,
  getMyRewards,
  getPastPrizes,
  getPastPrizesDetails,
  getPopularDeals,
  getRaffleCenterData,
  getSyncCenterData,
  getWeeklyRaffles,
  getWinningTickets,
  getYourPrizes,
  postClaimPrize,
  postClaimRaffleTickets,
  postFortuneCookie,
  postGenerateCoupon,
  postPortfolioSync,
} from '../api/members';

export const DealContext = createContext({} as any);

export function useDealsLegacy() {
  return useContext(DealContext);
}

export const DealsProviderLegacy = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [coupon, setCoupon] = useState(null);
  const [unverifiedWallets, setUnverfiedWallets] = useState(null);
  const [deals, setDeals] = useState(null);
  const [competitionData, setCompetitionData] = useState([]);
  const [isCompetitionLoading, setIsCompetitionLoading] = useState(false);
  const [dealsTag, setDealsTag] = useState(null);
  const [switchDeal, setSwitchDeal] = useState('findDeals');
  const [claimedDeals, setClaimedDeals] = useState(null);
  const [isClaimedDealsLoading, setIsClaimedDealsLoading] = useState(false);

  const [categoryDeals, setCategoryDeals] = useState([]);
  const [isCategoryDealsLoading, setIsCategoryDealsLoading] = useState(false);

  const [dealAssets, setDealAssets] = useState(null);
  const [isDealAssetsLoading, setIsDealAssetsLoading] = useState(false);
  const [dealDetails, setDealDetails] = useState(null);
  const [isDealDetailsLoading, setIsDealDetailsLoading] = useState(false);
  const [pastWaitlist, setPastWaitlist] = useState([]);
  const [raffles, setRaffles] = useState(null);
  const [fortuneCookie, setFortuneCookie] = useState(null);
  const [showFortuneCookieOpenings, setShowFortuneCookieOpenings] =
    useState(false);
  const [showPartyPopper, setShowPartyPopper] = useState(false);
  const [openFortuneCookieModal, setOpenFortuneCookieModal] = useState(false);
  const [openRaffleModal, setOpenRaffleModal] = useState(false);

  const [selectedWallet, setSelectedWallet] = useState(null);

  const [myOrders, setMyOrders] = useState(null);
  const [myRewards, setMyRewards] = useState(null);
  const [dealsCenter, setDealsCenter] = useState(null);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [pastResults, setPastResults] = useState(null);
  const [pastResultDetails, setPastResultDetails] = useState(null);
  const [yourPrizes, setYourPrizes] = useState(null);
  const [raffleCenterData, setRaffleCenterData] = useState(null);
  const [greatestGiveawayData, setGreatestGiveawayData] = useState(null);

  const [syncCenterData, setSyncCenterData] = useState(null);
  const [isSyncCenterLoading, setIsSyncCenterLoading] = useState(false);

  const fetchClaimedDeals = async () => {
    try {
      setIsClaimedDealsLoading(true);
      const resp = await getClaimedTickets();
      if (resp.status === 200) {
        setClaimedDeals(resp.data);
      }
      setIsClaimedDealsLoading(false);
    } catch (error) {
      setIsClaimedDealsLoading(false);
    }
  };

  const triggerPortfolioSync = async () => {
    try {
      const resp = await postPortfolioSync();
      if (resp.status === 200) {
        return 'success';
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchGreatestGiveaway = async () => {
    try {
      setIsLoading(true);
      const resp = await getGreatestGiveaway();

      if (resp.status === 200) {
        setGreatestGiveawayData(resp.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchSyncCenterData = async () => {
    try {
      setIsSyncCenterLoading(false);
      const resp = await getSyncCenterData();
      if (resp.status === 200) {
        setSyncCenterData(resp.data.sync_center);
        return resp.data.sync_center;
      }
      setIsSyncCenterLoading(true);
    } catch (error) {
      setIsSyncCenterLoading(false);
    }
  };
  const fetchMyRewards = async () => {
    try {
      setIsOrdersLoading(true);
      const resp = await getMyRewards();
      if (resp.status === 200) {
        setMyRewards(resp.data);
      }
      setIsOrdersLoading(false);
    } catch (error) {
      setIsOrdersLoading(false);
    }
  };

  const fetchMyOrders = async () => {
    try {
      setIsOrdersLoading(true);
      const resp = await getMyOrders();
      if (resp.status === 200) {
        setMyOrders(resp.data.purchases);
      }
      setIsOrdersLoading(false);
    } catch (error) {
      setIsOrdersLoading(false);
    }
  };

  const fetchYourPrizes = async () => {
    try {
      const resp = await getYourPrizes();
      if (resp.status === 200) setYourPrizes(resp.data.user_prizes);
    } catch (error) {}
  };

  const claimYourPrize = async id => {
    try {
      const resp = await postClaimPrize(id);
      if (resp.status === 200) {
        return 'success';
      }
    } catch (error) {
      return 'error';
    }
  };

  const fetchRaffleCenterData = async () => {
    try {
      const resp = await getRaffleCenterData();

      if (resp.status === 200) {
        setRaffleCenterData(resp.data.raffle_center);
      }
    } catch (error) {}
  };

  const fetchPastResults = async () => {
    try {
      const resp = await getPastPrizes();
      if (resp.status === 200) setPastResults(resp.data.raffles);
    } catch (error) {}
  };

  const fetchPastResultDetail = async id => {
    try {
      const resp = await getPastPrizesDetails(id);
      if (resp.status === 200) setPastResultDetails(resp.data);
    } catch (error) {}
  };

  const fetchDealsCenter = async () => {
    try {
      const resp = await getDealsCenter();
      if (resp.status === 200) {
        setDealsCenter(resp.data.deals_center);
      }
    } catch (error) {}
  };

  const showWinningTicket = async () => {
    try {
      const resp = await getWinningTickets();
      if (resp.status === 200) {
        setRaffles(resp.data);
      }
    } catch (error) {}
  };

  const claimRaffleTickets = async () => {
    try {
      const resp = await postClaimRaffleTickets();

      if (resp.status === 200) {
        // setRaffles(resp.data);
        setShowPartyPopper(true);
        toast.success('Giveaway ticket claimed successfully!');
      }
    } catch (error) {}
  };

  const checkFortuneCookie = async () => {
    try {
      const resp = await getFortuneCookie();

      if (resp.status === 200) {
        setFortuneCookie(resp.data);
        return resp.data.fortune_cookie;
      }
    } catch (error) {
      return error;
    }
  };

  const openFortuneCookie = async () => {
    setShowFortuneCookieOpenings(true);
    const resp = await postFortuneCookie();

    if (resp.status === 200) {
      setFortuneCookie(resp.data);
      return resp.data;
    }
  };

  const fetchWeeklyRaffles = async () => {
    try {
      const resp = await getWeeklyRaffles();

      if (resp.status === 200) {
        setRaffles(resp.data);
      }
    } catch (error) {}
  };

  const fetchDealDetails = async dealId => {
    try {
      setIsDealDetailsLoading(true);
      analytics.track('my_deals_deal_details_started', {
        platform: 'web',
        dealId,
      });
      const resp = await getDealDetails(dealId);
      if (resp.status === 200) {
        analytics.track('my_deals_deal_details_success', {
          platform: 'web',
          dealId,
        });
        setDealDetails(resp.data);
      } else {
        analytics.track('my_deals_deal_details_failed', {
          platform: 'web',
          dealId,
        });
      }
      setIsDealDetailsLoading(false);
    } catch (error) {
      analytics.track('my_deals_deal_details_error', {
        platform: 'web',
        dealId,
      });
      setIsDealDetailsLoading(false);
    }
  };

  const fetchDealAssets = async () => {
    try {
      setIsDealAssetsLoading(true);
      const resp = await getDealAssets();
      if (resp.status === 200) {
        analytics.track('my_deals_deal_assets_fetched', {
          platform: 'web',
        });
        setDealAssets(resp.data);
      } else {
        analytics.track('my_deals_deal_assets_failed', {
          platform: 'web',
        });
      }
      setIsDealAssetsLoading(false);
    } catch (error) {
      analytics.track('my_deals_deal_assets_error', {
        platform: 'web',
      });
      setIsDealAssetsLoading(false);
    }
  };

  const fetchCategoryDealsData = async (tag, orderKey, topTier = false) => {
    try {
      setIsCategoryDealsLoading(true);
      const resp = await getPopularDeals(tag, orderKey, topTier);
      if (resp.status === 200) {
        analytics.track(`my_deals_${tag ? tag : orderKey}_deals_fetched`, {
          platform: 'web',
          tag,
        });
      } else {
        // set
        analytics.track(`my_deals_${tag ? tag : orderKey}_deals_failed`, {
          platform: 'web',
          tag,
        });
      }
      setIsCategoryDealsLoading(false);
      return resp.data;
    } catch (error) {
      analytics.track(`my_deals_${tag ? tag : orderKey}_deals_error`, {
        platform: 'web',
        tag,
      });
      setIsCategoryDealsLoading(false);
    }
  };

  const fetchPastDealsWaitlist = async () => {
    try {
      const resp = await getCommunityPastWaitlist();

      if (resp.status === 200) {
        setPastWaitlist(resp.data.waitlist_assets);
      }
    } catch (error) {}
  };

  const fetchDealsTag = async () => {
    try {
      const resp = await getDealsTag();
      if (resp.status === 200) {
        setDealsTag(resp.data);
        analytics.track('my_deals_tag_fetched', {
          platform: 'web',
        });
      } else {
        // set
        analytics.track('my_deals_tag_failed', {
          platform: 'web',
        });
      }
    } catch (err) {
      analytics.track('my_deals_tag_error', {
        platform: 'web',
      });
    }
  };

  const fetchDeals = async (dealTag, page) => {
    try {
      page === 1 && setIsLoading(true);
      const resp = await getDeals(dealTag, page);
      if (resp.status === 200) {
        if (page === 1) setDeals(resp?.data?.deals);
        else {
          if (resp?.data?.deals?.length > 0)
            setDeals(prevState => [...prevState, ...resp.data.deals]);
        }
        analytics.track('my_deals_success', {
          platform: 'web',
          dealTag,
          page,
        });
      } else {
        analytics.track('my_deals_failed', {
          platform: 'web',
          dealTag,
          page,
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      analytics.track('my_deals_error', {
        platform: 'web',
        dealTag,
        page,
      });
    }
  };

  const generateCoupon = async dealId => {
    try {
      setIsLoading(true);
      analytics.track('my_deals_generate_coupon_started', {
        platform: 'web',
        dealId,
      });
      const couponResponse = await postGenerateCoupon(dealId);
      const { data } = couponResponse || {};
      const couponData = data?.coupon;
      const unverifiedWallets = data?.unverified_wallets;

      if (couponData) {
        setCoupon(couponData.coupon_code);
        setIsLoading(false);
        analytics.track('my_deals_generate_coupon_success', {
          platform: 'web',
          dealId,
        });
        return { status: 'success' };
      } else {
        setUnverfiedWallets(unverifiedWallets);
        // setVerifyModalOpen(true);
        setIsLoading(false);
        analytics.track('my_deals_generate_coupon_not_success', {
          platform: 'web',
          dealId,
          status: 'verify_wallet',
        });
        return { status: 'verify_wallet' };
      }
    } catch (error) {
      //   setOpen(false);
      setIsLoading(false);
      analytics.track('my_deals_generate_coupon_error', {
        platform: 'web',
        dealId,
      });
      setError(error);
      toast.error(error.response.data.detail);
      return { status: 'failed' };
    }
  };

  const fetchDealsCompetitionData = async () => {
    try {
      setIsCompetitionLoading(true);
      const resp = await fetchCompetition();

      if (resp.status === 200) {
        setCompetitionData(resp.data.waitlist_assets);
        analytics.track('competition_data_success', {
          platform: 'web',
        });
      } else {
        analytics.track('competition_data_failed', {
          platform: 'web',
        });
      }
      setIsCompetitionLoading(false);
    } catch (err) {
      analytics.track('competition_data_error', {
        platform: 'web',
      });
      setIsCompetitionLoading(false);
    }
  };

  return (
    <DealContext.Provider
      value={{
        coupon,
        setCoupon,
        unverifiedWallets,
        setUnverfiedWallets,
        isLoading,
        setIsLoading,
        error,
        setError,
        generateCoupon,
        deals,
        setDeals,
        fetchDeals,
        fetchDealsCompetitionData,
        competitionData,
        isCompetitionLoading,
        fetchDealsTag,
        dealsTag,
        categoryDeals,
        setCategoryDeals,
        isCategoryDealsLoading,
        fetchCategoryDealsData,
        fetchDealAssets,
        dealAssets,
        isDealAssetsLoading,
        isDealDetailsLoading,
        dealDetails,
        fetchDealDetails,
        pastWaitlist,
        fetchPastDealsWaitlist,
        fetchWeeklyRaffles,
        raffles,
        openFortuneCookie,
        fortuneCookie,
        showFortuneCookieOpenings,
        setShowFortuneCookieOpenings,
        checkFortuneCookie,
        openFortuneCookieModal,
        setOpenFortuneCookieModal,
        openRaffleModal,
        setOpenRaffleModal,
        claimRaffleTickets,
        showWinningTicket,
        showPartyPopper,
        setShowPartyPopper,
        selectedWallet,
        setSelectedWallet,
        fetchMyOrders,
        myOrders,
        isOrdersLoading,
        setIsOrdersLoading,
        fetchDealsCenter,
        dealsCenter,
        fetchYourPrizes,
        yourPrizes,
        fetchPastResults,
        pastResults,
        claimYourPrize,
        raffleCenterData,
        setRaffleCenterData,
        fetchRaffleCenterData,
        fetchPastResultDetail,
        pastResultDetails,
        setPastResultDetails,
        switchDeal,
        setSwitchDeal,
        fetchMyRewards,
        myRewards,
        fetchSyncCenterData,
        syncCenterData,
        isSyncCenterLoading,
        triggerPortfolioSync,
        fetchClaimedDeals,
        claimedDeals,
        isClaimedDealsLoading,
        fetchGreatestGiveaway,
        greatestGiveawayData,
        // new values
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
