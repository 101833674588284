import emptyLottieAnim from '../../../../assets/lottie/empty.json';
import giftOpening from '../../../../assets/lottie/gift-opening.json';
import maintenance from '../../../../assets/lottie/maintenance.json';
import partyLottieAnim from '../../../../assets/lottie/party.json';
import Lottie from 'lottie-react';

interface AnimationData {
  party: any;
  empty: any;
  maintenance: any;
  giftOpening: any;
}

const animations: AnimationData = {
  party: partyLottieAnim,
  empty: emptyLottieAnim,
  maintenance: maintenance,
  giftOpening: giftOpening,
};

interface Props {
  name: keyof AnimationData;
  width?: number;
  height?: number;
}

const LottieAnimation: React.FC<Props> = ({ name, width, height }) => {
  return (
    <Lottie animationData={animations[name]} height={height} width={width} />
  );
};

export default LottieAnimation;
