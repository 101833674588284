export class AssetDashExpeditionTagsResponse {
  expeditionTags?: AssetDashExpeditionTag[] | null;

  constructor({
    expeditionTags,
  }: {
    expeditionTags?: AssetDashExpeditionTag[] | null;
  }) {
    this.expeditionTags = expeditionTags;
  }

  static fromJson(json: any): AssetDashExpeditionTagsResponse {
    return new AssetDashExpeditionTagsResponse({
      expeditionTags: json['tags']
        ? json['tags'].map((v: any) => AssetDashExpeditionTag.fromJson(v))
        : null,
    });
  }

  toJson(): any {
    return {
      tags: this.expeditionTags
        ? this.expeditionTags.map((v: AssetDashExpeditionTag) => v.toJson())
        : null,
    };
  }
}

export class AssetDashExpeditionTag {
  id: string;
  name: string;

  constructor({ id, name }: { id: string; name: string }) {
    this.id = id;
    this.name = name;
  }

  static fromJson(json: any): AssetDashExpeditionTag {
    return new AssetDashExpeditionTag({
      id: json['id'],
      name: json['name'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
