import React from 'react';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { CardCenter } from '@/features/cards/models/CardCenter';

interface DiamondStatusCardProps {
  cardCenter: CardCenter | undefined;
}

const DiamondStatusCard: React.FC<DiamondStatusCardProps> = ({
  cardCenter,
}) => {
  if (!cardCenter) {
    return <SkeletonLoader height={150} />;
  }

  return (
    <div className="card p-6 rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-element-text-light dark:text-element-text-dark">
        Diamond Status
      </h2>
      <div className="whitespace-nowrap text-gray-600 dark:text-gray-300 text-lg">
        <div className="flex justify-between items-center space-x-2 py-1 px-2">
          <div>All-Time High Multiplier</div>
          <div className="font-semibold">{cardCenter.maxMultiplier}x</div>
        </div>
        <div className="flex justify-between items-center space-x-2 py-1 px-2">
          <div>Current Multiplier</div>
          <div className="font-semibold">{cardCenter.totalMultiplier}x</div>
        </div>
        <div className="flex justify-between items-center space-x-2 py-1 px-2">
          <div>Diamond Status</div>
          <div className="font-semibold">
            {cardCenter.isDiamondHands ? (
              <img
                src="/assets/icons/check-circle.png"
                alt="check-circle"
                className="w-4 h-4"
              />
            ) : (
              <img
                src="/assets/icons/x-circle.png"
                alt="x-circle"
                className="w-4 h-4"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiamondStatusCard;
