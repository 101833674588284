// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// recoil
import { useThemeState } from '../../../recoil/theme';

interface ConnectInvestmentsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  imageLocation: string;
  title: string;
  description: string;
}

export const ConnectInvestmentsModal: React.FC<
  ConnectInvestmentsModalProps
> = ({ isOpen, closeModal, imageLocation, description, title }) => {
  const navigate = useNavigate();
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const onClickContinue = async () => {
    closeModal();
    navigate('/wallets');
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${background}`}
              >
                <div className={`${textColor}`}>
                  <div className="text-center space-y-2">
                    <Dialog.Title
                      as="h3"
                      className={`text-xl font-semibold leading-6 text-left`}
                    >
                      <div className="flex justify-end items-center">
                        <button onClick={closeModal}>
                          <XMarkIcon className="text-price-red h-6 w-6" />
                        </button>
                      </div>
                    </Dialog.Title>
                    <h2 className="text-2xl font-semibold ">{title}</h2>
                    <div>{description}</div>
                    <div className="flex justify-center">
                      <img
                        src={imageLocation}
                        alt={imageLocation}
                        className="h-48"
                      />
                    </div>
                    <div className="flex justify-center space-x-4 mt-10 text-right text-black">
                      <button
                        type="button"
                        className="w-60 py-4 px-8 transition delay-100 ease-in leading-17 font-semibold text-sm rounded-xl bg-price-green"
                        onClick={() => onClickContinue()}
                      >
                        <div>Continue</div>
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
