import { AtomEffect } from 'recoil';

const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      try {
        // ensure objects, booleans, and numbers are converted
        const parsed = JSON.parse(savedValue);
        setSelf(parsed);
      } catch (e) {
        setSelf(savedValue as T);
      }
    }

    onSet((newValue, _, isReset: boolean) => {
      let value: T | string = newValue;

      if (isReset) {
        localStorage.removeItem(key);
      } else {
        if (typeof newValue === 'object') {
          value = JSON.stringify(newValue as object);
        }
        localStorage.setItem(key, value as string);
      }
    });
  };

export { localStorageEffect };
