// vendor libraries
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// hooks
import { useGames } from '../../../hooks/useGames';

// components
import { PartnerGameCard } from './PartnerGameCard';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

export const GamesTagContainer: React.FC = () => {
  const {
    loadPartnersGames,
    loadPartnersGameTags,

    // values
    gamesPartnersGamesList,
    gamesPartnersTags,
  } = useGames();
  const { tagId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadPartnersGameTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async (tag: string) => {
      setIsLoading(true);
      try {
        await loadPartnersGames(tag);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    if (tagId && gamesPartnersTags.map(t => t.id).includes(tagId)) {
      fetchData(tagId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId, gamesPartnersTags]);
  const tag = gamesPartnersTags.find(t => t.id === tagId);
  return (
    <div className="px-6 py-4 space-y-4">
      <h1 className="text-3xl">{tag && tag.name}</h1>
      {isLoading ? (
        <SkeletonLoader height={400} />
      ) : (
        <>
          <div className="flex overflow-x-auto flex-row space-x-4 items-start">
            {gamesPartnersGamesList.map(game => (
              <PartnerGameCard key={game.id} partnerGame={game} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
