export class ArcadeGame {
  id: string;
  gameId: string;
  name: string;
  status: string;
  description: string;
  imageUrl: string;
  gameUrl: string;
  coinsCost: number;
  instructions: string[];
  scoringInstructions: string[];
  instructionsLink: string;
  termsLink: string;

  constructor({
    id,
    gameId,
    name,
    status,
    description,
    imageUrl,
    gameUrl,
    coinsCost,
    instructions,
    scoringInstructions,
    instructionsLink,
    termsLink
  }: {
    id: string,
    gameId: string,
    name: string,
    status: string,
    description: string,
    imageUrl: string,
    gameUrl: string,
    coinsCost: number,
    instructions: string[],
    scoringInstructions: string[],
    instructionsLink: string,
    termsLink: string
  }) {
    this.id = id;
    this.gameId = gameId;
    this.name = name;
    this.status = status;
    this.description = description;
    this.imageUrl = imageUrl;
    this.gameUrl = gameUrl;
    this.coinsCost = coinsCost;
    this.instructions = instructions;
    this.scoringInstructions = scoringInstructions;
    this.instructionsLink = instructionsLink;
    this.termsLink = termsLink;
  }

  static fromJson(json: any): ArcadeGame {
    return new ArcadeGame({
      id: json['id'],
      gameId: json['game_id'],
      name: json['name'],
      status: json['status'],
      description: json['description'],
      imageUrl: json['image_url'],
      gameUrl: json['game_url'],
      coinsCost: json['cost_coins'],
      instructions: json['instructions'] || [],
      scoringInstructions: json['scoring_instructions'] || [],
      instructionsLink: json['instructions_link'],
      termsLink: json['terms_link']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      gameId: this.gameId,
      name: this.name,
      status: this.status,
      description: this.description,
      imageUrl: this.imageUrl,
      gameUrl: this.gameUrl,
      cost_coins: this.coinsCost,
      instructions: this.instructions,
      scoring_instructions: this.scoringInstructions,
      instructions_link: this.instructionsLink,
      terms_link: this.termsLink
    };
  }
}

export class ArcadeGamesResponse {
  arcadeGames?: ArcadeGame[];

  constructor({ arcadeGames }: { arcadeGames?: ArcadeGame[] }) {
    this.arcadeGames = arcadeGames;
  }

  static fromJson(json: any): ArcadeGamesResponse {
    const arcadeGames = json['arcade_games'] ? json['arcade_games'].map((v: any) => ArcadeGame.fromJson(v)) : undefined;
    return new ArcadeGamesResponse({ arcadeGames });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.arcadeGames) data['arcade_games'] = this.arcadeGames.map((game) => game.toJson());
    return data;
  }
}
