import React, { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { ElementsContainer } from '@/components/v2/elements/ElementsContainer';

export default function ElementsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title={`AssetDash | Elements`} lang="en_IE" />
      <ElementsContainer />
    </div>
  );
}
