import React, { useEffect } from 'react';
import { useCardCenter } from '@/hooks/useCardCenter';
import ElementsStatsCard from './ElementsStatsCard';
import { ElementsListCard } from './ElementsListCard';
import { usePortfolio } from '@/hooks/usePortfolio';
import ElementsUpgradeCard from './ElementsUpgradeCard';
import DiamondStatusCard from './DiamondStatusCard';

export const ElementsContainer: React.FC = () => {
  const { load, cardCenter } = useCardCenter();
  const { elementNFTItems = [] } = usePortfolio();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDataLoaded = elementNFTItems.length > 0;

  return (
    <div>
      <div
        className={`grid ${
          isDataLoaded
            ? 'grid-cols-1 lg:grid-cols-3'
            : 'grid-cols-1 lg:grid-cols-3'
        } gap-6 text-white mb-6`}
      >
        <div className="flex-1 card p-6 rounded-md relative">
          <img
            className="absolute top-0 right-0 w-full md:w-1/2 h-3/4 md:h-full opacity-50 dark:opacity-40"
            src="/assets/images/elements_set.svg"
            alt="elements set"
          />
          <div className="relative z-10 flex flex-col justify-center h-full max-w-[540px]">
            <h2 className="text-2xl font-bold mb-2 text-element-text-light dark:text-element-text-dark">
              Elements
            </h2>
            <p className="mb-4 text-element-text-light dark:text-element-text-dark">
              Elements are the first PFP collection on WEN. History and
              Provenance intertwined with the power of AssetDash.
            </p>
          </div>
        </div>
        <ElementsStatsCard cardCenter={cardCenter} />
        <DiamondStatusCard cardCenter={cardCenter} />
        {!isDataLoaded && <ElementsUpgradeCard />}
      </div>
      {isDataLoaded ? (
        <div className="mt-12">
          <ElementsListCard elementNFTItems={elementNFTItems} />
        </div>
      ) : (
        <div className="flex justify-center items-center h-64 text-center text-xl text-element-text-light dark:text-element-text-dark mt-12">
          <div>
            <img
              className="mx-auto mb-4"
              src="/assets/icons/no_elements_icon.svg"
              alt="No Elements"
            />
            You don't have any elements yet
          </div>
        </div>
      )}
    </div>
  );
};
