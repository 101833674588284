// models
import { AssetDashExpedition } from '../../features/expeditions/models/AssetDashExpeditions';
import { PartnerExpedition } from '../../features/expeditions/models/PartnerExpeditions';

// components
import { AssetdashExpeditionCard } from './AssetdashExpeditionCard';
import { PartnerExpeditionCard } from './PartnerExpeditionCard';

interface CompletedExpeditionsTabProps {
  completedAssetDashExpeditions: AssetDashExpedition[];
  completedPartnerExpeditions: PartnerExpedition[];
}

export const CompletedExpeditionsTab: React.FC<
  CompletedExpeditionsTabProps
> = ({ completedAssetDashExpeditions, completedPartnerExpeditions }) => {
  return (
    <div className="space-y-2">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {completedAssetDashExpeditions.map(completedAssetDashExpedition => (
          <AssetdashExpeditionCard
            assetdashExpedition={completedAssetDashExpedition}
          />
        ))}
        {completedPartnerExpeditions.map(completedPartnerExpedition => (
          <PartnerExpeditionCard
            partnerExpedition={completedPartnerExpedition}
          />
        ))}
      </div>
    </div>
  );
};
