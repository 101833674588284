import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { AssetdashExpeditionCard } from './AssetdashExpeditionCard';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { useExpeditions } from '../../hooks/useExpeditions';

export const AssetdashExpeditionTagContainer: React.FC = () => {
  const navigate = useNavigate();
  const { tagId } = useParams();

  const { getAssetDashTagExpeditions, assetdashTagExpeditions } =
    useExpeditions();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (tagId) {
      loadAssetDashExpeditionTags();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId]);

  const loadAssetDashExpeditionTags = async () => {
    try {
      await getAssetDashTagExpeditions(tagId!);
    } catch (err) {
      navigate('/expeditions');
    }
    setIsLoading(false);
  };

  return (
    <div className="space-y-2">
      {!isLoading && assetdashTagExpeditions ? (
        <div className="space-y-2">
          <div className="text-2xl">{tagId}</div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {assetdashTagExpeditions.map(assetdashExpedition => (
              <AssetdashExpeditionCard
                assetdashExpedition={assetdashExpedition}
              />
            ))}
          </div>
        </div>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
    </div>
  );
};
