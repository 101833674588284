// vendor libraries
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

interface CreateManualWalletFormProps {
  setShowManualWallet: (show: boolean) => void;
}

export const CreateManualWalletForm: React.FC<CreateManualWalletFormProps> = ({
  setShowManualWallet,
}) => {
  const [walletName, setWalletName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = useState(false);
  const { addNewManualWallet } = usePortfolio();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleWalletNameChange = e => {
    const name = e.target.value;
    setWalletName(name);
    setIsValid(name.length > 0);
  };

  const createManualWallet = async () => {
    if (isValid) {
      setIsLoading(!isLoading);
      const response = await addNewManualWallet(walletName);
      setIsLoading(false);
      if (response.status === 200) {
        navigate(`/wallets/${response.data.user_wallet_id}`);
        toast.success('Wallet created');
      } else {
        toast.error('Error creating wallet');
      }
      setShowManualWallet(false);
    }
  };

  return (
    <div className="bg-white dark:bg-deals-card-top rounded-card-box px-6 py-4">
      <div className="space-y-4">
        <div>New Manual Wallet</div>
        <div className="space-y-4">
          <input
            ref={inputRef}
            type="text"
            className="w-full bg-primary-gray dark:bg-blue-dark rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
            placeholder="Enter Wallet Name"
            onChange={handleWalletNameChange}
          />
          <div className="space-x-4">
            <button
              disabled={!isValid}
              type="button"
              className={`h-12 w-32 py-4 px-8 leading-17 font-semibold text-sm rounded-xl ${
                isValid && !isLoading
                  ? 'bg-price-green'
                  : 'bg-primary-gray dark:bg-blue-dark'
              }`}
              onClick={createManualWallet}
            >
              <div className="flex justify-center">
                {isLoading ? (
                  <div className="loader w-full"></div>
                ) : (
                  <div>Submit</div>
                )}
              </div>
            </button>
            <button
              type="button"
              className="h-12 w-32 bg-primary-gray dark:bg-blue-dark py-4 px-8 leading-17 font-semibold text-sm rounded-xl"
              disabled={!isValid || isLoading}
              onClick={() => {
                setShowManualWallet(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
