export class NFTDetail {
  id?: string;
  description?: string;
  floorPriceNative?: number;
  floorPriceUSD?: number;
  topBidPriceNative?: number;
  topBidPriceUSD?: number;
  marketCapNative?: number;
  marketCapUSD?: number;
  totalListed?: number;
  totalSupply?: number;
  owners?: number;
  nftChain?: string;
  floorPriceNative1D?: number;
  floorPriceNative2D?: number;
  floorPriceNative3D?: number;
  floorPriceNative4D?: number;
  floorPriceNative5D?: number;
  floorPriceNative6D?: number;
  floorPriceNative7D?: number;
  volumeNative1D?: number;
  volumeNative2D?: number;
  volumeNative3D?: number;
  volumeNative4D?: number;
  volumeNative5D?: number;
  volumeNative6D?: number;
  volumeNative7D?: number;

  constructor(data?: Partial<NFTDetail>) {
    Object.assign(this, data);
  }

  static fromJSON(json: any): NFTDetail {
    return new NFTDetail({
      id: json['id'],
      description: json['description'],
      floorPriceNative: json['floor_price_native'],
      floorPriceUSD: json['floor_price_usd'],
      topBidPriceNative: json['top_bid_price_native'],
      topBidPriceUSD: json['top_bid_price_usd'],
      marketCapNative: json['market_cap_native'],
      marketCapUSD: json['market_cap_usd'],
      nftChain: json['nft_chain'],
      totalListed: json['total_listed'],
      totalSupply: json['total_supply'],
      volumeNative1D: json['volume_native_1d'],
      volumeNative2D: json['volume_native_2d'],
      volumeNative3D: json['volume_native_3d'],
      volumeNative4D: json['volume_native_4d'],
      volumeNative5D: json['volume_native_5d'],
      volumeNative6D: json['volume_native_6d'],
      volumeNative7D: json['volume_native_7d'],
      floorPriceNative1D: json['floor_price_native_1d'],
      floorPriceNative2D: json['floor_price_native_2d'],
      floorPriceNative3D: json['floor_price_native_3d'],
      floorPriceNative4D: json['floor_price_native_4d'],
      floorPriceNative5D: json['floor_price_native_5d'],
      floorPriceNative6D: json['floor_price_native_6d'],
      floorPriceNative7D: json['floor_price_native_7d'],
      owners: json['owners'],
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      description: this.description,
      volume_native_1d: this.volumeNative1D,
      volume_native_2d: this.volumeNative2D,
      volume_native_3d: this.volumeNative3D,
      volume_native_4d: this.volumeNative4D,
      volume_native_5d: this.volumeNative5D,
      volume_native_6d: this.volumeNative6D,
      volume_native_7d: this.volumeNative7D,
      floor_price_native: this.floorPriceNative,
      floor_price_usd: this.floorPriceUSD,
      floor_price_native_1d: this.floorPriceNative1D,
      floor_price_native_2d: this.floorPriceNative2D,
      floor_price_native_3d: this.floorPriceNative3D,
      floor_price_native_4d: this.floorPriceNative4D,
      floor_price_native_5d: this.floorPriceNative5D,
      floor_price_native_6d: this.floorPriceNative6D,
      floor_price_native_7d: this.floorPriceNative7D,
      top_bid_price_native: this.topBidPriceNative,
      top_bid_price_usd: this.topBidPriceUSD,
      market_cap_native: this.marketCapNative,
      market_cap_usd: this.marketCapUSD,
      nft_chain: this.nftChain,
      total_listed: this.totalListed,
      total_supply: this.totalSupply,
      owners: this.owners,
    };
  }

  copyWith(data: Partial<NFTDetail>): NFTDetail {
    return new NFTDetail({ ...this, ...data });
  }
}
