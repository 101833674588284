import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { AssetdashExpeditionDetailContainer } from '../../components/expeditions/AssetdashExpeditionDetailContainer';

export default function AssetdashExpeditionDetailPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO
        title={`AssetDash | AssetDash Expedition Detail`}
        lang="en_IE"
      />
      <div className="main-content mx-auto space-y-6">
        <AssetdashExpeditionDetailContainer />
      </div>
    </>
  );
}
