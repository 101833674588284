// vendor libraries
import { useTable } from 'react-table';

// components
import { ArrowButton } from '../Button/ArrowButton';
import { Holding } from '@/features/portfolio/models/Holding';

// types
import { TableComponentProps } from './types';

// recoil
import { useThemeState } from '@/recoil/theme';

export const LargeTable: React.FC<TableComponentProps<Holding>> = ({
  data,
  columns,
  handleSort,
  sortValue,
  sort,
  rowHandler,
}) => {
  const [theme] = useThemeState();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const itemsLength = rows?.length || 0;

  const defaultThColor = theme === 'light' ? '#A8A8A8' : '#D8D8D8';
  const defaultIconColor = theme === 'light' ? '#1F3840' : '#A8A8A8';

  const onRowClick = (assetTypeGroup: string, id: string) => {
    rowHandler && rowHandler(assetTypeGroup, id);
  };

  return (
    <div>
      <div className="full-page-table-container bg-white dark:bg-search-bg/50 border-b-1 dark:border-b-tabs-bg text-black dark:text-white rounded-t-20 overflow-hidden">
        <table
          {...getTableProps()}
          className="w-full flex flex-col border-collapse"
        >
          <thead
            style={{ cursor: 'initial' }}
            className="pt-4 pl-8 md:pl-60p pb-3 pr-4 md:pr-27 border-b border-learn-course-card"
          >
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="flex items-center"
              >
                {headerGroup.headers.map(column => {
                  const { id } = column;

                  return (
                    <th
                      {...column.getHeaderProps()}
                      onClick={() => {
                        !column.disableSortBy && handleSort(id);
                      }}
                      style={{
                        cursor: !column.disableSortBy ? 'pointer' : 'initial',
                      }}
                      className="text-gray-text dark:text-border-bg align-middle text-xs flex items-center p-0"
                    >
                      {(!column.disableSortBy && (
                        <ArrowButton
                          buttonStyle={{
                            color:
                              (id === sortValue && '#F5AC6E') || defaultThColor,
                            fontSize: '12px',
                            fontWeight: id === sortValue ? '600' : '400',
                          }}
                          direction={
                            id === sortValue
                              ? sort === ':desc'
                                ? 'down'
                                : 'up'
                              : 'down'
                          }
                          iconStyle={{
                            color:
                              (id === sortValue && '#F5AC6E') ||
                              defaultIconColor,
                            opacity: (id === sortValue && '1') || '0.2',
                          }}
                        >
                          {column.render('Header')}
                        </ArrowButton>
                      )) ||
                        column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            id="fullPageTable"
            className=" scrollbar p-0 max-h-730 h-full scroller"
          >
            {rows.map(row => {
              prepareRow(row);
              const holding = row.original;
              const asset = holding.asset;
              const { assetTypeGroup, id } = asset;

              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-primary-gray dark:bg-dark hover:bg-short-description dark:hover:bg-deals-card cursor-pointer transition-bg linear w-full h-16 font-normal text-sm leading-17 flex items-center pl-2 md:pl-6 pr-2 md:pr-27 border-b border-learn-course-card"
                  onClick={() => {
                    onRowClick(assetTypeGroup!, id!);
                  }}
                >
                  {row.cells.map(cell => {
                    const { id } = cell.column;

                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          fontWeight: id === sortValue ? '600' : '400',
                        }}
                        className="p-0 align-middle"
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        className="flex items-center justify-end font-bold text-xs leading-4 p-6 
        bg-white dark:bg-search-bg/50 rounded-b-20 text-black dark:text-gray-text"
      >
        {`${itemsLength} items in the list`}
      </div>
    </div>
  );
};
