// vendor libraries
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

// components
import { WalletsList } from './wallets_list/WalletsList';
import { WalletDetailsContainer } from './wallet_details/WalletDetailsContainer';
import { CreateManualWalletForm } from './CreateManualWalletForm';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

export const WalletsContainer = () => {
  const { walletId } = useParams();
  const { load, wallets, isLoading } = usePortfolio();
  const location = useLocation();
  const query = location.search;

  useEffect(() => {
    if (query) {
      const myURL = new URL(window.location.href);
      const urlWalletId = myURL.searchParams.get('walletId');
      const urlStatus = myURL.searchParams.get('status');
      const urlMessage = myURL.searchParams.get('message');

      load({ checkCache: false });

      if (urlMessage !== '' && urlWalletId !== '' && urlStatus === 'success') {
        window.history.replaceState(null, '', `/wallets`);
      } else if (urlStatus && urlMessage) {
        toast.error(urlMessage);
        window.history.replaceState(null, '', `/wallets`);
      } else window.history.replaceState(null, '', `/wallets`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const [showManualWallet, setShowManualWallet] = useState(false);

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 gap-x-0 md:gap-x-6 gap-y-4 md:gap-y-0 overflow-scroll-y">
      <div className="col-span-1 md:col-span-2 lg:col-span-1">
        <WalletsList setShowManualWallet={setShowManualWallet} />
      </div>

      {showManualWallet ? (
        <div className="col-span-2">
          <CreateManualWalletForm setShowManualWallet={setShowManualWallet} />
        </div>
      ) : walletId ? (
        <WalletDetailsContainer />
      ) : isLoading ? (
        <SkeletonLoader height={600} width={780} />
      ) : wallets.length === 0 ? (
        <div className="col-span-2 mt-30">
          <div className="space-y-6 text-center">
            <div className="text-xl font-semibold leading-6">
              Connect Your Investment
            </div>
            <div className="text-black dark:text-border-bg font-semibold leading-4.75">
              This is your hub to manage your connected Investments on
              AssetDash.
            </div>
            <div className="text-black dark:text-border-bg font-semibold leading-4.75">
              Select the "New wallet +" to start pasting crypto addresses and
              connecting accounts!
            </div>
          </div>
        </div>
      ) : (
        <div className="col-span-1 md:col-span-2 bg-white dark:bg-deals-card-top rounded-card-box px-6 py-4 h-20 ">
          <div className="leading-17 text-sm text-black dark:text-border-bg h-12 flex items-center">
            Select wallet to see details
          </div>
        </div>
      )}
    </div>
  );
};
