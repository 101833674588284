import { useNavigate } from 'react-router-dom';

// enums
import { HoldingTypeGroupInfo } from '@/features/portfolio/holdings/enums/HoldingTypeGroup';

// utils
import { formatNumber, getNumberColor } from '@/utils/number';

// hooks
import { useAuth } from '@/hooks/useAuth';

// models
import { HoldingCategory } from '@/features/portfolio/holdings/models/HoldingCategory';

interface PortfolioHoldingCategoryListItemProps {
  holdingCategory: HoldingCategory;
}

export const PortfolioHoldingCategoryListItem: React.FC<
  PortfolioHoldingCategoryListItemProps
> = ({ holdingCategory }) => {
  const { convertUSDCurrency } = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      `/portfolio/${HoldingTypeGroupInfo.apiValue[holdingCategory.type]}`,
    );
  };

  return (
    <div
      className="flex items-center justify-between w-full rounded-8 px-2 pr-4 cursor-pointer border-l-8 hover:bg-border-bg dark:hover:bg-blue-dark text-right"
      style={{ borderColor: HoldingTypeGroupInfo.color[holdingCategory.type] }}
      onClick={handleClick}
    >
      <div className="text-left w-full md:w-1/3">
        <span className="text-sm font-bold leading-4 text-left">
          {HoldingTypeGroupInfo.title[holdingCategory.type]}
        </span>
      </div>
      <div className="flex justify-end w-2/3 space-x-4 md:space-x-8">
        <span className="text-black dark:text-gray-text text-sm leading-4 w-20 md:w-1/4 text-right">
          {`${formatNumber(holdingCategory.allocation)}%`}
        </span>
        <span className="text-sm leading-4 w-24 md:w-1/4 text-right">
          {convertUSDCurrency(holdingCategory.value)}
        </span>
        <span
          className="font-semibold text-sm leading-4 w-20 md:w-1/4 text-right"
          style={{ color: getNumberColor(holdingCategory.dayReturn) }}
        >
          {`${holdingCategory.dayReturn >= 0 ? '+' : ''}${formatNumber(
            holdingCategory.dayReturn,
          )}%`}
        </span>
      </div>
    </div>
  );
};
