import { axiosAssetDashApi } from './api';

export interface VerifyWalletFormValues {
  user_wallet_id: string;
  wallet_type: string;
  address: string;
  signature?: string;
  hashed_address?: string;
  access_token?: string;
}

export const postVerifyWallet = (formValues: VerifyWalletFormValues) => {
  return axiosAssetDashApi.post(
    `/api/api_v4/wallets/verification/verify_wallet`,
    formValues,
  );
};

export const createManualWallet = (name: string) => {
  return axiosAssetDashApi.post('/api/api_v4/wallets/create_manual_wallet/', {
    name,
  });
};

export const deleteWallet = (walletId: string) => {
  return axiosAssetDashApi.delete(`/api/api_v4/wallets/${walletId}`);
};

export const updateWallet = (walletId: string, name: string) => {
  return axiosAssetDashApi.put(`/api/api_v4/wallets/${walletId}`, { name });
};

export const syncManualWallet = (walletId: string) => {
  return axiosAssetDashApi.post(`/api/api_v4/wallets/sync/${walletId}`);
};

export const setPrizeWallet = (walletType: string, walletId: string) => {
  return axiosAssetDashApi.post(`/api/api_v4/wallets/set_prize_wallet/`, {
    wallet_type: walletType,
    user_wallet_id: walletId,
  });
};

export interface AddManualAssetFormValues {
  assetType: string;
  name?: string;
  price?: number;
  quantity?: number;
  percentOwnership?: number;
  companyValuation?: number;
  value?: number;
  assetId?: string;
  manualWalletId?: string;
}

export const addManualAsset = ({
  assetType,
  name,
  price,
  quantity,
  percentOwnership,
  companyValuation,
  value,
  assetId,
  manualWalletId,
}: AddManualAssetFormValues) => {
  const params: any = {
    asset_type: assetType,
  };

  if (assetId) params.asset_id = assetId;
  if (name) params.manual_asset_name = name;
  if (price) params.price = price.toString();
  if (quantity != null) params.quantity = quantity.toString();
  if (value != null) params.value = value.toString();
  if (percentOwnership != null)
    params.percent_ownership = percentOwnership.toString();
  if (companyValuation != null)
    params.company_valuation = companyValuation.toString();
  if (manualWalletId) params.wallet_id = manualWalletId.toString();

  return axiosAssetDashApi.post(
    '/api/api_v4/wallets/manual/create_holding',
    params,
  );
};

export interface EditManualAssetFormValues {
  holdingId: string;
  name?: string;
  price?: number;
  quantity?: number;
  percentOwnership?: number;
  companyValuation?: number;
  value?: number;
  assetId?: string;
  manualWalletId?: string;
}

export const editManualAsset = ({
  holdingId,
  name,
  price,
  quantity,
  percentOwnership,
  companyValuation,
  value,
  assetId,
  manualWalletId,
}: EditManualAssetFormValues) => {
  const params: any = {};
  if (assetId) params.asset_id = assetId;
  if (name) params.manual_asset_name = name;
  if (price) params.price = price.toString();
  if (quantity != null) params.quantity = quantity.toString();
  if (value != null) params.value = value.toString();
  if (percentOwnership != null)
    params.percent_ownership = percentOwnership.toString();
  if (companyValuation != null)
    params.company_valuation = companyValuation.toString();
  if (manualWalletId) params.wallet_id = manualWalletId.toString();

  return axiosAssetDashApi.put(
    `/api/api_v4/wallets/manual/update_holding/${holdingId}`,
    params,
  );
};

export const deleteManualHolding = (
  manualWalletId: string,
  holdingId: string,
) => {
  const params: any = { holding_id: holdingId };
  if (manualWalletId) params.wallet_id = manualWalletId;
  return axiosAssetDashApi.delete(`/api/api_v4/wallets/manual/delete_holding`, {
    data: params,
  });
};
