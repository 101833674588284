// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useParams } from 'react-router-dom';

// hooks
import { usePortfolio } from '../../hooks/usePortfolio';

// components
import { NFTCard } from '../../components/nft/NFTCard';
import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';

export default function PortfolioNFTCollectionPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { assetId } = useParams();
  const { assetIdToWalletNFTItemsMap, isLoading } = usePortfolio();

  const walletNFTItems = assetIdToWalletNFTItemsMap.get(assetId!) || [];

  return (
    <>
      <SuperSEO title="AssetDash | NFT Gallery" lang="en_IE" />
      <div className="mx-auto main-content space-y-10">
        {!isLoading && walletNFTItems.length > 0 ? (
          <div className="bg-white dark:bg-deals-card-top rounded-card-box h-full flex flex-col">
            <div className="mx-5 py-4 px-4 md:px-6 flex justify-between items-center">
              <span className="text-tabs-bg dark:text-white font-semibold text-xl leading-card-title flex items-center space-x-2">
                <div>{walletNFTItems[0].nftItem?.collectionName}</div>
                <div className="text-price-green text-xs leading-17 bg-primary-gray dark:bg-tabs-bg w-max px-4p py-3p rounded-2xl mt-1">
                  {walletNFTItems[0].chain}
                </div>
              </span>
            </div>
            <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 md:gap-8 px-2 md:px-4 py-4">
              {walletNFTItems.map(item => (
                <NFTCard key={item.id} item={item} />
              ))}
            </div>
          </div>
        ) : (
          <SkeletonLoader height={500} />
        )}
      </div>
    </>
  );
}
