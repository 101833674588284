import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from './Tooltip/Tooltip';

interface ProgressBarProps {
  bgcolor: string;
  progress?: number;
  height: string;
  showInfoIcon?: boolean;
  index?: number;
  multiplier?: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  bgcolor,
  progress = 0,
  height,
  showInfoIcon = false,
  index = 1,
  multiplier = 3.33,
}) => {
  const Parentdiv = {
    height: height,
    display: 'flex',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
  };

  const Childdiv = {
    height: '8px',
    width: `${progress * multiplier}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
  };

  const progresstext = {
    padding: 10,
    color: '#21CE99',
    fontWeight: 900,
  };

  return (
    <div className="flex items-center justify-between">
      <div style={Parentdiv} className="w-full">
        <div style={Childdiv}></div>
      </div>
      <p style={progresstext}>{`${progress ? progress : 0}%`}</p>
      {index === 0 && showInfoIcon && (
        <Tooltip
          headerText={'Info'}
          text={
            'This progress bar tracks the amount of holders currently on AssetDash. Reach 30% to unlock DashDeals access for your entire community!'
          }
          position="bottom"
        >
          <InformationCircleIcon className="h-4 w-4" />
        </Tooltip>
      )}
    </div>
  );
};
