import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { PartnerExpeditionCard } from './PartnerExpeditionCard';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { useExpeditions } from '../../hooks/useExpeditions';

export const PartnerExpeditionTagContainer: React.FC = () => {
  const navigate = useNavigate();
  const { tagId } = useParams();

  const { getPartnerTagExpeditions, partnerTagExpeditions } = useExpeditions();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (tagId) {
      loadPartnerExpeditionTags();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId]);

  const loadPartnerExpeditionTags = async () => {
    try {
      await getPartnerTagExpeditions(tagId!);
    } catch (err) {
      navigate('/expeditions');
    }
    setIsLoading(false);
  };

  return (
    <div>
      {!isLoading && partnerTagExpeditions ? (
        <div className="space-y-2">
          <div className="text-2xl">{tagId}</div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {partnerTagExpeditions.map(partnerTagExpedition => (
              <PartnerExpeditionCard partnerExpedition={partnerTagExpedition} />
            ))}
          </div>
        </div>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
    </div>
  );
};
