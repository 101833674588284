import { useRef, useEffect, useState } from 'react';
import { useInbox } from '@/hooks/useInbox';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { InboxUserMessage } from './InboxUserMessage';
import { hexToRGBA } from '@/utils/theme';
import { InboxMessageDetailsCard } from './InboxMessageDetailsCard';
import { useScreenWidth } from '@/hooks/useScreenWidth';
import { useNavigate } from 'react-router-dom';

export const InboxContainer = () => {
  const {
    inboxMessages,
    inboxTags,
    inboxMessageCanLoadMore,
    isLoading,
    isMessagesLoading,
    getUserInboxUserMessagesNext,
    load,
  } = useInbox();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  const isMessageVisible = screenWidth >= 1280;

  const observer = useRef<IntersectionObserver | null>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && inboxMessageCanLoadMore) {
        getUserInboxUserMessagesNext();
      }
    });

    if (lastMessageRef.current) {
      observer.current.observe(lastMessageRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [isLoading, inboxMessageCanLoadMore, getUserInboxUserMessagesNext]);

  const [messageId, setMessageId] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const filteredMessages =
    selectedCategory && inboxMessages
      ? inboxMessages.filter(message => message?.tag?.name === selectedCategory)
      : inboxMessages;

  const onMessageClick = (messageId: string) => {
    setMessageId(messageId);
    if (!isMessageVisible) {
      navigate('/inbox/' + messageId);
    }
  };

  return (
    <div className="w-full space-y-4">
      <h1 className="text-32 font-semibold text-2xl leading-none ml-3">
        Inbox
      </h1>
      <div className="mx-2 overflow-x-auto">
        <div className="flex flex-row space-x-1 md:space-x-4 min-w-full">
          <button
            className="text-xs sm:text-sm flex p-2 items-center justify-center rounded-xl bg-new-gray dark:bg-white text-white dark:text-black md:p-6 flex-shrink-0"
            onClick={() => setSelectedCategory(null)} // Reset filter
          >
            All
          </button>
          {inboxTags &&
            inboxTags.map((tag, index) => (
              <button
                key={index}
                className="text-xs sm:text-sm p-2 flex-grow h-full rounded-3xl md:p-6 flex-shrink-0"
                style={{
                  backgroundColor: hexToRGBA(
                    tag.color ? `#${tag.color}` : '#0085FF',
                    0.09,
                  ),
                  borderColor: tag.color ? `#${tag.color}` : '#0085FF',
                  borderWidth: 1,
                }}
                onClick={() => setSelectedCategory(tag.name)}
              >
                {tag.name}
              </button>
            ))}
        </div>
      </div>

      <div className="flex flex-col md:flex-row space-x-4">
        <div className="w-full flex flex-col space-y-4">
          <div className="card flex flex-row items-center justify-between px-7 py-4">
            <span className="text-2xl">Messages</span>
            {/* <button className="flex flex-row items-center hover:bg-gray-800 hover:dark:opacity-60">
              <DocumentCheckIcon className="w-4 h-4 text-price-green mr-2" />
              <span className="text-lg">Mark all as read</span>
            </button> */}
          </div>
          {isMessagesLoading ? (
            <SkeletonLoader height={300} />
          ) : filteredMessages?.length === 0 ? (
            <div className="flex justify-center">No Messages</div>
          ) : (
            <div className="card p-2 h-screen overflow-y-auto scroller space-y-4">
              {filteredMessages?.map((message, index) => (
                <div
                  key={message.id}
                  ref={
                    index === filteredMessages.length - 1
                      ? lastMessageRef
                      : null
                  }
                >
                  <InboxUserMessage
                    inboxMessage={message}
                    onMessageClick={onMessageClick}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {isMessageVisible && (
          <div className="w-full">
            {messageId ? (
              <InboxMessageDetailsCard messageId={messageId} />
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-lg">Select a message to view its details</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
