// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

// recoil
import { useThemeState } from '@/recoil/theme';

interface GiveawayConfirmBuyPassesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  coins: number;
  passes: number;
}

export const GiveawayConfirmBuyPassesModal: React.FC<
  GiveawayConfirmBuyPassesModalProps
> = ({ isOpen, closeModal, onSubmit, coins, passes }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const handleSubmit = async () => {
    closeModal();
    onSubmit();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${textColor} ${background}`}
              >
                <Dialog.Title as="h3" className={`text-xl text-left leading-6`}>
                  <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-semibold ">
                      {' '}
                      Buy Giveaway Passes
                    </h2>

                    <button
                      onClick={closeModal}
                      className="text-sm text-price-red"
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Title>
                <div className="mt-2">
                  <p className={`text-md text-left text-gray-300`}>
                    You are about to spend{' '}
                    <span className="text-price-green font-semibold">
                      {coins}
                    </span>{' '}
                    coins to purchase{' '}
                    <span className="text-price-green font-semibold">
                      {passes} pass{passes === 1 ? '' : 'es'}
                    </span>
                    .
                  </p>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex justify-center">
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium
                      focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-price-green text-black cursor-pointer`}
                    onClick={handleSubmit}
                  >
                    <div>Buy Now</div>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
