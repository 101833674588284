import { MyAuctionBidLeaderboard, MyAuctionBidLeaderboardJson} from './AuctionDetail';

interface AuctionMyBidsResponseJson {
  auction_my_bids?: MyAuctionBidLeaderboardJson[];
}

export class AuctionMyBidsResponse {
  auctionMyBids?: MyAuctionBidLeaderboard[];

  constructor(auctionMyBids?: MyAuctionBidLeaderboard[]) {
    this.auctionMyBids = auctionMyBids;
  }

  static fromJson(json: AuctionMyBidsResponseJson): AuctionMyBidsResponse {
    const auctionMyBids = json.auction_my_bids
      ? json.auction_my_bids.map(MyAuctionBidLeaderboard.fromJson)
      : undefined;

    return new AuctionMyBidsResponse(auctionMyBids);
  }

  toJson(): AuctionMyBidsResponseJson {
    return {
      auction_my_bids: this.auctionMyBids?.map(bid => bid.toJson()),
    };
  }
}
