import React, { useEffect, useState } from 'react';

// theme
import { AppColors } from '@/features/core/theming/colors';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';
import { CustomNFTPrice } from '@/features/portfolio/models/CustomNFTPrice';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

// components
import { SvgIcon } from '../common/Icon/SvgIcon';
import { NFTEditCostBasisModal } from './NFTEditCostBasisModal';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// utils
import { getNumberColor } from '@/utils/number';

interface NFTItemCostBasisCardProps {
  walletNFTItem?: WalletNFTItem;
  isNative?: boolean;
}

export const NFTItemCostBasisCard: React.FC<NFTItemCostBasisCardProps> = ({
  walletNFTItem,
  isNative = false,
}) => {
  const { convertUSDCurrency } = useAuth();
  const { customNFTPricesMap, isLoading } = usePortfolio();
  const [customNFTPrice, setCustomNFTPrice] = useState<CustomNFTPrice>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (walletNFTItem && customNFTPricesMap.has(walletNFTItem.nftItemId!)) {
      setCustomNFTPrice(customNFTPricesMap.get(walletNFTItem.nftItemId!)!);
    }
  }, [walletNFTItem, customNFTPricesMap, isLoading]);

  const { costBasisUsd, costBasisNative } = customNFTPrice || {};

  const costBasis = isNative ? costBasisNative : costBasisUsd;
  const displayPrice = isNative
    ? walletNFTItem?.priceNative
    : walletNFTItem?.priceUsd;
  const convertCurrency = isNative
    ? (value: number | undefined) =>
        value !== undefined
          ? `${value.toFixed(2)} ${walletNFTItem?.chain}`
          : '--.--'
    : convertUSDCurrency;
  const title = isNative
    ? `Cost Basis ${walletNFTItem?.chain}`
    : 'Cost Basis USD';

  return (
    <div className="card">
      {!isLoading ? (
        <>
          <div className="flex text-lg font-bold justify-between items-center pt-5 px-4 md:px-6">
            {title}
          </div>
          <div className="p-5 grid grid-cols-3 divide-x-2 divide-border-bg dark:divide-tabs-bg">
            <div className="pr-6 space-y-2 flex flex-col items-center">
              <div className="font-semibold text-sm leading-4 text-bg-tabs">
                <div>Purchase Price</div>
              </div>
              <div className="flex space-x-1">
                {costBasis ? (
                  <div className="text-sm text-price-green">
                    {convertCurrency(costBasis)}
                  </div>
                ) : (
                  <div className="text-sm">--.--</div>
                )}
                <div className="cursor-pointer" onClick={openModal}>
                  <SvgIcon
                    iconFileName="edit"
                    color={AppColors.primary()}
                    size={{ height: 15, width: 15 }}
                  />
                </div>
              </div>
            </div>
            <div className="px-6 space-y-2 flex flex-col items-center">
              <div className="font-semibold text-sm leading-4 text-bg-tabs">
                Current Price
              </div>
              <div className="space-x-2 flex flex-col items-center">
                <div>{convertCurrency(displayPrice!)}</div>
              </div>
            </div>
            <div className="px-6 space-y-2 flex flex-col items-center">
              <div className="font-semibold text-sm leading-4 text-bg-tabs">
                P/L
              </div>

              {costBasis ? (
                <div
                  style={{
                    color: getNumberColor(displayPrice! - costBasis),
                  }}
                >
                  {displayPrice! - costBasis > 0 ? '+' : ''}
                  {convertCurrency(displayPrice! - costBasis)}
                </div>
              ) : (
                <div>--.--</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <SkeletonLoader height={180} />
      )}
      <NFTEditCostBasisModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        walletNFTItem={walletNFTItem}
        isNative={isNative}
      />
    </div>
  );
};
