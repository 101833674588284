type JsonMap = { [key: string]: any };

export class InboxMessageTagsResponse {
  gameTags?: InboxMessageTag[];

  constructor(gameTags?: InboxMessageTag[]) {
    this.gameTags = gameTags;
  }

  static fromJson(json: JsonMap): InboxMessageTagsResponse {
    const gameTags: InboxMessageTag[] = [];
    if (json['tags']) {
      json['tags'].forEach((v: JsonMap) => {
        gameTags.push(InboxMessageTag.fromJson(v));
      });
    }
    return new InboxMessageTagsResponse(gameTags);
  }

  toJson(): JsonMap {
    const data: JsonMap = {};
    if (this.gameTags) {
      data['tags'] = this.gameTags.map(v => v.toJson());
    }
    return data;
  }
}

export class InboxMessageTag {
  id: string;
  name: string;
  color?: string;
  constructor(id: string, name: string, color?: string) {
    this.id = id;
    this.name = name;
    this.color = color;
  }

  static fromJson(json: JsonMap): InboxMessageTag {
    return new InboxMessageTag(json['id'], json['name'], json['color']);
  }

  toJson(): JsonMap {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
    };
  }
}
