import {
  ChevronRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import DashDropClaimModalStandard from '@/components/v2/market/deals/DashDropClaimModalStandard';
import DashDropGiveawayModal from '@/components/v2/market/deals/DashDropGiveawayModal';
import CurrentPrizeModal from '@/components/v2/giveaway/CurrentPrizeModal';
import CurrentPrizes from '@/components/v2/giveaway/CurrentPrizes';
import GetLuckyOpeningScreen from '@/components/v2/giveaway/GetLuckyOpeningScreen';
import PastGiveawayModal from '@/components/v2/giveaway/PastGiveawayModal';
import RaffleTicketModal from '@/components/v2/giveaway/RaffleTicketModal';
import YourPrizeModal from '@/components/v2/giveaway/YourPrizeModal';
import { Tooltip } from '@/components/v2/common/Tooltip/Tooltip';
import FortuneCookieDetails from '@/components/v2/giveaway/FortuneCookieResultModal';
import { datediff, parseCustomDate } from '../utils/date';

import { useDealsLegacy } from '../hooks/useDealsLegacy';

import { getUserMemberCenter } from '../api/user';

export default function GiveawayPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const navigate = useNavigate();
  const {
    fetchWeeklyRaffles,
    raffles,
    claimRaffleTickets,
    showPartyPopper,
    setShowPartyPopper,
    showFortuneCookieOpenings,
    fetchYourPrizes,
    yourPrizes,
    pastResults,
    fetchPastResults,
    claimYourPrize,
    fetchRaffleCenterData,
    openFortuneCookieModal,
    setOpenFortuneCookieModal,
    raffleCenterData,
  } = useDealsLegacy();

  const [memberCenterData, setMemberCenterData] = useState<any>({});

  const [startDate, setStartDate] = useState('');
  const [dueDays, setDueDays] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openCurrentPrizeModal, setOpenCurrentPrizeModal] = useState(false);
  const [openRaffleTicketModal, setOpenRaffleTicketModal] = useState(false);
  const [openYourPrizesModal, setOpenYourPrizesModal] = useState(false);
  const [openPastGiveawayModal, setOpenPastGiveawayModal] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);

  const fetchMemberCenterData = async () => {
    const response = await getUserMemberCenter();
    setMemberCenterData(response.data.member_center);
  };

  const handleSeeAll = id => {
    if (id === 'current_prizes') {
      setOpenCurrentPrizeModal(true);
    } else if (id === 'your_tickets') {
      setOpenRaffleTicketModal(true);
    } else if (id === 'your_prizes') {
      setOpenYourPrizesModal(true);
    } else if (id === 'past_giveaways') {
      setOpenPastGiveawayModal(true);
    }
  };

  const tooltipMessage = {
    member:
      'Source determines how you received this ticket for the giveaway. This ticket was awarded to you based on your AssetDash Membership Tier. Boost your membership tier by connecting more investments to receive more tickets per week.',
    fortune_cookie:
      'Source determines how you received this ticket for the giveaway. This ticket was awarded to you from opening a Fortune Cookie this week. You can open your Fortune Cookie once every 24 hours for a chance to win additional tickets.',
    reward:
      'Source determines how you received this ticket for the giveaway. This ticket was awarded to you from opening a Fortune Cookie this week. You can open your Fortune Cookie once every 24 hours for a chance to win additional tickets.',
    gift: 'Source determines how you received this ticket for the giveaway. This ticket was gifted to you by the AssetDash team. Enjoy!',
    cash_back:
      'You received these extra tickets as part of a Cash Back Purchase reward. Enjoy!',
    boost:
      'This ticket has been awarded to you because a partner has given you a Gold Boost which comes with an extra giveaway ticket per week.',
    learn_reward: 'You received this ticket for completing a Learn course.',
    bonus:
      'You received this ticket as a bonus for collecting another 100 DashDrop tickets.',
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (raffles?.raffle) {
      setStartDate(moment(raffles?.raffle?.date).format('MMM DD'));

      // const xyz = moment(new Date(raffles?.raffle?.date)).fromNow();

      if (raffles?.raffle?.date) {
        const today = new Date();
        const dd = today.getDate();

        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        const finalDate =
          yyyy + '-' + (mm < 10 ? '0' + mm.toString() : mm) + '-' + dd;
        const dateDifference = datediff(
          parseCustomDate(raffles?.raffle?.date),
          parseCustomDate(finalDate),
        );

        if (finalDate === raffles?.raffle?.date) {
          setDueDays('(Today)');
        } else if (dateDifference >= 0)
          setDueDays(
            '(In ' +
              dateDifference +
              `${dateDifference === 1 ? ' day)' : ' days)'}`,
          );
      }
    }
  }, [raffles]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    await fetchWeeklyRaffles();
    await fetchMemberCenterData();
    await fetchYourPrizes();
    await fetchPastResults();
    await fetchRaffleCenterData();
    setIsLoading(false);
  };

  const handleClaimYourPrize = async id => {
    const resp = await claimYourPrize(id);

    if (resp === 'success') {
      await fetchYourPrizes();
      toast.success('Prize Claimed Successful!');
    }
  };

  const { raffle, raffle_tickets, winning_percent, claimed_tickets } =
    raffles || '';

  useEffect(() => {
    if (showPartyPopper) {
      setTimeout(() => {
        setShowPartyPopper(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPartyPopper]);

  const { prizes, image_url } = raffle || {};

  const getPastResultDate = date => {
    const resp = moment(date).format('MM/DD/YYYY');
    return resp;
  };

  const { total_tickets, next_ticket_bonus } = raffleCenterData || {};

  const faq = [
    {
      question: 'What is a DashDrop?',
      answer:
        'DashDrop is a weekly giveaway exclusive for our members. Each week you will receive tickets to the DashDrop according to your membership tier. Check the "Winning Tickets" button at the end of the week to see if you won the giveaway.',
    },
    {
      question: 'How does it Work?',
      answer:
        'Every week you will be distributed tickets, claim them, and see if one of your ticket numbers is the winner once the Giveaway is complete.',
    },
  ];
  const [open, setOpen] = useState(false);

  return (
    <div>
      <SuperSEO title={`AssetDash | Giveaway Center`} lang="en_IE" />
      <div className="mx-auto main-content space-y-6">
        {!isLoading ? (
          <div className="bg-white dark:bg-black rounded-12 p-4 md:p-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10">
              <div>
                <img
                  src={image_url}
                  alt="giveaway banner"
                  className="h-auto w-full"
                />
              </div>
              <div className="space-y-4">
                <div>
                  <div className="flex items-center space-x-2 mb-10x">
                    <p className="text-8 font-bold">{raffle?.title}</p>
                    <span
                      onClick={() => {
                        //open modal
                        analytics.track('giveaway_how_it_works_clicked', {
                          platform: 'web',
                        });
                        setOpen(true);
                      }}
                      className="cursor-pointer"
                    >
                      <InformationCircleIcon className="h-4 w-4" />
                    </span>
                  </div>
                  <div className="font-bold text-price-red flex items-center space-x-2">
                    <p className="bg-giveaway-box py-2 px-5 rounded-full border-giveaway-border text-sm">
                      End{' '}
                    </p>
                    <p className="text-lg">
                      {startDate} <span className="capitalize">{dueDays}</span>
                    </p>
                  </div>
                </div>
                <p className="text-xl leading-9">{raffle?.description}</p>
                <div>
                  {claimed_tickets !== raffle_tickets?.length ||
                  memberCenterData?.member_status === 'standard' ? (
                    <button
                      className="px-8 py-2 bg-price-green rounded-8 text-black mt-4"
                      onClick={async () => {
                        if (memberCenterData?.member_status === 'standard') {
                          setOpenClaimModal(true);
                        } else {
                          await claimRaffleTickets();
                          await fetchWeeklyRaffles();
                          analytics.track(
                            'member_center_claim_all_ticket_click',
                            {
                              platform: 'web',
                            },
                          );
                        }
                      }}
                    >
                      Claim Your Tickets
                    </button>
                  ) : (
                    <div className="px-8 py-2 rounded-8 bg-gray-text dark:bg-input-box w-max mt-4">
                      Claimed
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          isLoading && (
            <div className="grid grid-cols-2 gap-4">
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div className="space-y-8">
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
              </div>
            </div>
          )
        )}

        <div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 border border-input-box border-solid rounded-xl px-4 md:px-8 py-4 text-center">
            <div className="space-y-2">
              <p className="text-gray-text text-base">Your claimed tickets</p>
              <p className="font-bold text-center text-xl">{claimed_tickets}</p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-text text-base">Chance of winning</p>
              <p className="font-bold text-center text-xl">
                {winning_percent % 2 !== 0
                  ? winning_percent?.toFixed(2)
                  : winning_percent}
                %
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-text text-base">All-Time Tickets</p>
              <p className="font-bold text-center text-xl">{total_tickets}</p>
            </div>
            <div className="space-y-2">
              <div className="text-gray-text text-base flex items-center space-x-2 justify-center">
                <p>Next Bonus</p>

                <Tooltip
                  text="You will get bonus tickets when your All-Time Tickets total surpasses this number. Keep claiming tickets to hit your bonus!"
                  position="bottom"
                >
                  <InformationCircleIcon className="h-4 w-4" />
                </Tooltip>
              </div>
              <p className="font-bold text-center text-xl">
                {next_ticket_bonus}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-8">
            {prizes && prizes.length > 0 && (
              <p className="text-8 font-bold">Current Prizes</p>
            )}
            {prizes && prizes.length > 0 && (
              <div
                className="mt-4"
                onClick={() => {
                  handleSeeAll('current_prizes');
                }}
              >
                <div className="cursor-pointer flex space-x-2 items-center py-3 px-10 bg-white dark:bg-black rounded-2xl w-max ml-auto">
                  <p>See All</p>
                  <ChevronRightIcon className="h-4 w-4" />
                </div>
              </div>
            )}
          </div>

          {prizes && prizes.length > 0 ? (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <CurrentPrizes prizes={prizes} />
              </div>
            </div>
          ) : (
            isLoading && (
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
              </div>
            )
          )}
        </div>

        <div className="flex items-center justify-between">
          <p className="font-bold text-8">Your Tickets</p>
          {raffle_tickets && raffle_tickets.length > 0 && (
            <div
              className="mt-4"
              onClick={() => {
                handleSeeAll('your_tickets');
              }}
            >
              <div className="cursor-pointer flex space-x-2 items-center py-3 px-10 bg-white dark:bg-black rounded-2xl w-max ml-auto">
                <p>See All</p>
                <ChevronRightIcon className="h-4 w-4" />
              </div>
            </div>
          )}
        </div>

        {raffle_tickets && raffle_tickets.length > 0 ? (
          <div>
            <div className="grid grid-cols-1 gap-4">
              {raffle_tickets?.slice(0, 3).map((raffle_ticket, index) => {
                return (
                  <div key={raffle_ticket?.id}>
                    <div className="p-4 flex justify-between items-center rounded-2xl bg-white dark:bg-black space-y-2 text-left">
                      <div className="flex flex-col-reverse md:flex-row space-x-4 items-center">
                        <div>
                          <img
                            src={raffle_ticket?.image_url}
                            alt="raffle"
                            className="w-max"
                            style={{ height: '70px' }}
                          />
                        </div>
                        <div className="flex space-x-2 items-center">
                          <p
                            className={`text-black dark:text-white text-sm md:text-xl capitalize`}
                          >
                            {raffle_ticket?.title}
                          </p>
                          {raffle_ticket?.info && (
                            <Tooltip
                              text={raffle_ticket?.info}
                              position="bottom"
                            >
                              <InformationCircleIcon className="h-4 w-4" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <button
                        onClick={async () => {
                          if (raffle_ticket.claimed) {
                            navigator.clipboard.writeText(
                              raffle_ticket.ticket_code,
                            );
                            analytics.track(
                              'member_center_giveaway_ticket_copy',
                              {
                                ticket_code: raffle_ticket.ticket_code,
                                platform: 'web',
                              },
                            );
                            toast.success(
                              'Giveaway ticket copied successfully',
                            );
                          } else {
                            if (
                              memberCenterData?.member_status === 'standard'
                            ) {
                              setOpenClaimModal(true);
                            } else {
                              await claimRaffleTickets();
                              await fetchWeeklyRaffles();
                              analytics.track(
                                'member_center_claim_giveaway_ticket',
                                {
                                  id: raffle_ticket?.id,
                                  platform: 'web',
                                },
                              );
                            }
                          }
                        }}
                        className={`border-2 w-max text-black dark:text-white border-price-green px-4 md:px-8 h-max py-2 rounded-8 min-w-36 md:min-w-42`}
                      >
                        {raffle_ticket.claimed
                          ? raffle_ticket.ticket_code
                          : 'Claim'}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : isLoading ? (
          <div className="grid grid-cols-1 gap-4">
            <div
              className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
              style={{ height: '100px' }}
            ></div>
            <div
              className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
              style={{ height: '100px' }}
            ></div>
            <div
              className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
              style={{ height: '100px' }}
            ></div>
            <div
              className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
              style={{ height: '100px' }}
            ></div>
          </div>
        ) : (
          <div className="text-center border border-input-box border-solid rounded-br-xl rounded-bl-xl p-4">
            <img
              src="/assets/images/no-tickets.png"
              alt="Giveaway Tickets"
              className="m-auto h-25p"
            />
            <p className="text-black dark:text-gray-text text-lg mt-8">
              No Giveaway Tickets Available
            </p>
          </div>
        )}

        {yourPrizes && yourPrizes.length > 0 ? (
          <div>
            <div className="flex items-center justify-between mb-8">
              <p className="text-8 font-bold">Your Prizes</p>
              <div
                className="mt-4"
                onClick={() => {
                  handleSeeAll('your_prizes');
                }}
              >
                <div className="cursor-pointer flex space-x-2 items-center py-3 px-10 bg-white dark:bg-black rounded-2xl w-max ml-auto">
                  <p>See All</p>
                  <ChevronRightIcon className="h-4 w-4" />
                </div>
              </div>
            </div>
            <div className="space-y-2">
              {yourPrizes.map(yourPrize => {
                const { id, claimed, raffle_prize, raffle_ticket } = yourPrize;
                return (
                  <div
                    key={yourPrize.id}
                    className="bg-white dark:bg-black p-4 md:p-10 rounded-2xl flex flex-col md:flex-row items-center justify-between"
                  >
                    <div className="flex items-center space-x-4">
                      <img
                        src={raffle_prize.image_url}
                        className="h-16 w-16 "
                        alt="prize"
                      />
                      <div className="space-y-2">
                        <p className="text-8 add-overflow-dot max-w-60 md:max-w-80 lg:max-w-770">
                          {raffle_prize.title}
                        </p>
                        <p className="text-xl font-bold text-gray-text">
                          Winning Ticket
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0">
                      {!claimed ? (
                        <button
                          className="py-3 px-4 font-bold min-w-36 rounded-2xl text-center text-price-green border-price-green border-solid border-1"
                          onClick={() => {
                            handleClaimYourPrize(id);
                          }}
                        >
                          Claim
                        </button>
                      ) : (
                        <div className="bg-primary-dark dark:bg-input-box text-white dark:text-white font-bold py-3 px-4 min-w-36 rounded-2xl text-center">
                          {raffle_ticket.ticket_code}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          isLoading && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
            </div>
          )
        )}

        {pastResults && pastResults.length > 0 ? (
          <div className="">
            <div className="flex items-center justify-between mb-8">
              <p className="text-8 font-bold">Past Giveaways</p>
              <div
                className="mt-4"
                onClick={() => {
                  handleSeeAll('past_giveaways');
                }}
              >
                <div className="cursor-pointer flex space-x-2 items-center py-3 px-10 bg-white dark:bg-black rounded-2xl w-max ml-auto">
                  <p>See All</p>
                  <ChevronRightIcon className="h-4 w-4" />
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
                {pastResults?.slice(0, 6).map(pastResult => {
                  return (
                    <div
                      className="bg-white dark:bg-black rounded-2xl p-4 md:p-10 grid grid-cols-2 gap-4 cursor-pointer"
                      key={pastResult.id}
                      onClick={() => {
                        analytics.track('giveaway_center_past_giveaway_click', {
                          id: pastResult.id,
                          platform: 'web',
                        });
                        navigate(`/giveaways/${pastResult.id}`);
                      }}
                    >
                      <div className="space-y-4">
                        <p className="text-xl md:text-8 font-bold leading-10">
                          {pastResult.title}
                        </p>
                        <p className="text-gray-text text-lg md:text-xl">
                          Date: {getPastResultDate(pastResult.date)}
                        </p>
                      </div>
                      <div>
                        <img
                          src={pastResult.image_url}
                          className="h-120"
                          alt="past giveaways"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          isLoading && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '200px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '200px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '200px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '200px' }}
              ></div>
            </div>
          )
        )}
      </div>

      {showFortuneCookieOpenings && <GetLuckyOpeningScreen />}
      <FortuneCookieDetails
        open={openFortuneCookieModal}
        setOpen={setOpenFortuneCookieModal}
      />

      {openCurrentPrizeModal && (
        <CurrentPrizeModal
          open={openCurrentPrizeModal}
          setOpen={setOpenCurrentPrizeModal}
          prizes={prizes}
          handleSeeAll={handleSeeAll}
          isLoading={isLoading}
        />
      )}
      {openRaffleTicketModal && (
        <RaffleTicketModal
          open={openRaffleTicketModal}
          setOpen={setOpenRaffleTicketModal}
          raffleTickets={raffle_tickets}
          message={tooltipMessage}
        />
      )}
      {openYourPrizesModal && (
        <YourPrizeModal
          open={openYourPrizesModal}
          setOpen={setOpenYourPrizesModal}
          yourPrizes={yourPrizes}
          handleClaimYourPrize={handleClaimYourPrize}
        />
      )}
      {openPastGiveawayModal && (
        <PastGiveawayModal
          open={openPastGiveawayModal}
          setOpen={setOpenPastGiveawayModal}
          pastResults={pastResults}
          getPastResultDate={getPastResultDate}
        />
      )}
      {open && (
        <DashDropGiveawayModal open={open} setOpen={setOpen} faqs={faq} />
      )}
      <DashDropClaimModalStandard
        open={openClaimModal}
        setOpen={setOpenClaimModal}
      />
    </div>
  );
}
