import { useState } from 'react';
import { toast } from 'react-hot-toast';

// components
import { ConfirmAuctionBidModal } from './ConfirmAuctionBidModal';

// models
import { Auction } from '@/features/market/auctions/models/Auctions';
import { MyAuctionBidLeaderboard } from '@/features/market/auctions/models/AuctionDetail';
import { AuctionBidLeaderboard } from '@/features/market/auctions/models/AuctionBidLeaderboards';
import { CoinCenter } from '@/features/coins/models/CoinCenter';

// utils
import { formatNumber } from '@/utils/number';

// hooks
import { useAuctions } from '@/hooks/useAuctions';

interface AuctionPlaceBidCardProps {
  auction: Auction;
  myAuctionBidLeaderboard?: MyAuctionBidLeaderboard;
  topAuctionBidLeaderboard?: AuctionBidLeaderboard;
  coinCenter: CoinCenter;
}

export const AuctionPlaceBidCard: React.FC<AuctionPlaceBidCardProps> = ({
  auction,
  myAuctionBidLeaderboard,
  topAuctionBidLeaderboard,
  coinCenter,
}) => {
  const { placeAuctionBid } = useAuctions();
  const [coinsAmount, setCoinsAmount] = useState('');
  const [confirmBuyPassesModalOpen, setConfirmBuyPassesModalOpen] =
    useState(false);

  const handleCoinsAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setCoinsAmount(value);
    }
  };

  const handleBid = async () => {
    try {
      await placeAuctionBid(auction.id, Number(coinsAmount));
      toast.success('Super auction bid placed!');
      setCoinsAmount('');
    } catch {}
  };

  return (
    <>
      <div className="space-y-2">
        <p className="text-left text-2xl">Place Bid</p>
        <div className="bg-white dark:bg-deals-card-top py-4 px-8 rounded-xl space-y-4 dark:text-gray-300">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div className="flex space-x-1 items-center">
                <div className="mt-1 min-w-6">
                  <img
                    className="rounded-full"
                    src="/assets/icons/coin.png"
                    alt="Coin"
                    height={20}
                    width={20}
                  />
                </div>
                <div className="font-semibold">AssetDash Coins</div>
              </div>
              <div className="text-xs text-fade-text-light dark:text-fade-text-dark">
                Minimum 100 Coins
              </div>
            </div>
            <input
              className="flex justify-between px-5 bg-gray-300 dark:bg-black p-3 rounded-xl w-full"
              type="number"
              placeholder="Enter quantity"
              value={coinsAmount}
              onChange={handleCoinsAmountChange}
            ></input>
            <div className="border-t border-border-bg"></div>
            <div className="flex flex-col">
              <div className="flex justify-between text-element-text-light dark:text-element-text-dark">
                <div>Your Liquid Coins</div>
                <div className="font-bold">
                  {formatNumber(coinCenter?.liquidCoins || 0, 0)} coins
                </div>
              </div>
              <div className="flex justify-between text-element-text-light dark:text-element-text-dark">
                <div>Your Current Bid</div>
                <div className="font-bold">
                  {formatNumber(myAuctionBidLeaderboard?.numCoins || 0, 0)}{' '}
                  coins
                </div>
              </div>
              <div className="flex justify-between text-element-text-light dark:text-element-text-dark">
                <div>Top Bid</div>
                <div className="font-bold">
                  {formatNumber(topAuctionBidLeaderboard?.numCoins || 0, 0)}{' '}
                  coins
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-evenly space-x-4 text-right">
            <button
              type="button"
              className={`whitespace-nowrap font-bold w-60 py-4 px-8 leading-17 text-md rounded-xl bg-gray-300 dark:bg-tabs-bg text-price-green`}
              onClick={() => setCoinsAmount('')}
            >
              Clear
            </button>
            <button
              type="button"
              disabled={
                Number(coinsAmount) < 100 ||
                (!auction.isOpenPhase() && !auction.isLockedPhase())
              }
              className={`whitespace-nowrap font-bold w-60 py-4 px-8 transition delay-100 ease-in leading-17 text-md rounded-xl text-black ${
                Number(coinsAmount) >= 100 &&
                (auction.isOpenPhase() || auction.isLockedPhase())
                  ? 'bg-price-green'
                  : `bg-gray-200 dark:bg-tabs-bg`
              }`}
              onClick={() => {
                if (
                  Number(coinsAmount) >= 100 &&
                  (auction.isOpenPhase() || auction.isLockedPhase())
                ) {
                  setConfirmBuyPassesModalOpen(true);
                }
              }}
            >
              {auction.isOpenPhase() || auction.isLockedPhase()
                ? 'Place Bid'
                : 'Closed'}
            </button>
          </div>
        </div>
      </div>
      <ConfirmAuctionBidModal
        isOpen={confirmBuyPassesModalOpen}
        closeModal={() => setConfirmBuyPassesModalOpen(false)}
        coins={Number(coinsAmount)}
        onSubmit={handleBid}
      />
    </>
  );
};
