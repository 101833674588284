// vendor libraries
import { useEffect, useState } from 'react';

// theme
import { AppColors } from '@/features/core/theming/colors';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';
import { CustomNFTPrice } from '@/features/portfolio/models/CustomNFTPrice';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

// components
import { SvgIcon } from '../common/Icon/SvgIcon';
import { NFTEditPriceModal } from './NFTEditPriceModal';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

interface NFTItemMultiplierCardProps {
  walletNFTItem?: WalletNFTItem;
}
export const NFTItemMultiplierCard: React.FC<NFTItemMultiplierCardProps> = ({
  walletNFTItem,
}) => {
  const { convertUSDCurrency } = useAuth();
  const { customNFTPricesMap, isLoading } = usePortfolio();
  const [customNFTPrice, setCustomNFTPrice] = useState<CustomNFTPrice>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (walletNFTItem && customNFTPricesMap.has(walletNFTItem?.nftItem?.id!)) {
      setCustomNFTPrice(customNFTPricesMap.get(walletNFTItem?.nftItem?.id!)!);
    }
  }, [walletNFTItem, customNFTPricesMap]);

  const { floorMultiplier } = customNFTPrice || {};

  return (
    <div className="card">
      {!isLoading ? (
        <>
          <div className="flex text-lg font-bold justify-between items-center pt-5 px-4 md:px-6">
            AssetDash Multipler
          </div>
          <div className="p-5 grid grid-cols-3 divide-x-2 divide-border-bg dark:divide-tabs-bg">
            <div className="pr-6 space-y-2 flex flex-col items-center">
              <div className="font-semibold text-sm leading-4 text-bg-tabs">
                Floor Price
              </div>
              <div className="text-sm">
                {convertUSDCurrency(walletNFTItem?.floorPriceUsd!)}
              </div>
            </div>
            <div className="px-6 space-y-2 flex flex-col items-center">
              <div className="font-normal text-sm leading-4 text-bg-tabs">
                <div className="flex space-x-1 font-semibold text-sm leading-4 text-bg-tabs">
                  <div>Multipler</div>
                </div>
              </div>
              <div className="space-x-2 flex items-center">
                <div>{floorMultiplier || '--.--'}</div>
                <div className="cursor-pointer" onClick={openModal}>
                  <SvgIcon
                    iconFileName="edit"
                    color={AppColors.primary()}
                    size={{ height: 15, width: 15 }}
                  />
                </div>
              </div>
            </div>
            <div className="px-6 space-y-2 flex flex-col items-center">
              <div className="font-semibold text-sm leading-4 text-bg-tabs">
                Final Price
              </div>
              <div>
                {floorMultiplier ? (
                  <div className="text-price-green">
                    {convertUSDCurrency(
                      walletNFTItem?.floorPriceUsd! * floorMultiplier,
                    )}
                  </div>
                ) : (
                  '--.--'
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SkeletonLoader height={80} />
      )}
      <NFTEditPriceModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        walletNFTItem={walletNFTItem}
        defaultTab="multiplier"
      />
    </div>
  );
};
