import { toast } from 'react-hot-toast';
import { CheckCircle } from '../common/Icon/CheckCircle';
import { ConversionIcon } from '../common/Icon/ConversionIcon';
import { CopyIcon } from '../common/Icon/CopyIcon';

export default function MyShareSection({ myShare }) {
  const { course, num_completed, num_conversions, num_tickets, share_url } =
    myShare || null;

  return (
    <div key={course.id}>
      <div
        className="mt-8 bg-toggle-bg dark:bg-black divide-y-1 divide-gray-text flex flex-col p-4 py-4 md:p-5 md:py-5 rounded-2xl space-y-4"
        key={course.id}
      >
        <div
          className="space-y-4 pt-4"
          // onClick={() => handleStart(course)}
        >
          <div className="flex space-x-4 lg:space-x-6">
            <img
              src={course.image_url}
              className="h-105 w-105 object-cover rounded-full backdrop-contrast-50"
              alt="course"
            />
            <div className="space-y-2 m-auto">
              <p className="text-base md:text-xl font-bold lineClamp-2">
                {course.title}
              </p>
              <p className="lineClamp-1 text-">{course.partner_name}</p>
              <button
                className="bg-price-green flex space-x-2 items-center py-1 px-2 rounded-lg text-black font-semibold"
                onClick={() => {
                  navigator.clipboard.writeText(share_url);
                  toast.success('Share link copied!');
                }}
              >
                <CopyIcon color="black" />
                <p>My Link</p>
              </button>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <p className="pt-2">My Stats</p>
          <div className="text-xs flex space-x-2 overflow-scroll scroller">
            <div className="flex space-x-2 items-center border-1 border-price-green px-2 py-0.5 text-price-green rounded-full w-max">
              <CheckCircle />
              <p className="flex space-x-1 flex-nowrap">
                <span className="font-bold">{num_completed}</span>{' '}
                <span>Completions</span>
              </p>
            </div>
            <div className="flex space-x-2 items-center border-1 border-price-green px-2 py-0.5 text-price-green rounded-full w-max">
              <ConversionIcon />
              <p className="flex space-x-1 flex-nowrap">
                <span className="font-bold">{num_conversions}</span>{' '}
                <span>Conversions</span>
              </p>
            </div>
            <div className="flex space-x-2 items-center border-1 border-price-green px-2 py-0.5 text-price-green rounded-full w-max">
              <img
                src="/assets/images/ticket.png"
                alt="ticket"
                className="h-4 w-4"
              />
              <div className="flex space-x-1 flex-nowrap pr-4">
                <span className="font-bold">{num_tickets}</span>{' '}
                <span>Tickets</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
