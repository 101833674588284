export class PartnerExpeditionTagsResponse {
  expeditionTags?: PartnerExpeditionTag[] | null;

  constructor({
    expeditionTags,
  }: {
    expeditionTags?: PartnerExpeditionTag[] | null;
  }) {
    this.expeditionTags = expeditionTags || null;
  }

  static fromJson(json: any): PartnerExpeditionTagsResponse {
    return new PartnerExpeditionTagsResponse({
      expeditionTags: json['tags']
        ? json['tags'].map((v: any) => PartnerExpeditionTag.fromJson(v))
        : null,
    });
  }

  toJson(): any {
    return {
      tags: this.expeditionTags
        ? this.expeditionTags.map(v => v.toJson())
        : null,
    };
  }
}

export class PartnerExpeditionTag {
  id: string;
  name: string;

  constructor({ id, name }: { id: string; name: string }) {
    this.id = id;
    this.name = name;
  }

  static fromJson(json: any): PartnerExpeditionTag {
    return new PartnerExpeditionTag({
      id: json['id'],
      name: json['name'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
