export function getShortenedAddress(address: string): string {
  const maxLength = 20;
  const frontChars = 6;
  const backChars = 4;

  if (address.length < maxLength) return address;

  return (
    address.substring(0, frontChars) +
    '...' +
    address.substring(address.length - backChars)
  );
}
