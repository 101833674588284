// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { ArcadeGameDetailsContainer } from '../../components/games/arcade-games/ArcadeGameDetailsContainer';

export default function ArcadeGamesDetailsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SuperSEO title={`AssetDash | Arcade Game Details`} lang="en_IE" />
      <ArcadeGameDetailsContainer />
    </div>
  );
}
