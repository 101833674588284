// vendor libraries
import { useEffect, useState } from 'react';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// components
import { OptionsTab } from '../common/Tab/OptionsTab';
import { useNavigate } from 'react-router-dom';
import { NFTTransaction } from '@/features/portfolio/models/NFTTransaction';
import { getAssetNFTListings, getAssetNFTTransactions } from '@/api/assets';
import NFTListingItem from '../nft/NFTListingItem';
import NFTTransactionItem from '../nft/NFTTransactionItem';
import { NFTListing } from '@/features/portfolio/models/NFTLIsting';

interface AssetNFTActivityCardProps {
  assetId: any;
}

export const AssetNFTActivityCard: React.FC<AssetNFTActivityCardProps> = ({
  assetId,
}) => {
  const navigate = useNavigate();
  const { hiddenAssetIds, isLoading } = usePortfolio();

  const [activeTab, setActiveTab] = useState<string>('liveListings');
  const [activityTabs] = useState<{ [key: string]: string }>({
    liveListings: 'Live Listings',
    liveSales: 'Live Sales',
  });

  const [nftListings, setNftListings] = useState<NFTListing[] | undefined>();
  const [nftTransactions, setNftTransactions] = useState<
    NFTTransaction[] | undefined
  >();

  const handleTabClick = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (assetId) {
      if ([...hiddenAssetIds].includes(assetId)) {
        navigate('/portfolio');
      }
      fetchNFTListingsAndTransactions(assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const fetchNFTListingsAndTransactions = async (assetId: string) => {
    try {
      const response = await getAssetNFTListings(assetId);

      const nftListings = response.data.listings.map((listing: any) =>
        NFTListing.fromJSON(listing),
      );
      setNftListings(nftListings);

      const resp_transactions = await getAssetNFTTransactions(assetId);
      const nftTransactions = resp_transactions.data.transactions.map(
        (transaction: any) => NFTTransaction.fromJSON(transaction),
      );
      setNftTransactions(nftTransactions);
    } catch (error) {
      console.error('Failed to fetch NFT listings and transactions', error);
    }
  };

  const loading = isLoading || !assetId;

  return (
    <div
      className={`card py-4 px-4 md:px-6 space-y-4 divide-y divide-gray-text dark:divide-blue-dark ${!loading ? 'mt-0' : ''}`}
    >
      <div className="relative">
        {!loading ? (
          <div className="absolute top-0 left-0 w-full flex items-center justify-center">
            <OptionsTab
              tabs={activityTabs}
              activeTab={activeTab}
              onClick={handleTabClick}
            />
          </div>
        ) : (
          <SkeletonLoader height={64} />
        )}
        <div>
          {loading ? (
            <SkeletonLoader height={240} />
          ) : (
            <div className="h-[480px]">
              {activeTab === 'liveListings' && (
                <div className="pt-14">
                  {nftListings && nftListings.length > 0 ? (
                    <ul className="h-[380px] grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-1 overflow-y-auto scrollbar">
                      {nftListings.map((listing, index) => (
                        <li key={index} className="mb-4">
                          <NFTListingItem listing={listing} />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No listings available.</p>
                  )}
                </div>
              )}
              {activeTab === 'liveSales' && (
                <div className="pt-14">
                  {nftTransactions && nftTransactions.length > 0 ? (
                    <ul className="h-[380px] overflow-y-auto scrollbar">
                      {nftTransactions.map((transaction, index) => (
                        <li key={index} className="mb-4">
                          <NFTTransactionItem transaction={transaction} />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No sales available.</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetNFTActivityCard;
