import { UserIcon } from '@heroicons/react/24/solid';
import moment from 'moment';

import { GiveawayPassPurchase } from '@/api/games';

interface GiveawayActivityTabProps {
  giveawayPassPurchases: GiveawayPassPurchase[];
}

moment.updateLocale('en', {
  relativeTime: {
    s: 'a minute',
  },
});

export const GiveawayActivityTab: React.FC<GiveawayActivityTabProps> = ({
  giveawayPassPurchases,
}) => {
  return (
    <div>
      {giveawayPassPurchases.map((giveawayPassPurchase, i) => (
        <div className="flex justify-between p-4 border-b border-gray-text">
          <div className="flex items-center justify-between w-2/3">
            <div className="bg-white rounded-xl w-6 h-6 text-center text-black">
              {i + 1}
            </div>
            <div className="flex items-center space-x-1">
              <UserIcon height="20" width="20" />
              <div>{giveawayPassPurchase.public_user_id}</div>
            </div>
            <div className="text-price-green">
              {giveawayPassPurchase.num_passes} pass
              {giveawayPassPurchase.num_passes > 1 && 'es'}
            </div>
          </div>
          <div className="flex justify-end w-1/3 whitespace-nowrap text-ellipsis">
            {moment(giveawayPassPurchase.created + 'Z').fromNow()}
          </div>
        </div>
      ))}
    </div>
  );
};
