import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import AnswerQuestion from '../components/learn/AnswerQuestion';
import { ArrowIcon } from '../components/common/Icon/ArrowIcon';
import useDevice from '../hooks/useDevice';
import { useLearn } from '../hooks/useLearn';

import { useThemeState } from '../recoil/theme.ts';

export default function LearnCourseStart() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchCourseDetails, courseDetails, activeStep, setActiveStep } =
    useLearn();
  const { isMobile: device, isIpad: ipad } = useDevice();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [theme] = useThemeState();

  const { steps, questions, reward, cta } = courseDetails?.course || '';

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
      fetchCourseDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const NextSlideButton = ({ onClick }) => {
    const [theme] = useThemeState();

    return (
      <div
        className="max-w-40p w-10 h-10 bg-white rounded-full absolute top-1/2 right-0 transform -translate-y-1/2 flex items-center justify-center cursor-pointer z-20"
        onClick={e => {
          if (currentIndex < steps.length - 1) {
            setCurrentIndex(currentIndex + 1);
            onClick();
          } else {
            analytics.track('learn_course_step_switch_to', {
              name: 'questions',
            });
            setActiveStep('questions');
          }
        }}
      >
        <ArrowIcon
          direction="right"
          iconStyle={{ color: theme === 'light' ? '#000000' : '#21CE99' }}
        />
      </div>
    );
  };

  const PrevSlideButton = ({ onClick }) => {
    const [theme] = useThemeState();

    return (
      <div className="bg-black">
        <div
          className="max-w-40p h-10 w-10 bg-white rounded-full absolute top-1/2 left-0 transform -translate-y-1/2 flex items-center justify-center cursor-pointer z-20"
          onClick={e => {
            if (currentIndex !== 1) {
              setCurrentIndex(currentIndex - 1);
              onClick();
            }
          }}
        >
          <ArrowIcon
            direction="left"
            iconStyle={{ color: theme === 'light' ? '#000000' : '#21CE99' }}
          />
        </div>
      </div>
    );
  };

  const getStyledDots = dots => {
    return (
      <ul
        style={{
          display: 'contents',
        }}
      >
        {dots &&
          dots.map((dot, index) => {
            return (
              <li key={index} className="inline-flex align-middle">
                {dot.props.children}
              </li>
            );
          })}
      </ul>
    );
  };

  const setIndexes = next => {
    setCurrentIndex(next);
  };

  const styledDot = index => {
    return (
      <div
        style={{
          width: index === currentIndex ? '22px' : '18px',
          height: '6px',
          background:
            index === currentIndex
              ? '#21CE99'
              : theme === 'light'
                ? '#D8D8D8'
                : '#49494D',
          borderRadius: '3px',
          marginLeft: '8px',
          marginTop: '20px',
          cursor: 'pointer',
        }}
      />
    );
  };
  return (
    <div className="mx-auto main-learn-content">
      <div className="px-0 my-0 md:my-8 course-details-box">
        {activeStep === 'steps' && (
          <div className="py-4">
            <Slider
              autoplay={false}
              slidesToShow={1}
              slidesToScroll={1}
              arrows={true}
              dots={true}
              infinite={false}
              initialSlide={0}
              speed={800}
              nextArrow={<NextSlideButton />}
              prevArrow={<PrevSlideButton />}
              swipeToSlide={true}
              beforeChange={(_prev, next) => setIndexes(next)}
              customPaging={index => styledDot(index)}
              appendDots={dots => getStyledDots(dots)}
              centerMode={device ? true : false}
              centerPadding={device ? (ipad ? '0px' : '0px') : '20px'}
            >
              {steps &&
                steps.length > 0 &&
                steps.map(step => {
                  return (
                    <div key={step.id} className="px-2 lg:px-8">
                      <div className="space-y-4">
                        <p className="text-2xl text-center font-bold">
                          {step.text}
                        </p>
                        <img
                          src={step.image_url}
                          alt={step.title}
                          className="mx-auto w-full rounded-12 h-40vh object-contain"
                        />
                      </div>

                      {/* <div className="text-right mt-4 md:mt-2">
                        {currentIndex === steps.length - 1 && (
                          <button
                            className="myBtnSmall"
                            onClick={() => setActiveStep("questions")}
                          >
                            Move to questions
                          </button>
                        )}
                      </div> */}

                      {/* <div className="mt-10"></div> */}
                    </div>
                  );
                })}
            </Slider>
          </div>
        )}
        {activeStep === 'questions' && (
          <div className="h-full lg:h-70vh">
            <AnswerQuestion id={id} questions={questions} />
          </div>
        )}
        {activeStep === 'successScreen' && (
          <div className="text-center space-y-4">
            <p className="text-3xl font-bold">Congratulations!</p>
            <img
              src="/assets/images/congrats_course.png"
              alt="congrats screen"
              className="mx-auto w-full rounded-12 h-40vh object-contain"
            />
            <p className="text-xl">
              You have{' '}
              {courseDetails?.course_user?.status === 'completed'
                ? 'already'
                : ''}{' '}
              received{' '}
              {reward.cash_back > 0 && (
                <p>
                  <span className="text-main-yellow font-bold">
                    ${reward.cash_back}
                  </span>
                  <span className="text-main-yellow"> Cash Back</span> and{' '}
                </p>
              )}
              {reward.coins > 0 && (
                <p>
                  <span className="text-price-greenfont-bold">
                    {reward.coins}
                  </span>
                  <span className="text-price-green"> Coins</span> and{' '}
                </p>
              )}
              <span className="text-price-green font-bold">
                {reward.tickets}
              </span>{' '}
              <span className="text-price-green">
                Giveaway
                {reward.tickets > 1 ? ' Tickets' : ' Ticket'}
              </span>{' '}
              for completing this course!
            </p>

            <button
              className="py-3 w-full bg-price-green rounded-12 text-black"
              onClick={() => {
                if (cta) {
                  // window.open(cta.cta_url);
                  setActiveStep('ctaScreen');
                  analytics.track('learn_course_step_switch_to', {
                    name: 'ctaScreen',
                  });
                } else {
                  navigate('/learn');
                }
              }}
            >
              Continue
            </button>
          </div>
        )}
        {activeStep === 'ctaScreen' && cta && (
          <div className="space-y-8 h-full flex flex-col justify-between">
            <div className="space-y-4">
              <p className="text-2xl text-center font-bold">{cta.text}</p>
              <img
                src={cta.image_url}
                alt={cta.title}
                className="mx-auto w-full rounded-12 h-40vh object-contain"
              />
            </div>
            <button
              className="py-3 w-full bg-price-green rounded-12 text-black"
              onClick={() => {
                if (cta) {
                  window.open(cta.cta_url);
                  navigate('/learn');
                }
              }}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
