// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

//
import { RewardsContainer } from '@/components/v2/rewards/RewardsContainer';

export default function RewardsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title={`AssetDash | Rewards`} lang="en_IE" />
      <RewardsContainer />
    </div>
  );
}
