// PartnerRewardsContainer.tsx
import React, { useEffect } from 'react';
import { useRewards } from '@/hooks/useRewards';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { useNavigate } from 'react-router-dom';

export const PartnerRewardsContainer: React.FC = () => {
  const { isLoading, loadPartnerRewards, partnerRewards } = useRewards();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await loadPartnerRewards();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">💧 My Airdrops & Points</h2>
      </div>
      <p className="mt-2">
        Track all of your points and airdrop allocations on partner platforms.
      </p>
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : partnerRewards.length === 0 ? (
        <div className="flex justify-center mt-4">No Airdrops or Points</div>
      ) : (
        <div className="mt-4 space-y-2 divide-y divide-dark-background">
          {partnerRewards.map((reward, index) => (
            <div
              key={index}
              onClick={() => {
                navigate(`/rewards/${reward.id}`);
              }}
              className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors duration-200 cursor-pointer"
            >
              <div className="flex items-center">
                <img
                  src={reward.imageUrl}
                  className="w-8 h-8 bg-gray-600 rounded-md"
                  alt=""
                />
                <div className="ml-4">
                  <p className="text-sm">{reward.name}</p>
                  <p className="text-sm text-gray-400">{reward.type}</p>
                </div>
              </div>
              <p className="text-sm text-gray-400">{reward.rewardUnits ?? 0}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
