const defaultNFTUrl: string =
  'https://storage.googleapis.com/assetdash-prod-images/asset_types/nft.png';

export class NFTItem {
  id: string;
  name: string;
  url: string;
  chain: string;
  collectionName: string;
  assetId?: string;
  collectionId?: string;
  thumbnailUrl?: string;
  rarityRank?: number;
  nftItemDetail?: NftItemDetail;
  nftItemTraits?: NftItemTraits;

  constructor(data?: {
    id?: string;
    name?: string;
    url?: string;
    chain?: string;
    collectionName?: string;
    nftItemDetail?: NftItemDetail;
    assetId?: string;
    collectionId?: string;
    thumbnailUrl?: string;
    rarityRank?: number;
    nftItemTraits?: NftItemTraits;
  }) {
    this.id = data?.id || '';
    this.name = data?.name || '';
    this.url = data?.url || defaultNFTUrl;
    this.chain = data?.chain || '';
    this.collectionName = data?.collectionName || '';
    this.assetId = data?.assetId;
    this.collectionId = data?.collectionId;
    this.thumbnailUrl = data?.thumbnailUrl;
    this.rarityRank = data?.rarityRank;
    this.nftItemDetail = data?.nftItemDetail;
    this.nftItemTraits = data?.nftItemTraits;
  }

  static fromJSON(json: any): NFTItem {
    return new NFTItem({
      id: json['id'],
      name: json['name'] || 'Unknown',
      chain: json['nft_chain'],
      url: json['nft_url'] || defaultNFTUrl,
      assetId: json['asset_id'],
      collectionName: json['collection_name'] || 'Unknown',
      collectionId: json['nft_collection_id'],
      thumbnailUrl: json['nft_thumbnail_url'],
      rarityRank: json['rarity_rank'],
      nftItemDetail: json['nft_item']
        ? NftItemDetail.fromJSON(json['nft_item'])
        : undefined,
      nftItemTraits: json['traits']
        ? NftItemTraits.fromJSON(json['traits'])
        : undefined,
    });
  }

  toJSON(): { [key: string]: any } {
    return {
      id: this.id,
    };
  }

  getThumbnailUrl(): string {
    return this.thumbnailUrl || this.url;
  }
}

export class NftItemDetail {
  name?: string;
  nftChain?: string;
  nftUrl?: string;
  nftThumbnailUrl?: string;
  collectionName?: string;
  nftItemPriceEstimates?: NftItemPriceEstimates[];

  constructor(data?: {
    name?: string;
    nftChain?: string;
    nftUrl?: string;
    nftThumbnailUrl?: string;
    collectionName?: string;
    nftItemPriceEstimates?: NftItemPriceEstimates[];
  }) {
    this.name = data?.name;
    this.nftChain = data?.nftChain;
    this.nftUrl = data?.nftUrl;
    this.nftThumbnailUrl = data?.nftThumbnailUrl;
    this.collectionName = data?.collectionName;
    this.nftItemPriceEstimates = data?.nftItemPriceEstimates;
  }

  static fromJSON(json: any): NftItemDetail {
    const nftItemDetail = new NftItemDetail({
      name: json['name'],
      nftChain: json['nft_chain'],
      nftUrl: json['nft_url'],
      nftThumbnailUrl: json['nft_thumbnail_url'],
      collectionName: json['collection_name'],
    });
    if (json['nft_item_price_estimates']) {
      nftItemDetail.nftItemPriceEstimates = json[
        'nft_item_price_estimates'
      ].map((v: any) => NftItemPriceEstimates.fromJSON(v));
    }
    return nftItemDetail;
  }

  toJSON(): any {
    const data: any = {};
    data['name'] = this.name;
    data['nft_chain'] = this.nftChain;
    data['nft_url'] = this.nftUrl;
    data['nft_thumbnail_url'] = this.nftThumbnailUrl;
    data['collection_name'] = this.collectionName;
    if (this.nftItemPriceEstimates) {
      data['nft_item_price_estimates'] = this.nftItemPriceEstimates.map(v =>
        v.toJSON(),
      );
    }
    return data;
  }
}

export class NftItemPriceEstimates {
  id?: string;
  nftItemId?: string;
  priceEstimateService?: string;
  priceNative?: number;
  priceUsd?: number;
  serviceLabel?: string;
  serviceInfo?: string;
  serviceLogo?: string;
  verified?: boolean;

  constructor(data?: {
    id?: string;
    nftItemId?: string;
    priceEstimateService?: string;
    priceNative?: number;
    priceUsd?: number;
    serviceLabel?: string;
    serviceInfo?: string;
    serviceLogo?: string;
    verified?: boolean;
  }) {
    this.id = data?.id;
    this.nftItemId = data?.nftItemId;
    this.priceEstimateService = data?.priceEstimateService;
    this.priceNative = data?.priceNative;
    this.priceUsd = data?.priceUsd;
    this.serviceLabel = data?.serviceLabel;
    this.serviceInfo = data?.serviceInfo;
    this.serviceLogo = data?.serviceLogo;
    this.verified = data?.verified;
  }

  static fromJSON(json: any): NftItemPriceEstimates {
    return new NftItemPriceEstimates({
      id: json['id'],
      nftItemId: json['nft_item_id'],
      priceEstimateService: json['price_estimate_service'],
      priceNative: json['price_native'],
      priceUsd: json['price_usd'],
      serviceLabel: json['service_label'],
      serviceInfo: json['service_info'],
      serviceLogo: json['service_logo'],
      verified: json['verified'],
    });
  }

  toJSON(): any {
    const data: any = {};
    data['id'] = this.id;
    data['nft_item_id'] = this.nftItemId;
    data['price_estimate_service'] = this.priceEstimateService;
    data['price_native'] = this.priceNative;
    data['price_usd'] = this.priceUsd;
    data['service_label'] = this.serviceLabel;
    data['service_info'] = this.serviceInfo;
    data['service_logo'] = this.serviceLogo;
    data['verified'] = this.verified;
    return data;
  }
}

export class NftItemTraits {
  traits?: NftItemTrait[];

  constructor(data?: { traits?: NftItemTrait[] }) {
    this.traits = data?.traits;
  }

  static fromJSON(json: any): NftItemTraits {
    const nftItemTraits = new NftItemTraits();
    if (json['traits']) {
      nftItemTraits.traits = json['traits'].map((v: any) =>
        NftItemTrait.fromJSON(v),
      );
    }
    return nftItemTraits;
  }

  toJSON(): any {
    const data: any = {};
    if (this.traits) {
      data['traits'] = this.traits.map(v => v.toJSON());
    }
    return data;
  }
}

export class NftItemTrait {
  type?: string;
  value?: string;
  displayType?: string;
  floorPriceNative?: number;
  rarity?: number;

  constructor(data?: {
    type?: string;
    value?: string;
    displayType?: string;
    floorPriceNative?: number;
    rarity?: number;
  }) {
    this.type = data?.type;
    this.value = data?.value;
    this.displayType = data?.displayType;
    this.floorPriceNative = data?.floorPriceNative;
    this.rarity = data?.rarity;
  }

  static fromJSON(json: any): NftItemTrait {
    return new NftItemTrait({
      type: json['trait_type'],
      value: json['value'],
      displayType: json['display_type'],
      floorPriceNative: json['floor_price_native'],
      rarity: json['rarity'],
    });
  }

  toJSON(): any {
    const data: any = {};
    data['trait_type'] = this.type;
    data['value'] = this.value;
    data['display_type'] = this.displayType;
    data['floor_price_native'] = this.floorPriceNative;
    data['rarity'] = this.rarity;
    return data;
  }
}
