import { useEffect, useState } from 'react';
import { AssetLogo } from '../../../common/Logo/AssetLogo';

// api
import { getMetals } from '@/api/assets';
import { getStakedAssets } from '@/api/portfolio';

// models
import { AssetSearchResult } from '@/features/assets/models/AssetSearchResults';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

// components
import { SkeletonLoader } from '../../../common/Skeleton/SkeletonLoader';

interface SelectAssetTypeProps {
  assetType: string;
  selectedAsset: AssetSearchResult | null;
  setSelectedAsset: (asset: any) => void;
  quantity: number | null;
  setQuantity: (quantity: number) => void;
  holding: WalletHolding | null;
}

export const SelectAssetType: React.FC<SelectAssetTypeProps> = ({
  assetType,
  selectedAsset,
  setSelectedAsset,
  quantity,
  setQuantity,
  holding,
}) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (!holding) {
      fetchWalletAsset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holding]);

  const fetchWalletAsset = async () => {
    if (assetType === 'metals') {
      const response = await getMetals();
      setData(response.data);
    } else if (assetType === 'staked') {
      const response = await getStakedAssets();
      setData(response.data);
    }
  };

  const dataKey = assetType === 'metals' ? 'metals' : 'assets';

  const text = holding
    ? holding?.asset?.name
    : selectedAsset
      ? selectedAsset.name
      : 'Select Asset Type';

  return (
    <div>
      <div className="space-y-4">
        <div>Asset Type</div>
        <div className="bg-primary-gray dark:bg-deals-card py-3 px-4 rounded-xl flex justify-between">
          <div className="capitalize">{text}</div>
        </div>
        <div className="space-y-2 h-max max-h-screen overflow-scroll scroller">
          {data ? (
            data[dataKey as any] &&
            data[dataKey as any].map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedAsset(item);
                  }}
                  className={`cursor-pointer bg-primary-gray dark:bg-blue-dark rounded-xl py-3 px-4 flex space-x-4 items-center`}
                  style={
                    selectedAsset &&
                    selectedAsset.name?.toLowerCase() ===
                      item.name.toLowerCase()
                      ? {}
                      : { backgroundColor: '#262C3A' }
                  }
                >
                  <div className="w-8">
                    <AssetLogo
                      height={32}
                      width={32}
                      type={
                        item?.asset
                          ? item.asset.asset_type
                          : item.manual_asset?.asset_type
                      }
                      logoUrl={item?.logo_url}
                    />
                  </div>
                  <div className="capitalize text-sm leading-3 font-bold">
                    {item.name}
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <SkeletonLoader height={40} />
              <SkeletonLoader height={40} />
            </div>
          )}
        </div>
        <div>
          <input
            type="number"
            className="h-16 w-full rounded-xl bg-primary-gray dark:bg-deals-card-top px-4 border-1 border-solid"
            placeholder={
              assetType === 'metals'
                ? 'Set ounces amount here'
                : 'Set NFTs quantity here'
            }
            value={quantity || ''}
            onChange={e => {
              setQuantity(parseFloat(e.target.value));
            }}
          />
        </div>
      </div>
    </div>
  );
};
