import { useState } from 'react';
import { UserIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-hot-toast';

import { GiveawayUserPrize, MyGiveawayUserPrize } from '@/api/games';

import { useGames } from '@/hooks/useGames';

import { SkeletonLoader } from '../../../common/Skeleton/SkeletonLoader';

interface GiveawayResultsTabProps {
  giveawayUserPrizes: GiveawayUserPrize[];
  myGiveawayUserPrizes: MyGiveawayUserPrize[];
  giveawayId: string;
}

export const GiveawayResultsTab: React.FC<GiveawayResultsTabProps> = ({
  giveawayUserPrizes,
  myGiveawayUserPrizes,
  giveawayId,
}) => {
  const refresh = async () => {
    setIsLoading(true);
    await loadGiveaway(giveawayId);
    setIsLoading(false);
  };

  const copyAndOpenLink = (link: string) => {
    navigator.clipboard.writeText(link);

    toast.success('Copied to clipboard!');

    setTimeout(() => {
      window.open(link, '_blank');
    }, 2000);
  };

  const { loadGiveaway } = useGames();
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <SkeletonLoader height={400} />;
  }

  if (giveawayUserPrizes.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-1/4"
          src="/assets/images/giveway_deals.png"
          alt="giveway deals"
        />
        <div className="font-bold text-lg text-center">
          Results are not in yet!
        </div>
        <button
          onClick={refresh}
          className={`w-30 m-4 py-4 px-8 leading-17 font-semibold text-sm rounded-xl bg-gray-600 text-price-green`}
        >
          Refresh
        </button>
      </div>
    );
  }
  return (
    <div>
      {myGiveawayUserPrizes.map(userPrize => (
        <div
          key={userPrize.pass_code}
          className="flex justify-between items-center p-4 border-b border-gray-text text-price-green bg-price-green bg-opacity-20"
        >
          <div className="w-full">
            <img
              src={userPrize.prize_image_url}
              height="60"
              width="60"
              alt="prize"
            />
          </div>
          <div className="w-full flex justify-start">
            {userPrize.prize_title}
          </div>
          <div className="flex justify-start space-x-1 w-full">
            <UserIcon height="20" width="20" />
            <div>Me</div>
          </div>
          <div className="w-full flex justify-end">
            {userPrize.prize_link && (
              <button
                type="button"
                className={`w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 text-base font-medium
                      focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-price-green text-black cursor-pointer`}
                onClick={() => copyAndOpenLink(userPrize.prize_link!)}
              >
                Claim
              </button>
            )}
          </div>
        </div>
      ))}
      {giveawayUserPrizes.map(userPrize => (
        <div
          key={userPrize.pass_code}
          className="flex justify-between items-center p-4 border-b border-gray-text"
        >
          <div className="w-full">
            <img
              src={userPrize.prize_image_url}
              height="60"
              width="60"
              alt="prize"
            />
          </div>
          <div className="w-full flex justify-start">
            {userPrize.prize_title}
          </div>
          <div className="flex justify-start space-x-1 w-full">
            <UserIcon height="20" width="20" />
            <div>{userPrize.public_user_id}</div>
          </div>
          <div className="w-full flex justify-end"></div>
        </div>
      ))}
    </div>
  );
};
