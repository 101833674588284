// vendor libraries
import { useEffect, useState } from 'react';
import {
  ChevronLeftIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

// components
import { AddWalletProviderList } from './AddWalletProviderList';
import { Tooltip } from '../../common/Tooltip/Tooltip';
import { SvgIcon } from '../../common/Icon/SvgIcon';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// api
import {
  getCryptoProviders,
  GetCryptoProvidersParams,
} from '@/api/providers';
import { getMatricaOauthURL } from '@/api/matrica';

export interface Provider {
  id: string;
  name: string;
  wallet_type: string;
  logo_url?: string;
  display_name: string;
}

interface AddWalletCardProps {
  hideAddWalletCard: () => void;
  handleManualWalletClick: () => void;
}

export const AddWalletCard: React.FC<AddWalletCardProps> = ({
  hideAddWalletCard,
  handleManualWalletClick,
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [topCryptoProviders, setTopCryptoProviders] = useState<Provider[]>([]);
  const [topBrokerageProviders, setTopBrokerageProviders] = useState<
    Provider[]
  >([]);
  const [topBankProviders, setTopBankProviders] = useState<Provider[]>([]);
  const [providers, setProviders] = useState([] as any);

  const getMatricaVerifyURL = () => {
    return getMatricaOauthURL()
      .then(res => {
        if (res.status === 200 && res.data) {
          const oauthURL = res.data.oauth_url;
          window.open(oauthURL, '_blank');
        } else {
          throw new Error('Error, please try again.');
        }
      })
      .catch(err => {
        toast.error('Error, please try again.');
      });
  };

  const fetchCryptoProviders = async (payload: GetCryptoProvidersParams) => {
    try {
      const resp = await getCryptoProviders(payload);
      if (resp.status === 200) {
        setProviders([...providers, ...resp.data.providers]);
        setTopBankProviders(resp.data.top_bank_providers);
        setTopBrokerageProviders(resp.data.top_brokerage_providers);
        setTopCryptoProviders(resp.data.top_crypto_providers);
      }
    } catch (error) {
      toast.error('Error, please try again.');
    }
  };

  useEffect(() => {
    const payload: GetCryptoProvidersParams = { page: 1, limit: 10 };
    if (searchText) payload.name = searchText;
    fetchCryptoProviders(payload);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const loadMore = () => {
    const payload: GetCryptoProvidersParams = { page: page + 1, limit: 10 };
    if (searchText) payload.name = searchText;
    fetchCryptoProviders(payload);
    setPage(page => page + 1);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className="flex items-center justify-between space-x-2">
          <ChevronLeftIcon
            className="w-6 h-6 text-price-red cursor-pointer"
            onClick={() => {
              hideAddWalletCard();
            }}
          />
        </div>
        <input
          type="text"
          className="w-full bg-primary-gray dark:bg-deals-card-top rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-1 border-solid"
          placeholder="Search the wallet providers"
          value={searchText}
          onChange={e => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div className="space-y-2">
        <div>
          <div className="flex flex-col my-2">
            <div className="flex items-center">
              <div className="mr-2">Connect via Matrica</div>
              <Tooltip
                text={
                  'Connect your Matrica account to automatically add and verify all your crypto addresses on AssetDash.'
                }
                position="bottom"
              >
                <InformationCircleIcon
                  className="text-price-red"
                  width="20"
                  height="20"
                />
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <button onClick={getMatricaVerifyURL}>
              <SvgIcon iconFileName="matrica_button" size={{ width: 220 }} />
            </button>
            <div className="text-sm font-bold text-accent">Recommended</div>
          </div>
        </div>
        {topCryptoProviders.length > 0 ? (
          <AddWalletProviderList
            providers={topCryptoProviders}
            filterString={searchText}
            title="Top Cryptos"
          />
        ) : (
          <div>
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
          </div>
        )}
      </div>
      <div className="space-y-2">
        {topBrokerageProviders.length > 0 ? (
          <AddWalletProviderList
            providers={topBrokerageProviders}
            filterString={searchText}
            title="Top Brokerages"
          />
        ) : (
          <div>
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
          </div>
        )}
      </div>
      <div className="space-y-2">
        {topBankProviders.length > 0 ? (
          <AddWalletProviderList
            providers={topBankProviders}
            filterString={searchText}
            title="Top Banks"
          />
        ) : (
          <div>
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
          </div>
        )}
      </div>
      <div className="space-y-2 cursor-pointer">
        <div>Manual Wallets</div>
        {providers.length > 0 ? (
          <div
            className="flex space-x-4 items-center bg-primary-gray dark:bg-blue-dark p-4  hover:bg-gray-text dark:hover:bg-deals-card rounded-3xl py-3 px-4"
            onClick={handleManualWalletClick}
          >
            <div
              className="border solid border-gray-text rounded-full overflow-hidden flex items-center justify-center"
              style={{ height: 32, width: 32 }}
            >
              <SvgIcon
                round
                iconFileName="manual_wallet"
                size={{ width: 28, height: 28 }}
              />
            </div>
            <div className="flex items-center space-x-2">
              <div>Add Manual Wallet</div>
              <div>
                <Tooltip
                  text={
                    'Create a manual wallet to manually add \n\n investments such as Staked NFTs, Real Estate, Private Equity, and Collectibles to your AssetDash Portfolio.'
                  }
                  position="bottom"
                >
                  <InformationCircleIcon
                    className="text-price-red"
                    width="20"
                    height="20"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
          </div>
        )}
      </div>
      <div className="space-y-2">
        {providers ? (
          <AddWalletProviderList
            title="Connect Accounts"
            providers={providers}
            infiniteScroll={true}
            loadMore={loadMore}
            filterString={searchText}
          />
        ) : (
          <div>
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
            <SkeletonLoader height={40} borderRadius={2} />
          </div>
        )}
      </div>
    </div>
  );
};
