import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { PartnerExpeditionTagContainer } from '../../components/expeditions/PartnerExpeditionTagContainer';

export default function AssetdashExpeditionTagPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | Partner Expedition Tag`} lang="en_IE" />
      <div className="main-content mx-auto space-y-6">
        <PartnerExpeditionTagContainer />
      </div>
    </>
  );
}
