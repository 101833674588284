// vendor libraries
import { FC } from 'react';

// icons
import { EyeIcon } from '../Icon/EyeIcon';

interface PasswordButtonProps {
  onClick?: () => void;
  crossLine: boolean;
}

export const PasswordButton: FC<PasswordButtonProps> = ({
  onClick,
  crossLine,
}) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button onClick={handleClick}>
      <EyeIcon crossLine={crossLine} width="16" height="16" />
    </button>
  );
};
