import React from 'react';
import { NFTItemsSlider } from '@/components/v2/nft/NFTItemsSlider';
import { ComponentPlaceholder } from '../../common/Placeholder/ComponentPlaceholder';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import { useScreenWidth } from '@/hooks/useScreenWidth';

interface ElementsListCardProps {
  elementNFTItems: WalletNFTItem[];
}

export const ElementsListCard: React.FC<ElementsListCardProps> = ({
  elementNFTItems,
}) => {
  const items: WalletNFTItem[] = elementNFTItems.slice(0, 15);

  const screenWidth = useScreenWidth();
  const large = screenWidth > 1280;
  return (
    <div className="card flex flex-col">
      <div className="p-6 text-lg font-bold text-element-text-light dark:text-element-text-dark">
        My elements
      </div>
      {items.length > 0 ? (
        <NFTItemsSlider items={items} large={large} />
      ) : items.length === 0 ? (
        <div className="px-6">
          <div className="flex-grow">
            <SkeletonLoader height={200} />
          </div>
        </div>
      ) : (
        <div className="h-42">
          <ComponentPlaceholder />
        </div>
      )}
    </div>
  );
};
