export class NFTTransaction {
  timestamp?: string;
  nftUrl?: string;
  nftName?: string;
  nftTokenAddress?: string;
  nftChain?: string;
  nftChainLogo?: string;
  activityType?: string;
  price?: number;
  sellerAddress?: string;
  buyerAddress?: string;

  constructor(data?: Partial<NFTTransaction>) {
    Object.assign(this, data);
  }

  static fromJSON(json: any): NFTTransaction {
    return new NFTTransaction({
      timestamp: json['timestamp'],
      nftUrl: json['nft_url'],
      nftName: json['nft_name'],
      nftTokenAddress: json['nft_token_address'],
      nftChain: json['nft_chain'],
      nftChainLogo: json['nft_chain_logo'],
      activityType: json['activity_type'],
      price: json['price'],
      sellerAddress: json['seller_address'],
      buyerAddress: json['buyer_address'],
    });
  }

  toJSON(): any {
    return {
      timestamp: this.timestamp,
      nft_url: this.nftUrl,
      nft_name: this.nftName,
      nft_token_address: this.nftTokenAddress,
      nft_chain: this.nftChain,
      nft_chain_logo: this.nftChainLogo,
      activity_type: this.activityType,
      price: this.price,
      seller_address: this.sellerAddress,
      buyer_address: this.buyerAddress,
    };
  }

  copyWith(data: Partial<NFTTransaction>): NFTTransaction {
    return new NFTTransaction({ ...this, ...data });
  }
}
