export const getBearerToken = () => {
  if (typeof localStorage === 'undefined') {
    return 'Bearer';
  }
  const token = localStorage.getItem('token');

  return token ? `Bearer ${token}` : 'Bearer';
};

export const setBearerToken = async (config: any) => {
  const token = getBearerToken();
  config.headers.Authorization = token;
  return config;
};

export const handleConfigError = (error: any) => {
  return Promise.reject(error);
};
