import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  GlobeAltIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  ArrowRightIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { Asset, AssetActionButton } from '@/features/portfolio/models/Asset';

type AssetActionButtonsCardProps = {
  asset: Asset;
};

const AssetActionButtonsCard: React.FC<AssetActionButtonsCardProps> = ({
  asset,
}) => {
  const { actionButtons } = asset;

  const handleButtonClick = (button: AssetActionButton) => {
    window.open(button.url, '_blank');
  };

  const renderMenuItems = (buttons: AssetActionButton[]) =>
    buttons.map(button => (
      <Menu.Item key={button.name}>
        {({ active }) => (
          <div
            onClick={() => handleButtonClick(button)}
            className={`flex items-center p-2 cursor-pointer ${
              active ? 'bg-gray-100 dark:bg-gray-700' : ''
            }`}
          >
            <img
              src={button.imageUrl}
              alt={button.name}
              className="w-8 h-auto mr-2"
              style={{
                filter:
                  'invert(38%) sepia(64%) saturate(547%) hue-rotate(115deg) brightness(92%) contrast(90%)',
              }}
            />
            <span className="flex-1 text-primary">{button.name}</span>
            {button.featured && (
              <StarIcon className="w-6 h-6 text-main-yellow" />
            )}
            <ArrowRightIcon className="w-4 h-4 text-primary" />
          </div>
        )}
      </Menu.Item>
    ));

  const iconMap: { [key: string]: JSX.Element } = {
    socials: <GlobeAltIcon className="w-6 h-6 text-primary" />,
    tools: <ChartBarIcon className="w-6 h-6 text-primary" />,
    trade: <CurrencyDollarIcon className="w-6 h-6 text-primary" />,
  };

  if (!actionButtons) return null;

  return (
    <div className="z-50 px-4 py-4">
      <div className="flex justify-around">
        {actionButtons.socialButtons.length > 0 && (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center space-x-2 border border-primary text-primary rounded-full px-4 py-2">
                {iconMap['socials']}
                <span>Socials</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {renderMenuItems(actionButtons.socialButtons)}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
        {actionButtons.toolButtons.length > 0 && (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center space-x-2 border border-primary text-primary rounded-full px-4 py-2">
                {iconMap['tools']}
                <span>Tools</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {renderMenuItems(actionButtons.toolButtons)}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
        {actionButtons.tradeButtons.length > 0 && (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center space-x-2 border border-primary text-primary rounded-full px-4 py-2">
                {iconMap['trade']}
                <span>Trade</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {renderMenuItems(actionButtons.tradeButtons)}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  );
};

export default AssetActionButtonsCard;
