import { useNavigate } from 'react-router-dom';

// components
import { DealProgressBar } from './DealProgressBar';
import { ImageTag } from '../../common/Image/ImageTag';

export const DealCard: React.FC<{ deal: any }> = ({ deal }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/deals/${deal.id}`);
      }}
      className={`cursor-pointer h-92 w-44  border solid p-2 space-y-2 rounded-card-box ${
        deal.is_top_tier
          ? 'border-top-tier-color'
          : deal.is_vanta
          ? 'border-vanta-color'
          : 'border-border-bg dark:border-tabs-bg'
      }`}
    >
      <div className="rounded-card-box relative">
        <ImageTag url={deal.image_url || deal.store.image_url} alt="logo" />
        {deal.nft_chain && (
          <div className="absolute top-0 right-0 mt-2 mr-2 h-5 flex items-center justify-center p-2 text-xs border text-primary font-semibold bg-element-background-light dark:bg-element-background-dark border-fade-text-light dark:border-fade-text-dark rounded-full text-ellipsis whitespace-nowrap overflow-hiddens">
            {deal.nft_chain}
          </div>
        )}
      </div>
      <div>
        <div className="text-xs leading-17 text-ellipsis whitespace-nowrap overflow-hidden text-gray-text">
          {deal.store.name}
        </div>
        <div className="text-sm leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {deal.name}
        </div>
      </div>
      <div className="flex justify-between items-end">
        <div>
          <div className="text-xs leading-17 text-ellipsis whitespace-nowrap overflow-hidden text-gray-text">
            {deal.custom_label_text ? deal.custom_label_text : 'Deal'}
          </div>
          <div className="text-sm leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {deal.deal_text}
          </div>
        </div>
        {(deal.coins_cost || deal.custom_chip) && (
          <div className="h-5 flex items-center justify-center p-2 text-xs border bg-price-green bg-opacity-40 text-price-green border-price-green rounded-full text-ellipsis whitespace-nowrap overflow-hidden">
            {deal.coins_cost ? `${deal.coins_cost} Coins` : deal.custom_chip}
          </div>
        )}
      </div>
      <div className="space-y-1">
        <DealProgressBar
          percent={deal.percent_coupons_claimed}
          color={
            deal.is_vanta
              ? 'vanta-color'
              : deal.is_top_tier
              ? 'top-tier-color'
              : 'price-green'
          }
        />
      </div>
      <div
        className={`w-full py-2 text-center rounded-12
        ${
          deal.sold_out
            ? 'bg-gray-200 dark:bg-dark text-negative'
            : deal.is_top_tier
            ? 'bg-top-tier-color text-white'
            : deal.is_vanta
            ? 'bg-vanta-color text-black'
            : 'bg-price-green text-black'
        }`}
      >
        {deal.sold_out
          ? 'Sold Out'
          : deal.custom_button_text
          ? deal.custom_button_text
          : 'Get Deal'}
      </div>
    </div>
  );
};
