import {
  BoltIcon,
  LockClosedIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../common/ProgressBar';
import { formatNumber } from '@/utils/number';

export default function CourseDetailsSection({ course }) {
  const navigate = useNavigate();
  const { reward, is_boosted, weekly_completions_percent, num_views } =
    course || null;
  const { tickets, coins } = reward || '';

  const handleStart = course => {
    analytics.track('learn_course_view_detail', {
      id: course.id,
    });
    navigate(`/learn/${course.id}`);
  };

  return (
    <div
      className="bg-toggle-bg dark:bg-black cursor-pointer flex flex-col p-4 py-4 md:p-5 md:py-5 rounded-2xl space-y-4"
      key={course.id}
      onClick={() => handleStart(course)}
    >
      {/* Reward and View section */}
      <div className="flex items-center justify-between whitespace-nowrap">
        <div className="flex justify-start space-x-1">
          {coins > 0 && (
            <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-green px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
              <img
                src="/assets/icons/coin.png"
                className="h-4 w-4"
                alt="cash"
              />
              <p className="text-price-green text-10 md:text-sm">
                {coins} coins
              </p>
            </div>
          )}
          {tickets > 0 && (
            <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-green px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
              <img
                src="/assets/images/ticket.png"
                className="h-4 w-4"
                alt="ticket"
              />
              <p className="text-price-green text-10 md:text-sm">
                {tickets} {tickets > 1 ? 'Tickets' : 'Ticket'}
              </p>
            </div>
          )}
        </div>
        <div>
          {num_views && (
            <div>
              <div className="flex space-x-1 items-center">
                <UsersIcon className="h-4 w-4" />
                <span>{formatNumber(num_views, 0)}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex space-x-4 lg:space-x-6">
          <img
            src={course.image_url}
            className="h-105 w-105 object-cover rounded-full backdrop-contrast-50 border-1 border-solid"
            alt="course"
          />
          <div className="space-y-1 m-auto w-full">
            <div className="flex justify-between">
              <p className="text-base md:text-xl font-bold lineClamp-2">
                {course.title}
              </p>
              {is_boosted && (
                <div className="w-4 h-4 mt-1">
                  <BoltIcon
                    height="24"
                    width="24"
                    className=" stroke-boosted-blue border-1 border-boosted-blue border-solid rounded-full"
                  />
                </div>
              )}
            </div>
            <div>
              <p className="lineClamp-1 text-">{course.partner_name}</p>
              {weekly_completions_percent !== null &&
                weekly_completions_percent > 0 && (
                  <div>
                    <ProgressBar
                      progress={weekly_completions_percent}
                      bgcolor="#21CE99"
                      multiplier={1}
                      height={'4'}
                    />
                  </div>
                )}
            </div>
            {weekly_completions_percent !== null &&
              weekly_completions_percent > 0 && (
                <p className="text-xs flex space-x-2 items-center">
                  <span>
                    {Math.round(100 - (weekly_completions_percent || 0), 2)}%
                    until locked
                  </span>
                  <LockClosedIcon className="h-3 w-3 text-black dark:text-white" />
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
