import { AssetDashExpedition } from './AssetDashExpeditions';

export class AssetDashExpeditionDetailResponse {
  assetdashExpedition?: AssetDashExpedition | null;
  assetDashExpeditionUser?: AssetDashExpeditionUser | null;

  constructor({
    assetdashExpedition,
    assetDashExpeditionUser,
  }: {
    assetdashExpedition?: AssetDashExpedition | null;
    assetDashExpeditionUser?: AssetDashExpeditionUser | null;
  }) {
    this.assetdashExpedition = assetdashExpedition;
    this.assetDashExpeditionUser = assetDashExpeditionUser;
  }

  static fromJson(json: any): AssetDashExpeditionDetailResponse {
    return new AssetDashExpeditionDetailResponse({
      assetdashExpedition: json['assetdash_expedition']
        ? AssetDashExpedition.fromJson(json['assetdash_expedition'])
        : null,
      assetDashExpeditionUser: json['assetdash_expedition_user']
        ? AssetDashExpeditionUser.fromJson(json['assetdash_expedition_user'])
        : null,
    });
  }

  toJson(): any {
    return {
      assetdash_expedition: this.assetdashExpedition
        ? this.assetdashExpedition.toJson()
        : null,
      assetdash_expedition_user: this.assetDashExpeditionUser
        ? this.assetDashExpeditionUser.toJson()
        : null,
    };
  }
}

export class AssetDashExpeditionUser {
  id: string;
  earnedCoins: number;

  constructor({ id, earnedCoins }: { id: string; earnedCoins: number }) {
    this.id = id;
    this.earnedCoins = earnedCoins;
  }

  static fromJson(json: any): AssetDashExpeditionUser {
    return new AssetDashExpeditionUser({
      id: json['id'],
      earnedCoins: json['earned_coins'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      earned_coins: this.earnedCoins,
    };
  }
}
