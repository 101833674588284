// vendor libraries
import { useState } from 'react';

// components
import { OptionsTab } from '../../components/common/Tab/OptionsTab';
import { CoinStats } from './CoinStats';
import { CoinDistributions } from './CoinDistributions';
import { CoinSpendings } from './CoinSpendings';
import { CoinCenterHeader } from './CoinCenterHeader';

export const CoinCenterContainer: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('distributions');
  const modalTabs: { [key: string]: string } = {
    distributions: 'Received',
    spendings: 'Spent',
  };

  return (
    <div className="mx-auto main-content grid-cols-1">
      <CoinCenterHeader />
      <CoinStats />
      <div className="py-2 sm:py-5 md:px-6 flex items-center justify-center">
        <OptionsTab
          tabs={modalTabs}
          activeTab={selectedTab}
          onClick={(key: string) => setSelectedTab(key)}
        />
      </div>
      <div className={`${selectedTab === 'distributions' ? '' : 'hidden'}`}>
        <CoinDistributions />
      </div>
      <div className={`${selectedTab === 'spendings' ? '' : 'hidden'}`}>
        <CoinSpendings />
      </div>
    </div>
  );
};
