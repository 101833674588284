import Lottie from 'lottie-react';
import partyLottieAnim from '../../assets/lottie/party.json';
const PartyLottie = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: partyLottieAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div
      className="flex justify-center items-center"
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '999',
        width: '100%',
        height: '100%',
      }}
    >
      <Lottie {...defaultOptions} height={1000} width={1000} />
    </div>
  );
};

export default PartyLottie;
