import { usePortfolio } from '@/hooks/usePortfolio';

import { PortfolioBaseHoldingCard } from '../featuredHoldingCard/PortfolioBaseHoldingCard';

import { useAuth } from '@/hooks/useAuth';

export const PortfolioTopGainerCard: React.FC = () => {
  const { bestPerformance, isLoading } = usePortfolio();
  const { convertUSDCurrency } = useAuth();

  return (
    <PortfolioBaseHoldingCard
      title="Top Gainer"
      value={`+${convertUSDCurrency(bestPerformance?.valueChange)}`}
      holding={bestPerformance!}
      isLoading={isLoading}
    />
  );
};
