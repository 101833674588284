export class NFTChainPrice {
  eth?: NFTChainAssetPrice;
  sol?: NFTChainAssetPrice;
  near?: NFTChainAssetPrice;
  flow?: NFTChainAssetPrice;
  matic?: NFTChainAssetPrice;
  bitcoin?: NFTChainAssetPrice;
  bsc?: NFTChainAssetPrice;
  avalanche?: NFTChainAssetPrice;
  arbitrum?: NFTChainAssetPrice;

  constructor(
    eth?: NFTChainAssetPrice,
    sol?: NFTChainAssetPrice,
    near?: NFTChainAssetPrice,
    flow?: NFTChainAssetPrice,
    matic?: NFTChainAssetPrice,
    bitcoin?: NFTChainAssetPrice,
    bsc?: NFTChainAssetPrice,
    avalanche?: NFTChainAssetPrice,
    arbitrum?: NFTChainAssetPrice,
  ) {
    this.eth = eth;
    this.sol = sol;
    this.near = near;
    this.flow = flow;
    this.matic = matic;
    this.bitcoin = bitcoin;
    this.bsc = bsc;
    this.avalanche = avalanche;
    this.arbitrum = arbitrum;
  }

  static fromJSON(json: any): NFTChainPrice {
    return new NFTChainPrice(
      json['ETH'] ? NFTChainAssetPrice.fromJSON(json['ETH']) : undefined,
      json['SOL'] ? NFTChainAssetPrice.fromJSON(json['SOL']) : undefined,
      json['NEAR'] ? NFTChainAssetPrice.fromJSON(json['NEAR']) : undefined,
      json['FLOW'] ? NFTChainAssetPrice.fromJSON(json['FLOW']) : undefined,
      json['MATIC'] ? NFTChainAssetPrice.fromJSON(json['MATIC']) : undefined,
      json['BITCOIN']
        ? NFTChainAssetPrice.fromJSON(json['BITCOIN'])
        : undefined,
      json['BSC'] ? NFTChainAssetPrice.fromJSON(json['BSC']) : undefined,
      json['AVAX'] ? NFTChainAssetPrice.fromJSON(json['AVAX']) : undefined,
      json['ARB'] ? NFTChainAssetPrice.fromJSON(json['ARB']) : undefined,
    );
  }

  toJSON(): any {
    const data: any = {};
    if (this.eth) data['ETH'] = this.eth.toJSON();
    if (this.sol) data['SOL'] = this.sol.toJSON();
    if (this.near) data['NEAR'] = this.near.toJSON();
    if (this.flow) data['FLOW'] = this.flow.toJSON();
    if (this.matic) data['MATIC'] = this.matic.toJSON();
    if (this.bitcoin) data['BITCOIN'] = this.bitcoin.toJSON();
    if (this.bsc) data['BSC'] = this.bsc.toJSON();
    if (this.avalanche) data['AVAX'] = this.avalanche.toJSON();
    if (this.arbitrum) data['ARB'] = this.arbitrum.toJSON();
    return data;
  }

  getPriceForChain(chain: string): number {
    switch (chain) {
      case 'ETH':
        return this.eth?.priceUsd ?? 0.0;
      case 'SOL':
        return this.sol?.priceUsd ?? 0.0;
      case 'NEAR':
        return this.near?.priceUsd ?? 0.0;
      case 'FLOW':
        return this.flow?.priceUsd ?? 0.0;
      case 'MATIC':
        return this.matic?.priceUsd ?? 0.0;
      case 'BITCOIN':
        return this.bitcoin?.priceUsd ?? 0.0;
      case 'BSC':
        return this.bsc?.priceUsd ?? 0.0;
      case 'AVAX':
        return this.avalanche?.priceUsd ?? 0.0;
      case 'ARB':
        return this.arbitrum?.priceUsd ?? 0.0;
      default:
        return 0.0;
    }
  }
}

export class NFTChainAssetPrice {
  id?: string;
  priceUsd?: number;

  constructor(id?: string, priceUsd?: number) {
    this.id = id;
    this.priceUsd = priceUsd;
  }

  static fromJSON(json: any): NFTChainAssetPrice {
    return new NFTChainAssetPrice(json['id'], json['price_usd']);
  }

  toJSON(): any {
    return {
      id: this.id,
      price_usd: this.priceUsd,
    };
  }
}
