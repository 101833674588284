import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useThemeState } from '@/recoil/theme';

export default function YourPrizeModal({
  open,
  setOpen,
  yourPrizes,
  handleClaimYourPrize,
}) {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';
  const rowBg = theme === 'light' ? 'bg-primary-gray' : 'bg-black';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${background} relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6`}
              >
                <div>
                  <div className="text-left">
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon
                        height={24}
                        width={24}
                        className="text-price-red"
                      />
                    </span>
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl font-bold mb-4 ${textColor}`}
                    >
                      Your Prizes
                    </Dialog.Title>
                    <div className="space-y-2">
                      {yourPrizes.map(yourPrize => {
                        const { id, claimed, raffle_prize, raffle_ticket } =
                          yourPrize;
                        return (
                          <div
                            key={yourPrize.id}
                            className={`${rowBg} p-4 md:p-10 rounded-2xl flex flex-col md:flex-row items-center justify-between`}
                          >
                            <div className="flex items-center space-x-4">
                              <img
                                src={raffle_prize.image_url}
                                className="h-16 w-16 "
                                alt="prize"
                              />
                              <div className="space-y-2">
                                <p
                                  className={`${textColor} text-base md:text-8 add-overflow-dot max-w-40 md:max-w-80`}
                                >
                                  {raffle_prize.title}
                                </p>
                                <p className="text-xl font-bold text-gray-text">
                                  Winning Ticket
                                </p>
                              </div>
                            </div>
                            <div className="mt-4 md:mt-0">
                              {!claimed ? (
                                <button
                                  className="py-3 px-4 font-bold min-w-36 rounded-2xl text-center text-price-green border-price-green border-solid border-1"
                                  onClick={() => {
                                    handleClaimYourPrize(id);
                                  }}
                                >
                                  Claim
                                </button>
                              ) : (
                                <div className="bg-white dark:bg-input-box text-black dark:text-white font-bold py-3 px-4 min-w-36 rounded-2xl text-center">
                                  {raffle_ticket.ticket_code}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
