import { createContext, useContext, useState } from 'react';

// api
import {
  getUserDeals,
  getDealAssets,
  getDealTags,
  getUserPremiumDeals,
  getUserVantaDeals,
  getUserDealCoupon,
  getUserPurchases,
  getUserDealsCenter,
  getUserDealDetails,
} from '../api/deals';

export const DealContext = createContext({} as any);

export function useDeals() {
  return useContext(DealContext);
}

export const DealsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [dealsAssets, setDealsAssets] = useState<any>({});
  const [dealsTags, setDealsTags] = useState<any>([]);
  const [premiumDeals, setPremiumDeals] = useState<any>([]);
  const [vantaDeals, setVantaDeals] = useState<any>([]);
  const [popularDeals, setPopularDeals] = useState<any>([]);
  const [giveawayDeals, setGiveawayDeals] = useState<any>([]);
  const [latestDeals, setLatestDeals] = useState<any>([]);
  const [coupons, setCoupons] = useState<any>([]);
  const [userAllDeals, setUserAllDeals] = useState<any>([]);
  const [userDeals, setUserDeals] = useState<any>([]);
  const [userPurchases, setUserPurchases] = useState<any>([]);
  const [userCashBack, setUserCashBack] = useState<any>([]);
  const [userDealDetail, setUserDealDetail] = useState<any>({});
  const [userDealDetailsLoading, setUserDealDetailsLoading] =
    useState<any>(false);
  const [generatedCoupons, setGeneratedCoupons] = useState<any>({} as any);
  const [dealsLoading, setDealsLoading] = useState(false);

  const loadUserDeals = async () => {
    await loadUserDealsAssets();
    await loadUserFilterTags();

    try {
      await loadAllUserPremiumDeals();
      await loadAllUserVantaDeals();

      const popularDealsResponse = await getUserDeals({
        tag: 'popular',
        limit: 40,
      });
      setPopularDeals(popularDealsResponse.data.deals || []);

      const giveawayDealsResponse = await getUserDeals({
        tag: 'giveaway',
        limit: 40,
      });
      setGiveawayDeals(giveawayDealsResponse.data.deals || []);

      const latestDealsResponse = await getUserDeals({
        limit: 40,
        orderKey: 'latest',
      });
      setLatestDeals(latestDealsResponse.data.deals || []);
    } catch (error) {}
  };

  const loadUserDealsCategories = async (tag: string) => {
    try {
      const response = await getUserDeals({ tag });
      setUserDeals(response.data.deals);
    } catch (error) {}
  };

  const loadUserDealsAssets = async () => {
    try {
      const response = await getDealAssets();
      setDealsAssets(response.data.assets);
    } catch (error) {}
  };

  const loadUserFilterTags = async () => {
    try {
      const response = await getDealTags();
      setDealsTags(response.data.tags);
    } catch (error) {}
  };

  const loadAllUserPremiumDeals = async () => {
    try {
      const response = await getUserPremiumDeals({ limit: 40 });
      setPremiumDeals(response.data.deals || []);
    } catch (error) {}
  };

  const loadAllUserVantaDeals = async () => {
    try {
      const response = await getUserVantaDeals({ limit: 40 });
      setVantaDeals(response.data.deals || []);
    } catch (error) {}
  };

  const loadUserCoupons = async () => {
    try {
      const response = await getUserDealCoupon();
      setCoupons(response.data.coupons || []);
    } catch (error) {}
  };

  const loadAllUserDeals = async () => {
    try {
      const response = await getUserDeals();
      setUserAllDeals(response.data.deals || []);
    } catch (error) {}
  };

  const loadUserOrders = async () => {
    try {
      const response = await getUserPurchases();
      setUserPurchases(response.data.purchases || []);
    } catch (error) {}
  };

  const loadUserCashBacks = async () => {
    try {
      const response = await getUserDealsCenter();
      setUserCashBack(response.data.deals_center || []);
    } catch (error) {}
  };

  const loadUserDealDetails = async (id: string) => {
    setUserDealDetailsLoading(true);
    try {
      const response = await getUserDealDetails(id);
      setUserDealDetail(response.data || {});
    } catch (error) {}
    setUserDealDetailsLoading(false);
  };

  return (
    <DealContext.Provider
      value={{
        // methods
        loadUserDeals,
        loadUserCoupons,
        loadAllUserDeals,
        loadUserDealsCategories,
        loadUserOrders,
        loadUserCashBacks,
        loadUserDealDetails,
        setDealsLoading,
        setGeneratedCoupons,
        // values
        dealsAssets,
        dealsTags,
        premiumDeals,
        vantaDeals,
        popularDeals,
        giveawayDeals,
        latestDeals,
        coupons,
        userAllDeals,
        userDeals,
        userPurchases,
        userCashBack,
        userDealDetail,
        userDealDetailsLoading,
        generatedCoupons,
        dealsLoading,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
