import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import DashDropGiveawayModal from '../components/market/deals/DashDropGiveawayModal';
import CurrentPrizes from '../components/giveaway/CurrentPrizes';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useDealsLegacy } from '../hooks/useDealsLegacy';

export default function GiveawayDetailPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { id } = useParams();
  const {
    fetchPastResultDetail,
    pastResultDetails,
    claimYourPrize,
    fetchYourPrizes,
  } = useDealsLegacy();

  const [isLoading, setIsLoading] = useState(false);

  const faq = [
    {
      question: 'What is a DashDrop?',
      answer:
        'DashDrop is a weekly giveaway exclusive for our members. Each week you will receive tickets to the DashDrop according to your membership tier. Check the "Winning Tickets" button at the end of the week to see if you won the giveaway.',
    },
    {
      question: 'How does it Work?',
      answer:
        'Every week you will be distributed tickets, claim them, and see if one of your ticket numbers is the winner once the Giveaway is complete.',
    },
  ];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async () => {
    setIsLoading(true);
    await fetchPastResultDetail(id);
    setIsLoading(false);
  };

  const { raffle, user_prizes: yourPrizes } = pastResultDetails || {};

  const { prizes, image_url, status } = raffle || {};

  const handleClaimYourPrize = async id => {
    const resp = await claimYourPrize(id);

    if (resp === 'success') {
      await fetchYourPrizes();
      toast.success('Prize Claimed Successful!');
    }
  };

  //   const getPastResultDate = (date) => {
  //     const resp = moment(date).format("MM/DD/YYYY");
  //     return resp;
  //   };

  //   const { total_tickets, next_ticket_bonus } = raffleCenterData || {};

  return (
    <div>
      <SuperSEO title={`AssetDash | Giveaway Center`} lang="en_IE" />
      <div className="mx-auto main-content space-y-8">
        {!isLoading ? (
          <div className="bg-white dark:bg-black rounded-12 p-4 md:p-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10">
              <div>
                <img
                  src={image_url}
                  alt="giveaway banner"
                  className="h-auto w-full"
                />
              </div>
              <div className="space-y-4">
                <div>
                  <div className="flex items-center space-x-2 mb-10x">
                    <p className="text-8 font-bold">{raffle?.title}</p>
                    <span
                      onClick={() => {
                        //open modal
                        setOpen(true);
                      }}
                      className="cursor-pointer"
                    >
                      <InformationCircleIcon className="h-4 w-4" />
                    </span>
                  </div>
                  <div className="font-bold text-price-green flex items-center space-x-2 capitalize">
                    {status}
                  </div>
                </div>
                <p className="text-xl leading-9">{raffle?.description}</p>
                {!isLoading && yourPrizes?.length === 0 && (
                  <p className="bg-tabs-bg dark:bg-deals-card w-auto md:w-max p-4 rounded-12 text-left text-price-red text-base">
                    You did not have a winning ticket, try again in the next
                    giveaway!
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          isLoading && (
            <div className="grid grid-cols-2 gap-4">
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div className="space-y-8">
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '40px' }}
                ></div>
              </div>
            </div>
          )
        )}

        <div>
          {prizes && prizes.length > 0 && (
            <p className="text-8 font-bold mb-8">Prizes</p>
          )}

          {prizes && prizes.length > 0 ? (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <CurrentPrizes prizes={prizes} />
              </div>
            </div>
          ) : (
            isLoading && (
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
                <div
                  className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                  style={{ height: '450px' }}
                ></div>
              </div>
            )
          )}
        </div>

        {yourPrizes && yourPrizes.length > 0 ? (
          <div>
            <p className="text-8 font-bold mb-8">Your Prizes</p>
            <div className="space-y-2">
              {yourPrizes.map(yourPrize => {
                const { id, claimed, raffle_prize, raffle_ticket } = yourPrize;
                return (
                  <div
                    key={yourPrize.id}
                    className="bg-white dark:bg-black p-4 md:p-10 rounded-2xl flex flex-col md:flex-row items-center justify-between"
                  >
                    <div className="flex items-center space-x-4">
                      <img
                        src={raffle_prize.image_url}
                        className="h-16 w-16 "
                        alt="prize"
                      />
                      <div className="space-y-2">
                        <p className="text-8 add-overflow-dot max-w-60 md:max-w-80 lg:max-w-770">
                          {raffle_prize.title}
                        </p>
                        <p className="text-xl font-bold text-gray-text">
                          Winning Ticket
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0">
                      {!claimed ? (
                        <button
                          className="py-3 px-4 font-bold min-w-36 rounded-2xl text-center text-price-green border-price-green border-solid border-1"
                          onClick={() => {
                            handleClaimYourPrize(id);
                          }}
                        >
                          Claim
                        </button>
                      ) : (
                        <div className="bg-input-box font-bold py-3 px-4 min-w-36 rounded-2xl text-center">
                          {raffle_ticket.ticket_code}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          isLoading && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
              <div
                className="animate-pulse flex rounded-xl bg-white dark:bg-dark"
                style={{ height: '450px' }}
              ></div>
            </div>
          )
        )}
      </div>
      {open && (
        <DashDropGiveawayModal open={open} setOpen={setOpen} faqs={faq} />
      )}
    </div>
  );
}
