// vendor libraries
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';
import { formatNumber } from '@/utils/number';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import { ArrowUpIcon } from '@heroicons/react/24/solid';
import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
} from '@heroicons/react/24/outline';
import { AssetDetails } from '@/features/portfolio/models/AssetDetail';
import { CryptoDetail } from '@/features/portfolio/models/CryptoDetail';

interface AssetAnalyticsCardProps {
  assetDetail?: AssetDetails;
}

export const AssetAnalyticsCard: React.FC<AssetAnalyticsCardProps> = ({
  assetDetail,
}) => {
  const { isLoading } = usePortfolio();

  const loading = isLoading || !assetDetail;

  const cryptoDetail = assetDetail as CryptoDetail;

  const formatChangeBox = (change: number, timeFrame: string) => {
    const isPositive = change > 0;
    const borderClass = isPositive
      ? 'border-buy-percent-green'
      : 'border-sell-red';
    const boxClass = isPositive ? 'bg-buy-percent-green/20' : 'bg-sell-red/20';
    const textColorClass = isPositive
      ? 'text-buy-percent-green'
      : 'text-sell-red';

    return (
      <div
        className={`flex flex-col items-end justify-between px-3 py-3 ${boxClass} border ${borderClass} rounded-xl`}
      >
        <span className={`font-medium text-sm ${textColorClass}`}>
          {timeFrame}
        </span>
        <span
          className={`flex items-center text-sm md:text-lg font-bold ${textColorClass}`}
        >
          {isPositive ? (
            <ArrowUpIcon className="w-4 h-4 mr-1" />
          ) : (
            <ArrowDownIcon className="w-4 h-4 mr-1" />
          )}
          {Math.abs(change).toFixed(2)}%
        </span>
      </div>
    );
  };

  return (
    <div
      className={`h-[420px] card py-5 px-4 md:px-6 space-y-4 divide-y divide-gray-text dark:divide-blue-dark`}
    >
      {loading ? (
        <SkeletonLoader height={240} />
      ) : (
        <div className="w-full flex flex-col space-y-5">
          <div className="w-full grid grid-cols-4 gap-4">
            {formatChangeBox(cryptoDetail?.percentChange1H!, '1H')}
            {formatChangeBox(cryptoDetail?.percentChange2H!, '2H')}
            {formatChangeBox(cryptoDetail?.percentChange8H!, '8H')}
            {formatChangeBox(cryptoDetail?.percentChange24H!, '24H')}
          </div>
          <div className="text-lg font-bold flex justify-between">
            <span>Volume</span>
            <span>${formatNumber(cryptoDetail?.volume24HUSD!)}</span>
          </div>
          <div className="flex flex-row justify-between items-center space-x-2 text-sm">
            <div className="flex flex-row grow items-center space-x-4">
              <div className="p-2 rounded-full bg-light-green/20 border border-light-green">
                <ArrowUpRightIcon className="w-4 h-4 mr-1 text-light-green" />
              </div>
              <span>Buy</span>
              <div className="h-2 grow rounded-md w-10 bg-buy-gradient"></div>
            </div>
            <span>${formatNumber(cryptoDetail?.buy24H!)}</span>
          </div>
          <div className="flex flex-row justify-between items-center space-x-2 text-sm">
            <div className="flex flex-row grow items-center space-x-4">
              <div className="p-2 rounded-full bg-sell-red/20 border border-sell-red">
                <ArrowDownRightIcon className="w-4 h-4 mr-1 text-sell-red" />
              </div>
              <span>Sell</span>
              <div className="h-2 grow rounded-md bg-sell-gradient"></div>
            </div>
            <span>${formatNumber(cryptoDetail?.sell24H!)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
