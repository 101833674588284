import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

export const MemberCard = ({ memberCenterData, setOpenGoldBoost }) => {
  const memberCard = memberCenterData?.member_benefits?.member_card;
  const partnerOrganizations = memberCenterData?.partner_organizations;
  const memberStatus = memberCenterData?.member_status;
  return (
    <div className="card py-4 px-4 rounded-12 justify-center space-y-2">
      <div
        className="absolute cursor-pointer w-52 "
        onClick={() =>
          memberStatus && memberStatus !== 'standard' && setOpenGoldBoost(true)
        }
      >
        <div className="flex flex-wrap relative top-8 md:top-24 left-4">
          {partnerOrganizations &&
            partnerOrganizations.length > 0 &&
            partnerOrganizations.slice(0, 6).map(item => {
              return (
                <div key={item.partner_id} className="ml-4 mt-2">
                  <img
                    src={item.logo_url}
                    style={{
                      background: 'rgba(255, 214, 135, 0.31)',
                      backdropFilter: 'blur(11.1405px)',
                    }}
                    className="h-12 md:h-16 w-12 md:w-16 p-2 rounded-full object-contain"
                    alt={item.partner_id}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex items-center m-auto h-full">
        {memberCard ? (
          <img
            onClick={() =>
              memberStatus &&
              memberStatus !== 'standard' &&
              setOpenGoldBoost(true)
            }
            src={memberCard}
            alt="card"
            className="w-full cursor-pointer rounded-12 object-cover"
          />
        ) : (
          <SkeletonLoader height={300} width={550} className="rounded-xl" />
        )}
      </div>
    </div>
  );
};
