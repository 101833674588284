import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import toast from 'react-hot-toast';

// recoil
import { useThemeState } from '@/recoil/theme.ts';

// models
import { Wallet } from '@/features/portfolio/models/Wallet.ts';

interface ChooseVerificationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  wallet: Wallet;
}

export const ProofOfOwnershipModal: React.FC<ChooseVerificationModalProps> = ({
  open,
  setOpen,
  wallet,
}) => {
  const [theme] = useThemeState();

  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    toast.success('Address copied successfully');
  };

  const items = [
    {
      index: 1,
      message:
        'Send any amount of crypto to the address below. If verifying a SOL wallet, send to the SOL address below. If verifying an ETH wallet, send to the ETH address below.',
    },
    {
      index: 2,
      message: 'After sending the payment, fill out the form below.',
    },
    {
      index: 3,
      message: `Your wallet will be verified in ${
        wallet.walletType === 'solana' ? '1 hour' : '24 hours'
      }.`,
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg  px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 ${background}`}
              >
                <div>
                  <div className="mt-3 text-center space-y-4 sm:mt-5 ">
                    <Dialog.Title
                      as="h3"
                      className={`text-4xl font-bold ${textColor}`}
                    >
                      Prove ownership by sending a{' '}
                      <br className="hidden md:block" />
                      micro-transaction
                    </Dialog.Title>
                    <div className="space-y-6">
                      {items.map(item => {
                        return (
                          <div
                            key={item.index}
                            className="flex text-left space-x-4"
                          >
                            <span className="circle-box">{item.index}</span>
                            <div className={`${textColor} text-lg`}>
                              {item.message}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="text-left space-y-4 py-4 overflow-scroll scroller">
                      <div
                        className={`${textColor} flex justify-between items-center py-4 px-4 rounded-12 ${
                          wallet.walletType === 'zapper' && 'cursor-pointer'
                        } ${
                          wallet.walletType === 'zapper'
                            ? 'bg-transparent border-1 border-price-green'
                            : 'bg-input-box'
                        }`}
                        onClick={() => {
                          wallet.walletType === 'zapper' &&
                            copyAddress(
                              '0x484c7eD911D77e7cA276559f86b37aeB6B308466',
                            );
                        }}
                      >
                        <p>0x484c7eD911D77e7cA276559f86b37aeB6B308466</p>
                        <img
                          src="/assets/icons/eth.png"
                          className="h-6 w-6 rounded-full"
                          alt="ethereum logo"
                        />
                      </div>
                      <div
                        className={`${textColor} flex justify-between items-center py-4 px-4 rounded-12 ${
                          wallet?.walletType === 'solana' && 'cursor-pointer'
                        } ${
                          wallet?.walletType === 'solana'
                            ? 'bg-transparent border-1 border-price-green'
                            : 'bg-input-box'
                        }`}
                        onClick={() => {
                          wallet?.walletType === 'solana' &&
                            copyAddress(
                              '6T6ASMRRGVA3fhunY73xfZMV53MDkooaVr9JSMfq5UWf',
                            );
                        }}
                      >
                        <p>6T6ASMRRGVA3fhunY73xfZMV53MDkooaVr9JSMfq5UWf</p>
                        <img
                          src="/assets/icons/solana.png"
                          className="h-6 w-6 rounded-full"
                          alt="solana logo"
                        />
                      </div>
                    </div>
                    <div className="pb-4">
                      <button
                        className="bg-price-green px-16 py-4 rounded-12 font-bold"
                        onClick={() => {
                          window.open(
                            'https://kakqrt6qwdd.typeform.com/to/KQVNABPU',
                            '_blank',
                          );
                        }}
                      >
                        Fill Out Form
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
