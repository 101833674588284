// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';
import { useAuth } from '@/hooks/useAuth';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import {
  NFTHoldingChartSeries,
  NFTHoldingChartItem,
} from '@/features/nft/models/NFTHoldingsChart';

// components
import { SeriesCardLineChart } from '@/components/v2/common/SeriesCard/SeriesCardLineChart';
import { SeriesCardTabBar } from '@/components/v2/common/SeriesCard/SeriesCardTabBar';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// utils
import { formatNumber, getNumberColor } from '@/utils/number';

const tabToDataField: {
  [key: string]: keyof NFTHoldingChartSeries;
} = {
  '1': 'day1Items',
  '7': 'week1Items',
  '30': 'month1Items',
  '90': 'month3Items',
  '365': 'year1Items',
  '3650': 'all',
};

interface NFTItemSeriesCardProps {
  walletNFTItem?: WalletNFTItem;
}

export const NFTItemSeriesCard: React.FC<NFTItemSeriesCardProps> = ({
  walletNFTItem,
}) => {
  const { convertUSDCurrency } = useAuth();
  const [activeValue, setActiveValue] = useState(null);
  const { nftItemId } = useParams();
  const [activeTimePeriod, setActiveTimePeriod] = useState('1');
  const [activeDate, setActiveDate] = useState<string | null>(null);
  const {
    fetchNFTHoldingSeriesChart,
    nftHoldingChartDataMap,
    isNFTSeriesChartFetching,
    isLoading,
  } = usePortfolio();

  useEffect(() => {
    if (walletNFTItem?.nftItem?.id) {
      fetchNFTHoldingSeriesChart(walletNFTItem?.nftItem?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletNFTItem?.nftItem?.id]);

  const getFormattedDate = (date: Date) => {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const handleActiveDatapoint = (datapoint: any, active: boolean) => {
    if (active && datapoint) {
      const formattedDate = getFormattedDate(new Date(datapoint.payload.date));
      setActiveDate(formattedDate);
      setActiveValue(datapoint.payload.value);
    } else {
      setActiveValue(null);
      setActiveDate(null);
    }
  };

  const onTimePeriodTabClick = (key: string) => {
    setActiveTimePeriod(key);
  };
  const seriesChart = nftHoldingChartDataMap.get(nftItemId!);
  let data;
  let valueChange = 0;
  let valuePercentChange = 0;
  const loading =
    isNFTSeriesChartFetching ||
    !seriesChart ||
    isLoading ||
    !walletNFTItem ||
    !nftHoldingChartDataMap.has(nftItemId!);
  if (!loading) {
    data = (
      seriesChart[
        tabToDataField[activeTimePeriod]
      ] as Array<NFTHoldingChartItem>
    ).map((nftHoldingChartItem: NFTHoldingChartItem) => {
      return {
        value: nftHoldingChartItem.priceUsd!,
        date: new Date(
          nftHoldingChartItem.day || nftHoldingChartItem.timestampDatetime!,
        ),
      };
    });

    if (data && data.length > 0) {
      valueChange =
        activeValue !== null
          ? activeValue - data[0].value!
          : data[data.length - 1].value! - data[0].value!;
      valuePercentChange =
        valueChange === 0
          ? 0
          : data[0].value === 0
            ? 100
            : (valueChange / data[0].value!) * 100;
    }
  }

  return (
    <div className="w-full h-full card py-5 px-4 md:px-6 space-y-4">
      <div className="space-y-2">
        {!loading ? (
          <>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="flex space-x-2 items-center">
                  <div
                    className="text-2xl"
                    style={{ color: getNumberColor(valueChange!) }}
                  >
                    {activeValue
                      ? convertUSDCurrency(activeValue!)
                      : convertUSDCurrency(walletNFTItem?.priceUsd)}
                  </div>
                  <span className="text-xl text-gray-text">
                    {formatNumber(valuePercentChange!)}%
                  </span>
                </div>
              </div>
              <div className="flex space-x-2 items-center">
                <div className="dark:text-border-bg text-sm">{activeDate}</div>
              </div>
            </div>
          </>
        ) : (
          <SkeletonLoader height={64} />
        )}
        <div>
          {!loading && data && data.length > 0 ? (
            <div className="h-[240px]">
              <SeriesCardLineChart
                data={data}
                isPriceGain={true}
                maxHeight={200}
                handleActiveDatapoint={handleActiveDatapoint}
              />
              <SeriesCardTabBar
                selected={activeTimePeriod}
                handleClick={onTimePeriodTabClick}
              />
            </div>
          ) : (
            loading && <SkeletonLoader height={200} />
          )}
        </div>
      </div>
    </div>
  );
};
