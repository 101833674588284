// vendor libraries
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';

// recoil
import { useThemeState } from '../../../recoil/theme';

// components
import { IconButtonWrapper } from './IconButtonWrapper';

export const ThemeButton = () => {
  const [theme, setTheme] = useThemeState();

  return (
    <IconButtonWrapper
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
    >
      {theme === 'dark' ? (
        <MoonIcon width="12.57" height="12.57" className=" text-price-green" />
      ) : (
        <SunIcon width="12.57" height="12.57" className=" text-price-green" />
      )}
    </IconButtonWrapper>
  );
};
