// vendor libraries
import { useEffect, useState } from 'react';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// components
import { SeriesCardLineChart } from '../common/SeriesCard/SeriesCardLineChart';
import { SeriesCardTabBar } from '../common/SeriesCard/SeriesCardTabBar';

// models
import { Holding } from '@/features/portfolio/models/Holding';
import { Asset } from '@/features/portfolio/models/Asset';
import { AssetPriceChartSeries } from '@/features/assets/models/ChartResponse';

// utils
import {
  formatNumber,
  formatPriceNumber,
  getNumberColor,
} from '@/utils/number';
import { getQuantityDenomination } from '@/features/portfolio/utils/holdings/holdingsUtils';
import { OptionsTab } from '../common/Tab/OptionsTab';

const tabToDataField: {
  [key: string]: keyof AssetPriceChartSeries;
} = {
  '1': 'day1Items',
  '7': 'week1Items',
  '30': 'month1Items',
  '90': 'month3Items',
  '365': 'year1Items',
  '3650': 'all',
};

interface AssetSeriesCardProps {
  asset?: Asset;
  onUpdateChartTab: (key: string) => void;
}

export const AssetSeriesCard: React.FC<AssetSeriesCardProps> = ({
  asset,
  onUpdateChartTab,
}) => {
  const {
    isAssetSeriesChartFetching,
    isAssetPriceChartFetching,
    assetsMap,
    assetPriceChartMap,
    assetHoldingChartDataMap,
    fetchAssetHoldingSeriesChart,
    isLoading,
    fetchAssetPriceChart,
    assetIdToHoldingMap,
  } = usePortfolio();

  const [activeValue, setActiveValue] = useState(null);
  const [activeDate, setActiveDate] = useState<string | null>(null);

  useEffect(() => {
    if (asset) {
      const isCryptoProtocol = asset.assetType === 'crypto_protocol';
      setChartTabs(
        isCryptoProtocol
          ? { value: 'Value' }
          : { price: 'Price', value: 'Net worth' },
      );
      setActiveChartTab(isCryptoProtocol ? 'value' : 'price');
    }
  }, [asset]);

  const [activeChartTab, setActiveChartTab] = useState<string>('Value');
  const [activeTimePeriod, setActiveTimePeriod] = useState('1');
  const [chartTabs, setChartTabs] = useState<{ [key: string]: string }>({
    price: 'Price',
    value: 'Net worth',
  });
  const [holding, setHolding] = useState<Holding>();
  const [assetMapAsset, setAssetMapAsset] = useState<Asset>();

  useEffect(() => {
    if (!isLoading && asset) {
      fetchAssetPriceChart(asset.id!);
      if (assetIdToHoldingMap.has(asset.id!)) {
        fetchAssetHoldingSeriesChart(asset.id!);
        setHolding(assetIdToHoldingMap.get(asset.id!));
      }
      if (assetsMap.has(asset.id!)) {
        setAssetMapAsset(assetsMap.get(asset.id!));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, isLoading]);

  const onChartTabClick = (key: string) => {
    setActiveChartTab(key);
    onUpdateChartTab(chartTabs[key]);
  };

  const onTimePeriodTabClick = (key: string) => {
    setActiveTimePeriod(key);
  };

  const getFormattedDate = (date: Date) => {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const handleActiveDatapoint = (datapoint: any, active: boolean) => {
    if (active && datapoint) {
      const formattedDate = getFormattedDate(new Date(datapoint.payload.date));
      setActiveDate(formattedDate);
      setActiveValue(datapoint.payload.value);
    } else {
      setActiveValue(null);
      setActiveDate(null);
    }
  };

  const formatCurrencyValue = (value: number) => {
    if (value < 0.001) {
      return value.toFixed(7);
    } else if (value < 1) {
      return value.toFixed(4);
    } else {
      return formatPriceNumber(value);
    }
  };

  let loading = true;
  if (holding) {
    loading =
      isAssetPriceChartFetching ||
      isAssetSeriesChartFetching ||
      !asset ||
      isLoading;
  }

  let data;
  let assetDatapoints;
  let value;
  let valueChange = 0;
  let valuePercentChange = 0;
  if (!loading) {
    if (holding) {
      assetDatapoints = assetHoldingChartDataMap.get(asset?.id!)?.[
        tabToDataField[activeTimePeriod]
      ];
      if (activeChartTab === 'price') {
        value =
          activeValue !== null
            ? activeValue
            : assetMapAsset?.priceUsd || asset?.priceUsd;
        data =
          assetPriceChartMap &&
          assetPriceChartMap
            .get(asset?.id!)
            ?.[tabToDataField[activeTimePeriod]]?.points.map(datapoint => {
              return {
                value: datapoint.value!,
                date: new Date(datapoint.timestamp!),
              };
            });
      } else if (activeChartTab === 'quantity') {
        value = activeValue !== null ? activeValue : holding.quantity;
        data = assetDatapoints?.map(datapoint => {
          return {
            value: datapoint.quantity!,
            date: new Date(datapoint?.timestampDatetime! || datapoint?.day!),
          };
        });
      } else if (activeChartTab === 'value') {
        value = activeValue !== null ? activeValue : holding.amount;
        data = assetDatapoints?.map(datapoint => {
          return {
            value: datapoint.value!,
            date: new Date(datapoint?.timestampDatetime! || datapoint?.day!),
          };
        });
      }
    } else {
      value = activeValue !== null ? activeValue : asset?.priceUsd;
      data =
        assetPriceChartMap &&
        assetPriceChartMap
          .get(asset?.id!)
          ?.[tabToDataField[activeTimePeriod]]?.points.map(datapoint => {
            return {
              value: datapoint.value,
              date: new Date(datapoint.timestamp!),
            };
          });
    }

    if (data && data.length > 0) {
      valueChange =
        activeValue !== null
          ? activeValue - data[0].value!
          : data[data.length - 1].value! - data[0].value!;
      valuePercentChange =
        valueChange === 0
          ? 0
          : data[0].value === 0
          ? 100
          : (valueChange / data[0].value!) * 100;
    } else if (holding) {
      valueChange = holding.valueChange;
      valuePercentChange =
        valueChange === 0
          ? 0
          : holding.amount === 0
          ? 100
          : (valueChange / holding.amount) * 100;
    }
  }

  return (
    <div className="h-[336px] card py-5 px-4 md:px-6 space-y-4">
      <div className="relative">
        {!loading ? (
          <>
            {holding && (
              <div className="absolute top-2 right-1">
                <OptionsTab
                  tabs={chartTabs}
                  activeTab={activeChartTab}
                  onClick={onChartTabClick}
                />
              </div>
            )}
            <div className="space-y-2">
              <div className="capitalize flex items-center space-x-4">
                <img
                  src={asset?.logoUrl}
                  alt={`${asset?.name} logo`}
                  className="h-12 w-12 rounded-full object-scale-down border solid border-gray-text"
                />
                <h1>{asset?.name}</h1>
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <div className="flex space-x-2 items-center">
                    <div className="text-2xl">
                      {activeChartTab === 'quantity'
                        ? `${formatNumber(value!)} ${getQuantityDenomination(
                            holding!,
                          )}`
                        : asset?.assetType === 'nft' &&
                          activeDate !== null &&
                          activeChartTab === 'price'
                        ? `${formatCurrencyValue(value!)} ${
                            asset.nativeCurrency
                          }`
                        : formatCurrencyValue(value!)}
                    </div>
                    {asset?.assetType === 'nft' &&
                      activeChartTab === 'price' &&
                      !activeDate && (
                        <span className="text-xl text-gray-text">
                          {formatPriceNumber(asset?.priceNative!)}{' '}
                          {asset.nativeCurrency}
                        </span>
                      )}
                  </div>
                  <div className="flex space-x-2 items-center">
                    {valueChange !== undefined && (
                      <div style={{ color: getNumberColor(valueChange!) }}>
                        {activeChartTab === 'quantity'
                          ? `${formatNumber(
                              valueChange!,
                            )} ${getQuantityDenomination(holding!)}`
                          : formatCurrencyValue(valueChange!)}
                      </div>
                    )}
                    {valuePercentChange !== undefined && (
                      <div>{formatNumber(valuePercentChange!)}%</div>
                    )}
                  </div>
                </div>
                <div className="flex space-x-2 items-center">
                  <div className="dark:text-border-bg text-sm">
                    {activeDate}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <SkeletonLoader height={64} />
        )}
        <div>
          {!loading && data && data.length > 0 ? (
            <div className="h-40">
              <SeriesCardLineChart
                data={data}
                isPriceGain={valuePercentChange! >= 0}
                maxHeight={140}
                handleActiveDatapoint={handleActiveDatapoint}
              />
              <SeriesCardTabBar
                selected={activeTimePeriod}
                handleClick={onTimePeriodTabClick}
                hideAllOption={activeChartTab === 'price'}
              />
            </div>
          ) : (
            loading && <SkeletonLoader height={226} />
          )}
        </div>
      </div>
    </div>
  );
};
