import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  getCompletedCourses,
  getCourseDetails,
  getCourseTags,
  getCourses,
  getLearnCenterData,
  getMyShares,
  getStartedCourses,
  postCourseStart,
  postGenerateUrl,
  postSubmitAnswer,
} from '../api/learn';

export const LearnContext = createContext({} as any);

export function useLearn() {
  return useContext(LearnContext);
}

export const LearnProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [courseTags, setCourseTags] = useState(null);
  const [courses, setCourses] = useState(null);
  const [myStats, setMyStats] = useState(null);
  const [myShares, setMyShares] = useState(null);
  const [featuredCourses, setFeaturedCourses] = useState(null);
  const [trendingCourses, setTrendingCourses] = useState(null);
  const [learnCenterData, setLearnCenterData] = useState(null);
  const [page, setPage] = useState(1);
  const [courseDetails, setCourseDetails] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [activeList, setActiveList] = useState('all');
  const [activeStep, setActiveStep] = useState('steps');

  const [selectedCourse, setSelectedCourse] = useState([]);

  const resetData = () => {
    setActiveStep('steps');
    setCourseDetails(null);
    setActiveQuestion(0);
  };

  const fetchCourseTags = async () => {
    try {
      setIsActionLoading(true);
      const resp = await getCourseTags();
      if (resp.status === 200) {
        setCourseTags(resp.data.tags);
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
    }
  };

  const generateShareUrl = async payload => {
    try {
      const resp = await postGenerateUrl(payload);

      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {}
  };

  const fetchCourses = async payload => {
    try {
      page === 1 && setIsLoading(true);
      let finalPayload = `?page=${payload.page}&limit=${payload.limit}`;
      const { tag_ids } = payload;
      if (tag_ids && tag_ids.length > 0) {
        tag_ids.forEach(item => {
          finalPayload += `&tag_ids=${item}`;
        });
      }
      const resp = await getCourses(finalPayload);
      if (resp.status === 200) {
        if (page === 1) {
          setCourses(resp.data.courses);
        } else {
          setCourses(prevState => [...prevState, ...resp.data.courses]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchFeaturedCourse = async () => {
    try {
      const resp = await getCourses(`?tag_ids=featured`);

      if (resp.status === 200) {
        setFeaturedCourses(resp.data.courses);
      }
    } catch (error) {}
  };

  const fetchTrendingCourse = async () => {
    try {
      const resp = await getCourses(`?tag_ids=trending`);

      if (resp.status === 200) {
        setTrendingCourses(resp.data.courses);
      }
    } catch (error) {}
  };

  const fetchStartedCourses = async payload => {
    try {
      page === 1 && setIsLoading(true);
      const resp = await getStartedCourses(payload);
      if (resp.status === 200) {
        if (page === 1) {
          setCourses(resp.data.courses);
        } else {
          setCourses(prevState => [...prevState, ...resp.data.courses]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchCompletedCourses = async payload => {
    try {
      page === 1 && setIsLoading(true);
      const resp = await getCompletedCourses(payload);
      if (resp.status === 200) {
        if (page === 1) {
          setCourses(resp.data.courses);
        } else {
          setCourses(prevState => [...prevState, ...resp.data.courses]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchMyShares = async () => {
    try {
      page === 1 && setIsLoading(true);
      const resp = await getMyShares();
      if (resp.status === 200) {
        setMyShares(resp.data.my_shares);
        setMyStats(resp.data.my_stats);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchLearnCenterData = async () => {
    try {
      const resp = await getLearnCenterData();

      if (resp.status === 200) {
        setLearnCenterData(resp.data.learn_center);
      }
    } catch (error) {}
  };

  const fetchCourseDetails = async id => {
    try {
      setIsLoading(true);

      const resp = await getCourseDetails({ course_id: id });

      if (resp.status === 200) {
        setCourseDetails(resp.data);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const startCourse = async id => {
    try {
      setIsActionLoading(true);
      const resp = await postCourseStart({ course_id: id });
      if (resp.status === 200) {
        setCourseDetails(resp.data);
        resetData();
        analytics.track('learn_course_start', { id });
        navigate(`/learn/${id}/start`);
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail);
    }
  };

  const submitAnswer = async payload => {
    try {
      setIsActionLoading(true);
      const resp = await postSubmitAnswer(payload);

      if (resp.status === 200) {
        if (activeQuestion >= courseDetails?.course?.questions?.length - 1) {
          setActiveStep('successScreen');
          analytics.track('learn_course_step_switch_to', {
            name: 'successScreen',
          });
        } else {
          setActiveQuestion(activeQuestion + 1);
        }

        toast.success('Correct Answer!');
      }

      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      if (
        error?.response?.data?.detail === "You've already completed this course"
      ) {
        setActiveStep('successScreen');
        analytics.track('learn_course_step_switch_to', {
          name: 'successScreen',
        });
      } else
        toast.error(error?.response?.data?.detail || 'Something went wrong!');
    }
  };

  return (
    <LearnContext.Provider
      value={{
        isLoading,
        isActionLoading,
        fetchCourseTags,
        courseTags,
        fetchCourses,
        fetchLearnCenterData,

        selectedCourse,
        setSelectedCourse,
        courses,
        setCourses,
        page,
        setPage,
        learnCenterData,
        courseDetails,
        fetchCourseDetails,
        submitAnswer,
        startCourse,
        activeQuestion,
        activeList,
        setActiveList,
        activeStep,
        setActiveStep,
        fetchStartedCourses,
        fetchCompletedCourses,
        resetData,
        fetchFeaturedCourse,
        featuredCourses,
        fetchTrendingCourse,
        trendingCourses,
        fetchMyShares,
        myStats,
        myShares,
        generateShareUrl,
      }}
    >
      {children}
    </LearnContext.Provider>
  );
};
