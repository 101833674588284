import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useDealsLegacy } from '@/hooks/useDealsLegacy';

import { useThemeState } from '@/recoil/theme';

export default function FortuneCookieDetails({ open, setOpen }) {
  const { fortuneCookie } = useDealsLegacy();

  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all  sm:w-full lg:max-w-2xl sm:my-8 sm:max-w-lg sm:p-6 ${background}`}
              >
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl p-8 font-bold items-stretch ${textColor}`}
                    >
                      {fortuneCookie?.fortune_cookie?.num_tickets ? (
                        <p>
                          Congrats! you won
                          <div className="flex flex-row justify-center">
                            <img
                              src="/assets/icons/ticket.png"
                              alt="coin"
                              className="w-8"
                            />
                            <span className="text-4xl">
                              {fortuneCookie?.fortune_cookie?.num_tickets}{' '}
                              tickets
                            </span>
                          </div>
                          {fortuneCookie?.num_coins_final > 0 && (
                            <div>
                              <img
                                src="/assets/icons/coin.png"
                                alt="coin"
                                className="w-8 inline-block"
                              />
                              <span className="text-4xl">
                                {fortuneCookie?.num_coins_final} coins
                              </span>
                            </div>
                          )}
                        </p>
                      ) : (
                        'This Fortune Cookie was empty. Better luck next time!'
                      )}
                      <div className="text-gray-text text-2xl order-1 mt-8">
                        You can open your fortune cookie again in 24 hours for a
                        chance to win more tickets!
                      </div>
                    </Dialog.Title>
                    <img
                      src="/assets/images/fortuneCookieOpening.png"
                      alt="fortuneCookie"
                    />
                  </div>
                </div>
                {fortuneCookie?.num_coins_final > 0 && (
                  <div className="flex flex-row justify-between items-center mb-4">
                    <div className="flex flex-col items-center text-xl text-primary-orange border border-primary-orange rounded-xl p-3">
                      <div className="flex flex-row items-center justify-center space-x-3">
                        <img
                          src="/assets/icons/coin.png"
                          className="w-6"
                          alt=""
                        />
                        <span>{fortuneCookie?.num_coins_original}</span>
                      </div>
                      <div>Base Coins</div>
                    </div>
                    {fortuneCookie?.coins_multiplier > 0 ? (
                      <div className="text-primary border border-primary rounded-lg p-3">
                        {fortuneCookie?.coins_multiplier} X
                      </div>
                    ) : (
                      <div className="border border-gray-500 text-gray-500 rounded-lg p-3">
                        No mulitplier
                      </div>
                    )}
                    <div className="flex flex-col items-center text-xl text-primary border border-primary rounded-xl p-3">
                      <div className="flex flex-row items-center justify-center space-x-3">
                        <img
                          src="/assets/icons/coin.png"
                          className="w-6"
                          alt=""
                        />
                        <span>{fortuneCookie?.num_coins_final}</span>
                      </div>
                      <div>Final Coins</div>
                    </div>
                  </div>
                )}
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent bg-price-green px-4 py-2 text-sm font-semibold  shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm text-tabs-bg`}
                    onClick={() => setOpen(false)}
                  >
                    Continue
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
