// imports
import { usePortfolio } from '@/hooks/usePortfolio';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import { getShortenedAddress } from '@/utils/address';

interface NFTItemCollectionInfoCardProps {
  walletNFTItem?: WalletNFTItem;
}

export const NFTItemCollectionInfoCard: React.FC<
  NFTItemCollectionInfoCardProps
> = ({ walletNFTItem }) => {
  const { isLoading, wallets } = usePortfolio();

  const userWallet =
    Array.isArray(wallets) && walletNFTItem
      ? wallets.find(wallet => wallet.id === walletNFTItem.userWalletId)
      : null;

  return (
    <div className="card w-full h-max p-3 md:p-6">
      {!isLoading && walletNFTItem ? (
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col rounded-2xl font-bold space-y-2 md:space-y-6">
            <span className="text-lg">Collections</span>
            <div className="card-full min-w-[200px] rounded-3xl flex flex-row space-x-3 p-3">
              <div className="w-11 h-11 rounded-full bg-dark dark:bg-white">
                <img
                  src={walletNFTItem.nftItem?.thumbnailUrl}
                  className="w-11 h-11 rounded-full"
                  alt="icon"
                ></img>
              </div>
              <span>
                Name by
                <br />
                <div className="text-primary">
                  {walletNFTItem?.nftItem?.collectionName}
                </div>
              </span>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl font-bold space-y-2 md:space-y-6">
            <span className="text-lg">Owner</span>
            <div className="card-full min-w-[200px] flex flex-row items-center space-x-3 p-3">
              <img
                src={userWallet?.logoUrl}
                className="w-11 h-11 rounded-full"
                alt="icon"
              />
              <span className="text-sm md:text-base">
                {getShortenedAddress(userWallet?.address!)}
              </span>
            </div>
          </div>
          {/* <div className="flex flex-col rounded-2xl font-bold space-y-2 md:space-y-6">
            <span className="text-lg">Marketplace</span>
            <div className="card-full min-w-[200px] flex flex-row items-center space-x-3 p-3">
              <div className="w-11 h-11 rounded-full bg-dark dark:bg-white"></div>
              <span>Tensor</span>
            </div>
          </div> */}
        </div>
      ) : (
        <SkeletonLoader height={160} />
      )}
    </div>
  );
};
