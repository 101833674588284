import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { ForgotPasswordForm } from '../../components/authentication/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title="AssetDash | Forgot Password" />
      <div
        className=" py-22p min-h-screen"
        style={{
          backgroundImage:
            "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
        }}
      >
        <div className="text-32 leading-none text-center mt-8 mb-8">
          Forgot Password
        </div>
        <ForgotPasswordForm />
      </div>
    </>
  );
};

export default ForgotPasswordPage;
