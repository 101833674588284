import moment from 'moment';

import { Auction } from '@/features/market/auctions/models/Auctions';
import { Countdown } from '@/components/v2/common/Countdown/Countdown';

interface AuctionStatusCardProps {
  auction: Auction;
}

export const AuctionStatusCard: React.FC<AuctionStatusCardProps> = ({
  auction,
}) => {
  let title;
  let subText;
  let countdown;
  let countdownStyles;
  if (auction.isNotLiveYet()) {
    title = 'Not Live Yet';
    subText = `This auction opens the blind phase in`;
    countdown = <Countdown targetTime={moment(auction.startOpenTime + 'Z')} />;
    countdownStyles = 'bg-accent border-accent';
  } else if (auction.isOpenPhase()) {
    title = 'Blind Phase';
    subText = `The auction is in the Blind Phase. The Open Phase begins in`;
    countdown = <Countdown targetTime={moment(auction.startLockedTime)} />;
    countdownStyles = 'bg-primary border-primary';
  } else if (auction.isLockedPhase()) {
    title = 'Final Phase';
    subText = `The auction is now in the Final Phase. Auction ends in`;
    countdown = <Countdown targetTime={moment(auction.endTime)} />;
    countdownStyles = 'bg-primary border-primary';
  } else {
    title = 'Completed';
    subText = `This auction has completed`;
    countdownStyles =
      'bg-fade-text-light dark:bg-fade-text-dark border-fade-text-light dark:border-fade-text-dark';
  }

  return (
    <div
      className={`flex flex-col justify-center w-full px-4 py-1 border-1 bg-opacity-10 dark:bg-opacity-10 rounded-2xl text-element-text-light dark:text-element-text-dark ${countdownStyles}`}
    >
      <div className="font-bold text-xl">{title}</div>
      <div className="text-sm">{subText}</div>
      <div>{countdown}</div>
    </div>
  );
};
