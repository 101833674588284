// vendor libraries
import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';

// components
import { MemberCard } from '@/components/v2/members/MemberCard';
import { TierBenefits } from '@/components/v2/members/TierBenefits';
import { MembershipTier } from '@/components/v2/members/MembershipTier';
import { GoldBoostModal } from '@/components/v2/members/GoldBoostModal';
import { SocialLink } from '@/components/v2/members/SocialLink';

// apis
import { getUserMemberCenter } from '../api/user';
import { getUserSocialCenter } from '../api/user';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';

export default function MemberCenterPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [memberCenterData, setMemberCenterData] = useState<any>({});
  const [socialCenterData, setSocialCenterData] = useState<any>({});
  const [openGoldBoost, setOpenGoldBoost] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchMemberCenterData();
  }, []);

  const fetchMemberCenterData = async () => {
    setIsLoading(true);
    const memberCenterResponse = await getUserMemberCenter();
    const socialCenterResponse = await getUserSocialCenter();

    setMemberCenterData(memberCenterResponse.data.member_center);
    setSocialCenterData(socialCenterResponse.data.social_center);
    setIsLoading(false);
  };

  return (
    <div>
      <SuperSEO title="AssetDash | Member Center" lang="en_IE" />
      <div className="main-content mx-auto space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <MemberCard
            memberCenterData={memberCenterData}
            setOpenGoldBoost={setOpenGoldBoost}
          />
          <TierBenefits
            memberCenterData={memberCenterData}
            setOpenGoldBoost={setOpenGoldBoost}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="grid grid-cols-1 gap-4">
            <MembershipTier
              memberCenterData={memberCenterData}
              openFAQ={() => setShowFaq(true)}
            />
            <div className="flex space-x-4 text-center items-center justify-between card py-4 px-6 rounded-12">
              <p>Member Number</p>
              <div className="flex space-x-2 items-center">
                <p className="text-price-red">
                  {memberCenterData?.member_number}
                </p>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      memberCenterData?.member_number,
                    );
                    toast.success('Member Number Copied!');
                  }}
                >
                  <DocumentDuplicateIcon
                    height={20}
                    width={20}
                    className="text-price-red"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <MyRewards /> */}
          <div className="flex flex-col space-y-4">
            {isLoading ? (
              <SkeletonLoader height={400} className="rounded-lg" />
            ) : (
              <>
                <SocialLink
                  platform="discord"
                  value={socialCenterData.discord}
                />
                <SocialLink
                  platform="twitter"
                  value={socialCenterData.twitter}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {openGoldBoost && (
        <GoldBoostModal
          open={openGoldBoost}
          setOpen={setOpenGoldBoost}
          partners={memberCenterData?.member_reasons}
        />
      )}
    </div>
  );
}
