// models
import { AssetDashExpedition } from '../../features/expeditions/models/AssetDashExpeditions';
import { PartnerExpedition } from '../../features/expeditions/models/PartnerExpeditions';
import { AssetDashExpeditionTag } from '../../features/expeditions/models/AssetDashExpeditionTag';
import { PartnerExpeditionTag } from '../../features/expeditions/models/PartnerExpeditionTag';

// components
import { AssetdashExpeditionCard } from './AssetdashExpeditionCard';
import { PartnerExpeditionCard } from './PartnerExpeditionCard';

// hooks
import { useNavigate } from 'react-router-dom';

interface AssetdashExpeditionsTabProps {
  assetdashExpeditions: AssetDashExpedition[];
  partnerExpeditions: PartnerExpedition[];
  assetdashExpeditionTags: AssetDashExpeditionTag[];
  partnerExpeditionTags: PartnerExpeditionTag[];
}

export const AvailableExpeditionsTab: React.FC<
  AssetdashExpeditionsTabProps
> = ({
  assetdashExpeditions,
  partnerExpeditions,
  assetdashExpeditionTags,
  partnerExpeditionTags,
}) => {
  const navigate = useNavigate();

  return (
    <div className="space-y-4">
      {assetdashExpeditions.length > 0 && (
        <div className="space-y-2">
          <div className="text-2xl">App Expeditions</div>
          <div className="flex items-start flex-wrap">
            {assetdashExpeditionTags.map((tag: AssetDashExpeditionTag) => (
              <div
                key={tag.id}
                className="h-15 cursor-pointer text-xs m-2 py-2 px-4 border-1 bg-opacity-10 border-black dark:border-gray-text rounded-full whitespace-nowrap"
                onClick={() =>
                  navigate(`/expeditions/assetdash/tag/${tag.name}`)
                }
              >
                <div className="text-black dark:text-gray-text text-sm">
                  {tag.name}
                </div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {assetdashExpeditions.map(assetdashExpedition => (
              <AssetdashExpeditionCard
                key={assetdashExpedition.id}
                assetdashExpedition={assetdashExpedition}
              />
            ))}
          </div>
        </div>
      )}
      {partnerExpeditions.length > 0 && (
        <div className="space-y-2">
          <div className="text-2xl">Ecosystem</div>
          <div className="flex items-start flex-wrap">
            {partnerExpeditionTags.map((tag: PartnerExpeditionTag) => (
              <div
                key={tag.id}
                className="h-15 cursor-pointer text-xs m-2 py-2 px-4 border-1 bg-opacity-10 border-black dark:border-gray-text rounded-full whitespace-nowrap"
                onClick={() => navigate(`/expeditions/partner/tag/${tag.name}`)}
              >
                <div className="text-black dark:text-gray-text text-sm">
                  {tag.name}
                </div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {partnerExpeditions.map(partnerExpedition => (
              <PartnerExpeditionCard
                key={partnerExpedition.id}
                partnerExpedition={partnerExpedition}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
