export type AssetActionButton = {
  name: string;
  url: string;
  imageUrl: string;
  featured?: boolean;
  useAuth?: boolean;
};

export type AssetActionButtons = {
  socialButtons: AssetActionButton[];
  toolButtons: AssetActionButton[];
  tradeButtons: AssetActionButton[];
};

export class Asset {
  id?: string;
  assetType?: string;
  assetTypeGroup?: string;
  ticker?: string;
  assetNetwork?: string;
  name?: string;
  logoUrl?: string;
  slug?: string;
  tickerDisplay?: string;
  nativeCurrency?: string;
  priceUsd?: number;
  priceNative?: number;
  marketCap?: number;
  performanceDay?: number;
  performanceWeek?: number;
  performanceMonth?: number;
  performanceYear?: number;
  week52High?: number;
  week52Low?: number;
  targetLow?: number;
  targetHigh?: number;
  targetMean?: number;
  targetMedian?: number;
  tags?: { [key: string]: any }[];
  description?: string;
  websiteUrl?: string;
  twitterUrl?: string;
  discordUrl?: string;
  actionButtons?: AssetActionButtons;

  constructor(data?: Partial<Asset>) {
    Object.assign(this, data);
  }

  static fromJSON(json: any): Asset {
    const actionButtons = json['action_buttons']
      ? {
          socialButtons: json['action_buttons']['social_buttons']?.map(
            (item: any) => ({
              name: item['name'],
              url: item['url'],
              imageUrl: item['image_url'],
              featured: item['featured'],
              useAuth: item['use_auth'],
            }),
          ),
          toolButtons: json['action_buttons']['tool_buttons']?.map(
            (item: any) => ({
              name: item['name'],
              url: item['url'],
              imageUrl: item['image_url'],
              featured: item['featured'],
              useAuth: item['use_auth'],
            }),
          ),
          tradeButtons:
            json['action_buttons']['trade_buttons']
              ?.map((item: any) => ({
                name: item['name'],
                url: item['url'],
                imageUrl: item['image_url'],
                featured: item['featured'],
                useAuth: item['use_auth'],
              }))
              .filter(
                (button: AssetActionButton) => button.name !== 'Dash Bot',
              ) ?? [],
        }
      : undefined;

    return new Asset({
      id: json['id'],
      assetType: json['asset_type'],
      assetTypeGroup: json['asset_type_group'],
      ticker: json['ticker'],
      assetNetwork: json['asset_network'],
      name: json['name'],
      logoUrl: json['logo_url'],
      slug: json['slug'],
      tickerDisplay: json['ticker_display'],
      nativeCurrency: json['native_currency'],
      priceUsd: json['price_usd'],
      priceNative: json['price_native'],
      marketCap: json['market_cap'],
      performanceDay: json['performance_day'],
      performanceWeek: json['performance_week'],
      performanceMonth: json['performance_month'],
      performanceYear: json['performance_year'],
      week52High: json['week_52_high'],
      week52Low: json['week_52_low'],
      targetLow: json['target_low'],
      targetHigh: json['target_high'],
      targetMean: json['target_mean'],
      targetMedian: json['target_median'],
      tags: json['tags'] ? Array.from(json['tags']) : undefined,
      description: json['description'],
      websiteUrl: json['website_url'],
      twitterUrl: json['twitter_url'],
      discordUrl: json['discord_url'],
      actionButtons: actionButtons,
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      asset_type: this.assetType,
      asset_type_group: this.assetTypeGroup,
      ticker: this.ticker,
      asset_network: this.assetNetwork,
      name: this.name,
      logo_url: this.logoUrl,
      slug: this.slug,
      ticker_display: this.tickerDisplay,
      native_currency: this.nativeCurrency,
      price_usd: this.priceUsd,
      price_native: this.priceNative,
      market_cap: this.marketCap,
      performance_day: this.performanceDay,
      performance_week: this.performanceWeek,
      performance_month: this.performanceMonth,
      performance_year: this.performanceYear,
      week_52_high: this.week52High,
      week_52_low: this.week52Low,
      target_low: this.targetLow,
      target_high: this.targetHigh,
      target_mean: this.targetMean,
      target_median: this.targetMedian,
      tags: this.tags,
      description: this.description,
      website_url: this.websiteUrl,
      twitter_url: this.twitterUrl,
      discord_url: this.discordUrl,
      action_buttons: this.actionButtons
        ? {
            social_buttons: this.actionButtons.socialButtons.map(
              (v: AssetActionButton) => ({
                name: v.name,
                url: v.url,
                image_url: v.imageUrl,
                featured: v.featured,
                use_auth: v.useAuth,
              }),
            ),
            tool_buttons: this.actionButtons.toolButtons.map(
              (v: AssetActionButton) => ({
                name: v.name,
                url: v.url,
                image_url: v.imageUrl,
                featured: v.featured,
                use_auth: v.useAuth,
              }),
            ),
            trade_buttons: this.actionButtons.tradeButtons
              .filter((v: AssetActionButton) => v.name !== 'Dash Bot')
              .map((v: AssetActionButton) => ({
                name: v.name,
                url: v.url,
                image_url: v.imageUrl,
                featured: v.featured,
                use_auth: v.useAuth,
              })),
          }
        : undefined,
    };
  }

  copyWith(data: Partial<Asset>): Asset {
    return new Asset({ ...this, ...data });
  }
}
