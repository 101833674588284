export default function CurrentPrizes({ prizes, page }) {
  return (
    <>
      {prizes.slice(0, 4).map(prize => {
        return (
          <div
            key={prize.id}
            className="p-4 bg-white dark:bg-black space-y-6 rounded-xl"
          >
            <img
              src={prize.image_url}
              className="rounded-xl w-full object-cover"
              style={{ height: '240px' }}
              alt="prize"
            />
            <div className="space-y-2">
              <div>
                <p className="text-deals-title text-lg">Provider</p>
                <p className="text-2xl font-bold add-overflow-dot">
                  {prize.title}
                </p>
              </div>
              <div>
                <p className="text-deals-title text-lg">Estimated Value</p>
                <p className="text-2xl font-bold">
                  {page
                    ? prize.value > 0
                      ? '$' + prize.value?.toFixed(2)
                      : '?'
                    : '$' + prize.value?.toFixed(2)}
                </p>
              </div>
              <div>
                <p className="text-deals-title text-lg">Number of Winners</p>
                <p className="text-2xl font-bold">
                  {page
                    ? prize.num_winners > 0
                      ? prize.num_winners
                      : '?'
                    : prize.num_winners}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
