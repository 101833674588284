// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { SuperGiveawayDetailsContainer } from '@/components/v2/games/super-giveaway/SuperGiveawayDetailsContainer';

export default function SuperGiveawayDetailsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SuperSEO title={`AssetDash | Super Drops`} lang="en_IE" />
      <SuperGiveawayDetailsContainer />
    </div>
  );
}
