import { useEffect, useState, useRef } from 'react';

// api
import { getCoinDistributions } from '../../api/coin';

// models
import {
  CoinDistributionsResponse,
  CoinDistribution,
} from '../../features/coins/models/CoinDistributions';

import { CoinDistributionSortByModal } from './modals/CoinDistributionSortByModal';
import { CoinDistributionFilterByModal } from './modals/CoinDistributionFilterByModal';

const displayValueMap: { [key: string]: string } = {
  created: 'Created',
  unlock_date: 'Unlock Date',
  all: 'All',
  unlocked: 'Unlocked',
  locked: 'Locked',
};

export const CoinDistributions = () => {
  const [coinDistributions, setCoinDistributions] =
    useState<CoinDistribution[]>();
  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const [isFilterByModalOpen, setIsFilterByModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('created');
  const [filterBy, setFilterBy] = useState('all');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const sentinel = useRef(null);

  useEffect(() => {
    const currentSentinel = sentinel.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !loading && hasMore) {
          // Increment your page state here
          setPage(prevPage => prevPage + 1);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    fetchCoinDistributions().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, filterBy, page]);

  const fetchCoinDistributions = async () => {
    try {
      const response = await getCoinDistributions(sortBy, filterBy, page);
      const newCoinDistributions = CoinDistributionsResponse.fromJson(
        response.data,
      ).coinDistributions;
      if (newCoinDistributions?.length === 0) {
        setHasMore(false);
        return;
      }
      setCoinDistributions(prevCoinDistributions => [
        ...(prevCoinDistributions || []),
        ...newCoinDistributions!,
      ]);
    } catch (error) {
      // Handle the error
    }
  };

  return (
    <div className="space-y-10">
      <div className="flex justify-between flex-row space-y-2">
        <div className="text-3xl font-semibold">Distributions</div>
        <div className="flex space-x-2">
          <div
            className="flex space-x-1 items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-white border-1 px-4 py-2 rounded-3xl text-md cursor-pointer"
            onClick={() => setIsSortByModalOpen(true)}
          >
            <img
              src="/assets/icons/sort_by.png"
              alt="sort_by"
              className="h-4"
            />
            <div>{displayValueMap[sortBy]}</div>
          </div>
          <div
            className="flex space-x-1 items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-white border-1 px-4 py-2 rounded-3xl text-md cursor-pointer"
            onClick={() => setIsFilterByModalOpen(true)}
          >
            <img
              src="/assets/icons/filter_by.png"
              alt="filter_by"
              className="h-4"
            />
            <div>{displayValueMap[filterBy]}</div>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        {coinDistributions?.map((distribution: CoinDistribution, i) => {
          let endMultiplerColor = 'border-gray-400 dark:border-gray-600';
          if (
            distribution.coinsMultiplier > distribution.coinsMultiplierOriginal
          ) {
            endMultiplerColor = 'border-price-green';
          } else if (
            distribution.coinsMultiplier < distribution.coinsMultiplierOriginal
          ) {
            endMultiplerColor = 'border-negative';
          }

          return (
            <div
              key={i}
              className="bg-white dark:bg-deals-card-top p-4 rounded-xl space-y-2"
            >
              <div className="flex justify-between items-start space-y-1 flex-row">
                <div className="flex space-x-2">
                  <div className="mt-1 min-w-6">
                    <img
                      className="rounded-full"
                      src="/assets/icons/coin.png"
                      alt="Coin"
                      height={24}
                      width={24}
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-semibold text-gray-600 dark:text-gray-300 text-lg">
                      {distribution.coinsTotal} AssetDash Coins
                    </div>
                    <div className="text-sm text-gray-text max-w-40 md:max-w-md lg:max-w-lg">
                      {distribution.distributionDescription}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex space-x-1 whitespace-nowrap items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 px-2 py-1 h-8 rounded-2xl text-sm">
                    <img
                      src="/assets/icons/coin_check.png"
                      alt="check"
                      height={16}
                      width={16}
                    />
                    <div>{distribution.getCreatedDay()}</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start space-x-2 space-y-2 flex-row">
                <div
                  className={`flex space-x-1 whitespace-nowrap items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 ${
                    distribution.isLocked()
                      ? 'border-accent'
                      : 'border-price-green'
                  }  border-1 py-1 px-2 rounded-xl text-sm`}
                >
                  <img
                    src="/assets/icons/unlock.png"
                    alt="unlock"
                    className="h-4"
                  />
                  <div>{distribution.getLockDateDisplay()}</div>
                </div>
                <div className="flex space-x-2 items-center">
                  {distribution.coinsMultiplierOriginal > 1 ||
                  distribution.coinsMultiplier > 1 ? (
                    <>
                      <div className="flex space-x-1 items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-gray-600 border-1 px-2 rounded-xl text-sm">
                        <img
                          src="/assets/icons/coin_multiplier_zap.png"
                          alt="coin_multiplier_zap"
                          className="h-4"
                        />
                        <div>{`${distribution.coinsMultiplierOriginal}x`}</div>
                      </div>
                      <img
                        src="/assets/icons/arrow_right.png"
                        alt="arrow-right"
                        className="h-4"
                      />
                      <div
                        className={`${endMultiplerColor} flex space-x-1 items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300  border-1 px-2 rounded-xl text-sm`}
                      >
                        <img
                          src="/assets/icons/coin_multiplier_zap.png"
                          alt="coin_multiplier_zap"
                          className="h-4"
                        />
                        <div>
                          {distribution.isLocked()
                            ? '?'
                            : `${distribution.coinsMultiplier}x`}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex space-x-1 items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-gray-600 border-1 px-2 rounded-xl text-sm">
                        <img
                          src="/assets/icons/coin_multiplier_zap.png"
                          alt="coin_multiplier_zap"
                          className="h-4"
                        />
                        <div>Multiplier Not Eligible</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center" ref={sentinel}>
        {hasMore && 'Loading...'}
      </div>
      <CoinDistributionSortByModal
        isOpen={isSortByModalOpen}
        closeModal={() => setIsSortByModalOpen(false)}
        initialSortBy={sortBy}
        onSubmit={(selected: string) => {
          setPage(1);
          setCoinDistributions([]);
          setHasMore(true);
          setSortBy(selected);
          setIsSortByModalOpen(false);
        }}
      />
      <CoinDistributionFilterByModal
        isOpen={isFilterByModalOpen}
        closeModal={() => setIsFilterByModalOpen(false)}
        initialFilterBy={filterBy}
        onSubmit={(selected: string) => {
          setPage(1);
          setCoinDistributions([]);
          setHasMore(true);
          setFilterBy(selected);
          setIsFilterByModalOpen(false);
        }}
      />
    </div>
  );
};
