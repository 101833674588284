// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

// hooks
import { useExpeditions } from '../../hooks/useExpeditions';

// components
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// utils
import { formatNumber } from '../../utils/number';

export const PartnerExpeditionDetailContainer = () => {
  const { id } = useParams();
  const {
    getPartnerExpeditionDetail,
    clearPartnerExpeditionDetail,
    partnerExpeditionDetail,
    launchPartnerExpedition,
  } = useExpeditions();
  const [isLoading, setIsLoading] = useState(true);
  const [isLaunchExpeditionLoading, setIsLaunchExpeditionLoading] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      loadExpedition(id);
    } else {
      clearPartnerExpeditionDetail();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadExpedition = async (id: string) => {
    setIsLoading(true);
    try {
      await getPartnerExpeditionDetail(id);
    } catch (err) {
      navigate('/expeditions');
    }
    setIsLoading(false);
  };

  const launchExpedition = async () => {
    setIsLaunchExpeditionLoading(true);
    try {
      await launchPartnerExpedition(id!);
      window.open(partnerExpeditionDetail?.partnerExpedition?.expeditionUrl);
    } catch (err) {
    } finally {
      setIsLaunchExpeditionLoading(false);
    }
  };

  return (
    <div className="mx-auto main-content grid grid-cols-1 gap-y-6 md:gap-y-0 md:gap-6">
      {!isLoading && partnerExpeditionDetail ? (
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-8 mt-8 px-4">
          <div className="md:col-span-2 lg:col-span-2 bg-white p-3 dark:bg-deals-card rounded-13 h-max">
            <img
              src={partnerExpeditionDetail.partnerExpedition?.imageUrl}
              className="w-full h-96 object-contain mx-auto"
              alt="logo"
            />
          </div>
          <div className="text-center md:col-span-2 lg:col-span-3 space-y-4">
            <div className="flex flex-row space-x-2 justify-between items-center">
              <div className={`font-semibold text-left text-3xl`}>
                {partnerExpeditionDetail.partnerExpedition?.name}
              </div>
            </div>
            <div className="inline-flex w-full justify-center items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-lg">
              <div className="pl-2">
                <img
                  className="rounded-full"
                  src="/assets/icons/coin.png"
                  alt="Coin"
                  height={24}
                  width={24}
                />
              </div>
              <span className="pr-2 text-sm">
                {partnerExpeditionDetail.partnerExpeditionUser?.earnedCoins
                  ? `You earned ${formatNumber(
                      partnerExpeditionDetail.partnerExpeditionUser
                        ?.earnedCoins || 0,
                      0,
                    )} coins`
                  : `You can earn ${formatNumber(
                      partnerExpeditionDetail.partnerExpedition?.rewardCoins ||
                        0,
                      0,
                    )} coins`}
              </span>
            </div>
            <div
              className={`grid 
                  'grid-cols-1'
                } gap-6 my-8`}
            >
              <div className="text-left space-y-2">
                <p className="text-xl font-bold">Description</p>
                <p>{partnerExpeditionDetail.partnerExpedition?.description}</p>
              </div>
            </div>

            {partnerExpeditionDetail?.partnerExpedition?.instructions &&
              partnerExpeditionDetail?.partnerExpedition?.instructions.length >
                0 && (
                <div className="mt-2 bg-white dark:bg-deals-card-top p-4 rounded-xl">
                  <div>
                    <div className="space-y-4">
                      <p className="text-left text-xl font-bold">
                        Instructions
                      </p>
                      <div className="space-y-2">
                        {partnerExpeditionDetail?.partnerExpedition?.instructions.map(
                          (instruction, index) => {
                            return (
                              <div
                                key={instruction}
                                className="flex items-center space-x-2"
                              >
                                <p className="min-w-6 text-black bg-price-green rounded-full">
                                  {index + 1}
                                </p>
                                <p className="text-left">{instruction}</p>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {partnerExpeditionDetail?.partnerExpedition?.expeditionUrl && (
              <div className="h-20 flex items-center justify-center">
                {isLaunchExpeditionLoading ? (
                  <MoonLoader color="#36d7b7" size={30} />
                ) : (
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-primary
              rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      launchExpedition();
                    }}
                  >
                    <div className="text-black text-2xl h-10 flex justify-center items-center">
                      Launch Expedition
                    </div>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
    </div>
  );
};
