// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  InformationCircleIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';

// hooks
import { useGames } from '../../../hooks/useGames';

// components
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// recoil
import { useTokenState } from '../../../recoil/token';

export const ArcadeGameDetailsContainer = () => {
  const [token] = useTokenState();

  const { arcadeGameId } = useParams();
  const { loadArcadeGameDetail, arcadeGameDetail } = useGames();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (arcadeGameId) {
      loadArcadeGame(arcadeGameId);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arcadeGameId]);

  const loadArcadeGame = async (arcadeGameId: string) => {
    try {
      await loadArcadeGameDetail(arcadeGameId);
    } catch (err) {
      navigate('/games');
    }
    setIsLoading(false);
  };

  return (
    <div className="mx-auto main-content grid grid-cols-1 gap-y-6 md:gap-y-0 md:gap-6">
      {!isLoading && arcadeGameDetail ? (
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-8 mt-8 px-4">
          <div className="md:col-span-2 lg:col-span-2 bg-white p-3 dark:bg-deals-card rounded-13 h-max">
            <img
              src={arcadeGameDetail.image_url!}
              className="w-full h-96 object-contain mx-auto"
              alt="logo"
            />
          </div>
          <div className="text-center md:col-span-2 lg:col-span-3 space-y-4">
            <div className="flex flex-row space-x-2 justify-between items-center">
              <div className={`font-semibold text-left text-3xl`}>
                {arcadeGameDetail.name}
              </div>
            </div>
            <div className="flex space-x-2 mb-2">
              <div className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full">
                <div className="pl-2">
                  <img
                    className="rounded-full"
                    src="/assets/icons/coin.png"
                    alt="Coin"
                    height={24}
                    width={24}
                  />
                </div>
                <span className="pr-2 text-sm">Play Now</span>
              </div>
            </div>
            <div
              className={`grid 
                  'grid-cols-1'
                } gap-6 my-8`}
            >
              <div className="text-left space-y-2">
                <div className="flex justify-between">
                  <p className="text-xl font-bold">Description</p>
                  <div className="flex space-x-2">
                    <InformationCircleIcon
                      className="cursor-pointer"
                      height={24}
                      width={24}
                      onClick={() =>
                        window.open(arcadeGameDetail.instructions_link)
                      }
                    />
                    <ListBulletIcon
                      className="cursor-pointer"
                      height={24}
                      width={24}
                      onClick={() => window.open(arcadeGameDetail.terms_link)}
                    />
                  </div>
                </div>
                <p>{arcadeGameDetail.description}</p>
              </div>
            </div>

            {arcadeGameDetail.instructions &&
              arcadeGameDetail.instructions.length > 0 && (
                <div className="mt-2 bg-white dark:bg-deals-card-top p-4 rounded-xl">
                  <div>
                    <div className="space-y-4">
                      <p className="text-left text-xl font-bold">
                        Instructions
                      </p>
                      <div className="space-y-2">
                        {arcadeGameDetail.instructions &&
                          arcadeGameDetail.instructions.length > 0 &&
                          arcadeGameDetail.instructions.map(
                            (instruction, index) => {
                              return (
                                <div
                                  key={instruction}
                                  className="flex items-center space-x-2"
                                >
                                  <p className="min-w-6 text-black bg-price-green rounded-full">
                                    {index + 1}
                                  </p>
                                  <p className="text-left">{instruction}</p>
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {arcadeGameDetail.scoring_instructions &&
              arcadeGameDetail.scoring_instructions.length > 0 && (
                <div className="mt-2 bg-white dark:bg-deals-card-top p-4 rounded-xl">
                  <div>
                    <div className="space-y-4">
                      <p className="text-left text-xl font-bold">Scoring</p>
                      <div className="space-y-2">
                        {arcadeGameDetail.scoring_instructions &&
                          arcadeGameDetail.scoring_instructions.length > 0 &&
                          arcadeGameDetail.scoring_instructions.map(
                            (instruction, index) => {
                              return (
                                <div
                                  key={instruction}
                                  className="flex items-center space-x-2"
                                >
                                  <p className="min-w-6 text-black bg-price-green rounded-full">
                                    {index + 1}
                                  </p>
                                  <p className="text-left">{instruction}</p>
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {arcadeGameDetail && (
              <div className="space-y-2">
                <button
                  type="button"
                  className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                  onClick={() => {
                    window.open(`${arcadeGameDetail.game_url}?token=${token}`);
                  }}
                >
                  <div className="text-black text-2xl ">Play</div>
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
    </div>
  );
};
