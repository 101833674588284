import { ImageTag } from './ImageTag';

export const ImageWrapper: React.FC<{
  verified: boolean;
  url: string;
  alt: string;
  className?: string;
}> = ({ verified, url, alt, className }) => {
  return (
    <div style={{ position: 'relative' }}>
      {verified && (
        <img
          src="/assets/icons/verified.png"
          alt="verified"
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
            width: '12px',
            height: '12px',
            zIndex: 1,
          }}
        />
      )}
      <ImageTag className={className} url={url} alt={alt} />
    </div>
  );
};
