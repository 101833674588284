// vendor libraries
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { SuperSEO } from 'react-super-seo';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '../../components/common/Tooltip/Tooltip';

// api
import { getUserInviteCenter } from '../../api/user';

export default function InviteCenterPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [inviteCenterData, setInviteCenterData] = useState<any>({});

  useEffect(() => {
    fetchInviteCenterData();
  }, []);

  const { invite_code, total_signups, total_conversions, total_coins } =
    inviteCenterData || '';

  const fetchInviteCenterData = async () => {
    const response = await getUserInviteCenter();
    setInviteCenterData(response.data.invite_center);
  };

  const copyDownloadLink = () => {
    navigator.clipboard.writeText('https://bit.ly/3dNXbSJ');
    toast.success('Download link copied to clipboard');
  };

  const copyInviteCode = () => {
    navigator.clipboard.writeText(invite_code);
    toast.success('Invite code copied to clipboard');
  };

  return (
    <>
      <SuperSEO title={`AssetDash | Invite Center`} lang="en_IE" />
      <h1 className="text-32 leading-none text-center mt-8 mb-8">
        Invite Friends
      </h1>
      <div className="main-content mx-auto space-y-4">
        <div className="h-max bg-white dark:bg-deals-card-top rounded-card-box py-5 px-4 md:px-6 space-y-4  divide-gray-text dark:divide-blue-dark">
          <div className="flex space-x-2 text-xl justify-center items-center pt-5 px-4 md:px-6">
            <div>Invite a Friend Stats</div>
            <img src="/assets/icons/chart.png" alt="chart" className="h-5" />
          </div>
          <div className="p-5 grid grid-cols-3 divide-x-2 divide-border-bg dark:divide-tabs-bg">
            <div className="pr-6 space-y-2 flex flex-col items-center">
              <div className="font-normal text-lg leading-4 text-bg-tabs text-gray-text">
                Sign Ups
              </div>
              <div className="text-lg">{total_signups}</div>
            </div>
            <div className="pr-6 space-y-2 flex flex-col items-center">
              <div className="font-normal text-lg leading-4 text-bg-tabs text-gray-text">
                Conversions
              </div>
              <div className="text-lg">{total_conversions}</div>
            </div>
            <div className="pr-6 space-y-2 flex flex-col items-center">
              <div className="font-normal text-lg leading-4 text-bg-tabs text-gray-text">
                Coins
              </div>
              <div className="text-lg">{total_coins}</div>
            </div>
          </div>
        </div>
        <div className="h-max flex flex-col text-center items-center bg-white dark:bg-deals-card-top rounded-card-box py-5 px-4 md:px-6 space-y-4 divide-gray-text dark:divide-blue-dark">
          <div className="flex space-x-2 text-xl justify-center items-center pt-5 px-4 md:px-6">
            <div>Invite Friends & Get Coins</div>
            <Tooltip
              text={
                'Sign ups are the amount of people who signed up with your code. \n\nConversions are sign ups that became Bronze Tier or higher through verifying wallets. \n\nYou get Coins for every Conversion!'
              }
              position="top"
            >
              <InformationCircleIcon className="h-4 w-4 text-orange-dark" />
            </Tooltip>
          </div>
          <div className="flex space-x-2 text-lg justify-center items-center pt-5 px-4 md:px-6">
            Each time you invite a friend, both of you get 500 Coins!
          </div>
          <div>
            <img src="/assets/images/invite_coins.png" alt="invite-friends" />
          </div>
          <p>
            Invite a friend to AssetDash, and once they become Bronze or higher,
            both of you will receive 500 Coins.
          </p>
          <p>
            Simply share your unique referral code below with your friends and
            ask them to enter it at sign up!
          </p>
          <div className="flex items-center space-x-4">
            <div className="px-10 py-5 w-full bg-gray-100 dark:bg-darkest-dark rounded-xl">
              {invite_code}
            </div>
            <div
              onClick={() => copyInviteCode()}
              className="px-10 py-5 text-center w-full md:w-max bg-price-green text-black rounded-12 cursor-pointer"
            >
              Copy
            </div>
          </div>
          <p>
            Need to send your friend a link to download AssetDash? Copy the link
            below but don't forget to also share your referral code with them so
            they can enter it at sign up!
          </p>
          <div
            onClick={() => copyDownloadLink()}
            className="px-10 py-5 text-center w-full md:w-max bg-price-green text-black rounded-12 cursor-pointer"
          >
            Copy Link
          </div>
        </div>
      </div>
    </>
  );
}
