interface AuctionBidLeaderboardJson {
  public_user_id: string;
  status: string;
  created: string;
  num_coins: number;
}

export class AuctionBidLeaderboard {
  publicUserId: string;
  status: string;
  created: Date;
  numCoins: number;

  constructor(
    publicUserId: string,
    status: string,
    created: Date,
    numCoins: number,
  ) {
    this.publicUserId = publicUserId;
    this.status = status;
    this.created = created;
    this.numCoins = numCoins;
  }

  static fromJson(json: AuctionBidLeaderboardJson): AuctionBidLeaderboard {
    return new AuctionBidLeaderboard(
      json.public_user_id,
      json.status,
      new Date(json.created),
      json.num_coins,
    );
  }

  toJson(): AuctionBidLeaderboardJson {
    return {
      public_user_id: this.publicUserId,
      status: this.status,
      created: this.created.toISOString(),
      num_coins: this.numCoins,
    };
  }

  isLocked(): boolean {
    return this.status === 'locked';
  }
}

interface AuctionBidLeaderboardsResponseJson {
  auction_bid_leaderboards?: AuctionBidLeaderboardJson[];
}

export class AuctionBidLeaderboardsResponse {
  auctionBidLeaderboards?: AuctionBidLeaderboard[];

  constructor(auctionBidLeaderboards?: AuctionBidLeaderboard[]) {
    this.auctionBidLeaderboards = auctionBidLeaderboards;
  }

  static fromJson(
    json: AuctionBidLeaderboardsResponseJson,
  ): AuctionBidLeaderboardsResponse {
    const leaderboards = json.auction_bid_leaderboards
      ? json.auction_bid_leaderboards.map(AuctionBidLeaderboard.fromJson)
      : undefined;
    return new AuctionBidLeaderboardsResponse(leaderboards);
  }

  toJson(): AuctionBidLeaderboardsResponseJson {
    return {
      auction_bid_leaderboards: this.auctionBidLeaderboards?.map(board =>
        board.toJson(),
      ),
    };
  }
}
