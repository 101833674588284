import { useEffect } from 'react';

const getMobileDetect = (userAgent, maxTouchPoints, platform) => {
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone/i));
  const isOpera = Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));
  const isMobile = Boolean(isAndroid || isIos || isOpera || isWindows);
  const isDesktop = Boolean(!isMobile && !isSSR);
  const isIpad =
    maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform);
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    isIpad,
  };
};
const useDevice = () => {
  useEffect(() => {}, []);
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const maxTouchPoints =
    typeof navigator === 'undefined' ? 'SSR' : navigator.maxTouchPoints;
  const platform =
    typeof navigator === 'undefined' ? 'SSR' : navigator.platform;
  return getMobileDetect(userAgent, maxTouchPoints, platform);
};

export default useDevice;
