import React, { useEffect, useState } from 'react';
import moment from 'moment';

interface CountdownProps {
  targetTime: moment.Moment;
}

export const Countdown: React.FC<CountdownProps> = ({ targetTime }) => {
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment();
      const diffDuration = moment.duration(targetTime.diff(now));

      setTimeLeft({
        days: Math.floor(diffDuration.asDays()),
        hours: diffDuration.hours(),
        minutes: diffDuration.minutes(),
        seconds: diffDuration.seconds(),
      });
    };

    updateCountdown(); // Update immediately, then every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [targetTime]);

  return (
    <div>
      {timeLeft.days > 0 ? `${timeLeft.days}d ` : ' '}
      {timeLeft.hours > 0 ? `${timeLeft.hours}h ` : '0h '}
      {timeLeft.minutes > 0 ? `${timeLeft.minutes}m ` : '0m '}
      {timeLeft.seconds >= 0 ? `${timeLeft.seconds}s` : '0s'}
    </div>
  );
};

export default Countdown;
