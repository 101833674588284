import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  getUserCardCenter,
  getUserVantaHands,
  getUserVantaStakedNFTs,
} from '../api/card';
import { CardCenter } from '../features/cards/models/CardCenter';
import { VantaHand } from '../features/cards/models/VantaHands';
import { VantaStakedNFT } from '../features/cards/models/VantaStakedNFTs';

import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

interface CardCenterContextProps {
  cardCenter?: CardCenter;
  vantaHands: VantaHand[];
  vantaStakedNFTs: VantaStakedNFT[];
  load: () => Promise<void>;
  loadCenter: () => Promise<void>;
}

export const CardCenterContext = createContext<
  CardCenterContextProps | undefined
>(undefined);

export const useCardCenter = (): CardCenterContextProps => {
  const context = useContext(CardCenterContext);
  if (!context) {
    throw new Error('useCardCenter must be used within a CardCenterProvider');
  }
  return context;
};

export const CardCenterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [cardCenter, setCardCenter] = useState<CardCenter>();
  const [vantaHands, setVantaHands] = useState<VantaHand[]>([]);
  const [vantaStakedNFTs, setVantaStakedNFTs] = useState<VantaStakedNFT[]>([]);

  const load = async () => {
    await loadUserCardCenter();
    await loadUserVantaHands();
    await loadUserVantaStakedNFTs();
  };

  const loadCenter = async () => {
    await loadUserCardCenter();
  };

  const loadUserCardCenter = async () => {
    try {
      const response = await getUserCardCenter();
      setCardCenter(response.cardCenter);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadUserVantaHands = async () => {
    try {
      const response = await getUserVantaHands();
      setVantaHands(response.vantaHands);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadUserVantaStakedNFTs = async () => {
    try {
      const response = await getUserVantaStakedNFTs();
      setVantaStakedNFTs(response.vantaStakedNFTs);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  return (
    <CardCenterContext.Provider
      value={{
        // methods
        load,
        loadCenter,

        // values
        cardCenter,
        vantaHands,
        vantaStakedNFTs,
      }}
    >
      {children}
    </CardCenterContext.Provider>
  );
};
