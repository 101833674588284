// vendor libraries
import { SuperSEO } from 'react-super-seo';
import { useEffect } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

export default function LegalPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | Legal`} lang="en_IE" />
      <h1 className="text-32 leading-none text-center mt-8 mb-8">Legal</h1>
      <div className="main-content mx-auto">
        <div className="divide-y divide-gray-text dark:divide-gray-600 py-5 h-full">
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card py-4"
            onClick={() => window.open('/terms-of-service', '_blank')}
          >
            <div className="flex items-center space-x-2">
              <div>Terms of Service</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card py-4"
            onClick={() =>
              window.open('https://www.assetdash.com/privacy-policy', '_blank')
            }
          >
            <div className="flex items-center space-x-2">
              <div>Privacy Policy</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card py-4"
            onClick={() =>
              window.open('https://teal-karen-81.tiiny.site/', '_blank')
            }
          >
            <div className="flex items-center space-x-2">
              <div>Giveaway Terms</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
        </div>
      </div>
    </>
  );
}
