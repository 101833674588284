import { MyAuctionUserPrize, MyAuctionUserPrizeJson } from './AuctionDetail';

interface AuctionMyPrizesResponseJson {
  auction_my_prizes?: MyAuctionUserPrizeJson[];
}

export class AuctionMyPrizesResponse {
  auctionMyPrizes?: MyAuctionUserPrize[];

  constructor(auctionMyPrizes?: MyAuctionUserPrize[]) {
    this.auctionMyPrizes = auctionMyPrizes;
  }

  static fromJson(json: AuctionMyPrizesResponseJson): AuctionMyPrizesResponse {
    const auctionMyPrizes = json.auction_my_prizes
      ? json.auction_my_prizes.map(MyAuctionUserPrize.fromJson)
      : undefined;

    return new AuctionMyPrizesResponse(auctionMyPrizes);
  }

  toJson(): AuctionMyPrizesResponseJson {
    return {
      auction_my_prizes: this.auctionMyPrizes?.map(prize => prize.toJson()),
    };
  }
}