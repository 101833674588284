import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

export const TierBenefits = ({ memberCenterData, setOpenGoldBoost }) => {
  const memberBenefits = memberCenterData?.member_benefits;

  return (
    <div className="card py-4 px-6 rounded-12 justify-center space-y-6">
      <div className="flex justify-between items-center">
        {memberBenefits?.logo ? (
          <img
            src={memberBenefits?.logo}
            className="h-8 w-12 rounded-full object-cover"
            alt="member status"
          />
        ) : (
          <SkeletonLoader circle height={40} />
        )}
        {/* <div
            onClick={() => openFAQ()}
            className="order-1 box-border leading-18 border-1 cursor-pointer solid border-border-box py-1 px-3 rounded-20 bg-my-box text-price-green"
          >
            Learn More
          </div> */}
      </div>
      <p className="font-bold text-22 order-1 leading-22">
        {memberBenefits?.title}
      </p>
      <div className="space-y-5 border-t-1 mb-5 solid border-t-gray-text"></div>
      <div className="space-y-5">
        {memberBenefits ? (
          memberBenefits?.items.map((item, index) => {
            return (
              <div key={index} className="flex flex-col justify-center">
                <div className="flex space-x-2 items-center">
                  <img
                    src={item.logo}
                    alt={item.title}
                    className="rounded-full h-5 w-auto"
                  />
                  <p className="text-xl leading-5 order-1">{item.title}</p>
                </div>
              </div>
            );
          })
        ) : (
          <SkeletonLoader height={100} className="rounded-lg" />
        )}
      </div>
      {memberCenterData && memberCenterData.member_status !== 'standard' && (
        <div>
          <button
            className="w-full py-4 rounded-xl bg-price-green text-black font-bold text-xl"
            onClick={() => {
              setOpenGoldBoost(true);
            }}
          >
            Tap to see your Gold Boosts
          </button>
        </div>
      )}
    </div>
  );
};
