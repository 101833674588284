import { MyGiveawayUserPrize } from '@/api/games';
import { toast } from 'react-hot-toast';

interface MyPrizeGiveawayCardProps {
  prize: MyGiveawayUserPrize;
}

export const MyPrizeGiveawayCard: React.FC<MyPrizeGiveawayCardProps> = ({
  prize,
}) => {
  const copyAndOpenLink = (link: string) => {
    navigator.clipboard.writeText(link);

    toast.success('Copied to clipboard!');

    setTimeout(() => {
      window.open(link, '_blank');
    }, 2000);
  };

  return (
    <div
      onClick={() => {
        if (prize.prize_link) copyAndOpenLink(prize.prize_link);
      }}
      className={`card relative cursor-pointer h-96 w-56 p-4 flex flex-col justify-between`}
    >
      <div className="relative md:col-span-2 lg:col-span-2 rounded-13 h-max">
        <img
          src={prize.prize_image_url!}
          className="bg-white dark:bg-deals-card min-w-42 h-42 object-contain mx-auto"
          alt="prize"
        />
        <div className="space-y-1 mt-8">
          <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {prize.prize_title}
          </div>
        </div>
      </div>

      <div className="space-y-1 flex justify-center">
        {prize.prize_link && (
          <button
            type="button"
            className={`w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 text-base font-medium
            focus:outline-none sm:w-auto sm:text-sm bg-price-green text-black cursor-pointer`}
            onClick={() => copyAndOpenLink(prize.prize_link!)}
          >
            Claim
          </button>
        )}
      </div>
    </div>
  );
};
