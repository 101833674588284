import { NFTItem } from "../../portfolio/models/NFTItem";

class VantaStakedNFTsResponse {
  vantaStakedNFTs: VantaStakedNFT[];

  constructor(vantaStakedNFTs: VantaStakedNFT[]) {
    this.vantaStakedNFTs = vantaStakedNFTs;
  }

  static fromJson(json: { [key: string]: any }): VantaStakedNFTsResponse {
    let vantaStakedNFTs: VantaStakedNFT[] = [];
    if (json['vanta_staked_nfts'] != null) {
      vantaStakedNFTs = json['vanta_staked_nfts'].map((v: any) => VantaStakedNFT.fromJson(v));
    }
    return new VantaStakedNFTsResponse(vantaStakedNFTs);
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.vantaStakedNFTs) {
      data['vanta_staked_nfts'] = this.vantaStakedNFTs.map((v) => v.toJson());
    }
    return data;
  }
}

class VantaStakedNFT {
  mintAddress?: string;
  stakeTime?: Date;
  unstakeTime?: Date;
  stakeDuration?: string;
  stakeDurationDisplay?: string;
  numCoinsDaily?: number;
  nftItem?: NFTItem;

  constructor({
    mintAddress,
    stakeTime,
    unstakeTime,
    stakeDuration,
    stakeDurationDisplay,
    numCoinsDaily,
    nftItem,
  }: {
    mintAddress?: string;
    stakeTime?: Date;
    unstakeTime?: Date;
    stakeDuration?: string;
    stakeDurationDisplay?: string;
    numCoinsDaily?: number;
    nftItem?: NFTItem;
  }) {
    this.mintAddress = mintAddress;
    this.stakeTime = stakeTime;
    this.unstakeTime = unstakeTime;
    this.stakeDuration = stakeDuration;
    this.stakeDurationDisplay = stakeDurationDisplay;
    this.numCoinsDaily = numCoinsDaily;
    this.nftItem = nftItem;
  }

  static fromJson(json: { [key: string]: any }): VantaStakedNFT {
    return new VantaStakedNFT({
      mintAddress: json['mint_address'],
      stakeTime: json['stake_time'] ? new Date(json['stake_time'] + 'Z') : undefined,
      unstakeTime: json['unstake_time'] ? new Date(json['unstake_time'] + 'Z') : undefined,
      stakeDuration: json['stake_duration'],
      stakeDurationDisplay: json['stake_duration_display'],
      numCoinsDaily: json['num_coins_daily'],
      nftItem: json['nft_item'] ? NFTItem.fromJSON(json['nft_item']) : undefined, // Assuming NFTItem has a fromJson method
    });
  }

  toJson(): { [key: string]: any } {
    return {
      mint_address: this.mintAddress,
      stake_time: this.stakeTime ? this.stakeTime.toISOString() : undefined,
      unstake_time: this.unstakeTime ? this.unstakeTime.toISOString() : undefined,
      stake_duration: this.stakeDuration,
      stake_duration_display: this.stakeDurationDisplay,
      num_coins_daily: this.numCoinsDaily,
      nft_item: this.nftItem?.toJSON(),
    };
  }
}

export { VantaStakedNFT, VantaStakedNFTsResponse}