// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useParams } from 'react-router-dom';

// components
import { DealCard } from '@/components/v2/market/deals/DealCard';
import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';

// hooks
import { useDeals } from '../../hooks/useDeals';

export default function DealCategoryPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { category } = useParams();
  const {
    popularDeals,
    vantaDeals,
    premiumDeals,
    giveawayDeals,
    latestDeals,
    userAllDeals,
  } = useDeals();

  const dealsByCategory = {
    popular: popularDeals,
    vanta: vantaDeals,
    premium: premiumDeals,
    giveaways: giveawayDeals,
    latest: latestDeals,
    all: userAllDeals,
  };

  const titleByCategory = {
    popular: 'Popular Deals',
    vanta: 'Vanta Drops',
    premium: 'Gold and Platinum Only',
    giveaways: 'Giveaways',
    latest: 'Latest Deals',
    all: 'All Deals',
  };

  return (
    <>
      <SuperSEO title="AssetDash | Deals" lang="en_IE" />
      <div className="mx-auto main-content space-y-10">
        {dealsByCategory[category] && dealsByCategory[category].length > 0 ? (
          <div className="bg-white dark:bg-deals-card-top rounded-card-box h-full flex flex-col pt-8">
            <div className="mx-5 py-4 px-4 md:px-6 flex justify-between items-center">
              <span className="text-tabs-bg dark:text-white font-semibold text-4xl leading-card-title flex items-center space-x-2">
                <div>{titleByCategory[category]}</div>
              </span>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 md:gap-8 sm:px-4 py-4 px-2">
              {dealsByCategory[category].map(deal => (
                <DealCard key={deal.id} deal={deal} />
              ))}
            </div>
          </div>
        ) : (
          <SkeletonLoader height={500} />
        )}
      </div>
    </>
  );
}
