// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

// recoil
import { useThemeState } from '../../../recoil/theme';

interface CoinDistributionSortByModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (sortBy: string) => void;
  initialSortBy: string;
}

export const CoinDistributionSortByModal: React.FC<
  CoinDistributionSortByModalProps
> = ({ isOpen, closeModal, onSubmit, initialSortBy }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const buttonText = theme === 'light' ? 'text-black' : 'text-black';
  const textColor = theme === 'light' ? 'text-gray-500' : 'text-white';

  const [selectedOption, setSelectedOption] = useState(initialSortBy);

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${background}`}
              >
                <div className={`${textColor}`}>
                  <div className=" text-center">
                    <Dialog.Title
                      as="h3"
                      className={`text-xl font-semibold leading-6 text-left`}
                    >
                      <div className="flex justify-between items-center">
                        <h2 className="text-xl">Sort by</h2>

                        <button
                          onClick={closeModal}
                          className="font-semibold text-sm text-price-red"
                        >
                          Close
                        </button>
                      </div>
                    </Dialog.Title>
                  </div>
                  <div className="pt-8">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="dateReceived"
                        name="option"
                        value="created"
                        className="hidden"
                        onChange={() => handleChange('created')}
                      />
                      <label
                        htmlFor="dateReceived"
                        className="flex items-center cursor-pointer space-x-4"
                      >
                        <div
                          className={`w-6 h-6 border-2 rounded-full border-gray-300 flex flex-shrink-0 justify-center items-center ${
                            selectedOption === 'created'
                              ? 'border-green-500'
                              : ''
                          }`}
                        >
                          <div
                            className={`w-4 h-4 bg-green-500 rounded-full ${
                              selectedOption === 'created' ? '' : 'hidden'
                            }`}
                          ></div>
                        </div>
                        <div className="ml-2">Date Received</div>
                      </label>
                    </div>
                    <div className="flex items-center mt-4">
                      <input
                        type="radio"
                        id="unlockDate"
                        name="option"
                        value="unlock_date"
                        className="hidden"
                        onChange={() => handleChange('unlock_date')}
                      />
                      <label
                        htmlFor="unlockDate"
                        className="flex items-center cursor-pointer space-x-4"
                      >
                        <div
                          className={`w-6 h-6 border-2 rounded-full border-gray-300 flex flex-shrink-0 justify-center items-center ${
                            selectedOption === 'unlock_date'
                              ? 'border-green-500'
                              : ''
                          }`}
                        >
                          <div
                            className={`w-4 h-4 bg-green-500 rounded-full ${
                              selectedOption === 'unlock_date' ? '' : 'hidden'
                            }`}
                          ></div>
                        </div>
                        <div className="ml-2">Unlock Date</div>
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-4  mt-10 text-right">
                    <button
                      type="button"
                      className={`w-60 py-4 px-8 leading-17 font-semibold text-sm rounded-xl bg-gray-600 text-price-green`}
                      onClick={() => {
                        setSelectedOption('created');
                        onSubmit('created');
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      className={`w-60 py-4 px-8 transition delay-100 ease-in leading-17 font-semibold text-sm rounded-xl bg-price-green ${buttonText}`}
                      onClick={() => {
                        onSubmit(selectedOption);
                      }}
                    >
                      <div>Apply</div>
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
