interface AuctionUserPrizesResponseJson {
  auction_user_prizes?: AuctionUserPrizeJson[];
}

export class AuctionUserPrizesResponse {
  auctionUserPrizes?: AuctionUserPrize[];

  constructor(auctionUserPrizes?: AuctionUserPrize[]) {
    this.auctionUserPrizes = auctionUserPrizes;
  }

  static fromJson(json: AuctionUserPrizesResponseJson): AuctionUserPrizesResponse {
    const auctionUserPrizes = json.auction_user_prizes
      ? json.auction_user_prizes.map(AuctionUserPrize.fromJson)
      : undefined;

    return new AuctionUserPrizesResponse(auctionUserPrizes);
  }

  toJson(): AuctionUserPrizesResponseJson {
    return {
      auction_user_prizes: this.auctionUserPrizes?.map(prize => prize.toJson())
    };
  }
}

interface AuctionUserPrizeJson {
  public_user_id: string;
  prize_image_url: string;
  prize_title: string;
}

export class AuctionUserPrize {
  publicUserId: string;
  prizeImageUrl: string;
  prizeTitle: string;

  constructor(publicUserId: string, prizeImageUrl: string, prizeTitle: string) {
    this.publicUserId = publicUserId;
    this.prizeImageUrl = prizeImageUrl;
    this.prizeTitle = prizeTitle;
  }

  static fromJson(json: AuctionUserPrizeJson): AuctionUserPrize {
    return new AuctionUserPrize(
      json.public_user_id,
      json.prize_image_url,
      json.prize_title
    );
  }

  toJson(): AuctionUserPrizeJson {
    return {
      public_user_id: this.publicUserId,
      prize_image_url: this.prizeImageUrl,
      prize_title: this.prizeTitle
    };
  }
}