import { axiosAssetDashApi } from './api';

export function getUserDealCoupon() {
  return axiosAssetDashApi.get('api/api_v4/deals/user_coupons');
}

export function getUserPurchases() {
  return axiosAssetDashApi.get('api/api_v4/deals/user_purchases');
}

interface GetUserDealsOptions {
  tag?: string;
  orderKey?: string;
  page?: number;
  limit?: number;
}

export function getUserDeals({
  tag,
  orderKey,
  page = 1,
  limit = 20,
}: GetUserDealsOptions = {}) {
  const params = {
    ...(tag && { tag_ids: tag }),
    ...(orderKey && { order_key: orderKey }),
    page: page,
    limit: limit,
  };

  return axiosAssetDashApi.get('api/api_v4/deals/user_deals', { params });
}

export function getUserDealsCenter() {
  return axiosAssetDashApi.get('api/api_v4/users/deals-center');
}

export function getDealAssets() {
  return axiosAssetDashApi.get('api/api_v4/deals/deals_assets');
}

export function getDealTags() {
  return axiosAssetDashApi.get('api/api_v4/deals/store_tags');
}

export function getUserVantaDeals({
  tag,
  orderKey,
  page = 1,
  limit = 20,
}: GetUserDealsOptions) {
  const params = {
    vanta_only: true,
    ...(tag && { tag_id: tag }),
    ...(orderKey && { order_key: orderKey }),
    page: page,
    limit: limit,
  };

  return axiosAssetDashApi.get('api/api_v4/deals/user_deals', { params });
}

export function getUserPremiumDeals({
  tag,
  orderKey,
  page = 1,
  limit = 20,
}: GetUserDealsOptions) {
  const params = {
    top_tier: true,
    ...(tag && { tag_id: tag }),
    ...(orderKey && { order_key: orderKey }),
    page: page,
    limit: limit,
  };

  return axiosAssetDashApi.get('api/api_v4/deals/user_deals', { params });
}

export function getUserDealDetails(id: string) {
  return axiosAssetDashApi.get(`api/api_v4/deals/user_deals/${id}`);
}

export const generateUserDealCoupon = (dealId: string) => {
  return axiosAssetDashApi.post(
    `/api/api_v4/deals/user_deals/${dealId}/generate_coupon`,
    {
      deal_id: dealId,
    },
  );
};

export const getDealsSearchResults = async (query: string) => {
  const params = {
    query: query,
  };

  return axiosAssetDashApi.get(`/api/api_v3/search/deals`, {
    params,
  });
};
