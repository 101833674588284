import { useEffect, useState } from 'react';

export default function MembershipCarousel({ tiers, memberStatus }) {
  const [percentage, setPercentage] = useState(20);

  useEffect(() => {
    if (tiers && tiers.length > 0) {
      setPercentage(tiers?.indexOf(memberStatus) * 20);
    }
  }, [tiers, memberStatus]);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-line" />
      <div
        className="progress-bar-line-filled"
        style={{ width: `${percentage}%` }}
      />
      {tiers &&
        tiers.map((tier, index) => {
          const isAccomplished = tiers.indexOf(memberStatus) >= index;
          return (
            <div
              key={tier}
              className="progress-bar-step"
              style={{ left: `${(index / (tiers.length - 1)) * 100}%` }}
            >
              <div
                className={`progress-bar-circle ${
                  isAccomplished ? 'accomplished' : ''
                }`}
              >
                <div className="progress-bar-label text-xs text-gray-text capitalize">
                  {tier}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
