// imports
import { NftItemTrait } from '@/features/portfolio/models/NFTItem';
import { usePortfolio } from '@/hooks/usePortfolio';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

interface NFTItemPriceCardProps {
  nftItemTraits: NftItemTrait[];
}

export const NFTItemTraitsCard: React.FC<NFTItemPriceCardProps> = ({
  nftItemTraits,
}) => {
  const { isLoading } = usePortfolio();

  return (
    <>
      <div className="card flex justify-between font-semibold mb-4 p-4">
        <span className="w-1/3 text-start">Traits</span>
        <span className="w-1/3 text-center">Rarity</span>
        <span className="w-1/3 text-end">Traits Floor Price</span>
      </div>
      <div className="card text-sm md:text-lg p-3">
        {nftItemTraits?.length > 0 ? (
          <>
            <div className="space-y-3">
              {nftItemTraits.map((trait, index) => {
                const getColorClass = (rarity?: number) => {
                  if (!rarity) return 'text-gray-500';
                  if (rarity < 1) return 'text-pink-500';
                  if (rarity < 5) return 'text-yellow-500';
                  if (rarity < 15) return 'text-purple-500';
                  if (rarity < 35) return 'text-blue-500';
                  if (rarity < 60) return 'text-green-500';
                  return 'text-gray-500';
                };
                return (
                  <div key={index} className="card flex justify-center p-3 ">
                    <div className="w-1/3 font-bold">
                      <div>{trait.type}</div>
                      <span className="text-gray-text">
                        {trait.value || 'N/A'}
                      </span>
                    </div>
                    <div
                      className={`w-1/3 rounded-md flex items-center justify-center ${getColorClass(
                        trait.rarity,
                      )}`}
                    >
                      <span className="rounded-md text-sm px-3 py-1">
                        {trait.rarity || '--'}%
                      </span>
                    </div>
                    <div className="w-1/3 flex items-center justify-center">
                      {trait.floorPriceNative || '0.0'} Sol
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          isLoading && <SkeletonLoader height={200} />
        )}
      </div>
    </>
  );
};
