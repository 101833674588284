export class PartnerExpedition {
  id: string;
  name: string;
  status: string;
  description: string;
  imageUrl: string;
  expeditionUrl: string;
  rewardCoins: number;
  instructions: string[];
  instructionsLink: string;
  termsLink: string;

  constructor({
    id,
    name,
    status,
    description,
    imageUrl,
    expeditionUrl,
    rewardCoins,
    instructions,
    instructionsLink,
    termsLink
  }: {
    id: string,
    name: string,
    status: string,
    description: string,
    imageUrl: string,
    expeditionUrl: string,
    rewardCoins: number,
    instructions: string[],
    instructionsLink: string,
    termsLink: string
  }) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.description = description;
    this.imageUrl = imageUrl;
    this.expeditionUrl = expeditionUrl;
    this.rewardCoins = rewardCoins;
    this.instructions = instructions;
    this.instructionsLink = instructionsLink;
    this.termsLink = termsLink;
  }

  static fromJson(json: any): PartnerExpedition {
    return new PartnerExpedition({
      id: json['id'],
      name: json['name'],
      status: json['status'],
      description: json['description'],
      imageUrl: json['image_url'],
      expeditionUrl: json['expedition_url'],
      rewardCoins: json['reward_coins'],
      instructions: json['instructions'] || [],
      instructionsLink: json['instructions_link'],
      termsLink: json['terms_link']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      description: this.description,
      image_url: this.imageUrl,
      expedition_url: this.expeditionUrl,
      reward_coins: this.rewardCoins,
      instructions: this.instructions,
      instructions_link: this.instructionsLink,
      terms_link: this.termsLink
    };
  }
}

export class PartnerExpeditionsResponse {
  partnerExpeditions?: PartnerExpedition[];

  constructor({ partnerExpeditions }: { partnerExpeditions?: PartnerExpedition[] }) {
    this.partnerExpeditions = partnerExpeditions;
  }

  static fromJson(json: any): PartnerExpeditionsResponse {
    const partnerExpeditions = json['partner_expeditions'] ? json['partner_expeditions'].map((v: any) => PartnerExpedition.fromJson(v)) : undefined;
    return new PartnerExpeditionsResponse({ partnerExpeditions });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.partnerExpeditions) data['partner_expeditions'] = this.partnerExpeditions.map((expedition) => expedition.toJson());
    return data;
  }
}