// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { ArcadeGamesTagContainer } from '../../components/games/arcade-games/ArcadeGamesTagContainer';

export default function ArcadeGamesTagPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title={`AssetDash | Arcade Games`} lang="en_IE" />
      <ArcadeGamesTagContainer />
    </div>
  );
}
