import { axiosAssetDashApi } from './api';

export const postAuthSignup = async (formValues: any) => {
  return await axiosAssetDashApi.post('web/auth/signup', formValues);
};

export const postLoginUser = async (
  email: string,
  password: string,
  walletData?: any,
) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('username', email);
  urlencoded.append('password', password);

  let loginURL = 'web/auth/login2';
  if (walletData?.walletAddress) {
    loginURL += `/?login_wallet_type=${walletData.walletType}&login_wallet_address=${walletData.walletAddress}`;
  }

  return await axiosAssetDashApi.post(loginURL, urlencoded, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const postOAuthLogin = async (
  token: string,
  platform: string,
  walletData: any,
) => {
  let payload = {};
  if (walletData) {
    const { walletAddress, walletType, partnerId } = walletData || {};
    payload = {
      token,
      backend: platform,
      platform_name: 'web',
      signup_wallet_type: walletType || undefined,
      signup_wallet_address: walletAddress || undefined,
      signup_source:
        walletAddress && partnerId !== 'deals'
          ? 'web_partner'
          : walletAddress && partnerId === 'deals'
          ? 'web_deals'
          : undefined,
      signup_partner_id:
        partnerId && partnerId !== 'deals' ? partnerId : undefined,
    };
  } else {
    payload = {
      token,
      backend: platform,
      platform_name: 'web',
    };
  }
  return await axiosAssetDashApi.post('web/auth/sign_in', payload);
};

export const postForgotPassword = async (email: string) => {
  return await axiosAssetDashApi.post('web/auth/forgot-password', { email });
};

export const postResetPassword = async (params: {
  password: string;
  reset_password_token: string;
}) => {
  return await axiosAssetDashApi.post('web/auth/reset-password', params);
};

export const postLogout = async (token: string) => {
  return await axiosAssetDashApi.post('web/auth/logout', { token });
};
