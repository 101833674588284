import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

import {
  getInboxCenter,
  getInboxUserMessages,
  getInboxTags,
  getInboxUserMessageDetail,
} from '../api/user';

import {
  InboxCenter,
  InboxCenterResponse,
} from '../features/inbox/models/InboxCenter';
import {
  InboxMessage,
  InboxMessagesResponse,
} from '../features/inbox/models/InboxUserMessages';
import {
  InboxMessageDetail,
  InboxMessageDetailResponse,
} from '../features/inbox/models/InboxUserMessages';
import {
  InboxMessageTag,
  InboxMessageTagsResponse,
} from '@/features/inbox/models/InboxMessageTag';

interface InboxContextProps {
  inboxCenter?: InboxCenter;
  inboxMessages?: InboxMessage[];
  inboxMessageDetail?: InboxMessageDetail;

  inboxTagId?: string;
  inboxTags?: InboxMessageTag[];
  inboxMessagePage: number;
  inboxMessageCanLoadMore: boolean;

  load: () => Promise<void>;
  loadCenter: () => Promise<void>;
  getUserInboxCenter: () => Promise<void>;
  getUserInboxUserMessages: () => Promise<void>;
  getUserInboxUserMessagesNext: () => Promise<void>;
  getUserInboxTags: () => Promise<void>;
  getUserInboxUserMessageDetail: (messageId: string) => Promise<void>;
  clearInboxUserMessageDetail: () => void;

  isLoading: boolean;
  isMessagesLoading: boolean;
}

export const InboxContext = createContext<InboxContextProps | undefined>(
  undefined,
);

export const useInbox = (): InboxContextProps => {
  const context = useContext(InboxContext);
  if (!context) {
    throw new Error('useInbox must be used within a InboxProvider');
  }
  return context;
};

export const InboxProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [inboxCenter, setInboxCenter] = useState<InboxCenter>();
  const [inboxMessages, setInboxMessages] = useState<InboxMessage[]>();
  const [inboxMessageDetail, setInboxMessageDetail] =
    useState<InboxMessageDetail>();
  const [inboxTags, setInboxTags] = useState<InboxMessageTag[]>();
  const [inboxTagId, setInboxTagId] = useState<string>();
  const [inboxMessagePage, setInboxMessagePage] = useState<number>(1);
  const [inboxMessageCanLoadMore, setInboxMessageCanLoadMore] =
    useState<boolean>(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  const load = async () => {
    setInboxMessagePage(1);
    await getUserInboxCenter();
    await getUserInboxTags();
    await getUserInboxUserMessages(inboxTagId);
  };

  const loadCenter = async () => {
    await getUserInboxCenter();
  };

  const getUserInboxCenter = async () => {
    setIsLoading(true);
    try {
      const response = await getInboxCenter();
      const inboxCenterResponse = InboxCenterResponse.fromJson(response.data);
      setInboxCenter(inboxCenterResponse.inboxCenter);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getUserInboxTags = async () => {
    try {
      const response = await getInboxTags();
      const inboxTagsResponse = InboxMessageTagsResponse.fromJson(
        response.data,
      );
      setInboxTags(inboxTagsResponse.gameTags);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };
  const getUserInboxUserMessages = async (tagId?: string) => {
    let messagePage = inboxMessagePage;
    if (inboxTagId !== tagId) {
      messagePage = 1;
      setInboxMessagePage(1);
    }
    setInboxTagId(tagId);
    setIsMessagesLoading(true);
    try {
      const response = await getInboxUserMessages(messagePage, tagId);
      const inboxMessagesResponse = InboxMessagesResponse.fromJson(
        response.data,
      );
      if (
        !inboxMessagesResponse.inboxMessages ||
        inboxMessagesResponse.inboxMessages.length === 0
      ) {
        setInboxMessageCanLoadMore(false);
      } else {
        setInboxMessages(currentMessages => [
          ...(currentMessages || []),
          ...inboxMessagesResponse.inboxMessages!,
        ]);
      }
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsMessagesLoading(false);
    }
  };

  const getUserInboxUserMessagesNext = async () => {
    const nextPage = inboxMessagePage + 1;
    setIsMessagesLoading(true);
    try {
      const response = await getInboxUserMessages(nextPage, inboxTagId);
      const inboxMessagesResponse = InboxMessagesResponse.fromJson(
        response.data,
      );
      if (
        !inboxMessagesResponse.inboxMessages ||
        inboxMessagesResponse.inboxMessages.length === 0
      ) {
        setInboxMessageCanLoadMore(false);
      } else {
        setInboxMessages(currentMessages => [
          ...(currentMessages || []),
          ...(inboxMessagesResponse.inboxMessages || []),
        ]);
        setInboxMessagePage(nextPage);
      }
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsMessagesLoading(false);
    }
  };
  const clearInboxUserMessageDetail = () => {
    setInboxMessageDetail(undefined);
  };

  const getUserInboxUserMessageDetail = async (inboxMessageId: string) => {
    setIsLoading(true);
    try {
      const response = await getInboxUserMessageDetail(inboxMessageId);
      const inboxMessageDetailResponse = InboxMessageDetailResponse.fromJson(
        response.data,
      );
      setInboxMessageDetail(inboxMessageDetailResponse.inboxMessageDetail);
      // Update read/seen status of inbox message
      setInboxMessages(
        currentMessages =>
          currentMessages?.map(message =>
            message.id === inboxMessageId
              ? message.copyWith({ readStatus: 'read', seenStatus: 'seen' })
              : message,
          ) || [],
      );
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <InboxContext.Provider
      value={{
        // methods
        load,
        loadCenter,
        getUserInboxCenter,
        getUserInboxUserMessages,
        getUserInboxTags,
        getUserInboxUserMessageDetail,
        getUserInboxUserMessagesNext,
        clearInboxUserMessageDetail,

        // values
        inboxCenter,
        inboxMessages,
        inboxTags,
        inboxMessageDetail,

        inboxTagId,
        inboxMessagePage,
        inboxMessageCanLoadMore,

        isLoading,
        isMessagesLoading,
      }}
    >
      {children}
    </InboxContext.Provider>
  );
};
