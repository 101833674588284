export const ComponentPlaceholder = ({ text = 'Calculating Portfolio' }) => {
  return (
    <div className="text-xl font-semibold h-full w-full flex justify-center items-center space-x-6">
      <div>{text}</div>
      <div className="stage">
        <div className="dot-pulse"></div>
      </div>
    </div>
  );
};
