import { useState } from 'react';
import { UserIcon } from '@heroicons/react/24/solid';
import moment from 'moment';

import { SkeletonLoader } from '../../../../common/Skeleton/SkeletonLoader';

import { Auction } from '@/features/market/auctions/models/Auctions';
import { AuctionBid } from '@/features/market/auctions/models/AuctionBids';

import { useAuctions } from '@/hooks/useAuctions';

interface AuctionActivityTabProps {
  auction: Auction;
  auctionBids: AuctionBid[];
}

moment.updateLocale('en', {
  relativeTime: {
    s: 'a minute',
  },
});

export const AuctionActivityTab: React.FC<AuctionActivityTabProps> = ({
  auction,
  auctionBids,
}) => {
  const { loadAuction } = useAuctions();
  const [isLoading, setIsLoading] = useState(false);

  const refresh = async () => {
    setIsLoading(true);
    await loadAuction(auction.id, false);
    setIsLoading(false);
  };

  if (auction?.isNotLiveYet()) {
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-1/4"
          src="/assets/images/giveway_deals.png"
          alt="giveway deals"
        />
        <div className="font-bold text-lg text-center">
          Auction is not yet live. Bids will be revealed after the blind phase
          completes.
        </div>
      </div>
    );
  }

  if (auction?.isOpenPhase()) {
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-1/4"
          src="/assets/images/giveway_deals.png"
          alt="giveway deals"
        />
        <div className="font-bold text-lg text-center">
          Auction is in the blind phase. Bids will be revealed after the blind
          phase completes.
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <SkeletonLoader height={400} />;
  }

  if (auctionBids.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-1/4"
          src="/assets/images/giveway_deals.png"
          alt="giveway deals"
        />
        <div className="font-bold text-lg text-center">No bids yet.</div>
        <button
          onClick={refresh}
          className={`w-30 m-4 py-4 px-8 leading-17 font-semibold text-sm rounded-xl bg-gray-600 text-price-green`}
        >
          Refresh
        </button>
      </div>
    );
  }

  return (
    <div>
      {auctionBids.map((purchase, i) => (
        <div className="flex justify-between p-4 border-b border-gray-text">
          <div className="flex items-center justify-between w-2/3">
            <div className="bg-white rounded-xl w-6 h-6 text-center text-black">
              {i + 1}
            </div>
            <div className="flex items-center space-x-1">
              <UserIcon height="20" width="20" />
              <div>{purchase.publicUserId}</div>
            </div>
            <div className="text-price-green">{purchase.numCoins} coins</div>
          </div>
          <div className="flex justify-end w-1/3 whitespace-nowrap text-ellipsis">
            {moment(purchase.created + 'Z').fromNow()}
          </div>
        </div>
      ))}
    </div>
  );
};
