// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useNavigate } from 'react-router-dom';

// components
import { PortfolioSeriesCard } from '@/components/v2/portfolio/PortfolioSeriesCard';
import { PortfolioBiggestPositionCard } from '@/components/v2/portfolio/featuredHoldingCard/PortfolioBiggestPositionCard';
import { PortfolioTopGainerCard } from '@/components/v2/portfolio/featuredHoldingCard/PortfolioTopGainerCard';
import { PortfolioHoldingsCard } from '@/components/v2/portfolio/holdingsCard/PortfolioHoldingsCard';
import { PortfolioTopHoldingsTable } from '@/components/v2/portfolio/PortfolioTopHoldingsTable';
import { PortfolioNFTHoldingsCard } from '@/components/v2/portfolio/nftHoldingsCard/PortfolioNFTHoldingsCard';
import { ArrowButton } from '@/components/v2/common/Button/ArrowButton';
import { BoltIcon } from '@heroicons/react/24/solid';

export default function PortfolioPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title="AssetDash | Portfolio" lang="en_IE" />
      <div className="main-content space-y-6">
        <div className="flex flex-wrap pad:flex-nowrap justify-between space-x-0 pad:space-x-6 space-y-16 pad:space-y-0">
          <PortfolioSeriesCard />
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <div className="pb-4 px-4 md:px-6 flex justify-between items-center">
              <span className="text-tabs-bg dark:text-white font-semibold text-2xl leading-card-title">
                Net worth
              </span>
            </div>
            <PortfolioHoldingsCard />
          </div>
          <div className="flex flex-col">
            <div className="px-4 md:px-6 flex justify-between">
              <span className="pb-4 text-tabs-bg dark:text-white font-semibold text-2xl leading-card-title">
                Top Gainer
              </span>
            </div>
            <div className="flex h-full space-x-6 overflow-x-clip md:overflow-x-visible">
              <PortfolioBiggestPositionCard />
              <PortfolioTopGainerCard />
              {/* {refreshButton} */}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <div className="py-4 px-4 md:px-6 flex justify-between items-center">
              <span className="text-tabs-bg dark:text-white font-semibold text-2xl leading-card-title">
                Top 5 Holdings
              </span>
              <ArrowButton
                onClick={() => {
                  navigate('/portfolio/all');
                }}
                direction="right"
              >
                View all
              </ArrowButton>
            </div>

            <PortfolioTopHoldingsTable />
          </div>
          <div className="flex flex-col">
            <div className="py-4 px-4 md:px-6 flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <span className="text-tabs-bg dark:text-white font-semibold text-2xl leading-card-title">
                  NFT
                </span>
                <div
                  className="h-15 cursor-pointer flex items-center space-x-1 px-2 py-1 text-xs justify-center border-1 bg-opacity-10 bg-boosted border-boosted text-boosted rounded-full"
                  onClick={() => navigate('/portfolio/nft-activity')}
                >
                  <BoltIcon width="17.5" height="17.5" />
                  <span className="pr-2  text-sm">Activity</span>
                </div>
              </div>
              <ArrowButton
                onClick={() => navigate('/portfolio/nft-gallery')}
                direction="right"
              >
                View all
              </ArrowButton>
            </div>
            <PortfolioNFTHoldingsCard />
          </div>
        </div>
      </div>
    </>
  );
}
