// vendor libraries
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

// components
import { OptionsTab } from '../common/Tab/OptionsTab';
import { AssetDashLogo } from '../common/Logo/AssetDashLogo';

// theme
import { useThemeState } from '@/recoil/theme';

// utils
import { formatPriceNumber } from '@/utils/number';

// api
import { editNFTDetails } from '@/api/portfolioSync';

const priceModalTabs: { [key: string]: string } = {
  multiplier: 'AssetDash Multiplier',
  manualPrice: 'Manual Price',
};

interface NFTEditPriceModalProps {
  walletNFTItem?: WalletNFTItem;
  isModalOpen: boolean;
  defaultTab?: string;
  closeModal: () => void;
}
export const NFTEditPriceModal: React.FC<NFTEditPriceModalProps> = ({
  walletNFTItem,
  isModalOpen,
  closeModal,
  defaultTab,
}) => {
  const [theme] = useThemeState();
  const background =
    theme === 'light' ? 'bg-primary-gray' : 'bg-dark-background';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const { convertUSDCurrency } = useAuth();
  const { customNFTPricesMap, load } = usePortfolio();
  const [selectedTab, setSelectedTab] = useState(defaultTab || 'multiplier');
  const customNFTPrice = customNFTPricesMap.get(walletNFTItem?.nftItem?.id!);
  const [multiplier, setMultiplier] = useState<number | ''>(
    customNFTPrice?.floorMultiplier || '',
  );
  const [manualPrice, setManualPrice] = useState<number | ''>(
    customNFTPrice?.manualPriceUsd || '',
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (walletNFTItem) {
      const customNFTPrice = customNFTPricesMap.get(
        walletNFTItem?.nftItem?.id!,
      );
      if (customNFTPrice?.floorMultiplier) {
        setMultiplier(customNFTPrice.floorMultiplier);
      }
      if (customNFTPrice?.manualPriceUsd) {
        setManualPrice(customNFTPrice.manualPriceUsd);
      }
    }
  }, [customNFTPricesMap, walletNFTItem]);

  const handleToggle = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleMultiplerInput = (input: string) => {
    if (!input) {
      setMultiplier('');
      return;
    }
    const decimals = input.split('.')[1];

    let num = parseFloat(input);
    if (decimals != null && decimals.length > 2) {
      num = parseFloat(parseFloat(input).toFixed(2));
    }

    if (num > 0 && num <= 100) {
      setMultiplier(num);
    } else {
      setMultiplier('');
    }
  };

  const handleManualPriceInput = (input: string) => {
    if (!input) {
      setManualPrice('');
      return;
    }
    let num = parseFloat(input);

    if (num > 0 && num <= 1000000) {
      setManualPrice(num);
    }
  };

  const handleSaveMultipler = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await editNFTDetails({
      userWalletCurrentNftItemId: walletNFTItem?.id!,
      floorMultiplier: multiplier || 0,
    });
    await load({ checkCache: false });
    closeModal();
    setLoading(false);
    setMultiplier('');
    setManualPrice('');
  };

  const handleSaveManualPrice = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await editNFTDetails({
      userWalletCurrentNftItemId: walletNFTItem?.id!,
      manualPriceUsd: manualPrice || 0,
    });
    await load({ checkCache: false });
    closeModal();
    setLoading(false);
    setMultiplier('');
    setManualPrice('');
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`${background} ${textColor}`}>
                <div className="p-10 space-y-4 rounded-lg">
                  <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-semibold ">Set Up Price</h2>
                    <button
                      onClick={closeModal}
                      className="font-semibold text-sm text-price-red"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="mt-4 flex">
                      <span className="font-normal text-md leading-5 mt-14 text-center">
                        Is your NFT worth more than the floor price? Set a
                        higher price by using the{' '}
                        <AssetDashLogo size="medium" /> multiplier or set a
                        static price manually.
                      </span>
                    </div>
                    <div className="py-5 px-4 md:px-6">
                      <OptionsTab
                        tabs={priceModalTabs}
                        activeTab={selectedTab}
                        onClick={(key: string) => handleToggle(key)}
                      />
                    </div>
                    {selectedTab === 'multiplier' && (
                      <div className="mt-4 space-y-4 flex flex-col items-center font-normal text-md">
                        <div>
                          Set a Multiplier of 2 if you believe your NFT is worth
                          double the floor.
                        </div>
                        <div className="p-4 rounded-lg border border-gray-text max-w-sm">
                          Floor Price x Multiplier = Final Price
                        </div>
                        <div className="flex flex-col p-4 w-1/2">
                          <div className="flex justify-between">
                            <div className="text-gray-text">Floor Price</div>
                            <div>
                              {convertUSDCurrency(
                                walletNFTItem?.floorPriceUsd!,
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between border-b border-gray-text pb-2">
                            <div className="text-gray-text">Multiplier</div>
                            <div>{multiplier || '1.00'}</div>
                          </div>
                          <div className="flex justify-between pt-2">
                            <div className="text-gray-text">Final Price</div>
                            <div className="text-price-green">
                              {convertUSDCurrency(
                                walletNFTItem?.floorPriceUsd! *
                                  (multiplier || 1),
                              )}
                            </div>
                          </div>
                        </div>
                        <form onSubmit={handleSaveMultipler}>
                          <div>Set up Multipler</div>
                          <input
                            type="number"
                            step="any"
                            className="text-center w-48 bg-transparent border-1 border-border-bg dark:border-tabs-bg font-normal text-4xl leading-44 px-6  placeholder-gray-text"
                            placeholder="1.00"
                            value={multiplier}
                            onChange={e => handleMultiplerInput(e.target.value)}
                          ></input>
                          <div className="mt-12 flex justify-center">
                            <button
                              type="submit"
                              className={`px-4 py-2 ${
                                !loading
                                  ? 'bg-price-green cursor-pointer'
                                  : 'bg-white dark:bg-tabs-bg'
                              }`}
                              disabled={loading}
                            >
                              {loading ? (
                                <div className="loader w-full"></div>
                              ) : (
                                <div>Save</div>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                    {selectedTab === 'manualPrice' && (
                      <div className="mt-4 w-full space-y-4 flex flex-col items-center font-normal text-md">
                        <div>Set a manual price for your NFT in USD</div>
                        <div className="flex justify-between w-1/2">
                          <div className="text-gray-text">Price</div>
                          {manualPrice ? (
                            <div className="text-price-green">
                              {convertUSDCurrency(manualPrice)}
                            </div>
                          ) : (
                            <div>
                              {convertUSDCurrency(
                                walletNFTItem?.floorPriceUsd!,
                              )}
                            </div>
                          )}
                        </div>
                        <form onSubmit={handleSaveManualPrice}>
                          <div>Set Manual Price ($)</div>
                          <input
                            type="number"
                            className="text-center w-64 bg-transparent border-1 border-border-bg dark:border-tabs-bg font-normal text-4xl leading-44 px-6  placeholder-gray-text"
                            placeholder={`$${formatPriceNumber(
                              walletNFTItem?.floorPriceUsd!,
                            )}`}
                            value={manualPrice}
                            onChange={e =>
                              handleManualPriceInput(e.target.value)
                            }
                          ></input>
                          <div className="mt-12 flex justify-center">
                            <button
                              type="submit"
                              className={`px-4 py-2 ${
                                !loading
                                  ? 'bg-price-green cursor-pointer'
                                  : 'bg-white dark:bg-tabs-bg'
                              }`}
                              disabled={loading}
                            >
                              {loading ? (
                                <div className="loader w-full"></div>
                              ) : (
                                <div>Save</div>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
