export class AssetDashExpeditionsResponse {
  assetdashExpeditions?: AssetDashExpedition[];

  constructor({
    assetdashExpeditions,
  }: {
    assetdashExpeditions?: AssetDashExpedition[];
  }) {
    this.assetdashExpeditions = assetdashExpeditions;
  }

  static fromJson(json: any): AssetDashExpeditionsResponse {
    const assetdashExpeditions = json['assetdash_expeditions']
      ? json['assetdash_expeditions'].map((v: any) =>
          AssetDashExpedition.fromJson(v),
        )
      : undefined;
    return new AssetDashExpeditionsResponse({ assetdashExpeditions });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.assetdashExpeditions)
      data['assetdash_expeditions'] = this.assetdashExpeditions.map(
        expedition => expedition.toJson(),
      );
    return data;
  }
}

export class AssetDashExpedition {
  id: string;
  slug: string;
  name: string;
  status: string;
  description: string;
  imageUrl: string;
  rewardCoins: number;
  instructions: string[];
  appScreen?: string;

  constructor({
    id,
    slug,
    name,
    status,
    description,
    imageUrl,
    rewardCoins,
    instructions,
    appScreen,
  }: {
    id: string;
    slug: string;
    name: string;
    status: string;
    description: string;
    imageUrl: string;
    rewardCoins: number;
    instructions: string[];
    appScreen?: string;
  }) {
    this.id = id;
    this.slug = slug;
    this.name = name;
    this.status = status;
    this.description = description;
    this.imageUrl = imageUrl;
    this.rewardCoins = rewardCoins;
    this.instructions = instructions;
    this.appScreen = appScreen;
  }

  static fromJson(json: any): AssetDashExpedition {
    return new AssetDashExpedition({
      id: json['id'],
      slug: json['slug'],
      name: json['name'],
      status: json['status'],
      description: json['description'],
      imageUrl: json['image_url'],
      rewardCoins: json['reward_coins'],
      instructions: json['instructions'] || [],
      appScreen: json['app_screen'],
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      slug: this.slug,
      name: this.name,
      status: this.status,
      description: this.description,
      image_url: this.imageUrl,
      reward_coins: this.rewardCoins,
      instructions: this.instructions,
      app_screen: this.appScreen,
    };
  }
}
