// import PrivateEquityTab from './PrivateEquityTab';

interface PrivateEquityViewProps {
  equityName: string;
  setEquityName: (value: string) => void;
  numberOfShares: number | null;
  setNumberOfShares: (value: number) => void;
  pricePerShare: number | null;
  setPricePerShare: (value: number) => void;
  percentOwnership: number | null;
  setPercentOwnership: (value: number) => void;
  companyEvaluation: number | null;
  setCompanyEvaluation: (value: number) => void;
  selectedEquity: string;
  setSelectedEquity: (value: string) => void;
}

export const PrivateEquityView: React.FC<PrivateEquityViewProps> = ({
  equityName,
  setEquityName,
  numberOfShares,
  setNumberOfShares,
  pricePerShare,
  setPricePerShare,
  percentOwnership,
  setPercentOwnership,
  companyEvaluation,
  setCompanyEvaluation,
  selectedEquity,
  setSelectedEquity,
}) => {
  // const equityTabs = {
  //   number_of_shares: 'Number of Shares',
  //   percent_ownership: 'Percent Ownership',
  // };

  // const onTabClick = (key: string) => {
  //   setSelectedEquity(key);
  // };

  return (
    <div>
      <div className="space-y-4">
        {/* <PrivateEquityTab
          tabs={equityTabs}
          selected={selectedEquity}
          onClick={onTabClick}
        /> */}
        <div>
          <label htmlFor="name" className="text-sm text-gray-600">
            Name
          </label>
          <input
            type="text"
            id="name"
            className="w-full bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
            value={equityName}
            onChange={e => {
              setEquityName(e.target.value);
            }}
          />
        </div>
        {selectedEquity === 'number_of_shares' ? (
          <div className="space-y-4">
            <div>
              <label htmlFor="numberOfShares" className="text-sm text-gray-600">
                Number of Shares
              </label>
              <input
                type="number"
                id="numberOfShares"
                className="w-full bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
                value={numberOfShares || ''}
                onChange={e => {
                  setNumberOfShares(parseFloat(e.target.value));
                }}
              />
            </div>
            <div>
              <label htmlFor="pricePerShare" className="text-sm text-gray-600">
                Price Per Share
              </label>
              <input
                type="number"
                id="pricePerShare"
                className="w-full bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
                value={pricePerShare || ''}
                onChange={e => {
                  setPricePerShare(parseFloat(e.target.value));
                }}
              />
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label
                htmlFor="percentOwnership"
                className="text-sm text-gray-600"
              >
                Percent Ownership
              </label>
              <input
                type="number"
                id="percentOwnership"
                max="100"
                min="0"
                className="w-full bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
                value={percentOwnership || ''}
                onChange={e => {
                  setPercentOwnership(parseFloat(e.target.value));
                }}
              />
            </div>
            <div>
              <label
                htmlFor="companyEvaluation"
                className="text-sm text-gray-600"
              >
                Company Evaluation
              </label>
              <input
                type="number"
                id="companyEvaluation"
                className="w-full bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
                value={companyEvaluation || ''}
                onChange={e => {
                  setCompanyEvaluation(parseFloat(e.target.value));
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
