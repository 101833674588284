import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useThemeState } from '@/recoil/theme';

export default function CurrentPrizeModal({ open, setOpen, prizes }) {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';
  const rowBg = theme === 'light' ? 'bg-primary-gray' : 'bg-black';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${background} relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6`}
              >
                <div>
                  <div className="text-center">
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon
                        height={24}
                        width={24}
                        className="text-price-red"
                      />
                    </span>
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl font-bold mb-4 ${textColor}`}
                    >
                      All Prizes
                    </Dialog.Title>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-145 overflow-scroll scroller">
                      {prizes.map(prize => {
                        return (
                          <div
                            key={prize.id}
                            className={`p-4 space-y-6 rounded-xl text-left ${rowBg}`}
                          >
                            <img
                              src={prize.image_url}
                              className="rounded-xl w-full object-cover"
                              style={{ height: '240px' }}
                              alt="prize"
                            />
                            <div className="space-y-2">
                              <div>
                                <p className="text-deals-title text-lg">
                                  Provider
                                </p>
                                <p
                                  className={`${textColor} text-2xl font-bold`}
                                >
                                  {prize.title}
                                </p>
                              </div>
                              <div>
                                <p className="text-deals-title text-lg">
                                  Estimated Value
                                </p>
                                <p
                                  className={`${textColor} text-2xl font-bold`}
                                >
                                  ${prize.value?.toFixed(2)}
                                </p>
                              </div>
                              <div>
                                <p className="text-deals-title text-lg">
                                  Number of Winners
                                </p>
                                <p
                                  className={`${textColor} text-2xl font-bold`}
                                >
                                  {prize.num_winners}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
