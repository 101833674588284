import dayjs from 'dayjs';

export const getFormattedDate = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format = 'DD MMMM YYYY',
) => {
  return dayjs(date).format(format);
};

export const datediff = (first, second) => {
  return Math.round((first - second) / (1000 * 60 * 60 * 24));
};

/**
 * new Date("dateString") is browser-dependent and discouraged, so we'll write
 * a simple parse function for U.S. date format (which does no error checking)
 */
export const parseCustomDate = str => {
  if (str) {
    var mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }
};

export const getDaysOfWeek = (): string[] => {
  const weekdays = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
  const todayIndex = new Date().getDay();
  return weekdays.slice(todayIndex).concat(weekdays.slice(0, todayIndex));
};
