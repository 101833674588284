import { useTokenState } from '@/recoil/token';
import {
  AcademicCapIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloudArrowDownIcon,
  FireIcon,
  GiftIcon,
  HomeIcon,
  LightBulbIcon,
  MapIcon,
  PuzzlePieceIcon,
  SwatchIcon,
  UserCircleIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface SidebarProps {
  isOpen: boolean;
  toggle: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [token] = useTokenState();

  const menuItems = [
    {
      name: 'Portfolio',
      path: '/portfolio',
      icon: <HomeIcon className="w-5 h-5" />,
    },
    {
      name: 'Swap',
      path: `${process.env.REACT_APP_SWAP_URL}?token=${token}`,
      icon: <SwatchIcon className="w-5 h-5" />,
    },
    {
      name: 'Wallets',
      path: '/wallets',
      icon: <WalletIcon className="w-5 h-5" />,
    },
    {
      name: 'Member',
      path: '/member',
      icon: <UserCircleIcon className="w-5 h-5" />,
    },
    {
      name: 'Elements',
      path: '/elements',
      icon: <LightBulbIcon className="w-5 h-5" />,
    },
    { name: 'Market', path: '/market', icon: <GiftIcon className="w-5 h-5" /> },
    {
      name: 'Learn',
      path: '/learn',
      icon: <AcademicCapIcon className="w-5 h-5" />,
    },
    {
      name: 'Expeditions',
      path: '/expeditions',
      icon: <MapIcon className="w-5 h-5" />,
    },
    {
      name: 'Games',
      path: '/games',
      icon: <PuzzlePieceIcon className="w-5 h-5" />,
    },
    {
      name: 'Rewards',
      path: '/rewards',
      icon: <CloudArrowDownIcon className="w-5 h-5" />,
    },
    {
      name: 'Dashdrop',
      path: '/giveaways',
      icon: <ChatBubbleOvalLeftIcon className="w-5 h-5" />,
    },
    {
      name: 'Vanta Burn',
      path: `${process.env.REACT_APP_BURN_URL}`,
      icon: <FireIcon className="w-5 h-5" />,
    },
  ];

  return (
    <div
      className={`sidebar fixed top-[70px] min-h-screen z-30 border-border-bg border-r-2 dark:border-transparent bg-white dark:bg-darkest-dark shadow-lg transition-all duration-300 ease-in-out ${
        isOpen ? 'w-64' : 'w-16'
      }`}
    >
      <nav className="mt-5">
        <ul className="space-y-2">
          {menuItems.map((item, index) => {
            const isActive =
              location.pathname === item.path ||
              (location.pathname === '/' && item.path === '/portfolio');

            return (
              <li key={index} className="px-4">
                <button
                  title={item.name}
                  className={`flex items-center space-x-2 ${
                    isActive
                      ? 'bg-primary text-white'
                      : 'text-gray-900 dark:text-white'
                  } ${
                    isOpen ? 'px-4' : 'justify-center'
                  } py-2 text-sm font-semibold rounded-lg w-full text-left hover:bg-primary/45`}
                  onClick={() =>
                    item.name === 'Swap' || item.name === 'Vanta Burn'
                      ? window.open(item.path, '_blank')
                      : navigate(item.path)
                  }
                >
                  {item.icon}
                  {isOpen && <span>{item.name}</span>}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="absolute top-80 -right-9 mt-4 ml-4">
        <button
          onClick={toggle}
          className="p-2 bg-white dark:bg-darkest-dark hover:bg-gray-300 dark:hover:bg-gray-700 rounded-e-xl border-t border-r border-b border-border-bg dark:border-transparent"
        >
          {isOpen ? (
            <ChevronLeftIcon className="w-5 h-5 text-gray-600 dark:text-gray-200" />
          ) : (
            <ChevronRightIcon className="w-5 h-5 text-gray-600 dark:text-gray-200" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
