export const getPasswordValidationMessage = (
  input: string,
  validate = true,
): string => {
  if (input.length === 0) {
    return 'Please enter the password';
  }
  if (validate && input.length < 8) {
    return 'Password must have at least eight characters';
  }

  return '';
};

export const getEmailValidationMessage = (input: string): string => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  if (!emailRegex.test(input) || input.length > 100) {
    return 'Invalid email address';
  }

  return '';
};
