export const ElementsBanner = () => {
  return (
    <div className="relative w-full h-64 flex items-center justify-center card overflow-hidden">
      <img
        src="https://storage.googleapis.com/assetdash-prod-images/games/games_top_banner.png"
        alt="Games Top Banner"
        className="absolute inset-0 mx-auto transform scale-150 h-full w-auto object-center object-cover"
      />
      <div className="absolute text-white bottom-0 w-full h-1/2 md:h-1/3 bg-black bg-opacity-40 flex flex-col justify-center p-4">
        <h2 className="text-2xl sm:text-4xl font-bold mb-1 sm:mb-2">
          Elements
        </h2>
        <p className="text-sm mb-2">
          Elements is the first generative PFP collection ever minted on WNS.
          Provenance and History intertwined with the power of AssetDash. Now
          live on Tensor!
        </p>
      </div>
    </div>
  );
};
