import { useEffect } from 'react';
import { useDealsLegacy } from '@/hooks/useDealsLegacy';
import { AssetDashLogo } from '../common/Logo/AssetDashLogo';
import styles from './GetLuckyOpeningScreen.module.css';
import LottieAnimation from '../common/LottieAnimation/LottieAnimation';

import { useConfetti } from '@/hooks/useConfetti';

export default function GetLuckyOpeningScreen() {
  const { setShowConfetti } = useConfetti();
  const { setShowFortuneCookieOpenings, setOpenFortuneCookieModal } =
    useDealsLegacy();

  const texts = [
    // "Your NFT gallery is loading",
    'Opening up your Fortune Cookie',
  ];
  const textIndex = texts[0];

  useEffect(() => {
    showForFiveSeconds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showForFiveSeconds = () => {
    setTimeout(() => {
      setShowFortuneCookieOpenings(false);
      // open modal with fortune cookie details
      // if (fortuneCookie?.num_tickets > 0)
      setOpenFortuneCookieModal(true);
      setShowConfetti(true);
      // else toast.error("Oops, You have not won any giveaway tickets!");
    }, 3000);
  };

  return (
    <div className="justify-center items-center">
      <div
        className={`${styles.loadingContainer} flex  flex-col `}
        style={{
          backgroundImage:
            "url('https://storage.googleapis.com/assetdash-prod-images/web_app/DealsLoadingPage.png')",
        }}
      >
        <div className="mb-4 md:mb-8 xxl:mb-16 items-center justify-center text-center mt-8 md:mt-28">
          <AssetDashLogo size="large" forceWhite={true} />
        </div>
        <p className="px-2 h-16 md:px-4 text-white text-2xl font-semibold items-center justify-center text-center leading-6 md:leading-15x">
          {textIndex}
        </p>
        <div className="mx-auto lottie-outer">
          <LottieAnimation name="giftOpening" />
        </div>
      </div>
    </div>
  );
}
