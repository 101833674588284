import React from 'react';

const ElementsUpgradeCard: React.FC = () => {
  return (
    <div className="card p-6 rounded-md relative">
      <img
        className="absolute top-0 right-0 w-full h-full opacity-100 dark:opacity-40"
        src="/assets/images/elements_upgrade_background.png"
        alt="elements set"
      />
      <div className="relative z-10 flex flex-col items-center justify-center h-full">
        <img
          className="mb-4"
          src="/assets/icons/icon_ranking.svg"
          alt="Upgrade Icon"
        />
        <h2 className="text-2xl font-bold mb-2 text-element-text-dark">
          Upgrade To Elements
        </h2>
        <p className="mb-4 text-element-text-dark text-center">
          Elements are the first PFP collection on WEN. History and Provenance
          intertwined with the power of AssetDash.
        </p>
        <button
          onClick={() => {
            window.open(
              'https://odd-chopper-fec.notion.site/Elements-Benefits-20c8c22beab14ccaa470468ebaf3c3bd',
            );
          }}
          className="w-full bg-white text-primary font-bold py-2 px-4 rounded-full"
        >
          Upgrade
        </button>
      </div>
    </div>
  );
};

export default ElementsUpgradeCard;
