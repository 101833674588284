// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { InboxMessageDetailsContainer } from '../components/inbox/InboxMessageDetailsContainer';

export default function InboxMessageDetailsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title={`AssetDash | Inbox Message`} lang="en_IE" />
      <InboxMessageDetailsContainer />
    </div>
  );
}
