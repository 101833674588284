// vendor libraries
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';

// types
import { ExtendedColumn } from '../common/Table/types';

// constants
import { SORT_ASC, SORT_DESC } from '@constants';

// components
import { AssetLogo } from '../common/Logo/AssetLogo';
import { ComponentPlaceholder } from '../common/Placeholder/ComponentPlaceholder';
import { SmallTable } from '../common/Table/SmallTable';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';
import { useAuth } from '@/hooks/useAuth';

// utils
import { formatNumber, getNumberColor } from '@/utils/number';

// models
import { Holding } from '@/features/portfolio/models/Holding';

export const PortfolioTopHoldingsTable: React.FC = () => {
  const [sortValue, setSortValue] = useState('value');
  const [sort, setSort] = useState(SORT_DESC);

  const { isLoading, holdings } = usePortfolio();
  const { convertUSDCurrency } = useAuth();

  const navigate = useNavigate();

  const columns = useMemo(
    () =>
      [
        {
          Header: '',
          accessor: 'logo_url',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            const asset = holding.asset;

            return (
              <div className="w-8 h-8">
                <AssetLogo
                  type={asset.assetTypeGroup}
                  logoUrl={asset.logoUrl}
                  width={28}
                  height={28}
                />
              </div>
            );
          },
        },
        {
          Header: 'Name',
          accessor: 'ticker_display',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            const asset = holding.asset;

            return <div>{`${asset.tickerDisplay}`}</div>;
          },
        },
        {
          Header: 'Allocation',
          accessor: 'percent',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            return <div>{`${formatNumber(holding.allocation)}%`}</div>;
          },
        },
        {
          Header: 'Value',
          accessor: 'value',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            return <div>{convertUSDCurrency(holding.amount)}</div>;
          },
        },
        {
          Header: 'Price',
          accessor: 'price',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            const asset = holding.asset;

            return <div>{convertUSDCurrency(asset.priceUsd!)}</div>;
          },
        },
        {
          Header: 'Price 1D',
          accessor: 'price_gain_percentage',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            return (
              <div
                style={{ color: getNumberColor(holding.priceGainPercentage!) }}
              >
                {`${formatNumber(holding.priceGainPercentage!)}%`}
              </div>
            );
          },
        },
      ] as ExtendedColumn<Holding>[],
    [convertUSDCurrency],
  );

  const rowHandler = (assetTypeGroup: string, id: string) => {
    navigate(`/assets/${id}`);
  };

  const handleSort = (value: typeof SORT_ASC | typeof SORT_DESC) => {
    if (value !== sortValue) {
      setSort(SORT_DESC);
    } else {
      setSort(prevState => (prevState === SORT_ASC ? SORT_DESC : SORT_ASC));
    }

    setSortValue(value);
  };

  return (
    <div className="card w-570 h-full grow overflow-hidden flex flex-col">
      {holdings?.length > 0 && !isLoading ? (
        <div className="top-holdings-table">
          <SmallTable
            data={holdings.slice(0, 5)}
            columns={columns}
            handleSort={handleSort}
            sortValue={sortValue}
            sort={sort}
            rowHandler={rowHandler}
          />
        </div>
      ) : isLoading ? (
        <div className="px-6">
          <div className="flex-grow">
            <SkeletonLoader height={200} />
          </div>
        </div>
      ) : (
        <div className="h-42">
          <ComponentPlaceholder />
        </div>
      )}
    </div>
  );
};
