// vendor libraries
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronRightIcon,
  UserPlusIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  CodeBracketIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  UserIcon,
  ChartBarSquareIcon,
  WalletIcon,
  CreditCardIcon,
  GiftIcon,
  AcademicCapIcon,
  PlayIcon,
  WrenchScrewdriverIcon,
  CircleStackIcon,
  ChatBubbleBottomCenterTextIcon,
  BellAlertIcon,
  CloudArrowDownIcon,
  ArrowsRightLeftIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import CountUp from 'react-countup';
import { IconButtonWrapper } from '../Button/IconButtonWrapper';
import { AuthButton } from '../Button/AuthButton';

import { useAuth } from '../../../hooks/useAuth';
import { usePortfolio } from '../../../hooks/usePortfolio';
import { useInbox } from '../../../hooks/useInbox';

// components
import { CurrencyFormatButton } from '@/components/v2/common/Button/CurrencyFormatButton';
import { CurrencyVisibilityButton } from '../Button/CurrencyVisibilityButton';
import { SyncButton } from '../Button/SyncButton';
import { ThemeButton } from '../Button/ThemeButton';

// api
import { getUserCoinCenter } from '../../../api/coin';

// models
import { CoinCenter } from '../../../features/coins/models/CoinCenter';
import { AssetDashLogo } from '@/components/v2/common/Logo/AssetDashLogo';
import { isMobile } from '@/utils/device';

interface HeaderProps {
  isOpen: boolean;
}

export const Header: React.FC<HeaderProps> = ({ isOpen }) => {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useAuth();
  const { clearData } = usePortfolio();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const { inboxCenter } = useInbox();

  const [coinCenter, setCoinCenter] = useState<CoinCenter>();

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserCoinCenter();
    }
  }, [isLoggedIn]);

  const fetchUserCoinCenter = async () => {
    const response = await getUserCoinCenter();
    setCoinCenter(CoinCenter.fromJson(response.data.coin_center));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        setIsDrawerOpen(false);
      }
    };

    if (isDrawerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div>
      <header className="bg-white dark:bg-darkest-dark fixed top-0 w-full z-10">
        <nav className="px-1 sm:px-4 lg:px-8 py-2 shadow-md" aria-label="Top">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-5">
              {isMobile() ? (
                <img
                  src="/assets/icons/logo.png"
                  className="mx-2 w-8 h-auto"
                  alt="AssetDash"
                />
              ) : (
                <AssetDashLogo />
              )}

              <div className="hidden md:inline-block">
                <ThemeButton />
              </div>
            </div>
            <div className="flex justify-end xl:w-1/2 mr-2 md:mr-0">
              <div className="space-x-1  md:space-x-2 flex flex-row items-center justify-end">
                {isLoggedIn && (
                  <>
                    {!isMobile() && (
                      <div className="flex items-center justify-between mx-2 p-1">
                        <div onClick={() => setIsDrawerOpen(false)}>
                          <SyncButton />
                        </div>
                      </div>
                    )}
                    {!isMobile() && (
                      <div className="flex items-center justify-between mx-2 p-1">
                        <CurrencyVisibilityButton />
                      </div>
                    )}
                    <div className="flex items-center justify-between mx-2 p-1">
                      <CurrencyFormatButton />
                    </div>
                    <div
                      className="h-15 cursor-pointer flex items-center space-x-1 text-xs justify-center px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full"
                      onClick={() => navigate('/coins')}
                    >
                      <div className="pl-2">
                        <img
                          className="rounded-full"
                          src="/assets/icons/coin.png"
                          alt="Coin"
                          height={24}
                          width={24}
                        />
                      </div>
                      <span className="pr-2 text-price-green text-sm">
                        <CountUp
                          duration={2}
                          end={coinCenter?.totalCoins || 0}
                        />
                      </span>
                    </div>
                    <div
                      className="intercom-launcher py-3 cursor-pointer"
                      onClick={() => setIsDrawerOpen(false)}
                    >
                      <ChatBubbleBottomCenterTextIcon
                        width={30}
                        height={30}
                        className="text-price-green mr-2"
                      />
                    </div>
                    <IconButtonWrapper onClick={() => navigate('/inbox')}>
                      <div className="relative inline-block cursor-pointer">
                        <img
                          src="/assets/icons/icon_inbox.png"
                          alt="icon"
                          width={24}
                          height={24}
                        />
                        {(inboxCenter?.unreadMessages || 0) > 0 && (
                          <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-price-green rounded-full"></div>
                        )}
                      </div>
                    </IconButtonWrapper>
                    <IconButtonWrapper onClick={() => setIsDrawerOpen(true)}>
                      <Cog6ToothIcon
                        width="18"
                        height="18"
                        className="text-primary font-bold"
                      />
                    </IconButtonWrapper>
                  </>
                )}
                {!isLoggedIn && (
                  <>
                    <ThemeButton />
                    <AuthButton />
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Menu */}
      <div
        ref={menuRef}
        className={`bg-gray-100 dark:bg-darkest-dark fixed top-0 left-0 h-full transform transition-transform duration-300 z-20 ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } w-full md:w-96`}
      >
        <nav
          className="bg-white dark:bg-deals-card-top px-4 rounded-b-20"
          aria-label="Top"
        >
          <div className="flex justify-between w-full items-center py-4">
            <h1 className="text-xl">Menu</h1>
            <IconButtonWrapper onClick={() => setIsMenuOpen(false)}>
              <XMarkIcon
                width="12.57"
                height="12.57"
                className=" text-orange-dark"
              />
            </IconButtonWrapper>
          </div>
        </nav>
        <div className="divide-y divide-gray-text dark:divide-gray-600 py-5 h-full px-4">
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/portfolio');
            }}
          >
            <div className="flex items-center space-x-2">
              <ChartBarSquareIcon width="17.5" height="17.5" />
              <div>Portfolio</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              window.location.href = `${process.env.REACT_APP_SWAP_URL}?token=${token}`;
            }}
          >
            <div className="flex items-center space-x-2">
              <ArrowsRightLeftIcon width="17.5" height="17.5" />
              <div>Swap</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/learn');
            }}
          >
            <div className="flex items-center space-x-2">
              <AcademicCapIcon width="17.5" height="17.5" />
              <div>Learn</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/games');
            }}
          >
            <div className="flex items-center space-x-2">
              <PlayIcon width="17.5" height="17.5" />
              <div>Games</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/market');
            }}
          >
            <div className="flex items-center space-x-2">
              <GiftIcon width="17.5" height="17.5" />
              <div>Market</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/wallets');
            }}
          >
            <div className="flex items-center space-x-2">
              <WalletIcon width="17.5" height="17.5" />
              <div>Wallets</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/expeditions');
            }}
          >
            <div className="flex items-center space-x-2">
              <CircleStackIcon width="17.5" height="17.5" />
              <div>Expeditions</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/member');
            }}
          >
            <div className="flex items-center space-x-2">
              <UserIcon width="17.5" height="17.5" />
              <div>Member</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/cards');
            }}
          >
            <div className="flex items-center space-x-2">
              <CreditCardIcon width="17.5" height="17.5" />
              <div>Cards</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>

          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              window.location.href = `${process.env.REACT_APP_SWAP_URL}?token=${token}`;
            }}
          >
            <div className="flex items-center space-x-2">
              <WrenchScrewdriverIcon width="17.5" height="17.5" />
              <div>Staking</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsMenuOpen(false);
              navigate('/rewards');
            }}
          >
            <div className="flex items-center space-x-2">
              <CloudArrowDownIcon width="17.5" height="17.5" />
              <div>Rewards</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
        </div>
      </div>

      {/* Drawer */}
      <div
        ref={drawerRef}
        className={`bg-gray-100 dark:bg-darkest-dark fixed top-0 right-0 h-full transform transition-transform duration-300 z-20 ${
          isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
        } w-full md:w-96`}
      >
        <nav
          className="bg-white dark:bg-deals-card-top px-4 rounded-b-20"
          aria-label="Top"
        >
          <div className="flex justify-between w-full items-center py-4">
            <h1 className="text-xl">Settings</h1>
            <IconButtonWrapper onClick={() => setIsDrawerOpen(false)}>
              <XMarkIcon
                width="12.57"
                height="12.57"
                className=" text-orange-dark"
              />
            </IconButtonWrapper>
          </div>
          <div className="divide-y divide-gray-text dark:divide-gray-600 pb-2">
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Theme</div>
              <ThemeButton />
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Privacy Mode</div>
              <CurrencyVisibilityButton />
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Currency</div>
              <CurrencyFormatButton />
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Sync</div>
              <div onClick={() => setIsDrawerOpen(false)}>
                <SyncButton />
              </div>
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Support</div>
              <div
                className="intercom-launcher py-3 cursor-pointer"
                onClick={() => setIsDrawerOpen(false)}
              >
                <ChatBubbleBottomCenterTextIcon
                  width="17.5"
                  height="17.5"
                  className="text-price-green mr-2"
                />
              </div>
            </div>
          </div>
        </nav>
        <div className="divide-y divide-gray-text dark:divide-gray-600 py-5 h-full px-4">
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/account');
            }}
          >
            <div className="flex items-center space-x-2">
              <UserIcon width="17.5" height="17.5" />
              <div>Account</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/hidden-assets');
            }}
          >
            <div className="flex items-center space-x-2">
              <CircleStackIcon width="17.5" height="17.5" />
              <div>Hidden assets</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/notifications');
            }}
          >
            <div className="flex items-center space-x-2">
              <BellAlertIcon width="17.5" height="17.5" />
              <div>Notifications</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/invite');
            }}
          >
            <div className="flex items-center space-x-2">
              <UserPlusIcon width="17.5" height="17.5" />
              <div>Invite friends</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/invite-code');
            }}
          >
            <div className="flex items-center space-x-2">
              <CodeBracketIcon width="17.5" height="17.5" />
              <div>Invite code</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/legal');
            }}
          >
            <div className="flex items-center space-x-2">
              <BookOpenIcon width="17.5" height="17.5" />
              <div>Legal</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/faq');
            }}
          >
            <div className="flex items-center space-x-2">
              <QuestionMarkCircleIcon width="17.5" height="17.5" />
              <div>FAQ</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              window.open('https://assetdash.gitbook.io/api-docs/', '_blank');
            }}
          >
            <div className="flex items-center space-x-2">
              <DocumentTextIcon width="17.5" height="17.5" />
              <div>Documentation</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2 cursor-pointer"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              clearData();
              logout();
            }}
          >
            <div className="flex items-center space-x-2">
              <ArrowRightOnRectangleIcon width="17.5" height="17.5" />
              <div>Logout</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
