import { NFTItem } from './NFTItem';

export class WalletNFTItem {
  id?: string;
  userId?: string;
  userWalletId?: string;
  nftItemId?: string;
  nftCollectionId?: string;
  assetId?: string;
  chain?: string;
  quantity?: number;
  priceNative?: number;
  priceUsd?: number;
  floorPriceNative?: number;
  floorPriceUsd?: number;
  staked?: boolean;
  verified?: boolean;
  nftItem?: NFTItem;

  constructor({
    id,
    userId,
    userWalletId,
    nftItemId,
    nftCollectionId,
    assetId,
    chain,
    quantity,
    priceNative,
    priceUsd,
    floorPriceNative,
    floorPriceUsd,
    staked,
    verified,
    nftItem,
  }: Partial<WalletNFTItem> = {}) {
    this.id = id;
    this.userId = userId;
    this.userWalletId = userWalletId;
    this.nftItemId = nftItemId;
    this.nftCollectionId = nftCollectionId;
    this.assetId = assetId;
    this.chain = chain;
    this.quantity = quantity;
    this.priceNative = priceNative;
    this.priceUsd = priceUsd;
    this.floorPriceNative = floorPriceNative;
    this.floorPriceUsd = floorPriceUsd;
    this.staked = staked;
    this.verified = verified;
    this.nftItem = nftItem;
  }

  static fromJSON(json: any): WalletNFTItem {
    return new WalletNFTItem({
      id: json['id'],
      userId: json['user_id'],
      userWalletId: json['user_wallet_id'],
      nftItemId: json['nft_item_id'],
      nftCollectionId: json['nft_collection_id'],
      assetId: json['asset_id'],
      chain: json['nft_chain'],
      quantity: json['quantity'],
      priceNative: json['price_native'],
      priceUsd: json['price_usd'],
      floorPriceNative: json['floor_price_native'],
      floorPriceUsd: json['floor_price_usd'],
      staked: json['staked'],
      verified: json['verified'],
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      user_id: this.userId,
      user_wallet_id: this.userWalletId,
      nft_item_id: this.nftItemId,
      nft_collection_id: this.nftCollectionId,
      asset_id: this.assetId,
      nft_chain: this.chain,
      quantity: this.quantity,
      price_native: this.priceNative,
      price_usd: this.priceUsd,
      floor_price_native: this.floorPriceNative,
      floor_price_usd: this.floorPriceUsd,
      staked: this.staked,
      verified: this.verified,
    };
  }

  copyWith({
    id,
    userId,
    userWalletId,
    nftItemId,
    nftCollectionId,
    assetId,
    chain,
    quantity,
    priceNative,
    priceUsd,
    floorPriceNative,
    floorPriceUsd,
    staked,
    verified,
    nftItem,
  }: Partial<WalletNFTItem>): WalletNFTItem {
    return new WalletNFTItem({
      id: id ?? this.id,
      userId: userId ?? this.userId,
      userWalletId: userWalletId ?? this.userWalletId,
      nftItemId: nftItemId ?? this.nftItemId,
      nftCollectionId: nftCollectionId ?? this.nftCollectionId,
      assetId: assetId ?? this.assetId,
      chain: chain ?? this.chain,
      quantity: quantity ?? this.quantity,
      priceNative: priceNative ?? this.priceNative,
      priceUsd: priceUsd ?? this.priceUsd,
      floorPriceNative: floorPriceNative ?? this.floorPriceNative,
      floorPriceUsd: floorPriceUsd ?? this.floorPriceUsd,
      staked: staked ?? this.staked,
      verified: verified ?? this.verified,
      nftItem: nftItem ?? this.nftItem,
    });
  }
}
