// vendor libraries
import React from 'react';

import { NFTItemsSlider } from '../../nft/NFTItemsSlider';
import { ComponentPlaceholder } from '../../common/Placeholder/ComponentPlaceholder';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

export const PortfolioNFTHoldingsCard: React.FC = () => {
  const { walletNFTItems, isLoading } = usePortfolio();

  const items: WalletNFTItem[] = walletNFTItems.slice(0, 15);

  return (
    <div className="card flex flex-col">
      {items.length > 0 && !isLoading ? (
        <NFTItemsSlider items={items} />
      ) : isLoading ? (
        <div className="px-6">
          <div className="flex-grow">
            <SkeletonLoader height={200} />
          </div>
        </div>
      ) : (
        <div className="h-42">
          <ComponentPlaceholder />
        </div>
      )}
    </div>
  );
};
