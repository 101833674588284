import InfiniteScroll from 'react-infinite-scroll-component';
import { useLearn } from '@/hooks/useLearn';
import CourseDetailsSection from './CourseDetailsSection';
import MyShareSection from './MyShareSection';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

export default function ListAllCourses({ type }) {
  const { courses, isLoading, setPage, page, myShares, myStats } = useLearn();
  const handleNext = () => {
    !isLoading && setPage(page + 1);
  };

  return (
    <>
      {!isLoading &&
      (type === 'myShares'
        ? myShares && myShares.length > 0
        : courses && courses.length > 0) ? (
        <InfiniteScroll
          dataLength={
            type === 'myShares' ? myShares.length : courses?.length || 30
          }
          hasMore={true}
          next={handleNext}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4">
            {type === 'myShares'
              ? myShares.map(myShare => {
                  return (
                    <MyShareSection
                      key={myShare.id}
                      myShare={myShare}
                      stats={myStats}
                    />
                  );
                })
              : courses.map(course => {
                  return (
                    <CourseDetailsSection key={course.id} course={course} />
                  );
                })}
          </div>
        </InfiniteScroll>
      ) : isLoading ? (
        <div>
          <SkeletonLoader height={300} />
        </div>
      ) : (
        <div className="text-center pt-8">
          <img
            src="/assets/images/no-course-found.png"
            alt="no course found"
            className="mx-auto"
          />
          <p className="font-bold text-xl mt-8">
            {type === 'completed'
              ? `No courses completed yet. Let's get started!`
              : 'All courses complete, check again soon!'}
          </p>
        </div>
      )}
    </>
  );
}
