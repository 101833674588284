import moment from 'moment';

import { LearnCourse } from '../../learn/models/Courses';
import { InboxMessageTag } from './InboxMessageTag';
import { Deal } from '../../market/deals/models/Deals';
import { ArcadeGame } from '../../games/models/ArcadeGames';
import { PartnerGame } from '../../games/models/PartnerGames';
import { AssetDashExpedition } from '../../expeditions/models/AssetDashExpeditions';
import { PartnerExpedition } from '../../expeditions/models/PartnerExpeditions';
import { Auction } from '../../market/auctions/models/Auctions';

export class InboxMessagesResponse {
  inboxMessages?: InboxMessage[];

  constructor(inboxMessages?: InboxMessage[]) {
    this.inboxMessages = inboxMessages;
  }

  static fromJson(json: any): InboxMessagesResponse {
    const inboxMessages = json['inbox_messages'] ? json['inbox_messages'].map((v: any) => InboxMessage.fromJson(v)) : [];
    return new InboxMessagesResponse(inboxMessages);
  }

  toJson(): any {
    return {
      inbox_messages: this.inboxMessages?.map(v => v.toJson()),
    };
  }
}

export class InboxMessageDetailResponse {
  inboxMessageDetail?: InboxMessageDetail;

  constructor(inboxMessageDetail?: InboxMessageDetail) {
    this.inboxMessageDetail = inboxMessageDetail;
  }

  static fromJson(json: any): InboxMessageDetailResponse {
    const inboxMessageDetail = InboxMessageDetail.fromJson(json['inbox_message']);
    return new InboxMessageDetailResponse(inboxMessageDetail);
  }

  toJson(): any {
    return {
      inbox_message: this.inboxMessageDetail?.toJson(),
    };
  }
}

export class InboxMessage {
  id: string;
  created: Date;
  userId: string;
  tagId: string;
  seenStatus: string;
  readStatus: string;
  title: string;
  description: string;
  imageUrl: string;
  tag?: InboxMessageTag;

  constructor(id: string, created: Date, userId: string, tagId: string, seenStatus: string, readStatus: string, title: string, description: string, imageUrl: string, tag?: InboxMessageTag) {
    this.id = id;
    this.created = created;
    this.userId = userId;
    this.tagId = tagId;
    this.seenStatus = seenStatus;
    this.readStatus = readStatus;
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.tag = tag;
  }

  static fromJson(json: any): InboxMessage {
    return new InboxMessage(
      json['id'],
      new Date(json['created']),
      json['user_id'],
      json['tag_id'],
      json['seen_status'],
      json['read_status'],
      json['title'],
      json['description'],
      json['image_url'],
      json['tag'] ? InboxMessageTag.fromJson(json['tag']) : undefined,
    );
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created.toISOString(),
      user_id: this.userId,
      tag_id: this.tagId,
      seen_status: this.seenStatus,
      read_status: this.readStatus,
      title: this.title,
      description: this.description,
      image_url: this.imageUrl,
      tag: this.tag?.toJson(),
    };
  }

  copyWith({ id, created, userId, tagId, seenStatus, readStatus, title, description, imageUrl, tag }: { id?: string; created?: Date; userId?: string; tagId?: string; seenStatus?: string; readStatus?: string; title?: string; description?: string; imageUrl?: string; tag?: InboxMessageTag }): InboxMessage {
    return new InboxMessage(
      id ?? this.id,
      created ?? this.created,
      userId ?? this.userId,
      tagId ?? this.tagId,
      seenStatus ?? this.seenStatus,
      readStatus ?? this.readStatus,
      title ?? this.title,
      description ?? this.description,
      imageUrl ?? this.imageUrl,
      tag ?? this.tag,
    );
  }

  getCreatedDay(): string {
    return this.created.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  }

  isRead(): boolean {
    return this.readStatus === 'read';
  }
}

export class InboxMessageDetail {
  created: Date;
  userId: string;
  tagId: string;
  seenStatus: string;
  readStatus: string;
  title: string;
  description: string;
  imageUrl: string;
  instructions?: string[];
  tag?: InboxMessageTag;
  appScreen?: string;
  websiteUrl?: string;
  learnCourse?: LearnCourse;
  deal?: Deal;
  arcadeGame?: ArcadeGame;
  partnerGame?: PartnerGame;
  assetdashExpedition?: AssetDashExpedition;
  partnerExpedition?: PartnerExpedition;
  auction?: Auction;

  constructor({
    created,
    userId,
    tagId,
    seenStatus,
    readStatus,
    title,
    description,
    imageUrl,
    instructions,
    appScreen,
    websiteUrl,
    tag,
    learnCourse,
    deal,
    arcadeGame,
    partnerGame,
    assetdashExpedition,
    partnerExpedition,
    auction
  }: {
    created: Date;
    userId: string;
    tagId: string;
    seenStatus: string;
    readStatus: string;
    title: string;
    description: string;
    imageUrl: string;
    instructions?: string[];
    appScreen?: string;
    websiteUrl?: string;
    tag?: InboxMessageTag;
    learnCourse?: LearnCourse;
    deal?: Deal;
    arcadeGame?: ArcadeGame;
    partnerGame?: PartnerGame;
    assetdashExpedition?: AssetDashExpedition;
    partnerExpedition?: PartnerExpedition;
    auction?: Auction;
  }) {
    this.created = created;
    this.userId = userId;
    this.tagId = tagId;
    this.seenStatus = seenStatus;
    this.readStatus = readStatus;
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.instructions = instructions;
    this.appScreen = appScreen;
    this.websiteUrl = websiteUrl;
    this.tag = tag;
    this.learnCourse = learnCourse;
    this.deal = deal;
    this.arcadeGame = arcadeGame;
    this.partnerGame = partnerGame;
    this.assetdashExpedition = assetdashExpedition;
    this.partnerExpedition = partnerExpedition;
    this.auction = auction;
  }

  static fromJson(json: any): InboxMessageDetail {
    return new InboxMessageDetail({
      created: new Date(json['created']),
      userId: json['user_id'],
      tagId: json['tag_id'],
      seenStatus: json['seen_status'],
      readStatus: json['read_status'],
      title: json['title'],
      description: json['description'],
      imageUrl: json['image_url'],
      instructions: json['instructions'] as string[],
      appScreen: json['app_screen'],
      websiteUrl: json['website_url'],
      tag: json['tag'] ? InboxMessageTag.fromJson(json['tag']) : null,
      learnCourse: json['learn_course'] ? LearnCourse.fromJson(json['learn_course']) : null,
      deal: json['deal'] ? Deal.fromJson(json['deal']) : null,
      arcadeGame: json['arcade_game'] ? ArcadeGame.fromJson(json['arcade_game']) : null,
      partnerGame: json['partner_game'] ? PartnerGame.fromJson(json['partner_game']) : null,
      assetdashExpedition: json['assetdash_expedition'] ? AssetDashExpedition.fromJson(json['assetdash_expedition']) : null,
      partnerExpedition: json['partner_expedition'] ? PartnerExpedition.fromJson(json['partner_expedition']) : null,
      auction: json['auction'] ? Auction.fromJson(json['auction']) : null,
    });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    data['created'] = this.created.toISOString();
    data['user_id'] = this.userId;
    data['tag_id'] = this.tagId;
    data['seen_status'] = this.seenStatus;
    data['read_status'] = this.readStatus;
    data['title'] = this.title;
    data['description'] = this.description;
    data['image_url'] = this.imageUrl;
    if (this.instructions) data['instructions'] = this.instructions;
    if (this.appScreen) data['app_screen'] = this.appScreen;
    if (this.websiteUrl) data['website_url'] = this.websiteUrl;
    if (this.tag) data['tag'] = this.tag.toJson();
    if (this.learnCourse) data['learn_course'] = this.learnCourse.toJson();
    if (this.deal) data['deal'] = this.deal.toJson();
    if (this.arcadeGame) data['arcade_game'] = this.arcadeGame.toJson();
    if (this.partnerGame) data['partner_game'] = this.partnerGame.toJson();
    if (this.assetdashExpedition) data['assetdash_expedition'] = this.assetdashExpedition.toJson();
    if (this.partnerExpedition) data['partner_expedition'] = this.partnerExpedition.toJson();
    if (this.auction) data['auction'] = this.auction.toJson();
    return data;
  }

  getCreatedDay(): string {
    return moment(this.created).format('MMM DD, yyyy');
  }

  isRead(): boolean {
    return this.readStatus === 'read';
  }
}
