import { useNavigate } from 'react-router-dom';

import { PartnerGame } from '@/api/games';

interface PartnerGameCardProps {
  partnerGame: PartnerGame;
}

export const PartnerGameCard: React.FC<PartnerGameCardProps> = ({
  partnerGame,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/games/ecosystem/${partnerGame.id}`);
      }}
      className={`cursor-pointer h-96 w-56 p-4 flex flex-col justify-between rounded-card-box bg-white dark:bg-deals-card-top`}
    >
      <div className="md:col-span-2 lg:col-span-2 bg-white p-1 mb-2 dark:bg-deals-card rounded-13 h-max">
        <img
          src={partnerGame.image_url!}
          className="min-w-42 h-42 object-contain mx-auto"
          alt="logo"
        />
      </div>
      <div className="space-y-1">
        <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {partnerGame.name}
        </div>
        <div className="text-xs leading-17 text-ellipsis overflow-hidden text-gray-text h-16">
          {partnerGame.description}
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-xs leading-17 text-ellipsis overflow-hidden text-gray-text">
          Price
        </div>
        <div className="inline-flex w-full justify-center items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full">
          <div className="pl-2">
            <img
              className="rounded-full"
              src="/assets/icons/coin.png"
              alt="Coin"
              height={24}
              width={24}
            />
          </div>
          <span className="pr-2 text-sm"> {partnerGame.cost_coins} Coins</span>
        </div>
      </div>
    </div>
  );
};
