import { AssetLogo } from '../../common/Logo/AssetLogo';
import { ComponentPlaceholder } from '../../common/Placeholder/ComponentPlaceholder';

import { useNavigate } from 'react-router-dom';

import { Holding } from '@/features/portfolio/models/Holding';

// components
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

interface PortfolioBaseHoldingCardProps {
  title: string;
  value: string;
  holding: Holding | null;
  isLoading?: boolean;
}

export const PortfolioBaseHoldingCard: React.FC<
  PortfolioBaseHoldingCardProps
> = ({ title, value, holding, isLoading }) => {
  const { asset, id } = holding || {};
  const { logoUrl, tickerDisplay, assetTypeGroup } = asset || {};
  const navigate = useNavigate();

  return (
    <div className="w-full h-full card appear-up rounded-card-box p-6 font-semibold relative">
      {holding ? (
        <button
          className="w-full h-full text-left"
          disabled={holding.isManual}
          onClick={() => {
            navigate(`/assets/${id}`);
          }}
        >
          <div className="h-full flex flex-col justify-between items-between">
            <div className="flex flex-row items-center">
              <div className="w-20 h-20">
                <AssetLogo
                  type={assetTypeGroup}
                  logoUrl={logoUrl}
                  width={80}
                  height={80}
                />
              </div>
              <div className="text-base md:text-lg max-w-16 sm:max-w-24 md:max-w-32 lg:max-w-48 ml-auto leading-22 capitalize">
                {tickerDisplay}
              </div>
            </div>
            <div>
              <div className="text-xl leading-22 mt-19 text-semibold text-price-green">
                {value}
              </div>
              <div className="mt-14 text-lg text-black dark:text-white">
                {title}
              </div>
            </div>
          </div>
        </button>
      ) : isLoading ? (
        <SkeletonLoader height={90} />
      ) : (
        <ComponentPlaceholder />
      )}
    </div>
  );
};
