import React from 'react';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import moment from 'moment';
import { formatNumber } from '@/utils/number';
import { getShortenedAddress } from '@/utils/address';
import { LinkIcon } from '@heroicons/react/24/solid';

interface WithdrawalTabProps {
  withdrawals: Array<any>;
  isLoading: boolean;
}

const WithdrawalTab: React.FC<WithdrawalTabProps> = ({
  withdrawals,
  isLoading,
}) => {
  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : (
        <div className="mt-4 space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
          {withdrawals.length === 0 ? (
            <p className="text-center text-gray-400">No Withdrawals</p>
          ) : (
            withdrawals.map(reward => (
              <div
                key={reward.asset.asset_id}
                className="flex flex-row justify-between items-center"
              >
                <div className="flex flex-row items-center">
                  <img
                    src={reward.asset.logo_url}
                    className="w-9 h-9 rounded-full my-3"
                    alt={reward.asset.name}
                  ></img>
                  <div className="ml-2 flex flex-col">
                    <p className="font-bold">{reward.asset.name}</p>
                    <p className="mt-1 text-gray-text">
                      {moment(reward.created).format('MM/DD/YYYY')}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold mb-1">{`${formatNumber(
                    reward.amount,
                  )} Tokens`}</p>
                  <div className="flex flex-row ml-2 bg-[#939596] text-xs rounded-sm px-1 py-0.5">
                    <a
                      href={reward.solscan_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getShortenedAddress(reward.solscan_link)}
                    </a>
                    <LinkIcon className="ml-1 w-3 h-3" />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default WithdrawalTab;
