import { PortfolioChartDatapoints } from '../../assets/models/ChartResponse';

export class NFTHoldingChartSeries {
  all?: NFTHoldingChartItem[];
  day1Items?: NFTHoldingChartItem[];
  week1Items?: NFTHoldingChartItem[];
  month1Items?: NFTHoldingChartItem[];
  month3Items?: NFTHoldingChartItem[];
  year1Items?: NFTHoldingChartItem[];

  constructor(
    all?: NFTHoldingChartItem[],
    day1Items?: NFTHoldingChartItem[],
    week1Items?: NFTHoldingChartItem[],
    month1Items?: NFTHoldingChartItem[],
    month3Items?: NFTHoldingChartItem[],
    year1Items?: NFTHoldingChartItem[],
  ) {
    this.all = all;
    this.day1Items = day1Items;
    this.week1Items = week1Items;
    this.month1Items = month1Items;
    this.month3Items = month3Items;
    this.year1Items = year1Items;
  }

  static fromJSON(json: any): NFTHoldingChartSeries {
    const instance = new NFTHoldingChartSeries();

    if (json['all']) {
      instance.all = json['all'].map((v: any) =>
        NFTHoldingChartItem.fromJSON(v),
      );
    }

    if (json['day_1']) {
      instance.day1Items = json['day_1'].map((v: any) =>
        NFTHoldingChartItem.fromJSON(v),
      );
    }

    if (json['week_1']) {
      instance.week1Items = json['week_1'].map((v: any) =>
        NFTHoldingChartItem.fromJSON(v),
      );
    }

    if (json['month_1']) {
      instance.month1Items = json['month_1'].map((v: any) =>
        NFTHoldingChartItem.fromJSON(v),
      );
    }

    if (json['month_3']) {
      instance.month3Items = json['month_3'].map((v: any) =>
        NFTHoldingChartItem.fromJSON(v),
      );
    }

    if (json['year_1']) {
      instance.year1Items = json['year_1'].map((v: any) =>
        NFTHoldingChartItem.fromJSON(v),
      );
    }

    return instance;
  }

  toJSON(): any {
    const data: any = {};

    if (this.all) {
      data['all'] = this.all.map(v => v.toJSON());
    }

    if (this.day1Items) {
      data['day_1'] = this.day1Items.map(v => v.toJSON());
    }

    if (this.week1Items) {
      data['week_1'] = this.week1Items.map(v => v.toJSON());
    }

    if (this.month1Items) {
      data['month_1'] = this.month1Items.map(v => v.toJSON());
    }

    if (this.month3Items) {
      data['month_3'] = this.month3Items.map(v => v.toJSON());
    }

    if (this.year1Items) {
      data['year_1'] = this.year1Items.map(v => v.toJSON());
    }

    return data;
  }

  getAllPricePoints(): PortfolioChartDatapoints {
    return this._mapPricePoints(this.all);
  }

  get1DayPricePoints(): PortfolioChartDatapoints {
    return this._mapPricePoints(this.day1Items);
  }

  get1WeekPricePoints(): PortfolioChartDatapoints {
    return this._mapPricePoints(this.week1Items);
  }

  get1MonthPricePoints(): PortfolioChartDatapoints {
    return this._mapPricePoints(this.month1Items);
  }

  get3MonthPricePoints(): PortfolioChartDatapoints {
    return this._mapPricePoints(this.month3Items);
  }

  get1YearPricePoints(): PortfolioChartDatapoints {
    return this._mapPricePoints(this.year1Items);
  }

  _mapPricePoints(items?: NFTHoldingChartItem[]): PortfolioChartDatapoints {
    return new PortfolioChartDatapoints(
      items?.map(
        element =>
          new PortfolioChartDatapoint(
            new Date(element.timestampDatetime || element.day || ''),
            element.priceUsd || 0,
          ),
      ) || [],
    );
  }
}

export class NFTHoldingChartItem {
  timestampDatetime?: string;
  day?: string;
  nftItemId?: string;
  priceNative?: number;
  priceUsd?: number;
  floorPriceUsd?: number;
  floorPriceNative?: number;

  constructor(
    timestampDatetime?: string,
    day?: string,
    nftItemId?: string,
    priceNative?: number,
    priceUsd?: number,
    floorPriceUsd?: number,
    floorPriceNative?: number,
  ) {
    this.timestampDatetime = timestampDatetime;
    this.day = day;
    this.nftItemId = nftItemId;
    this.priceNative = priceNative;
    this.priceUsd = priceUsd;
    this.floorPriceUsd = floorPriceUsd;
    this.floorPriceNative = floorPriceNative;
  }

  static fromJSON(json: any): NFTHoldingChartItem {
    return new NFTHoldingChartItem(
      json['timestamp_datetime'],
      json['day'],
      json['nft_item_id'],
      json['price_native'],
      json['price_usd'],
      json['floor_price_usd'],
      json['floor_price_native'],
    );
  }

  toJSON(): any {
    return {
      timestamp_datetime: this.timestampDatetime,
      day: this.day,
      nft_item_id: this.nftItemId,
      price_native: this.priceNative,
      price_usd: this.priceUsd,
      floor_price_usd: this.floorPriceUsd,
      floor_price_native: this.floorPriceNative,
    };
  }

  copyWith(args: any): NFTHoldingChartItem {
    return new NFTHoldingChartItem(
      args.timestampDatetime || this.timestampDatetime,
      args.day || this.day,
      args.nftItemId || this.nftItemId,
      args.priceNative || this.priceNative,
      args.priceUsd || this.priceUsd,
      args.floorPriceUsd || this.floorPriceUsd,
      args.floorPriceNative || this.floorPriceNative,
    );
  }
}
