import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { Tooltip } from '../common/Tooltip/Tooltip';

import { useThemeState } from '@/recoil/theme';

export const GoldBoostModal = ({ open, setOpen, partners }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-member-center-bg';
  const divBackground = theme === 'light' ? 'bg-primary-gray' : 'bg-blue-dark';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-xl px-4 pt-5 pb-4 text-left shadow-xl transition-all  sm:w-full lg:max-w-3xl sm:my-8 sm:max-w-lg sm:p-6 ${background}`}
              >
                <div className="absolute top-0 right-0  pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rotate-45 hover:text-gray-500 focus:outline-none "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <PlusIcon className="text-price-red h-6 w-6" />
                  </button>
                </div>
                <div className="mt-6 text-center sm:mt-5">
                  <Dialog.Title>
                    <div>
                      <p className={`${textColor} text-3xl`}>
                        Membership Boost
                      </p>
                      <div className="space-y-4 mt-8 text-left">
                        {partners &&
                          partners.map(item => {
                            return (
                              <div
                                key={item.name}
                                className={`${divBackground} p-4 rounded-xl flex space-x-4 items-center justify-center`}
                              >
                                <div className="min-w-30">
                                  <img
                                    src={item.logo_url}
                                    className="h-12 w-auto"
                                    alt={item.name}
                                  />
                                </div>
                                <div
                                  className={`${textColor} flex space-x-2 items-center`}
                                >
                                  <p className="text-yellow-magic font-bold capitalize">
                                    {item.status} Boost
                                  </p>{' '}
                                  <p>from {item.name}</p>
                                  <Tooltip text={item.tooltip} position="left">
                                    <InformationCircleIcon
                                      className={`h-4 w-4 ${textColor}`}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className={`${divBackground} p-4 rounded-xl space-y-4`}
                        >
                          <p className={`${textColor} text-2xl font-bold`}>
                            How it works?
                          </p>
                          <div className={`${textColor} space-y-2`}>
                            <p className=" font-bold">About Boosts:</p>
                            <p className="text-gray-text text-sm">
                              Boosts are what determine your tier on AssetDash.
                              The higher your tier, the more perks you get. Your
                              tier is based on the "highest boost" you have. For
                              example, if you have Silver and a Gold Boost, you
                              would be a Gold Member, since Gold is a higher
                              tier than Silver.
                            </p>
                          </div>
                          <div className={`${textColor} space-y-2`}>
                            <p className=" font-bold">How Do I Get Boosts?</p>
                            <p className="text-gray-text text-sm">
                              There are two ways to get boosts, based on your{' '}
                              <span className="text-price-green font-bold">
                                Portfolio
                              </span>{' '}
                              and from select{' '}
                              <span className="text-price-green font-bold">
                                Partners
                              </span>{' '}
                              . Portfolio boosts are based on your investments
                              and assets. Partner boosts are when a select
                              AssetDash partner decides to reward you with a
                              boost.
                            </p>
                            <p className="text-gray-text text-sm">
                              You want to accumulate as many boosts as possible
                              as this will unlock more perks and opportunities
                              in future releases.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Title>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
