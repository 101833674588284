// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

// recoil
import { useThemeState } from '@/recoil/theme';

interface PrizeWalletInfoModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const PrizeWalletInfoModal: React.FC<PrizeWalletInfoModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const buttonBackground =
    theme === 'light' ? 'bg-primary-gray' : 'bg-blue-dark';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${background}`}
              >
                <div className={`${textColor}`}>
                  <div className=" text-center">
                    <Dialog.Title
                      as="h3"
                      className={`text-xl font-semibold leading-6 text-left`}
                    >
                      <h2 className="text-2xl font-semibold ">Prize Wallet</h2>
                    </Dialog.Title>

                    <div className="mt-12 space-y-2 text-sm text-left">
                      <p>
                        Designate this wallet to receive NFTs and whitelists
                        received on AssetDash.
                      </p>
                      <p>
                        If you would like to unset this wallet from being your
                        prize wallet, please select another verified wallet.
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-center space-x-4  mt-10 text-right">
                    <button
                      type="button"
                      className={`w-60 py-4 px-8 leading-17 font-semibold text-sm rounded-xl text-accent ${buttonBackground}`}
                      onClick={closeModal}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
