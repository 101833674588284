// PartnerRewardsPage.tsx
import React from 'react';
import { SuperSEO } from 'react-super-seo';
import { PartnerRewardsContainer } from '@/components/v2/rewards/PartnerRewardsContainer';

export const PartnerRewardsPage: React.FC = () => {
  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title="AssetDash | Partner Rewards" lang="en_IE" />
      <PartnerRewardsContainer />
    </div>
  );
};
