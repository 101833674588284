type JsonType = { [key: string]: any };

export class PortfolioChartData {
  value: number;
  priceGain: number;
  priceGainPercent: number;
  datapoints: PortfolioChartDatapoint[];

  constructor(
    value: number,
    priceGain: number,
    priceGainPercent: number,
    datapoints: PortfolioChartDatapoint[],
  ) {
    this.value = value;
    this.priceGain = priceGain;
    this.priceGainPercent = priceGainPercent;
    this.datapoints = datapoints;
  }

  static fromJSON(json: JsonType): PortfolioChartData {
    let datapoints: PortfolioChartDatapoint[] = [];
    if (json['graph']) {
      json['graph'].forEach((v: JsonType) => {
        datapoints.push(PortfolioChartDatapoint.fromJSON(v));
      });
    }

    datapoints = datapoints.filter(element => element.timestamp !== null);
    return new PortfolioChartData(
      json['value'] ?? 0.0,
      json['price_gain'] ?? 0.0,
      json['price_gain_percent'] ?? 0.0,
      datapoints,
    );
  }

  toJSON(): JsonType {
    return {
      value: this.value,
      price_gain: this.priceGain,
      price_gain_percent: this.priceGainPercent,
      graph: this.datapoints.map(v => v.toJSON()),
    };
  }
}

export class PortfolioChartDatapoint {
  timestamp?: Date;
  value: number;

  constructor(timestamp: Date | undefined, value: number) {
    this.timestamp = timestamp;
    this.value = value;
  }

  static fromJSON(json: JsonType): PortfolioChartDatapoint {
    const parsedDate = new Date(json['timestamp']);
    return new PortfolioChartDatapoint(
      isNaN(parsedDate.getTime()) ? undefined : parsedDate,
      json['value'] ?? json['price'] ?? 0,
    );
  }

  toJSON(): JsonType {
    return {
      day: this.timestamp,
      value: this.value,
    };
  }

  copyWith({
    timestamp,
    value,
  }: {
    timestamp?: Date;
    value?: number;
  }): PortfolioChartDatapoint {
    return new PortfolioChartDatapoint(
      timestamp ?? this.timestamp,
      value ?? this.value,
    );
  }
}
