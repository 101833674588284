// vendor libraries
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Auction } from '@/features/market/auctions/models/Auctions';
import { Countdown } from '@/components/v2/common/Countdown/Countdown';

interface AuctionCardProps {
  auction: Auction;
}

export const AuctionCard: React.FC<AuctionCardProps> = ({ auction }) => {
  const navigate = useNavigate();
  let tag;
  let subText;
  let countdown;
  let countdownStyles;
  if (auction.isNotLiveYet()) {
    tag = 'Not Live Yet';
    subText = `Auction opens in`;
    countdown = <Countdown targetTime={moment(auction.startOpenTime)} />;
    countdownStyles = 'bg-accent border-accent';
  } else if (auction.isOpenPhase()) {
    tag = 'Blind Phase';
    subText = `Blind phase ends in`;
    countdown = <Countdown targetTime={moment(auction.startLockedTime)} />;
    countdownStyles = 'bg-primary border-primary';
  } else if (auction.isLockedPhase()) {
    tag = 'Final Phase';
    subText = `Final phase ends in`;
    countdown = <Countdown targetTime={moment(auction.endTime)} />;
    countdownStyles = 'bg-primary border-primary';
  } else {
    countdown = <div>Completed</div>;
    countdownStyles =
      'bg-fade-text-light dark:bg-fade-text-dark border-fade-text-light dark:border-fade-text-dark';
  }

  return (
    <div
      onClick={() => {
        navigate(`/auctions/${auction.id}`);
      }}
      className={`cursor-pointer flex flex-col justify-between rounded-card-box card`}
    >
      <div className="sm:p-4 md:col-span-2 lg:col-span-2 p-1 mb-2  rounded-13 h-max">
        <div className="rounded-card-box relative">
          <img
            src={auction.imageUrl}
            className="min-w-42 h-42 object-contain mx-auto rounded-13"
            alt="auction"
          />
          {tag && (
            <div className="absolute top-0 right-0 mt-2 mr-2 h-5 flex items-center text-element-text-light dark:text-element-text-dark bg-opacity-50 dark:bg-opacity-50 justify-center p-2 text-xs border font-semibold bg-element-background-light dark:bg-element-background-dark border-fade-text-light dark:border-fade-text-dark rounded-full text-ellipsis whitespace-nowrap overflow-hiddens">
              {tag}
            </div>
          )}
        </div>
        <div className="space-y-1 p-4">
          <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {auction.title}
          </div>
          <div className="text-xs text-gray-text h-16 card-description-multiline-truncate">
            {auction.description}
          </div>
        </div>
        <div className="space-y-1 p-2">
          <div className="min-h-4 text-xs text-element-text-light dark:text-element-text-dark">
            {subText}
          </div>
          <div
            className={`flex justify-center w-full px-4 py-1 border-1 bg-opacity-10 dark:bg-opacity-10 rounded-2xl text-element-text-light dark:text-element-text-dark ${countdownStyles}`}
          >
            {countdown}
          </div>
        </div>
      </div>
    </div>
  );
};
