export class ManualAsset {
  id?: string;
  name?: string;
  tickerDisplay?: string;
  assetType?: string;
  assetTypeGroup?: string;
  logoUrl?: string;
  slug?: string;

  constructor(data?: {
    id?: string;
    name?: string;
    tickerDisplay?: string;
    assetType?: string;
    assetTypeGroup?: string;
    logoUrl?: string;
    slug?: string;
  }) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.tickerDisplay = data.tickerDisplay;
      this.assetType = data.assetType;
      this.assetTypeGroup = data.assetTypeGroup;
      this.logoUrl = data.logoUrl;
      this.slug = data.slug;
    }
  }

  static fromJSON(json: any): ManualAsset {
    return new ManualAsset({
      id: json['id'],
      name: json['name'],
      tickerDisplay: json['ticker_display'],
      assetType: json['asset_type'],
      assetTypeGroup: json['asset_type_group'],
      logoUrl: json['logo_url'],
      slug: json['slug'],
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      ticker_display: this.tickerDisplay,
      asset_type: this.assetType,
      asset_type_group: this.assetTypeGroup,
      logo_url: this.logoUrl,
      slug: this.slug,
    };
  }
}
