import { Asset } from './Asset';
import { ManualAsset } from './ManualAsset';
import { UserWallet } from './UserWallet';

export class WalletHolding {
  id?: string;
  userId?: string;
  day?: string;
  quantity?: number;
  price?: number;
  value?: number;
  costBasis?: number;
  portfolioAllocation?: number;
  asset?: Asset;
  manualAsset?: ManualAsset;
  walletAllocation?: number;
  userWallet?: UserWallet;
  percentOwnership?: number;
  companyValuation?: number;
  userWalletId?: string;
  assetId?: string;
  manualAssetId?: string;
  price1dAgo?: number;
  quantity1dAgo?: number;
  value1dAgo?: number;
  verified?: boolean;

  constructor({
    id,
    userId,
    day,
    quantity,
    price,
    value,
    costBasis,
    portfolioAllocation,
    asset,
    manualAsset,
    walletAllocation,
    userWallet,
    percentOwnership,
    companyValuation,
    userWalletId,
    assetId,
    manualAssetId,
    price1dAgo,
    quantity1dAgo,
    value1dAgo,
    verified,
  }: Partial<WalletHolding> = {}) {
    this.id = id;
    this.userId = userId;
    this.day = day;
    this.quantity = quantity;
    this.price = price;
    this.value = value;
    this.costBasis = costBasis;
    this.portfolioAllocation = portfolioAllocation;
    this.asset = asset;
    this.manualAsset = manualAsset;
    this.walletAllocation = walletAllocation;
    this.userWallet = userWallet;
    this.percentOwnership = percentOwnership;
    this.companyValuation = companyValuation;
    this.userWalletId = userWalletId;
    this.assetId = assetId;
    this.manualAssetId = manualAssetId;
    this.price1dAgo = price1dAgo;
    this.quantity1dAgo = quantity1dAgo;
    this.value1dAgo = value1dAgo;
    this.verified = verified;
  }

  static fromJSON(json: any): WalletHolding {
    return new WalletHolding({
      id: json['id'],
      userId: json['user_id'],
      day: json['day'],
      quantity: json['quantity'],
      price: json['price'],
      value: json['value'],
      costBasis: json['cost_basis'],
      portfolioAllocation: json['portfolio_allocation'],
      asset: json['asset'] ? Asset.fromJSON(json['asset']) : undefined,
      manualAsset: json['manual_asset']
        ? ManualAsset.fromJSON(json['manual_asset'])
        : undefined,
      walletAllocation: json['wallet_allocation'],
      userWallet: json['user_wallet']
        ? UserWallet.fromJSON(json['user_wallet'])
        : undefined,
      percentOwnership: json['percent_ownership'],
      companyValuation: json['company_valuation'],
      userWalletId: json['user_wallet_id'],
      assetId: json['asset_id'],
      manualAssetId: json['manual_asset_id'],
      price1dAgo: json['price_1d_ago'],
      quantity1dAgo: json['quantity_1d_ago'],
      value1dAgo: json['value_1d_ago'],
      verified: json['verified'],
    });
  }

  toJSON(): any {
    const data: any = {
      id: this.id,
      user_id: this.userId,
      day: this.day,
      quantity: this.quantity,
      price: this.price,
      value: this.value,
      cost_basis: this.costBasis,
      portfolio_allocation: this.portfolioAllocation,
      wallet_allocation: this.walletAllocation,
      percent_ownership: this.percentOwnership,
      company_valuation: this.companyValuation,
      user_wallet_id: this.userWalletId,
      asset_id: this.assetId,
      manual_asset_id: this.manualAssetId,
      price_1d_ago: this.price1dAgo,
      quantity_1d_ago: this.quantity1dAgo,
      value_1d_ago: this.value1dAgo,
      verified: this.verified ?? false,
    };

    if (this.asset) {
      data['asset'] = this.asset.toJSON();
    }
    if (this.manualAsset) {
      data['manual_asset'] = this.manualAsset.toJSON();
    }
    if (this.userWallet) {
      data['user_wallet'] = this.userWallet.toJSON();
    }

    return data;
  }

  copyWith({
    id,
    userId,
    day,
    quantity,
    price,
    value,
    costBasis,
    portfolioAllocation,
    asset,
    manualAsset,
    walletAllocation,
    userWallet,
    percentOwnership,
    companyValuation,
    userWalletId,
    assetId,
    manualAssetId,
    price1dAgo,
    quantity1dAgo,
    value1dAgo,
    verified,
  }: Partial<WalletHolding>): WalletHolding {
    return new WalletHolding({
      id: id ?? this.id,
      userId: userId ?? this.userId,
      day: day ?? this.day,
      quantity: quantity ?? this.quantity,
      price: price ?? this.price,
      value: value ?? this.value,
      costBasis: costBasis ?? this.costBasis,
      portfolioAllocation: portfolioAllocation ?? this.portfolioAllocation,
      asset: asset ?? this.asset,
      manualAsset: manualAsset ?? this.manualAsset,
      walletAllocation: walletAllocation ?? this.walletAllocation,
      userWallet: userWallet ?? this.userWallet,
      percentOwnership: percentOwnership ?? this.percentOwnership,
      companyValuation: companyValuation ?? this.companyValuation,
      userWalletId: userWalletId ?? this.userWalletId,
      assetId: assetId ?? this.assetId,
      manualAssetId: manualAssetId ?? this.manualAssetId,
      price1dAgo: price1dAgo ?? this.price1dAgo,
      quantity1dAgo: quantity1dAgo ?? this.quantity1dAgo,
      value1dAgo: value1dAgo ?? this.value1dAgo,
      verified: verified ?? this.verified,
    });
  }
}
