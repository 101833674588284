import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { AssetdashExpeditionTagContainer } from '../../components/expeditions/AssetdashExpeditionTagContainer';

export default function AssetdashExpeditionTagPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | AssetDash Expedition Tag`} lang="en_IE" />
      <div className="main-content mx-auto space-y-6">
        <AssetdashExpeditionTagContainer />
      </div>
    </>
  );
}
