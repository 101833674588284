import { AppColors, Color } from '../../../core/theming/colors';

export enum HoldingType {
  Equity,
  Derivative,
  Etf,
  MutualFund,
  Currency,
  Crypto,
  CryptoProtocol,
  Nft,
  Metals,
  PrivateEquity,
  Collectible,
  RealEstate,
  Other,
}

export class HoldingTypeInfo {
  static apiValue: { [key in HoldingType]: string } = {
    [HoldingType.Equity]: 'equity',
    [HoldingType.Derivative]: 'derivative',
    [HoldingType.Etf]: 'etf',
    [HoldingType.MutualFund]: 'mutual_fund',
    [HoldingType.Currency]: 'currency',
    [HoldingType.Crypto]: 'crypto',
    [HoldingType.CryptoProtocol]: 'crypto_protocol',
    [HoldingType.Nft]: 'nft',
    [HoldingType.Metals]: 'metals',
    [HoldingType.PrivateEquity]: 'private_equity',
    [HoldingType.Collectible]: 'collectibles',
    [HoldingType.RealEstate]: 'real_estate',
    [HoldingType.Other]: 'other',
  };

  static holdingTypeByApiValue(apiValue: string): HoldingType | null {
    for (const [key, value] of Object.entries(HoldingTypeInfo.apiValue)) {
      if (value === apiValue) {
        return key as unknown as HoldingType;
      }
    }
    return null;
  }

  static icon: { [key in HoldingType]: string } = {
    [HoldingType.Crypto]: 'crypto',
    [HoldingType.CryptoProtocol]: 'crypto',
    [HoldingType.Nft]: 'crypto',
    [HoldingType.Equity]: 'equity',
    [HoldingType.Derivative]: 'derivative',
    [HoldingType.Etf]: 'etf',
    [HoldingType.MutualFund]: 'mutual_fund',
    [HoldingType.Currency]: 'currency',
    [HoldingType.Metals]: 'metals',
    [HoldingType.PrivateEquity]: 'private_equity',
    [HoldingType.Collectible]: 'сollectibles',
    [HoldingType.RealEstate]: 'real_estate',
    [HoldingType.Other]: 'other',
  };

  static title: { [key in HoldingType]: string } = {
    [HoldingType.Equity]: 'Equity',
    [HoldingType.Derivative]: 'Derivative',
    [HoldingType.Etf]: 'ETF',
    [HoldingType.MutualFund]: 'Mutual Fund',
    [HoldingType.Currency]: 'Currency',
    [HoldingType.Crypto]: 'Crypto',
    [HoldingType.CryptoProtocol]: 'Crypto Protocol',
    [HoldingType.Nft]: 'NFT',
    [HoldingType.Metals]: 'Metals',
    [HoldingType.PrivateEquity]: 'Private Equity',
    [HoldingType.Collectible]: 'Collectibles',
    [HoldingType.RealEstate]: 'Real Estate',
    [HoldingType.Other]: 'Other',
  };

  static color: { [key in HoldingType]: Color } = {
    [HoldingType.Equity]: AppColors.portfolioEquity(),
    [HoldingType.Derivative]: AppColors.portfolioDerivative(),
    [HoldingType.Etf]: AppColors.portfolioEtf(),
    [HoldingType.MutualFund]: AppColors.portfolioMutualFund(),
    [HoldingType.Currency]: AppColors.portfolioCurrency(),
    [HoldingType.Nft]: AppColors.portfolioNft(),
    [HoldingType.Crypto]: AppColors.portfolioCryptoToken(),
    [HoldingType.CryptoProtocol]: AppColors.portfolioCryptoProtocol(),
    [HoldingType.Metals]: AppColors.portfolioMetals(),
    [HoldingType.PrivateEquity]: AppColors.portfolioPrivateEquity(),
    [HoldingType.Collectible]: AppColors.portfolioCollectible(),
    [HoldingType.RealEstate]: AppColors.portfolioRealEstate(),
    [HoldingType.Other]: AppColors.portfolioOther(),
  };
}
