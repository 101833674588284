type Json = { [key: string]: any };

export class InboxCenterResponse {
  inboxCenter?: InboxCenter;

  constructor(inboxCenter?: InboxCenter) {
    this.inboxCenter = inboxCenter;
  }

  static fromJson(json: Json): InboxCenterResponse {
    const inboxCenter = json['inbox_center'] ? InboxCenter.fromJson(json['inbox_center']) : undefined;
    return new InboxCenterResponse(inboxCenter);
  }

  toJson(): Json {
    const data: Json = {};
    if (this.inboxCenter) {
      data['inbox_center'] = this.inboxCenter.toJson();
    }
    return data;
  }
}

export class InboxCenter {
  unseenMessages?: number;
  unreadMessages?: number;

  constructor(unseenMessages?: number, unreadMessages?: number) {
    this.unseenMessages = unseenMessages;
    this.unreadMessages = unreadMessages;
  }

  static fromJson(json: Json): InboxCenter {
    return new InboxCenter(
      json['unseen_messages'] ?? 0,
      json['unread_messages'] ?? 0
    );
  }

  toJson(): Json {
    return {
      'unseen_messages': this.unseenMessages,
      'unread_messages': this.unreadMessages,
    };
  }
}
