export function AssetDashLogo({
  alternateColors = false,
  forceDark = false,
  size = 'default',
  forceWhite = false,
}) {
  let textSize;
  if (size === 'default') {
    textSize = 'text-xl';
  } else if (size === 'large') {
    textSize = 'text-3xl';
  } else if (size === 'small') {
    textSize = 'text-sm';
  } else if (size === 'medium') {
    textSize = 'text-md';
  }

  let textClass = 'dark:text-white';
  if (forceDark) {
    textClass = 'text-dark';
  } else if (forceWhite) {
    textClass = 'text-white';
  }

  return (
    <span className={`${textSize} leading-logo  cursor-pointer ${textClass} `}>
      Asset
      <span
        className={`${!alternateColors ? 'text-price-green' : ''} font-bold`}
      >
        Dash
      </span>
    </span>
  );
}
