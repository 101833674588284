// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import { useNavigate } from 'react-router-dom';

// components
import { AssetSeriesCard } from '@/components/v2/assets/AssetSeriesCard';

// models
import { Asset } from '@/features/portfolio/models/Asset';

// api
import { getAssetDetails } from '@/api/assets';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';
import { AssetStatsContent } from '@/components/v2/assets/tabs/AssetStatsTab';
import { Holding } from '@/features/portfolio/models/Holding';
import { AssetHoldingsContent } from '@/components/v2/assets/tabs/AssetHoldingsTab';
import { AssetAnalyticsCard } from '@/components/v2/assets/AssetAnalyticsCard';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';
import { AssetNFTActivityCard } from '@/components/v2/assets/AssetNFTActivityCard';
import { Toggle } from '@/components/v2/common/Toggle';
import { hideUserAsset } from '@/api/hidden_assets';
import {
  AssetDetails,
  parseAssetDetails,
} from '@/features/portfolio/models/AssetDetail';
import AssetActionButtonsCard from './AssetActionButtonsCard';

export default function AssetDetailsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const navigate = useNavigate();
  const {
    load,
    hiddenAssetIds,
    assetIdToHoldingMap,
    assetIdToWalletHoldings,
    isLoading,
  } = usePortfolio();

  const { assetId } = useParams();

  const [asset, setAsset] = useState<Asset>();
  const [assetDetail, setAssetDetail] = useState<AssetDetails | null>(null);

  const [holding, setHolding] = useState<Holding>();
  const [walletHoldings, setWalletHoldings] = useState<WalletHolding[]>();

  const [currentChartTab, setCurrentChatTab] = useState<string>('Price');

  useEffect(() => {
    if (assetId) {
      if ([...hiddenAssetIds].includes(assetId)) {
        navigate('/portfolio');
      }
      fetchAssetDetails(assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    if (!isLoading && asset) {
      if (assetIdToHoldingMap.has(asset.id!)) {
        setHolding(assetIdToHoldingMap.get(asset.id!));
      }
      if (assetIdToWalletHoldings.has(asset.id!)) {
        setWalletHoldings(assetIdToWalletHoldings.get(asset.id!));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, isLoading]);

  const fetchAssetDetails = async (assetId: string) => {
    try {
      const response = await getAssetDetails(assetId);

      const assetDetails = Asset.fromJSON(response.data.asset);
      setAsset(assetDetails);

      const parsedAssetDetails = parseAssetDetails(
        response.data,
        assetDetails.assetType!,
      );

      setAssetDetail(parsedAssetDetails);
    } catch (error) {}
  };

  const onUpdateChartTab = (key: string) => {
    setCurrentChatTab(key);
  };

  const [isChecked, setIsChecked] = useState(true);

  const hideAsset = async () => {
    setIsChecked(false);
    try {
      await hideUserAsset(asset?.id!);
      await load({ checkCache: false });
    } catch (error) {
    } finally {
      navigate('/portfolio');
    }
  };

  return (
    <div>
      <SuperSEO title={`AssetDash | Asset Details`} lang="en_IE" />
      <div className="main-content grid grid-cols-1 gap-y-6 md:gap-y-0 md:gap-6">
        <div className="col-span-2">
          <div className="space-y-6">
            <div className="flex flex-col space-x-0 space-y-3 md:flex-row md:space-x-8 md:space-y-0">
              <div className="w-full h-full md:w-1/2 xl:w-3/5 flex flex-col">
                <span className="card-title">{currentChartTab}</span>
                <AssetSeriesCard
                  asset={asset!}
                  onUpdateChartTab={onUpdateChartTab}
                />
              </div>
              <div className="w-full h-full md:w-1/2 xl:w-2/5 flex flex-col">
                <span className="card-title">{asset?.name} Market Stats</span>
                <AssetStatsContent assetDetail={assetDetail!} />
                {asset && <AssetActionButtonsCard asset={asset!} />}
              </div>
            </div>

            <div className="flex flex-col space-x-0 space-y-3 md:flex-row md:space-x-8 md:space-y-0">
              <div className="w-full md:w-1/2 xl:w-3/5 flex flex-col">
                <span className="card-title">Portfolio Allocation</span>
                <AssetHoldingsContent
                  holding={holding!}
                  walletHoldings={walletHoldings!}
                />
              </div>
              {asset?.assetType === 'crypto' && (
                <div className="w-full md:w-1/2 xl:w-2/5 flex flex-col">
                  <span className="card-title">Analytics</span>
                  <AssetAnalyticsCard assetDetail={assetDetail!} />
                </div>
              )}
              {asset?.assetType === 'nft' && (
                <div className="w-full md:w-1/2 xl:w-2/5 flex flex-col">
                  <span className="card-title">Market Activity</span>
                  <AssetNFTActivityCard assetId={assetId} />
                </div>
              )}
            </div>
          </div>
        </div>
        {asset && (
          <div className="card flex rounded-xl justify-between items-center p-4 my-4">
            <div className="text-primary">Display in portfolio</div>
            <Toggle
              id={asset.id}
              handleToggle={hideAsset}
              isChecked={isChecked}
            />
          </div>
        )}
      </div>
    </div>
  );
}
