import { Asset } from './Asset';
import { HoldingType, HoldingTypeInfo } from '../holdings/enums/HoldingType';

export class Holding {
  id: string;
  allocation: number;
  roi: number;
  valueChange: number;
  quantity: number;
  quantityChange?: number;
  price: number;
  amount: number;
  companyValuation?: number;
  percentOwnership?: number;
  priceGain?: number;
  priceGainPercentage?: number;
  asset: Asset;

  constructor(params: {
    id: string;
    allocation: number;
    roi: number;
    valueChange: number;
    quantity: number;
    price: number;
    asset: Asset;
    amount?: number;
    quantityChange?: number;
    companyValuation?: number;
    percentOwnership?: number;
    priceGain?: number;
    priceGainPercentage?: number;
  }) {
    this.id = params.id;
    this.allocation = params.allocation;
    this.roi = params.roi;
    this.valueChange = params.valueChange;
    this.quantity = params.quantity;
    this.price = params.price;
    this.asset = params.asset;
    this.amount = params.amount || 0.0;
    this.quantityChange = params.quantityChange;
    this.companyValuation = params.companyValuation;
    this.percentOwnership = params.percentOwnership;
    this.priceGain = params.priceGain;
    this.priceGainPercentage = params.priceGainPercentage;
  }

  static fromJSON(json: any): Holding {
    return new Holding({
      id: json['id'],
      allocation: json['percent'] || 0.0,
      roi: json['percent_return'] || 0.0,
      valueChange: json['value_change'] || 0.0,
      quantity: json['quantity'] || 0.0,
      quantityChange: json['quantity_change'] || 0.0,
      price: json['price'] || 0.0,
      amount: json['value'] || 0.0,
      companyValuation:
        json['company_valuation'] ||
        (json['user_manual_holding'] &&
          json['user_manual_holding']['company_valuation']) ||
        undefined,
      percentOwnership:
        json['percent_ownership'] ||
        (json['user_manual_holding'] &&
          json['user_manual_holding']['percent_ownership']) ||
        undefined,
      asset: Asset.fromJSON(json['asset'] || json['manual_asset']),
      priceGain: json['price_gain'] || undefined,
      priceGainPercentage: json['price_gain_percentage'] || undefined,
    });
  }

  get isManual(): boolean {
    return [
      HoldingTypeInfo.apiValue[HoldingType.Collectible],
      HoldingTypeInfo.apiValue[HoldingType.Other],
      HoldingTypeInfo.apiValue[HoldingType.RealEstate],
      HoldingTypeInfo.apiValue[HoldingType.PrivateEquity],
    ].includes(this.asset.assetType!);
  }
}
