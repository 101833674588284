export class CryptoDetail {
  id?: string;
  buy24H?: number;
  buy24HChangePercent?: number;
  sell24H?: number;
  sell24HChangePercent?: number;
  percentChange1H?: number;
  percentChange2H?: number;
  percentChange4H?: number;
  percentChange6H?: number;
  percentChange8H?: number;
  percentChange12H?: number;
  percentChange24H?: number;
  percentChange30M?: number;
  priceUSD?: number;
  supply?: number;
  trade24H?: number;
  trade24HChangePercent?: number;
  marketCap?: number;
  volume24HUSD?: number;
  volumeBuy24HUSD?: number;
  volumeSell24HUSD?: number;

  constructor(data?: Partial<CryptoDetail>) {
    Object.assign(this, data);
  }

  static fromJSON(json: any): CryptoDetail {
    return new CryptoDetail({
      id: json['id'],
      buy24H: json['buy_24h'],
      buy24HChangePercent: json['buy_24h_change_percent'],
      sell24H: json['sell_24h'],
      sell24HChangePercent: json['sell_24h_change_percent'],
      percentChange1H: json['percent_change_1h'],
      percentChange2H: json['percent_change_2h'],
      percentChange4H: json['percent_change_4h'],
      percentChange6H: json['percent_change_6h'],
      percentChange8H: json['percent_change_8h'],
      percentChange12H: json['percent_change_12h'],
      percentChange24H: json['percent_change_24h'],
      percentChange30M: json['percent_change_30m'],
      priceUSD: json['price_usd'],
      supply: json['supply'],
      trade24H: json['trade_24h'],
      trade24HChangePercent: json['trade_24h_change_percent'],
      marketCap: json['market_cap'],
      volume24HUSD: json['volume_24h_usd'],
      volumeBuy24HUSD: json['volume_buy_24h_usd'],
      volumeSell24HUSD: json['volume_sell_24h_usd'],
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      buy_24h: this.buy24H,
      buy_24h_change_percent: this.buy24HChangePercent,
      sell_24h: this.sell24H,
      sell_24h_change_percent: this.sell24HChangePercent,
      percent_change_1h: this.percentChange1H,
      percent_change_2h: this.percentChange2H,
      percent_change_4h: this.percentChange4H,
      percent_change_6h: this.percentChange6H,
      percent_change_8h: this.percentChange8H,
      percent_change_12h: this.percentChange12H,
      percent_change_24h: this.percentChange24H,
      percent_change_30m: this.percentChange30M,
      price_usd: this.priceUSD,
      supply: this.supply,
      trade_24h: this.trade24H,
      trade_24h_change_percent: this.trade24HChangePercent,
      market_cap: this.marketCap,
      volume_24h_usd: this.volume24HUSD,
      volume_buy_24h_usd: this.volumeBuy24HUSD,
      volume_sell_24h_usd: this.volumeSell24HUSD,
    };
  }

  copyWith(data: Partial<CryptoDetail>): CryptoDetail {
    return new CryptoDetail({ ...this, ...data });
  }
}
