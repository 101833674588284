import { axiosAssetDashApi } from './api';

export const getUserTokenRewards = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/swap-center`);
};

export interface SwapCenterJson {
  total_swaps?: number | null;
  total_swap_volume?: number | null;
  swap_rewards?: any[] | null;
  swap_reward_withdrawals?: any[] | null;
  leaderboard_rank?: number | null;
  leaderboard_legendary?: boolean | null;
  leaderboard_top?: boolean | null;
  leaderboard_active?: boolean | null;
  public_user_id?: string | null;
}

export interface SwapCenterResponse {
  swap_center: SwapCenterJson;
}
