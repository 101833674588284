import moment from 'moment';

import { Giveaway } from '@/api/games';

import { Countdown } from '@/components/v2/common/Countdown/Countdown';

import { useGames } from '@/hooks/useGames';

interface GiveawayCountdownCardProps {
  giveaway: Giveaway;
}

export const GiveawayCountdownCard: React.FC<GiveawayCountdownCardProps> = ({
  giveaway,
}) => {
  const { isGiveawayCompleted } = useGames();

  return (
    <div className="space-y-4 bg-orange-gradient dark:bg-dark-orange-gradient mt-8 py-4 px-8 rounded-xl relative text-black dark:text-gray-300">
      <img
        src="/assets/icons/giveaway_clock.png"
        alt="instructions"
        className="h-16 w-16 p-2 rounded-full mx-auto absolute -top-8 left-1/2 transform -translate-x-1/2 bg-deals-card-top"
      />
      <p className="text-center text-2xl font-semibold">End</p>
      <div className="text-4xl font-bold pb-8">
        {isGiveawayCompleted(giveaway) ? (
          <div>Completed</div>
        ) : (
          <Countdown targetTime={moment(giveaway.end_time + 'Z')} />
        )}
      </div>
    </div>
  );
};
