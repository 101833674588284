export class PartnerReward {
  id: string;
  created: Date;
  updated: Date;
  identifier?: string;
  organizationId?: string;
  status?: string;
  type?: string;
  displayPriority?: string;
  name?: string;
  imageUrl?: string;
  description?: string;
  rewardUrl?: string;
  rewardUnit?: string;
  rewardUnits?: number;
  rewardUnitLogoUrl?: string;
  learnCourseId?: string;
  partnerExpeditionId?: string;

  constructor(
    id: string,
    created: Date,
    updated: Date,
    identifier?: string,
    organizationId?: string,
    status?: string,
    type?: string,
    displayPriority?: string,
    name?: string,
    imageUrl?: string,
    description?: string,
    rewardUrl?: string,
    rewardUnit?: string,
    rewardUnits?: number,
    rewardUnitLogoUrl?: string,
    learnCourseId?: string,
    partnerExpeditionId?: string,
  ) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.identifier = identifier;
    this.organizationId = organizationId;
    this.status = status;
    this.type = type;
    this.displayPriority = displayPriority;
    this.name = name;
    this.imageUrl = imageUrl;
    this.description = description;
    this.rewardUrl = rewardUrl;
    this.rewardUnit = rewardUnit;
    this.rewardUnits = rewardUnits;
    this.rewardUnitLogoUrl = rewardUnitLogoUrl;
    this.learnCourseId = learnCourseId;
    this.partnerExpeditionId = partnerExpeditionId;
  }

  static fromJson(json: any): PartnerReward {
    return new PartnerReward(
      json['id'],
      new Date(json['created']),
      new Date(json['updated']),
      json['identifier'],
      json['organization_id'],
      json['status'],
      json['type'],
      json['display_priority'],
      json['name'],
      json['image_url'],
      json['description'],
      json['reward_url'],
      json['reward_unit'],
      json['reward_units'],
      json['reward_unit_logo_url'],
      json['learn_course_id'],
      json['partner_expedition_id'],
    );
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created.toISOString(),
      updated: this.updated.toISOString(),
      identifier: this.identifier,
      organization_id: this.organizationId,
      status: this.status,
      type: this.type,
      display_priority: this.displayPriority,
      name: this.name,
      image_url: this.imageUrl,
      description: this.description,
      reward_url: this.rewardUrl,
      reward_unit: this.rewardUnit,
      reward_units: this.rewardUnits,
      reward_unit_logo_url: this.rewardUnitLogoUrl,
      learn_course_id: this.learnCourseId,
      partner_expedition_id: this.partnerExpeditionId,
    };
  }
}

export class PartnerRewardsResponse {
  partnerRewards?: PartnerReward[];

  constructor(partnerRewards?: PartnerReward[]) {
    this.partnerRewards = partnerRewards;
  }

  static fromJson(json: any): PartnerRewardsResponse {
    const partnerRewards = json['partner_reward_users']
      ? json['partner_reward_users'].map((v: any) => ({
          ...PartnerReward.fromJson(v?.partner_reward),
          rewardUnits: v.reward_units,
        }))
      : undefined;
    return new PartnerRewardsResponse(partnerRewards);
  }

  toJson(): any {
    return {
      partner_rewards: this.partnerRewards?.map(pr => pr.toJson()),
    };
  }
}
