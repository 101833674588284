import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// interfaces
import { DashboardBanner } from '@/features/core/models/Banner';

interface BannerCarouselProps {
  banners: DashboardBanner[];
}

export const BannerCarousel: React.FC<BannerCarouselProps> = ({ banners }) => {
  const navigate = useNavigate();
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -380, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 380, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative">
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll whitespace-nowrap"
      >
        {banners.map((banner, index) => (
          <div
            key={index}
            className={`relative mx-2 ${index === 0 ? 'ml-0' : ''} ${
              index === banners.length - 1 ? 'mr-0' : ''
            }`}
          >
            <div
              className="h-36 w-[363px] rounded-lg overflow-hidden cursor-pointer flex justify-center items-center"
              onClick={() => {
                if (banner.page === 'url') {
                  window.open(banner.pageId || '');
                } else if (banner.page === 'giveaway') {
                  navigate('/giveaways');
                } else if (banner.page === 'member_center') {
                  navigate('/member');
                } else if (
                  banner.page === 'giveaway' &&
                  banner.pageId === 'fortune_cookie'
                ) {
                  navigate('/games');
                } else if (banner.page === 'giveaway') {
                  navigate('/games');
                } else if (banner.page === 'wallet') {
                  navigate('/wallets');
                } else if (banner.page === 'deal' && banner.pageId != null) {
                  navigate('/deals/' + banner.pageId);
                } else if (banner.page === 'deal') {
                  navigate('/market');
                } else if (banner.page === 'invite_friend') {
                  navigate('/invite');
                } else if (banner.page === 'learn' && banner.pageId != null) {
                  navigate('/learn/' + banner.pageId);
                } else if (
                  banner.page === 'partner_game' &&
                  banner.pageId != null
                ) {
                  navigate('/games/ecosystem/' + banner.pageId);
                } else if (banner.page === 'learn') {
                  navigate('/learn');
                }
              }}
            >
              <img
                src={banner.imageUrl}
                alt={banner.pageId || banner.page}
                className="h-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
      <>
        <button
          onClick={scrollLeft}
          className="absolute left-[-0px] top-1/2 transform -translate-y-1/2 p-2 w-10 h-10 bg-gray-700 text-accent rounded-full z-10"
        >
          ◀
        </button>
        <button
          onClick={scrollRight}
          className="absolute right-[-0px] top-1/2 transform -translate-y-1/2 p-2 w-10 h-10 bg-gray-700 text-accent rounded-full z-10"
        >
          ▶
        </button>
      </>
    </div>
  );
};
