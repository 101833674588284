import { CoinCenterJson } from '../../../api/coin';

class CoinCenterResponse {
  coinCenter?: CoinCenter;

  constructor(coinCenter?: CoinCenter) {
    this.coinCenter = coinCenter;
  }

  static fromJson(json: { coin_center: CoinCenterJson }): CoinCenterResponse {
    return new CoinCenterResponse(
      json.coin_center ? CoinCenter.fromJson(json.coin_center) : undefined,
    );
  }

  toJson(): { coin_center?: CoinCenterJson } {
    if (this.coinCenter) {
      return { coin_center: this.coinCenter.toJson() };
    }
    return {};
  }
}

class CoinCenter {
  totalCoins?: number;
  liquidCoins?: number;
  lockedCoins?: number;
  bidCoins?: number;
  spentCoins?: number;

  constructor(
    totalCoins?: number,
    liquidCoins?: number,
    lockedCoins?: number,
    bidCoins?: number,
    spentCoins?: number,
  ) {
    this.totalCoins = totalCoins;
    this.liquidCoins = liquidCoins;
    this.lockedCoins = lockedCoins;
    this.bidCoins = bidCoins;
    this.spentCoins = spentCoins;
  }

  static fromJson(json: CoinCenterJson): CoinCenter {
    return new CoinCenter(
      json.total_coins ?? 0,
      json.liquid_coins ?? 0,
      json.locked_coins ?? 0,
      json.bid_coins ?? 0,
      json.spent_coins ?? 0,
    );
  }

  toJson(): CoinCenterJson {
    return {
      total_coins: this.totalCoins,
      liquid_coins: this.liquidCoins,
      locked_coins: this.lockedCoins,
      bid_coins: this.bidCoins,
      spent_coins: this.spentCoins,
    };
  }
}

export { CoinCenter, CoinCenterResponse };
