import { Asset } from './Asset';
import { WalletNFTItem } from './WalletNFTItem';

export class NFTCollection {
  id?: string;
  name?: string;
  chain?: string;
  logoUrl?: string;
  asset?: Asset;
  walletNFTItems: WalletNFTItem[] = [];
  totalValue?: number = 0.0;

  constructor(data?: {
    id?: string;
    name?: string;
    chain?: string;
    logoUrl?: string;
    asset?: Asset;
    walletNFTItems?: WalletNFTItem[];
    totalValue?: number;
  }) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.chain = data.chain;
      this.logoUrl = data.logoUrl;
      this.asset = data.asset;
      this.walletNFTItems = data.walletNFTItems || [];
      this.totalValue = data.totalValue;
    }
  }

  static fromJSON(json: any): NFTCollection {
    return new NFTCollection({
      id: json['id'],
      name: json['name'],
      chain: json['nft_chain'],
      logoUrl: json['logo_url'],
      asset: json['asset'] ? Asset.fromJSON(json['asset']) : undefined,
    });
  }

  toJSON(): any {
    const data: any = {
      id: this.id,
      name: this.name,
      nft_chain: this.chain,
      logo_url: this.logoUrl,
    };
    if (this.asset) {
      data['asset'] = this.asset.toJSON();
    }
    return data;
  }

  copyWith(data: {
    id?: string;
    name?: string;
    chain?: string;
    logoUrl?: string;
    asset?: Asset;
    walletNFTItems?: WalletNFTItem[];
    totalValue?: number;
  }): NFTCollection {
    return new NFTCollection({
      id: data.id || this.id,
      name: data.name || this.name,
      chain: data.chain || this.chain,
      logoUrl: data.logoUrl || this.logoUrl,
      asset: data.asset || this.asset,
      walletNFTItems: data.walletNFTItems || this.walletNFTItems,
      totalValue: data.totalValue || this.totalValue,
    });
  }
}
