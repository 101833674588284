import { usePortfolio } from '@/hooks/usePortfolio';

import { SeriesCardContainer } from '../common/SeriesCard/SeriesCardContainer';

export const PortfolioSeriesCard = () => {
  const { portfolioHoldingsChartDataMap, isLoading } = usePortfolio();

  return (
    <div className="w-full flex flex-col">
      <label className="font-bold mb-2 text-2xl px-4">Portfolio</label>
      <SeriesCardContainer
        title=""
        holdingChartDataMap={portfolioHoldingsChartDataMap.get('all')}
        isLoading={isLoading}
      />
    </div>
  );
};
