import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import { ImageWrapper } from '../common/Image/ImageWrapper';

import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';
interface NFTItemHeaderProps {
  walletNFTItem?: WalletNFTItem;
}
export const NFTItemHeader: React.FC<NFTItemHeaderProps> = ({
  walletNFTItem,
}) => {
  // const navigate = useNavigate();
  return (
    <>
      {walletNFTItem ? (
        <div className="w-full h-full flex flex-row space-x-6">
          <ImageWrapper
            className="h-full"
            verified={walletNFTItem?.verified!}
            url={walletNFTItem?.nftItem?.getThumbnailUrl()!}
            alt="nft"
          />
          {/* <div className="space-y-1">
            <div
              className="text-xl font-semibold text-primary-orange cursor-pointer"
              onClick={() => navigate(`/assets/${walletNFTItem.assetId}`)}
            >
              {walletNFTItem?.nftItem?.collectionName}
            </div>
            {walletNFTItem?.nftItem?.rarityRank && (
              <div className="text-price-green font-bold text-sm leading-17 bg-primary-gray dark:bg-tabs-bg w-max px-4p py-3p rounded-2xl mt-1">
                Rank # {walletNFTItem?.nftItem?.rarityRank}
              </div>
            )}
          </div> */}
        </div>
      ) : (
        <SkeletonLoader height={200} />
      )}
    </>
  );
};
