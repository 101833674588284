import { Auction, AuctionJson } from './Auctions';

interface AuctionResponseJson {
  auction?: AuctionJson;
}

export class AuctionResponse {
  auction?: Auction;

  constructor(auction?: Auction) {
    this.auction = auction;
  }

  static fromJson(json: AuctionResponseJson): AuctionResponse {
    return new AuctionResponse(
      json.auction ? Auction.fromJson(json.auction) : undefined
    );
  }

  toJson(): AuctionResponseJson {
    return {
      auction: this.auction?.toJson(),
    };
  }
}

export interface MyAuctionBidLeaderboardJson {
  id: string;
  status: string;
  user_id: string;
  auction_id: string;
  num_coins: number;
  auction?: AuctionJson;
}

export class MyAuctionBidLeaderboard {
  id: string;
  status: string;
  userId: string;
  auctionId: string;
  numCoins: number;
  auction?: Auction;

  constructor(
    id: string,
    status: string,
    userId: string,
    auctionId: string,
    numCoins: number,
    auction?: Auction
  ) {
    this.id = id;
    this.status = status;
    this.userId = userId;
    this.auctionId = auctionId;
    this.numCoins = numCoins;
    this.auction = auction;
  }

  static fromJson(json: MyAuctionBidLeaderboardJson): MyAuctionBidLeaderboard {
    return new MyAuctionBidLeaderboard(
      json.id,
      json.status,
      json.user_id,
      json.auction_id,
      json.num_coins,
      json.auction ? Auction.fromJson(json.auction) : undefined
    );
  }

  toJson(): MyAuctionBidLeaderboardJson {
    return {
      id: this.id,
      status: this.status,
      user_id: this.userId,
      auction_id: this.auctionId,
      num_coins: this.numCoins,
      auction: this.auction?.toJson(),
    };
  }

  isLocked(): boolean {
    return this.status === 'locked';
  }
}

export interface MyAuctionUserPrizeJson {
  id: string;
  user_id: string;
  auction_id: string;
  auction_prize_id: string;
  prize_title?: string;
  prize_image_url?: string;
  prize_link?: string;
  delivered_wallet_address?: string;
  delivered: boolean;
}

export class MyAuctionUserPrize {
  id: string;
  userId: string;
  auctionId: string;
  auctionPrizeId: string;
  prizeTitle?: string;
  prizeImageUrl?: string;
  prizeLink?: string;
  deliveredWalletAddress?: string;
  delivered: boolean;

  constructor(
    id: string,
    userId: string,
    auctionId: string,
    auctionPrizeId: string,
    prizeTitle?: string,
    prizeImageUrl?: string,
    prizeLink?: string,
    deliveredWalletAddress?: string,
    delivered: boolean
  ) {
    this.id = id;
    this.userId = userId;
    this.auctionId = auctionId;
    this.auctionPrizeId = auctionPrizeId;
    this.prizeTitle = prizeTitle;
    this.prizeImageUrl = prizeImageUrl;
    this.prizeLink = prizeLink;
    this.deliveredWalletAddress = deliveredWalletAddress;
    this.delivered = delivered;
  }

  static fromJson(json: MyAuctionUserPrizeJson): MyAuctionUserPrize {
    return new MyAuctionUserPrize(
      json.id,
      json.user_id,
      json.auction_id,
      json.auction_prize_id,
      json.prize_title,
      json.prize_image_url,
      json.prize_link,
      json.delivered_wallet_address,
      json.delivered
    );
  }

  toJson(): MyAuctionUserPrizeJson {
    return {
      id: this.id,
      user_id: this.userId,
      auction_id: this.auctionId,
      auction_prize_id: this.auctionPrizeId,
      prize_title: this.prizeTitle,
      prize_image_url: this.prizeImageUrl,
      prize_link: this.prizeLink,
      delivered_wallet_address: this.deliveredWalletAddress,
      delivered: this.delivered,
    };
  }
}

interface AuctionMyDetailsResponseJson {
  auction_bid_leaderboard?: MyAuctionBidLeaderboardJson;
  auction_user_prizes?: MyAuctionUserPrizeJson[];
}

export class AuctionMyDetailsResponse {
  myAuctionBidLeaderboard?: MyAuctionBidLeaderboard;
  myAuctionUserPrizes?: MyAuctionUserPrize[];

  constructor(
    myAuctionBidLeaderboard?: MyAuctionBidLeaderboard,
    myAuctionUserPrizes?: MyAuctionUserPrize[]
  ) {
    this.myAuctionBidLeaderboard = myAuctionBidLeaderboard;
    this.myAuctionUserPrizes = myAuctionUserPrizes;
  }

  static fromJson(json: AuctionMyDetailsResponseJson): AuctionMyDetailsResponse {
    const bidLeaderboard = json.auction_bid_leaderboard
      ? MyAuctionBidLeaderboard.fromJson(json.auction_bid_leaderboard)
      : undefined;
    const userPrizes = json.auction_user_prizes
      ? json.auction_user_prizes.map(MyAuctionUserPrize.fromJson)
      : undefined;

    return new AuctionMyDetailsResponse(bidLeaderboard, userPrizes);
  }

  toJson(): AuctionMyDetailsResponseJson {
    return {
      auction_bid_leaderboard: this.myAuctionBidLeaderboard?.toJson(),
      auction_user_prizes: this.myAuctionUserPrizes?.map(prize => prize.toJson()),
    };
  }
}
