export const CoinCenterHeader = () => {
  return (
    <div className="grid grid-cols-3">
      <div className="col-span-1"></div>
      <h1 className="col-span-1 text-32 text-center flex-1 leading-none pb-4">
        Coin Center
      </h1>
      <div className="flex w-full justify-end items-start">
        <div
          className="col-span-1 h-10 w-32 flex items-center justify-center cursor-pointer p-2 text-sm space-x-1
        text-md text-center px-4 py-1 border-1 bg-opacity-10 bg-accent border-accent text-accent rounded-lg"
          onClick={() => {
            window.open(
              'https://odd-chopper-fec.notion.site/Everything-You-Need-To-Know-About-AssetDash-Coins-a2bd8e4118354dffad9608f8021716d9',
            );
          }}
        >
          How it works
        </div>
      </div>
    </div>
  );
};
