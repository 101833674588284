import { toast } from 'react-hot-toast';
import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

export const toastError = (error: any) => {
  toast.error(
    (error as any)?.response?.data?.detail ||
      (error as any)?.response?.data?.errors[0]?.msg ||
      DEFAULT_ERROR_MESSAGE,
  );
};
