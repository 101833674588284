import { axiosAssetDashApi } from './api';

export enum ASSET_TYPE {
  equity = 'equity',
  derivative = 'derivative',
  etf = 'etf',
  mutual_fund = 'mutual_fund',
  currency = 'currency',
  crypto = 'crypto',
  crypto_protocol = 'crypto_protocol',
  nft = 'nft',
  metals = 'metals',
  private_equity = 'private_equity',
  real_estate = 'real_estate',
  collectibles = 'collectibles',
  other = 'other',
}

export enum ASSET_TYPE_GROUP {
  stocks = 'stocks',
  options = 'options',
  cash = 'cash',
  crypto = 'crypto',
  defi = 'defi',
  nft = 'nft',
  commodity = 'commodity',
  private_equity = 'private_equity',
  real_estate = 'real_estate',
  collectibles = 'collectibles',
  other = 'other',
}

export function getAssetChartSeries(assetId?: string) {
  const params = {
    ...(assetId && { asset_id: assetId }),
  };
  return axiosAssetDashApi.get('api/api_v5/assets/chart-series', {
    params,
  });
}

export function getAssetDetails(assetId?: string) {
  const params = {
    ...(assetId && { asset_id: assetId }),
  };
  return axiosAssetDashApi.get('api/api_v5/assets/details', {
    params,
  });
}

export function getNFTItemDetails(nftItemId?: string) {
  const params = {
    ...(nftItemId && { nft_item_id: nftItemId }),
  };
  return axiosAssetDashApi.get('api/api_v5/assets/nft-item-details', {
    params,
  });
}

export function getAssetNFTTransactions(assetId?: string) {
  const params = {
    ...(assetId && { asset_id: assetId }),
  };
  return axiosAssetDashApi.get('api/api_v5/assets/nft/transactions', {
    params,
  });
}

export function getAssetNFTListings(assetId?: string) {
  const params = {
    ...(assetId && { asset_id: assetId }),
  };
  return axiosAssetDashApi.get('api/api_v5/assets/nft/listings', {
    params,
  });
}

export function getMetals() {
  return axiosAssetDashApi.get('api/api_v3/assets/metals');
}

export function getAssetSearchResults(query: string) {
  return axiosAssetDashApi.get('api/api_v3/search/assets', {
    params: { query },
  });
}
