import React from 'react';
import { NFTTransaction } from '@/features/portfolio/models/NFTTransaction';
import moment from 'moment';

interface NFTTransactionItemProps {
  transaction: NFTTransaction;
}

const NFTTransactionItem: React.FC<NFTTransactionItemProps> = ({
  transaction,
}) => {
  return (
    <div className="card p-2 rounded-xl space-y-2 overflow-x-hidden">
      <div className="flex flex-row items-center space-x-4">
        <img
          src={transaction.nftUrl}
          className="w-20 h-20 sm:w-32 sm:h-32 rounded-card-box object-cover"
          alt="nft"
        />
        <div className="w-full flex flex-col justify-between space-y-3">
          <div className="flex justify-between items-start space-y-1 flex-row">
            <div className="flex space-x-2">
              <div className="flex flex-col">
                <div className="font-semibold text-gray-600 dark:text-gray-300 text-lg">
                  {transaction.nftName}
                </div>
              </div>
            </div>
            <img
              src={transaction.nftChainLogo}
              alt="chain"
              className="h-6 w-6 rounded-full object-scale-down border-solid border-gray-text"
            />
          </div>
          <div className="flex justify-between items-start space-y-1 flex-col sm:flex-row">
            <div className="text-sm text-black uppercase max-w-40 bg-price-green px-2 py-1 rounded-8 font-semibold">
              {transaction.activityType}
            </div>
            <div className="text-gray-text">
              {moment(transaction.timestamp).fromNow()}
            </div>
          </div>
          <div className="flex space-x-2 items-center">
            <div className="bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-gray-600 border-1 px-4 rounded-xl text-sm">
              {transaction.sellerAddress
                ? `${transaction.sellerAddress.slice(0, 6)}...`
                : 'Unknown'}
            </div>
            <img
              src="/assets/icons/arrow_right.png"
              alt="arrow-right"
              className="h-4"
            />
            <div className="bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 border-gray-400 dark:border-gray-600 border-1 px-4 rounded-xl text-sm">
              {transaction.buyerAddress
                ? `${transaction.buyerAddress.slice(0, 6)}...`
                : 'Unknown'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTTransactionItem;
