import React from 'react';

interface TooltipProps {
  text?: string;
  headerText?: string;
  children: React.ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  isVisible?: boolean;
  width?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  headerText,
  children,
  position = 'top',
  isVisible = true,
  width = '256px',
}) => {
  return (
    <div className="flex relative group">
      {children}
      {isVisible && (
        <div
          style={{ width }}
          className={`${
            (position === 'right' && '-top-4 left-8') ||
            (position === 'left' && '-top-4 right-8') ||
            (position === 'bottom' && 'top-8 -translate-x-1/2 left-1/2') ||
            (position === 'top' && 'bottom-8 -translate-x-1/2 left-1/2')
          } 
                     transform hidden group-hover:block font-10 z-10 absolute p-4 bg-border-bg dark:bg-dark rounded-8 text-black dark:text-white`}
        >
          {headerText && (
            <div className="font-bold text-xl mb-2">{headerText}</div>
          )}
          {text && <p className="font-normal text-14 leading-5 mb-2">{text}</p>}
          <div
            className={`${
              (position === 'right' && 'top-5 -left-1 -rotate-45') ||
              (position === 'left' && 'top-5 -right-1 -rotate-45') ||
              (position === 'bottom' &&
                '-translate-x-1/2 left-1/2 -top-1 -rotate-45') ||
              (position === 'top' &&
                '-translate-x-1/2 left-1/2 -bottom-1 -rotate-45')
            } 
                        absolute transform w-2 h-2 bg-border-bg dark:bg-dark`}
          />
        </div>
      )}
    </div>
  );
};
