import { atom } from 'recoil';
import { useRecoilState } from 'recoil';

import { localStorageEffect } from './effects/localStorageEffect';

const themeState = atom({
  key: 'theme',
  default: localStorage.getItem('theme') || 'dark',
  effects: [localStorageEffect('theme')],
});

const useThemeState = () => useRecoilState(themeState);

export { useThemeState };
