// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { AccountSettingsForm } from '../../components/authentication/AccountSettingsForm';

export default function AccountSettingsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | Account`} lang="en_IE" />
      <h1 className="text-32 leading-none text-center mt-8 mb-8">Account</h1>
      <AccountSettingsForm />
    </>
  );
}
