import { useDeals } from '@/hooks/useDeals';

import { CouponCard } from './CouponCard';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

export const MyDealsTab = () => {
  const { coupons, dealsLoading } = useDeals();
  return (
    <div>
      {dealsLoading ? (
        <SkeletonLoader height={600} />
      ) : (
        <div className="card rounded-card-box h-full flex flex-col pt-8 px-2">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 md:gap-8 md:px-4 py-4">
            {coupons.map(coupon => (
              <CouponCard key={coupon.id} coupon={coupon} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
