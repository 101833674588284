// vendor libraries
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { SuperSEO } from 'react-super-seo';
import { useNavigate } from 'react-router-dom';

// components
import { ProgressBar } from '../..//components/common/ProgressBar';

// api
import {
  getUserSyncData,
  getUserMemberCenter,
  userPortfolioSync,
} from '../../api/user';

export default function SyncCenterPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [syncCenterData, setSyncCenterData] = useState<any>({});
  const [memberCenterData, setMemberCenterData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUserSyncData();
    pollPortfolioSyncData();
    fetchMemberCenterData();
  }, []);

  const fetchUserSyncData = async () => {
    const response = await getUserSyncData();
    setSyncCenterData(response.data.sync_center);
  };

  const { member_benefits, member_status } = memberCenterData || '';

  const fetchMemberCenterData = async () => {
    const response = await getUserMemberCenter();
    setMemberCenterData(response.data.member_center);
  };

  const { daily_syncs_available, daily_syncs_triggered, user_sync_attempt } =
    syncCenterData || {};

  const progress = (daily_syncs_triggered / daily_syncs_available) * 100;

  const startSyncCenter = async () => {
    setIsLoading(true);
    await triggerPortfolioSync();
    await fetchUserSyncData();
    setIsLoading(false);
  };

  const triggerPortfolioSync = async () => {
    try {
      const resp = await userPortfolioSync();
      if (resp.status === 200) {
        toast.success('Sync started');
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || 'Something went wrong');
    }
  };

  const pollPortfolioSyncData = async () => {
    const polling = setInterval(async () => {
      try {
        const response = await getUserSyncData();
        setSyncCenterData(response.data.sync_center);

        if (
          response?.data?.sync_center?.user_sync_attempt?.sync_status !==
          'in_progress'
        ) {
          clearInterval(polling);
        }
      } catch (error) {}
    }, 15000);
  };

  const handleClose = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <SuperSEO title={`AssetDash | Sync Center`} lang="en_IE" />
      <div className="main-content mx-auto">
        <div className="relative bg-white dark:bg-input-box rounded-12 space-y-8 divide-y-4 divide-blue-dark">
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700"
          >
            <XMarkIcon className="h-6 w-6 text-black dark:text-white" />
          </button>
          <div className="space-y-4 p-4 md:p-8 ">
            <p className="text-center text-3xl md:text-4xl font-bold">
              Sync Latest Holdings
            </p>
            <p className="text-center text-xl md:text-2xl">
              Trigger a sync to your portfolio across all your accounts to
              update your holdings.
            </p>
          </div>
          <div className="space-y-4 p-4 md:p-8 ">
            <p className="text-2xl font-bold">How it works?</p>
            <ul className="text-lg md:text-xl list-disc space-y-3 pl-4">
              <li>
                Asset prices and portfolio values are updated every few minutes
                automatically.
              </li>
              <li>Holdings are updated once per day automatically.</li>
              <li>
                You can manually trigger a sync across your crypto wallets to
                update your holdings at any time. If you bought or sold a crypto
                asset and want to see it in your portfolio now, press sync
                below.
              </li>
            </ul>
          </div>
          {member_status && (
            <>
              <div className="space-y-2 p-4 md:p-8 ">
                <div className="flex space-x-4 items-center">
                  <img
                    src={member_benefits?.logo}
                    className="h-8 w-12 rounded-full object-cover"
                    alt="member status"
                  />
                  {member_status && (
                    <p className="capitalize text-xl font-bold ">
                      {member_status} member
                    </p>
                  )}
                </div>
                {member_status !== 'standard' ? (
                  <div className="space-y-4">
                    <div className="w-full md:w-1/3">
                      <ProgressBar
                        bgcolor="#21CE99"
                        progress={progress}
                        height="8px"
                        showInfoIcon={false}
                        multiplier={1}
                      />
                    </div>
                    <p className="text-lg">
                      <span className="font-bold">{daily_syncs_triggered}</span>{' '}
                      /{' '}
                      <span className="font-bold">{daily_syncs_available}</span>{' '}
                      daily holdings syncs used
                    </p>

                    <div>
                      <button
                        className={`mt-4 flex space-x-2 text-xl items-center w-full py-4 justify-center rounded-12 text-black font-bold ${
                          daily_syncs_triggered < daily_syncs_available &&
                          !isLoading &&
                          user_sync_attempt?.sync_status !== 'in_progress'
                            ? 'bg-price-green'
                            : 'bg-gray-text'
                        }`}
                        disabled={
                          daily_syncs_triggered >= daily_syncs_available ||
                          user_sync_attempt?.sync_status === 'in_progress' ||
                          isLoading
                        }
                        onClick={() => {
                          startSyncCenter();
                        }}
                      >
                        <p>
                          {isLoading
                            ? 'Loading'
                            : daily_syncs_triggered < daily_syncs_available
                              ? user_sync_attempt?.sync_status === 'in_progress'
                                ? 'Syncing'
                                : 'Sync'
                              : 'Daily Sync Limit Reached'}
                        </p>
                        <ArrowPathIcon
                          className={`h-5 w-5 text-input-box ${
                            user_sync_attempt?.sync_status === 'in_progress' ||
                            isLoading
                              ? 'animate-spin-slow'
                              : ''
                          }`}
                        />
                      </button>
                    </div>
                    <div className="flex items-center justify-between text-gray-text text-sm font-medium">
                      <p>Last time synced</p>
                      <p>
                        {(user_sync_attempt?.end_time ||
                          user_sync_attempt?.start_time) &&
                          (user_sync_attempt?.end_time
                            ? moment(user_sync_attempt?.end_time + 'Z')
                            : moment(user_sync_attempt?.start_time + 'Z')
                          ).fromNow()}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4 text-lg md:text-xl">
                    <p>
                      Only premium users are able to trigger portfolio syncs.
                      Please go to the Wallets page and connect and verify
                      wallets in order to get Premium Status
                    </p>
                    <div>
                      <a href="/wallets">
                        <button className="py-4 px-8 w-full md:w-max bg-price-green rounded-12 text-black">
                          Go to wallets
                        </button>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
