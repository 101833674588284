import { PartnerReward } from './PartnerRewards'; 

export class PartnerRewardDetailResponse {
  partnerReward?: PartnerReward;
  partnerRewardUser?: PartnerRewardUserDetail;

  constructor(partnerReward?: PartnerReward, partnerRewardUser?: PartnerRewardUserDetail) {
    this.partnerReward = partnerReward;
    this.partnerRewardUser = partnerRewardUser;
  }

  static fromJson(json: any): PartnerRewardDetailResponse {
    return new PartnerRewardDetailResponse(
      json['partner_reward'] ? PartnerReward.fromJson(json['partner_reward']) : undefined,
      json['partner_reward_user'] ? PartnerRewardUserDetail.fromJson(json['partner_reward_user']) : undefined
    );
  }

  toJson(): any {
    return {
      partner_reward: this.partnerReward?.toJson(),
      partner_reward_user: this.partnerRewardUser?.toJson()
    };
  }
}

export class PartnerRewardUser {
  id: string;
  created: Date;
  updated: Date;
  userId?: string;
  partnerRewardId?: string;
  rewardUnits?: number;
  rewardUnit?: string;
  rewardUnitLogoUrl?: string;
  rewardData?: RewardData;

  constructor(
    id: string,
    created: Date,
    updated: Date,
    userId?: string,
    partnerRewardId?: string,
    rewardUnits?: number,
    rewardUnit?: string,
    rewardUnitLogoUrl?: string,
    rewardData?: RewardData
  ) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.userId = userId;
    this.partnerRewardId = partnerRewardId;
    this.rewardUnits = rewardUnits;
    this.rewardUnit = rewardUnit;
    this.rewardUnitLogoUrl = rewardUnitLogoUrl;
    this.rewardData = rewardData;
  }

  static fromJson(json: any): PartnerRewardUser {
    return new PartnerRewardUser(
      json['id'],
      new Date(json['created']),
      new Date(json['updated']),
      json['user_id'],
      json['partner_reward_id'],
      json['reward_units'],
      json['reward_unit'],
      json['reward_unit_logo_url'],
      json['reward_data'] ? RewardData.fromJson(json['reward_data']) : undefined
    );
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created.toISOString(),
      updated: this.updated.toISOString(),
      user_id: this.userId,
      partner_reward_id: this.partnerRewardId,
      reward_units: this.rewardUnits,
      reward_unit: this.rewardUnit,
      reward_unit_logo_url: this.rewardUnitLogoUrl,
      reward_data: this.rewardData?.toJson()
    };
  }
}

export class RewardRow {
  label?: string;
  type?: string;
  value: any;

  constructor(label: string = '', type: string = '', value: any) {
    this.label = label;
    this.type = type;
    this.value = value;
  }

  static fromJson(json: any): RewardRow {
    return new RewardRow(json['label'], json['type'], json['value']);
  }

  toJson(): any {
    return {
      label: this.label,
      type: this.type,
      value: this.value
    };
  }
}

class RewardData {
  rows: RewardRow[];

  constructor(rows: RewardRow[]) {
    this.rows = rows;
  }

  static fromJson(json: any): RewardData {
    const rows = json['rows'].map((rowJson: any) => RewardRow.fromJson(rowJson));
    return new RewardData(rows);
  }

  toJson(): any {
    return {
      rows: this.rows.map(row => row.toJson())
    };
  }
}

export class PartnerRewardUserWallet {
  id: string;
  created: Date;
  updated: Date;
  userId?: string;
  userWalletId?: string;
  partnerRewardId?: string;
  rewardUnits?: number;
  rewardUnit?: string;
  rewardUnitLogoUrl?: string;
  rewardData?: RewardData;

  constructor(
    id: string,
    created: Date,
    updated: Date,
    userId?: string,
    userWalletId?: string,
    partnerRewardId?: string,
    rewardUnits?: number,
    rewardUnit?: string,
    rewardUnitLogoUrl?: string,
    rewardData?: RewardData
  ) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.userId = userId;
    this.userWalletId = userWalletId;
    this.partnerRewardId = partnerRewardId;
    this.rewardUnits = rewardUnits;
    this.rewardUnit = rewardUnit;
    this.rewardUnitLogoUrl = rewardUnitLogoUrl;
    this.rewardData = rewardData;
  }

  static fromJson(json: any): PartnerRewardUserWallet {
    return new PartnerRewardUserWallet(
      json['id'],
      new Date(json['created']),
      new Date(json['updated']),
      json['user_id'],
      json['user_wallet_id'],
      json['partner_reward_id'],
      json['reward_units'],
      json['reward_unit'],
      json['reward_unit_logo_url'],
      json['reward_data'] ? RewardData.fromJson(json['reward_data']) : undefined
    );
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created.toISOString(),
      updated: this.updated.toISOString(),
      user_id: this.userId,
      user_wallet_id: this.userWalletId,
      partner_reward_id: this.partnerRewardId,
      reward_units: this.rewardUnits,
      reward_unit: this.rewardUnit,
      reward_unit_logo_url: this.rewardUnitLogoUrl,
      reward_data: this.rewardData?.toJson()
    };
  }
}

export class PartnerRewardUserDetail extends PartnerRewardUser {
  partnerRewardUserWallets: PartnerRewardUserWallet[];

  constructor(
    id: string,
    created: Date,
    updated: Date,
    userId: string,
    partnerRewardId: string,
    rewardUnits: number,
    rewardUnit: string,
    rewardUnitLogoUrl: string,
    rewardData: RewardData | undefined,
    partnerRewardUserWallets: PartnerRewardUserWallet[]
  ) {
    super(id, created, updated, userId, partnerRewardId, rewardUnits, rewardUnit, rewardUnitLogoUrl, rewardData);
    this.partnerRewardUserWallets = partnerRewardUserWallets;
  }

  static fromJson(json: any): PartnerRewardUserDetail {
    const partnerRewardUserWallets = json['partner_reward_user_wallets'].map((walletJson: any) => PartnerRewardUserWallet.fromJson(walletJson));
    return new PartnerRewardUserDetail(
      json['id'],
      new Date(json['created']),
      new Date(json['updated']),
      json['user_id'],
      json['partner_reward_id'],
      json['reward_units'],
      json['reward_unit'],
      json['reward_unit_logo_url'],
      json['reward_data'] ? RewardData.fromJson(json['reward_data']) : undefined,
      partnerRewardUserWallets
    );
  }

  toJson(): any {
    return {
      ...super.toJson(),
      partner_reward_user_wallets: this.partnerRewardUserWallets.map(wallet => wallet.toJson())
    };
  }
}