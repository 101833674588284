export const ArrowIcon = ({
  direction = 'right',
  color = '#F5AC6E',
  bigSize = false,
}) => {
  return (
    <>
      {(direction === 'left' &&
        ((bigSize && (
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.65318 15.6743L0.719124 8.54307C0.426959 8.24288 0.426959 7.7579 0.719124 7.45693L7.65318 0.325723C8.07495 -0.108574 8.76116 -0.108574 9.18367 0.325723C9.60544 0.76002 9.60544 1.46488 9.18367 1.89917L3.25197 8.00039L9.18367 14.1C9.60544 14.5351 9.60544 15.24 9.18367 15.6743C8.76116 16.1086 8.07495 16.1086 7.65318 15.6743Z"
              fill={color}
            />
          </svg>
        )) || (
          <svg
            width="24"
            height="24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.49999 12C8.49999 11.744 8.59799 11.488 8.79299 11.293L12.793 7.29301C13.184 6.90201 13.816 6.90201 14.207 7.29301C14.598 7.68401 14.598 8.31601 14.207 8.70701L10.902 12.012L14.082 15.305C14.465 15.704 14.454 16.335 14.057 16.719C13.66 17.103 13.026 17.092 12.643 16.695L8.78099 12.695C8.59299 12.5 8.49999 12.25 8.49999 12Z"
              fill={color}
            />
            <mask
              id="mask0_11056_156818"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="8"
              y="6"
              width="7"
              height="11"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.49999 12C8.49999 11.744 8.59799 11.488 8.79299 11.293L12.793 7.29301C13.184 6.90201 13.816 6.90201 14.207 7.29301C14.598 7.68401 14.598 8.31601 14.207 8.70701L10.902 12.012L14.082 15.305C14.465 15.704 14.454 16.335 14.057 16.719C13.66 17.103 13.026 17.092 12.643 16.695L8.78099 12.695C8.59299 12.5 8.49999 12.25 8.49999 12Z"
                fill={color}
              />
            </mask>
            <g mask="url(#mask0_11056_156818)">
              <rect
                x="24"
                width="24"
                height="24"
                transform="rotate(90 24 0)"
                fill={color}
              />
            </g>
          </svg>
        ))) ||
        (direction === 'right' && (
          <svg
            width="24"
            height="24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5 12C15.5 12.256 15.402 12.512 15.207 12.707L11.207 16.707C10.816 17.098 10.184 17.098 9.79301 16.707C9.40201 16.316 9.40201 15.684 9.79301 15.293L13.098 11.988L9.91801 8.69499C9.53501 8.29599 9.54601 7.66499 9.94301 7.28099C10.34 6.89699 10.974 6.90799 11.357 7.30499L15.219 11.305C15.407 11.5 15.5 11.75 15.5 12Z"
              fill={color}
            />
            <mask
              id="mask0_11056_156819"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="7"
              width="7"
              height="11"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5 12C15.5 12.256 15.402 12.512 15.207 12.707L11.207 16.707C10.816 17.098 10.184 17.098 9.79301 16.707C9.40201 16.316 9.40201 15.684 9.79301 15.293L13.098 11.988L9.91801 8.69499C9.53501 8.29599 9.54601 7.66499 9.94301 7.28099C10.34 6.89699 10.974 6.90799 11.357 7.30499L15.219 11.305C15.407 11.5 15.5 11.75 15.5 12Z"
                fill={color}
              />
            </mask>
            <g mask="url(#mask0_11056_156819)">
              <rect
                y="24"
                width="24"
                height="24"
                transform="rotate(-90 0 24)"
                fill={color}
              />
            </g>
          </svg>
        )) ||
        (direction === 'up' && (
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Icon_chevron-up">
              <path
                id="Mask"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8.49999C12.256 8.49999 12.512 8.59799 12.707 8.79299L16.707 12.793C17.098 13.184 17.098 13.816 16.707 14.207C16.316 14.598 15.684 14.598 15.293 14.207L11.988 10.902L8.69499 14.082C8.29599 14.465 7.66499 14.454 7.28099 14.057C6.89699 13.66 6.90799 13.026 7.30499 12.643L11.305 8.78099C11.5 8.59299 11.75 8.49999 12 8.49999Z"
                fill={color}
              />
              <mask
                id="mask0_11056_156816"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="7"
                y="8"
                width="11"
                height="7"
              >
                <path
                  id="Mask_2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 8.49999C12.256 8.49999 12.512 8.59799 12.707 8.79299L16.707 12.793C17.098 13.184 17.098 13.816 16.707 14.207C16.316 14.598 15.684 14.598 15.293 14.207L11.988 10.902L8.69499 14.082C8.29599 14.465 7.66499 14.454 7.28099 14.057C6.89699 13.66 6.90799 13.026 7.30499 12.643L11.305 8.78099C11.5 8.59299 11.75 8.49999 12 8.49999Z"
                  fill={color}
                />
              </mask>
              <g mask="url(#mask0_11056_156816)">
                <g id="&#240;&#159;&#142;&#168; Color">
                  <rect
                    id="Base"
                    x="24"
                    y="24"
                    width="24"
                    height="24"
                    transform="rotate(-180 24 24)"
                    fill={color}
                  />
                </g>
              </g>
            </g>
          </svg>
        )) ||
        (direction === 'down' && (
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Icon_chevron-down">
              <path
                id="Mask"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 15.5C11.744 15.5 11.488 15.402 11.293 15.207L7.29301 11.207C6.90201 10.816 6.90201 10.184 7.29301 9.79301C7.68401 9.40201 8.31601 9.40201 8.70701 9.79301L12.012 13.098L15.305 9.91801C15.704 9.53501 16.335 9.54601 16.719 9.94301C17.103 10.34 17.092 10.974 16.695 11.357L12.695 15.219C12.5 15.407 12.25 15.5 12 15.5Z"
                fill={color}
              />
              <mask
                id="mask0_11056_156817"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="6"
                y="9"
                width="11"
                height="7"
              >
                <path
                  id="Mask_2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 15.5C11.744 15.5 11.488 15.402 11.293 15.207L7.29301 11.207C6.90201 10.816 6.90201 10.184 7.29301 9.79301C7.68401 9.40201 8.31601 9.40201 8.70701 9.79301L12.012 13.098L15.305 9.91801C15.704 9.53501 16.335 9.54601 16.719 9.94301C17.103 10.34 17.092 10.974 16.695 11.357L12.695 15.219C12.5 15.407 12.25 15.5 12 15.5Z"
                  fill={color}
                />
              </mask>
              <g mask="url(#mask0_11056_156817)">
                <g id="&#240;&#159;&#142;&#168; Color">
                  <rect id="Base" width="24" height="24" fill={color} />
                </g>
              </g>
            </g>
          </svg>
        ))}
    </>
  );
};
