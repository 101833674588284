// vendor libraries
import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useParams } from 'react-router-dom';

// hooks
import { usePortfolio } from '../../hooks/usePortfolio';

// models
import { WalletNFTItem } from '../../features/portfolio/models/WalletNFTItem';

// components
import { NFTItemHeader } from '@/components/v2/nft/NFTItemHeader';
import { NFTItemPriceCard } from '@/components/v2/nft/NFTItemPriceCard';
import { NFTItemSeriesCard } from '@/components/v2/nft/NFTItemSeriesCard';
import { NFTItemTraitsCard } from '@/components/v2/nft/NFTItemTraitsCard';
import { NFTItemMultiplierCard } from '@/components/v2/nft/NFTItemMultiplierCard';
import { NFTItemCostBasisCard } from '@/components/v2/nft/NFTItemCostBasisCard';
import { NFTItemCollectionInfoCard } from '@/components/v2/nft/NFTItemCollectionInfoCard';
import { getNFTItemDetails } from '@/api/assets';
import { NFTItemDetails } from '@/features/portfolio/models/NFTItemDetails';

export default function NFTItemPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { nftItemId } = useParams();
  const [walletNFTItem, setWalletNFTItem] = useState<WalletNFTItem>();

  const [nftItemDetails, setNFTItemDetails] = useState<NFTItemDetails>();

  const { nftItemToWalletNFTItemMap, fetchNFTHoldingSeriesChart } =
    usePortfolio();

  useEffect(() => {
    if (nftItemToWalletNFTItemMap.has(nftItemId!)) {
      setWalletNFTItem(nftItemToWalletNFTItemMap.get(nftItemId!));
    }
  }, [nftItemId, nftItemToWalletNFTItemMap]);

  useEffect(() => {
    if (nftItemId) {
      fetchNFTHoldingSeriesChart(nftItemId);
      fetchNFTItemDetails(nftItemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftItemId]);

  const fetchNFTItemDetails = async (nftItemId: string) => {
    try {
      const response = await getNFTItemDetails(nftItemId);

      const nftItemDetails = NFTItemDetails.fromJSON(
        response.data.nft_item_details,
      );

      setNFTItemDetails(nftItemDetails);
    } catch (error) {
      console.log('error:', error);
    }
  };
  return (
    <>
      <SuperSEO title="AssetDash | NFT Gallery" lang="en_IE" />

      <div className="main-content flex flex-col space-y-6">
        <div className="text-2xl font-semibold">
          {walletNFTItem?.nftItem?.name}
        </div>
        <div className="flex flex-col xl:flex-row gap-6">
          <div className="flex flex-col items-center md:items-start md:flex-row w-full xl:w-1/2 2xl:w-5/12 space-x-0 space-y-4 md:space-x-4 md:space-y-0">
            <div className="md:min-w-[298px] h-full">
              <NFTItemHeader walletNFTItem={walletNFTItem} />
            </div>
            <NFTItemPriceCard
              nftItemDetails={nftItemDetails}
              walletNFTItem={walletNFTItem}
            />
          </div>
          <div className="flex w-full xl:w-1/2 2xl:w-7/12">
            <NFTItemSeriesCard walletNFTItem={walletNFTItem} />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row gap-6">
          <div className="w-full xl:w-1/2 2xl:w-5/12">
            <NFTItemTraitsCard nftItemTraits={nftItemDetails?.traits || []} />
          </div>
          <div className="space-y-6 flex flex-col w-full xl:w-1/2 2xl:w-7/12">
            <NFTItemCollectionInfoCard walletNFTItem={walletNFTItem} />
            <div className="flex flex-col space-y-6">
              <NFTItemMultiplierCard walletNFTItem={walletNFTItem} />
              <NFTItemCostBasisCard walletNFTItem={walletNFTItem} />
              <NFTItemCostBasisCard
                walletNFTItem={walletNFTItem}
                isNative={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
