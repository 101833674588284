import { useState, useEffect } from 'react';
import { usePortfolio } from '@/hooks/usePortfolio';
import { formatNumber } from '@/utils/number';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import { Holding } from '@/features/portfolio/models/Holding';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { NFTItemsSlider } from '@/components/v2/nft/NFTItemsSlider';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';
import { AssetHoldingsGraphCard } from '../AssetHoldingsGraphCard';
import { useAuth } from '@/hooks/useAuth';
import { OptionsTab } from '@/components/v2/common/Tab/OptionsTab';

interface AssetHoldingsContentProps {
  holding: Holding;
  walletHoldings: WalletHolding[];
}

export const AssetHoldingsContent: React.FC<AssetHoldingsContentProps> = ({
  holding,
  walletHoldings,
}) => {
  const { convertUSDCurrency } = useAuth();
  const { assetIdToWalletNFTItemsMap } = usePortfolio();
  const [walletNFTItems, setWalletNFTItems] = useState<WalletNFTItem[]>([]);
  const [selectedTab, setSelectedTab] = useState('NFTs');

  useEffect(() => {
    if (holding && assetIdToWalletNFTItemsMap) {
      if (
        holding.asset.assetType === 'nft' &&
        assetIdToWalletNFTItemsMap.has(holding.asset.id!)
      ) {
        setWalletNFTItems(assetIdToWalletNFTItemsMap.get(holding.asset.id!)!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holding, assetIdToWalletNFTItemsMap]);

  if (!holding)
    return (
      <div className="card p-2">
        <SkeletonLoader height={330} />
      </div>
    );

  const { quantity, allocation, amount } = holding;

  const tabs = {
    NFTs: 'NFTs',
    Wallet: 'Wallet',
  };

  return (
    <div className="card flex flex-col h-auto">
      {holding.asset.assetType === 'nft' && (
        <div className="flex items-center justify-between pt-4 px-4 md:px-6">
          <OptionsTab
            tabs={tabs}
            activeTab={selectedTab}
            onClick={setSelectedTab}
          />
        </div>
      )}
      <div className="flex flex-wrap space-x-3 px-4 pt-4">
        <span className="bg-price-green/25 dark:bg-light-green/20 text-sm rounded-md p-1">
          <span className="text-price-green dark:text-light-green font-bold">
            {formatNumber(allocation)}%
          </span>
          &nbsp;Portfolio Allocation
        </span>
        <span className="bg-price-green/25 dark:bg-light-green/20 text-sm rounded-md p-1">
          <span className="text-price-green dark:text-light-green font-bold">
            {formatNumber(quantity)}&nbsp;Tokens
          </span>
          &nbsp;Quantity
        </span>
        <span className="bg-price-green/25 dark:bg-light-green/20 text-sm rounded-md p-1">
          <span className="text-price-green dark:text-light-green font-bold">
            {convertUSDCurrency(amount)}
          </span>
          &nbsp;Total
        </span>
      </div>
      {holding.asset.assetType === 'nft' && (
        <>
          {selectedTab === 'NFTs' ? (
            <NFTItemsSlider items={walletNFTItems.slice(0, 15)} />
          ) : (
            <AssetHoldingsGraphCard
              holding={holding}
              walletHoldings={walletHoldings}
            />
          )}
        </>
      )}
      {holding.asset.assetType !== 'nft' && (
        <AssetHoldingsGraphCard
          holding={holding}
          walletHoldings={walletHoldings}
        />
      )}
    </div>
  );
};
