// vendor libraries
import { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { SuperSEO } from 'react-super-seo';

// components
import { Tooltip } from '../components/common/Tooltip/Tooltip';
import { SkeletonLoader } from '../components/common/Skeleton/SkeletonLoader';
import { WithdrawModal } from '../components/market/deals/WithdrawModal';

// api
import { getUserDealsCenter, getUserRewards } from '../api/user';
import { getCourse } from '../api/learn';

// hooks
import { useAuth } from '../hooks/useAuth';

export default function Rewards() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [dealsCenter, setDealsCenter] = useState<any>({});
  const [courseInfo, setCourseInfo] = useState<any>({});
  const [myRewards, setMyRewards] = useState<any>({});
  const { convertUSDCurrency } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchMyRewards();
    fetchDealsCenter();
  }, []);

  useEffect(() => {
    if (myRewards) {
      fetchCourseInfo(myRewards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRewards]);

  const fetchCourseInfo = async myRewards => {
    if (myRewards?.learn_rewards?.length > 0) {
      setIsLoading(true);
      for (const learn_reward of myRewards?.learn_rewards) {
        const { course_id } = learn_reward;
        try {
          const course_resp = await getCourse(course_id);
          if (course_resp.status === 400) {
            throw new Error('Request failed with status 400');
          }
          setCourseInfo(prevCourseInfo => ({
            ...prevCourseInfo,
            [course_id]: course_resp.data,
          }));
        } catch (error) {}
      }
      setIsLoading(false);
    }
  };

  const fetchMyRewards = async () => {
    const resp = await getUserRewards();

    setMyRewards(resp.data);
  };

  const fetchDealsCenter = async () => {
    const resp = await getUserDealsCenter();
    setDealsCenter(resp.data.deals_center);
  };

  const { total_cash_back, total_tickets_back } = dealsCenter || {};

  const { purchases, cash_back_rewards, learn_rewards } = myRewards || '';

  return (
    <div className="mx-auto main-content">
      <SuperSEO title="AssetDash | My Rewards" lang="en_IE" />
      {
        <div className="my-8 flex flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-between text-center bg-white dark:bg-deals-card-top rounded-lg py-4 px-4">
          <div className="divide-x-1 divide-gray-text flex items-center">
            {
              <div className="px-6 space-y-1">
                <p className="text-gray-text">Cash Back</p>
                <p className="font-bold text-xl">
                  ${total_cash_back?.toFixed(2)}
                </p>
              </div>
            }
            {
              <div className="px-6 space-y-1">
                <div className="flex items-center space-x-2">
                  <p className="text-gray-text">Tickets Back</p>
                  <Tooltip
                    text="Tickets Back are automatically applied to current giveaway at the time of purchase."
                    position="bottom"
                  >
                    <InformationCircleIcon className="h-4 w-4 text-price-green" />
                  </Tooltip>
                </div>
                <p className="font-bold text-xl">{total_tickets_back || 0}</p>
              </div>
            }
          </div>
          <button
            className="py-2 px-8 bg-gray-text rounded-full text-black font-bold"
            onClick={() => {
              //open modal
              setWithdrawModalOpen(true);
            }}
          >
            Withdraw
          </button>
        </div>
      }
      {isLoading ? (
        <SkeletonLoader height={500} />
      ) : (
        <>
          <div className="space-y-4 mt-8 divide-y-1 divide-gray-text">
            {cash_back_rewards?.map(myOrder => {
              const { created, amount, raffle_prize } = myOrder;

              const { title, prize_type, image_url } = raffle_prize || '';

              const date = moment(created).format('MM/DD/YY');

              return (
                <div
                  key={myOrder.id}
                  className="flex items-center justify-between pt-4"
                >
                  <div className="flex items-center space-x-4 md:space-x-8">
                    <img
                      src={image_url}
                      className="h-12 w-12 rounded-full object-cover"
                      alt="order logo"
                    />
                    <div className="space-y-1">
                      <p className="text-2xl font-semibold">{title}</p>
                      <p className="text-sm text-gray-text capitalize">
                        {prize_type.split('_').join(' ')}
                      </p>
                    </div>
                  </div>
                  <div className="space-y-1 text-lg text-right">
                    <p className="text-gray-text">{date}</p>
                    {amount && (
                      <p className="text-price-green font-bold">${amount}</p>
                    )}
                  </div>
                </div>
              );
            })}
            {purchases?.length > 0 &&
              purchases.map(myOrder => {
                const { created, cash_back, deal, tickets_back } = myOrder;
                const { name, image_url } = deal;

                const date = moment(created).format('MM/DD/YY');

                const desc =
                  cash_back && tickets_back
                    ? 'Cash + Tickets Back'
                    : cash_back
                    ? 'Cash Back'
                    : 'Tickets Back';

                return (
                  <div
                    key={myOrder.id}
                    className="flex items-center justify-between pt-4"
                  >
                    <div className="flex items-center space-x-4 md:space-x-8">
                      <img
                        src={image_url}
                        className="h-12 w-12 rounded-full object-cover"
                        alt="deal logo"
                      />
                      <div className="space-y-1">
                        <p className="text-2xl font-semibold">{name}</p>
                        <p className="text-sm text-gray-text">{desc}</p>
                      </div>
                    </div>
                    <div className="space-y-1 text-lg text-right">
                      <p className="text-gray-text">{date}</p>
                      {cash_back && (
                        <p className="text-price-green font-bold">
                          {cash_back && <span>${cash_back}</span>}
                          {tickets_back && (
                            <span>
                              {' '}
                              + {tickets_back}{' '}
                              {tickets_back > 0 ? 'Tickets' : 'Ticket'}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            {learn_rewards?.length > 0 &&
              learn_rewards.map(myReward => {
                const { created, reward } = myReward;
                const { cash_back, tickets } = reward;

                const date = moment(created).format('MM/DD/YY');

                const desc =
                  cash_back && tickets
                    ? 'Cash + Tickets Back'
                    : cash_back
                    ? 'Cash Back'
                    : 'Tickets Back';

                return (
                  <div
                    key={myReward.id}
                    className="flex items-center justify-between pt-4"
                  >
                    <div className="flex items-center space-x-4 md:space-x-8">
                      <img
                        src={courseInfo[reward.course_id]?.course?.image_url}
                        className="h-12 w-12 rounded-full object-cover"
                        alt="course logo"
                      />
                      <div className="space-y-1">
                        <p className="text-2xl font-semibold">
                          {courseInfo[reward.course_id]?.course?.title}
                        </p>
                        <p className="text-sm text-gray-text">{desc}</p>
                      </div>
                    </div>
                    <div className="space-y-1 text-lg text-right">
                      <p className="text-gray-text">{date}</p>
                      {cash_back && (
                        <p className="text-price-green font-bold">
                          {cash_back && (
                            <span>{convertUSDCurrency(cash_back)}</span>
                          )}
                          {tickets && (
                            <span>
                              {' '}
                              + {tickets} {tickets > 0 ? 'Tickets' : 'Ticket'}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}

            <WithdrawModal
              open={withdrawModalOpen}
              setOpen={setWithdrawModalOpen}
            />
          </div>
        </>
      )}
    </div>
  );
}
