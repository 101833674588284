interface DealProgressBarProps {
  percent: number | null;
  multiplier?: number;
  color: string;
}

export const DealProgressBar: React.FC<DealProgressBarProps> = ({
  percent,
  multiplier = 1,
  color,
}) => {
  const computedProgress = percent ? Math.trunc(percent * multiplier) : 0;

  return (
    <div className="h-8">
      {percent !== null && (
        <div className="space-y-1">
          <div className="flex items-center justify-between">
            <div className="w-full bg-gray-text rounded-full h-2">
              <div
                className={`h-full rounded-full bg-${color}`}
                style={{ width: `${computedProgress}%` }}
              ></div>
            </div>
          </div>
          <p className="text-xs text-gray-text">{percent}% claimed</p>
        </div>
      )}
    </div>
  );
};
