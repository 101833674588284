import { useEffect, useState } from 'react';

// api
import { getCoinSpendings } from '../../api/coin';

import {
  CoinSpendingsResponse,
  CoinSpending,
} from '../../features/coins/models/CoinSpendings';

export const CoinSpendings: React.FC = () => {
  const [coinSpendings, setCoinSpendings] = useState<CoinSpending[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCoinSpendings();
  }, []);

  const fetchCoinSpendings = async () => {
    setIsLoading(true);
    try {
      const response = await getCoinSpendings();
      setCoinSpendings(
        CoinSpendingsResponse.fromJson(response.data).coinSpendings,
      );
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <div className="space-y-4">
      {!isLoading && coinSpendings?.length === 0 && (
        <div className="flex justify-center font-semibold text-lg text-gray-text">
          You have not spent any of your coins yet
        </div>
      )}
      {coinSpendings?.map((spending: CoinSpending, i) => {
        return (
          <div
            key={i}
            className="bg-white dark:bg-deals-card-top  p-4 rounded-xl text-gray-600 dark:text-gray-300"
          >
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <div className="mt-1">
                  <img
                    className="rounded-full"
                    src="/assets/icons/coin.png"
                    alt="Coin"
                    height={24}
                    width={24}
                  />
                </div>
                <div className="flex flex-col ">
                  <div className="font-semibold text-red-600 text-lg">
                    {spending.coinsSpent} AssetDash Coins
                  </div>
                  <div className="text-sm text-gray-text">
                    {spending.spendingDescription}
                  </div>
                </div>
              </div>
              <div className="flex space-x-1 whitespace-nowrap items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 px-2 py-1 h-8 rounded-2xl text-sm">
                <img
                  src="/assets/icons/coin_check.png"
                  alt="check"
                  height={16}
                  width={16}
                />
                <div>{spending.getCreatedDay()}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
