export class HiddenAssets {
  assetId?: string;

  constructor(data?: { assetId?: string }) {
    if (data) {
      this.assetId = data.assetId;
    }
  }

  static fromJSON(json: any): HiddenAssets {
    return new HiddenAssets({
      assetId: json['asset_id'],
    });
  }

  toJSON(): any {
    return {
      asset_id: this.assetId,
    };
  }
}
