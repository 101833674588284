// vendor libraries
import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';

// api
import { getUserHiddenAssets, unhideUserAsset } from '../../api/hidden_assets';

// components
import { Toggle } from '../../components/common/Toggle';

export default function HiddenAssetsPage() {
  const [hiddenAssets, setHiddenAssets] = useState<any>([]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const fetchUserHiddenAssets = async () => {
    const response = await getUserHiddenAssets();
    setHiddenAssets(response.data.assets);
  };

  const hideAsset = async (assetId: string) => {
    setHiddenAssets(prevAssets =>
      prevAssets.map(asset => {
        if (asset.id === assetId) {
          return { ...asset, hidden: !asset.hidden }; // Toggle the hidden state
        }
        return asset;
      }),
    );
    await unhideUserAsset(assetId);
    await fetchUserHiddenAssets();
  };

  useEffect(() => {
    fetchUserHiddenAssets();
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | Hidden Assets`} lang="en_IE" />
      <h1 className="text-32 leading-none text-center mt-8 mb-8">
        Hidden Assets
      </h1>
      <div className="flex justify-center">
        {hiddenAssets && hiddenAssets.length > 0 ? (
          <div className="flex flex-col space-y-4">
            <div className="text-lg text-accent pb-4">
              These assets are hidden from your portfolio. Change the toggle to
              add these assets back to your portfolio.
            </div>
            {hiddenAssets.map((asset: any) => (
              <div
                key={asset.id}
                className="flex rounded-xl justify-between items-center bg-white dark:bg-deals-card-top p-4"
              >
                <div className="flex space-x-2 items-center">
                  <img
                    alt={`${asset.name} logo`}
                    className="h-6 w-6 rounded-xl"
                    src={asset.logo_url}
                  />
                  <div>{asset.name}</div>
                </div>
                <Toggle
                  id={asset.id}
                  handleToggle={() => hideAsset(asset.id)}
                  isChecked={!asset.hidden}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-lg text-accent">
            Assets you hide will show up here and be excluded from your
            portfolio. You can hide assets from the asset information pages.
          </div>
        )}
      </div>
    </>
  );
}
