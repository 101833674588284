import React from 'react';
import './ProgressBar.css';
interface ProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  // Ensure percentage is not out of bounds
  const normalizedPercentage = Math.min(100, Math.max(0, percentage));

  return (
    <div className="w-full bg-container-background-dark rounded-full h-[10px]">
      <div
        className="bg-progressbar h-[10px] rounded-full"
        style={{ width: `${normalizedPercentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
