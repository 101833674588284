import { AppColors } from '../features/core/theming/colors';

export const formatNumber = (num: number, decimals = 2): string => {
  if (typeof num !== 'number' || num === 0) {
    return '0';
  }
  if (Math.abs(num) < 0.005) {
    return '< 0.01';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  // Handle numbers less than 10,000,000
  if (num < 100000000) {
    return formatter.format(num);
  }
  // Handle numbers from 10,000,000 to 999,999,999
  if (num < 1000000000) {
    return (num / 1000000).toFixed(decimals) + 'M';
  }
  // Handle numbers from 1,000,000,000 to 999,999,999,999
  if (num < 1000000000000) {
    return (num / 1000000000).toFixed(decimals) + 'B';
  }
  // Handle numbers greater than or equal to 1,000,000,000,000
  return (num / 1000000000000).toFixed(decimals) + 'T';
};

export const formatPriceNumber = (num: number): string => {
  if (typeof num !== 'number' || num === 0) {
    return '0';
  }
  if (Math.abs(num) < 0.0000005) {
    return '< 0.0000001';
  }

  let decimals;
  if (num < 0.001) {
    decimals = 7;
  } else if (num < 1) {
    decimals = 4;
  } else {
    decimals = 2;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  // Handle numbers less than 10,000,000
  if (num < 10000000) {
    return formatter.format(num);
  }
  // Handle numbers from 10,000,000 to 999,999,999
  if (num < 1000000000) {
    return (num / 1000000).toFixed(decimals) + 'M';
  }
  // Handle numbers from 1,000,000,000 to 999,999,999,999
  if (num < 1000000000000) {
    return (num / 1000000000).toFixed(decimals) + 'B';
  }
  // Handle numbers greater than or equal to 1,000,000,000,000
  return (num / 1000000000000).toFixed(decimals) + 'T';
};

export const getNumberColor = (num: number): string => {
  return num >= 0 ? AppColors.positive() : AppColors.negative();
};
