import { atom } from 'recoil';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { localStorageEffect } from './effects/localStorageEffect';

const tokenState = atom({
  key: 'token',
  default: localStorage.getItem('token') || null,
  effects: [localStorageEffect('token')],
});

const useTokenState = () => useRecoilState(tokenState);
const useResetTokenState = () => useResetRecoilState(tokenState);

export { useTokenState, useResetTokenState };
