import React, { ReactNode, MouseEventHandler } from 'react';

type IconButtonWrapperProps = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const IconButtonWrapper: React.FC<IconButtonWrapperProps> = ({
  children,
  onClick,
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className="flex items-center justify-center w-10p h-10p bg-transparent bg-center bg-no-repeat cursor-pointer rounded-full border border-solid hover:bg-primary-gray dark:hover:bg-deals-card hover:border-primary-gray  dark:border-primary-dark"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
