import { AuctionPrize } from '@/features/market/auctions/models/Auctions';

interface AuctionPrizesCardProps {
  prizes: AuctionPrize[];
}

export const AuctionPrizesCard: React.FC<AuctionPrizesCardProps> = ({
  prizes,
}) => {
  return (
    <div className="text-left space-y-2 overflow-x-auto">
      <p className="text-2xl">Prizes</p>
      <div className="flex overflow-x-auto whitespace-nowrap space-x-4">
        {prizes.map(prize => {
          return (
            <div key={prize.id} className="inline-block relative">
              <div className="relative h-36 flex items-center justify-center bg-gray-100 dark:bg-dark-background overflow-hidden">
                <img
                  src={prize.imageUrl}
                  alt={prize.title}
                  className="min-w-36 w-36 min-h-36 h-36 object-cover rounded-2xl"
                />
                <div className="absolute bottom-0 w-full h-1/6 bg-container-background-light dark:bg-container-background-dark  border-fade-text-light dark:border-fade-text-light text-element-text-light dark:text-element-text-dark bg-opacity-70 dark:bg-opacity-70 flex items-center px-4 rounded-b-2xl">
                  <p className="text-sm overflow-hidden text-ellipsis font-semibold">
                    {prize.title}
                  </p>
                </div>
              </div>
              {prize.prizeChain && (
                <div className="absolute top-0 left-0 text-xs sm:text-sm bg-container-background-light dark:bg-container-background-dark border-fade-text-light dark:border-fade-text-light text-element-text-light dark:text-element-text-dark bg-opacity-70 dark:bg-opacity-70 px-2 m-2 rounded-xl">
                  {prize.prizeChain}
                </div>
              )}
              <div className="absolute top-0 right-0 text-xs sm:text-sm bg-container-background-light dark:bg-container-background-dark border-fade-text-light dark:border-fade-text-light text-element-text-light dark:text-element-text-dark bg-opacity-70 dark:bg-opacity-70 px-2 m-2 rounded-xl">
                {prize.numWinners} winners
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
