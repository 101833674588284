import { Cell, Pie, PieChart } from 'recharts';
import { AssetWalletPieChartData } from '../AssetHoldingsGraphCard';

type WalletAssetPieChartProps = {
  data: AssetWalletPieChartData[];
  activeIndex: number | null;
  handleClick: (index: number) => void;
};

export const WalletAssetPieChart: React.FC<WalletAssetPieChartProps> = ({
  data,
  activeIndex,
  handleClick,
}) => {
  return (
    <PieChart height={240} width={240}>
      <Pie
        data={data}
        dataKey="value"
        paddingAngle={2}
        cornerRadius={4}
        stroke="none"
        outerRadius={120}
        innerRadius={95}
        style={{ cursor: 'pointer' }}
      >
        {data?.map((entry, index) => {
          const { color = '#21ce99' } = entry;

          return (
            <Cell
              key={`cell-${index}`}
              fill={color}
              onClick={() => handleClick && handleClick(index)}
              fillOpacity={
                activeIndex === null || activeIndex === index ? '1' : '0.3'
              }
            />
          );
        })}
      </Pie>
    </PieChart>
  );
};
