import { PortfolioChartDatapoints } from './ChartResponse';
import { PortfolioChartDatapoint } from '../../portfolio/worth/models/CategoryWorthModel';

export class AssetHoldingChartSeries {
  all?: AssetHoldingChartItem[];
  day1Items?: AssetHoldingChartItem[];
  week1Items?: AssetHoldingChartItem[];
  month1Items?: AssetHoldingChartItem[];
  month3Items?: AssetHoldingChartItem[];
  year1Items?: AssetHoldingChartItem[];

  constructor(
    all?: AssetHoldingChartItem[],
    day1Items?: AssetHoldingChartItem[],
    week1Items?: AssetHoldingChartItem[],
    month1Items?: AssetHoldingChartItem[],
    month3Items?: AssetHoldingChartItem[],
    year1Items?: AssetHoldingChartItem[],
  ) {
    this.all = all;
    this.day1Items = day1Items;
    this.week1Items = week1Items;
    this.month1Items = month1Items;
    this.month3Items = month3Items;
    this.year1Items = year1Items;
  }

  static fromJSON(json: any): AssetHoldingChartSeries {
    return new AssetHoldingChartSeries(
      json.all?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
      json.day_1?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
      json.week_1?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
      json.month_1?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
      json.month_3?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
      json.year_1?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
    );
  }

  toJSON(): any {
    let result: any = {};
    if (this.all) {
      result.all = this.all.map(v => v.toJSON());
    }
    if (this.day1Items) {
      result.day_1 = this.day1Items.map(v => v.toJSON());
    }
    if (this.week1Items) {
      result.week_1 = this.week1Items.map(v => v.toJSON());
    }
    if (this.month1Items) {
      result.month_1 = this.month1Items.map(v => v.toJSON());
    }
    if (this.month3Items) {
      result.month_3 = this.month3Items.map(v => v.toJSON());
    }
    if (this.year1Items) {
      result.year_1 = this.year1Items.map(v => v.toJSON());
    }

    return result;
  }

  private _mapValuePoints(
    items?: AssetHoldingChartItem[],
  ): PortfolioChartDatapoints {
    const points =
      items?.map(
        element =>
          new PortfolioChartDatapoint(
            new Date(element.timestampDatetime || element.day || ''),
            element.value || 0,
          ),
      ) || [];
    return new PortfolioChartDatapoints(points);
  }

  private _mapQuantityPoints(
    items?: AssetHoldingChartItem[],
  ): PortfolioChartDatapoints {
    const points =
      items?.map(
        element =>
          new PortfolioChartDatapoint(
            new Date(element.timestampDatetime || element.day || ''),
            element.quantity || 0,
          ),
      ) || [];
    return new PortfolioChartDatapoints(points);
  }

  getAllValuePoints(): PortfolioChartDatapoints {
    return this._mapValuePoints(this.all);
  }

  get1DayValuePoints(): PortfolioChartDatapoints {
    return this._mapValuePoints(this.day1Items);
  }

  get1WeekValuePoints(): PortfolioChartDatapoints {
    return this._mapValuePoints(this.week1Items);
  }

  get1MonthValuePoints(): PortfolioChartDatapoints {
    return this._mapValuePoints(this.month1Items);
  }

  get3MonthValuePoints(): PortfolioChartDatapoints {
    return this._mapValuePoints(this.month3Items);
  }

  get1YearValuePoints(): PortfolioChartDatapoints {
    return this._mapValuePoints(this.year1Items);
  }

  getAllQuantityPoints(): PortfolioChartDatapoints {
    return this._mapQuantityPoints(this.all);
  }

  get1DayQuantityPoints(): PortfolioChartDatapoints {
    return this._mapQuantityPoints(this.day1Items);
  }

  get1WeekQuantityPoints(): PortfolioChartDatapoints {
    return this._mapQuantityPoints(this.week1Items);
  }

  get1MonthQuantityPoints(): PortfolioChartDatapoints {
    return this._mapQuantityPoints(this.month1Items);
  }

  get3MonthQuantityPoints(): PortfolioChartDatapoints {
    return this._mapQuantityPoints(this.month3Items);
  }

  get1YearQuantityPoints(): PortfolioChartDatapoints {
    return this._mapQuantityPoints(this.year1Items);
  }
}

export class AssetHoldingChart {
  items?: AssetHoldingChartItem[];

  constructor(items?: AssetHoldingChartItem[]) {
    this.items = items;
  }

  static fromJSON(json: any): AssetHoldingChart {
    return new AssetHoldingChart(
      json.items?.map((v: any) => AssetHoldingChartItem.fromJSON(v)),
    );
  }

  toJSON(): any {
    return {
      items: this.items?.map(v => v.toJSON()),
    };
  }
}

export class AssetHoldingChartItem {
  timestampDatetime?: string;
  day?: string;
  assetId?: string;
  price?: number;
  quantity?: number;
  value?: number;

  constructor(
    timestampDatetime?: string,
    day?: string,
    assetId?: string,
    price?: number,
    quantity?: number,
    value?: number,
  ) {
    this.timestampDatetime = timestampDatetime;
    this.day = day;
    this.assetId = assetId;
    this.price = price;
    this.quantity = quantity;
    this.value = value;
  }

  static fromJSON(json: any): AssetHoldingChartItem {
    return new AssetHoldingChartItem(
      json.timestamp_datetime,
      json.day,
      json.asset_id,
      json.price,
      json.quantity,
      json.value,
    );
  }

  toJSON(): any {
    return {
      timestamp_datetime: this.timestampDatetime,
      day: this.day,
      asset_id: this.assetId,
      price: this.price,
      quantity: this.quantity,
      value: this.value,
    };
  }

  copyWith({
    timestampDatetime,
    day,
    assetId,
    price,
    quantity,
    value,
  }: Partial<AssetHoldingChartItem>): AssetHoldingChartItem {
    return new AssetHoldingChartItem(
      timestampDatetime || this.timestampDatetime,
      day || this.day,
      assetId || this.assetId,
      price || this.price,
      quantity || this.quantity,
      value || this.value,
    );
  }
}
