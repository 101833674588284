import { useThemeState } from '@/recoil/theme';

export const OptionsTab = ({ tabs, activeTab, onClick }) => {
  const [theme] = useThemeState();
  const backgroundTabs = theme === 'light' ? 'bg-white' : 'bg-primary-dark';
  const border = theme === 'light' ? '' : 'border-bg';

  const tabKeys = Object.keys(tabs);
  return (
    <div
      className={`flex w-fit p-0.5 space-x-2 dark:text-gray-text ${border} rounded-3xl font-semibold py-1 px-2`}
    >
      {tabKeys.map(key => (
        <div
          className={`
              ${
                activeTab === key &&
                `${backgroundTabs} shadow-tab-shadow text-price-green`
              } text-sm leading-15 rounded-xl cursor-pointer font-bold h-6 flex justify-center items-center p-4 whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
          onClick={() => onClick(key)}
          key={key}
        >
          {tabs[key]}
        </div>
      ))}
    </div>
  );
};
