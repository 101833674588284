import { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';
import { getUserTokenRewards, SwapCenterResponse } from '../api/swap';

interface SwapCenterContextProps {
  load: () => Promise<void>;
  clearSwapCenter: () => void;
  swapCenterData?: SwapCenterResponse;
  isLoading: boolean;
}

export const SwapCenterContext = createContext<
  SwapCenterContextProps | undefined
>(undefined);

export const useSwapCenter = (): SwapCenterContextProps => {
  const context = useContext(SwapCenterContext);
  if (!context) {
    throw new Error('useSwapCenter must be used within a SwapCenterProvider');
  }
  return context;
};

export const SwapCenterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [swapCenterData, setSwapCenterData] = useState<SwapCenterResponse>();
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setIsLoading(true);
    try {
      const response = await getUserTokenRewards();
      setSwapCenterData(response.data);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearSwapCenter = () => {
    setSwapCenterData(undefined);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <SwapCenterContext.Provider
      value={{
        load,
        clearSwapCenter,
        swapCenterData,
        isLoading,
      }}
    >
      {children}
    </SwapCenterContext.Provider>
  );
};
