import { useEffect } from 'react';

import { useInbox } from '../../hooks/useInbox';

import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';

import { useParams, useNavigate } from 'react-router-dom';

export const InboxMessageDetailsContainer = () => {
  const navigate = useNavigate();
  const { isLoading, getUserInboxUserMessageDetail, inboxMessageDetail } =
    useInbox();

  const { messageId } = useParams();

  useEffect(() => {
    if (messageId) {
      loadMessage(messageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);

  const loadMessage = async (messageId: string) => {
    try {
      await getUserInboxUserMessageDetail(messageId);
    } catch (err) {
      navigate('/inbox');
    }
  };

  const formatWebsiteUrl = (url: string) => {
    if (!url) return '';

    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }

    if (url.startsWith('www.')) {
      return `https://${url}`;
    }

    return `https://www.${url}`;
  };

  return (
    <div className="text-element-text-light dark:text-element-text-dark">
      <h1 className="text-32 leading-none text-center mb-8">Message</h1>
      {isLoading || !inboxMessageDetail ? (
        <SkeletonLoader height={300} />
      ) : (
        <>
          {/* For small screens */}
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-8 px-4">
            <div className="md:hidden flex items-start space-x-4 px-4">
              <div className="flex-shrink-0 w-1/5">
                <img
                  src={inboxMessageDetail.imageUrl!}
                  className="w-full h-24 object-contain"
                  alt="logo"
                />
              </div>
              <div className="space-y-2">
                <div className="font-semibold text-lg">
                  {inboxMessageDetail.title}
                </div>
                <div className="flex flex-wrap items-center space-x-2 mb-2">
                  {inboxMessageDetail.tag && (
                    <div className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-white dark:border-card-color rounded-full">
                      <span className="px-2 text-xs">
                        {inboxMessageDetail.tag.name}
                      </span>
                    </div>
                  )}
                  <div className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-white dark:bg-card-color border-white dark:border-card-color rounded-full">
                    <span className="px-2 text-xs">
                      {inboxMessageDetail.getCreatedDay()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Hide image, title, tag, and created on small screens since the code above handles that */}
            <div className="hidden md:flex md:col-span-1 lg:col-span-2 bg-white p-3 dark:bg-deals-card rounded-13 h-max">
              <img
                src={inboxMessageDetail.imageUrl!}
                className="w-full h-96 object-contain mx-auto"
                alt="logo"
              />
            </div>
            <div className="text-center md:col-span-3 lg:col-span-3 space-y-4">
              <div className="hidden md:flex flex-row space-x-2 justify-between items-center">
                <div className={`font-semibold text-left text-3xl truncate`}>
                  {inboxMessageDetail.title}
                </div>
              </div>
              <div className="hidden md:flex space-x-2 mb-2">
                {inboxMessageDetail.tag && (
                  <div className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-white dark:border-card-color rounded-full">
                    <span className="px-2 text-sm">
                      {inboxMessageDetail.tag.name}
                    </span>
                  </div>
                )}
                <div className="hidden md:inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-white dark:bg-card-color border-white dark:border-card-color rounded-full">
                  <span className="px-2 text-sm">
                    {inboxMessageDetail.getCreatedDay()}
                  </span>
                </div>
              </div>
              <div className="text-left">{inboxMessageDetail.description}</div>

              {inboxMessageDetail.instructions &&
                inboxMessageDetail.instructions.length > 0 && (
                  <div className="mt-2 bg-white dark:bg-deals-card-top p-4 rounded-xl">
                    <div>
                      <div className="space-y-4">
                        <p className="text-left text-xl font-bold">
                          Instructions
                        </p>
                        <div className="space-y-2">
                          {inboxMessageDetail.instructions &&
                            inboxMessageDetail.instructions.length > 0 &&
                            inboxMessageDetail.instructions.map(
                              (instruction, index) => {
                                return (
                                  <div
                                    key={instruction}
                                    className="flex items-center space-x-2"
                                  >
                                    <p className="min-w-6 text-black bg-price-green rounded-full">
                                      {index + 1}
                                    </p>
                                    <p className="text-left">{instruction}</p>
                                  </div>
                                );
                              },
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {inboxMessageDetail.appScreen && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'screen',
                        ctaId: inboxMessageDetail?.appScreen ?? '',
                      });
                      navigate(`/${inboxMessageDetail.appScreen}`);
                    }}
                  >
                    <div className="text-black text-2xl ">View</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.websiteUrl && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'websiteUrl',
                        ctaId: inboxMessageDetail?.websiteUrl ?? '',
                      });
                      window.open(
                        formatWebsiteUrl(inboxMessageDetail.websiteUrl!),
                      );
                    }}
                  >
                    <div className="text-black text-2xl ">View Website</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.deal?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'deal',
                        ctaId: inboxMessageDetail?.websiteUrl ?? '',
                      });
                      navigate(`/deals/${inboxMessageDetail.deal?.id}`);
                    }}
                  >
                    <div className="text-black text-2xl">View Deal</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.assetdashExpedition?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'assetdash_expedition',
                        ctaId: inboxMessageDetail.assetdashExpedition?.id ?? '',
                      });
                      navigate(
                        `/expeditions/assetdash/${inboxMessageDetail.assetdashExpedition?.id}`,
                      );
                    }}
                  >
                    <div className="text-black text-2xl">View Expedition</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.partnerExpedition?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'partner_expedition',
                        ctaId: inboxMessageDetail.partnerExpedition?.id ?? '',
                      });
                      navigate(
                        `/expeditions/ecosystem/${inboxMessageDetail.partnerExpedition?.id}`,
                      );
                    }}
                  >
                    <div className="text-black text-2xl">View Expedition</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.arcadeGame?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'arcade_game',
                        ctaId: inboxMessageDetail.arcadeGame?.id ?? '',
                      });
                      navigate(
                        `/games/arcade/${inboxMessageDetail.arcadeGame?.id}`,
                      );
                    }}
                  >
                    <div className="text-black text-2xl">View Game</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.partnerGame?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'partner_game',
                        ctaId: inboxMessageDetail.partnerGame?.id ?? '',
                      });
                      navigate(
                        `/games/ecosystem/${inboxMessageDetail.partnerGame?.id}`,
                      );
                    }}
                  >
                    <div className="text-black text-2xl">View Game</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.learnCourse?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'learn',
                        ctaId: inboxMessageDetail.learnCourse?.id ?? '',
                      });
                      navigate(`/learn/${inboxMessageDetail.learnCourse?.id}`);
                    }}
                  >
                    <div className="text-black text-2xl">View Learn</div>
                  </button>
                </div>
              )}

              {inboxMessageDetail.auction?.id && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      analytics.track('inbox_message_click_cta', {
                        inboxMessageId: messageId,
                        ctaType: 'auction',
                        ctaId: inboxMessageDetail.auction?.id ?? '',
                      });
                      navigate(`/auctions/${inboxMessageDetail.auction?.id}`);
                    }}
                  >
                    <div className="text-black text-2xl">View Auction</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
