// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import {
  DocumentDuplicateIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

// recoil
import { useThemeState } from '@/recoil/theme';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';

// components
import { PrizeWalletInfoModal } from './PrizeWalletInfoModal';
import { ConfirmSetPrizeWalletModal } from './ConfirmSetPrizeWalletModal';

interface WalletSettingsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  wallet: Wallet;
  openConfirmRemoveWalletModal: () => void;
}

export const WalletSettingsModal: React.FC<WalletSettingsModalProps> = ({
  isOpen,
  closeModal,
  wallet,
  openConfirmRemoveWalletModal,
}) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const buttonBackground =
    theme === 'light' ? 'bg-primary-gray' : 'bg-blue-dark';
  const buttonText = theme === 'light' ? 'text-black' : 'text-white';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const { syncWallet, updateWalletName } = usePortfolio();
  const [walletName, setWalletName] = useState(wallet.name);

  const [showPrizeWalletModal, setShowPrizeWalletModal] = useState(false);
  const [showConfirmSetPrizeWalletModal, setShowConfirmSetPrizeWalletModal] =
    useState(false);

  useEffect(() => {
    if (wallet) {
      setWalletName(wallet.name);
    }
  }, [wallet]);

  const valid = walletName && walletName !== wallet.name;

  const copyAddress = () => {
    navigator.clipboard.writeText(wallet.address!);
    closeModal();
    toast.success('Address copied to clipboard');
  };

  const saveEditedWallet = async () => {
    if (valid && walletName && walletName !== wallet.name) {
      closeModal();
      await updateWalletName(wallet.id!, walletName);
      toast.success('Wallet updated successfully');
    } else {
      toast.error('Enter a valid wallet name');
    }
  };

  const onClickSyncWallet = async () => {
    closeModal();
    try {
      await syncWallet(wallet.id!);
      toast.success('Wallet sync started');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const removeWallet = () => {
    closeModal();
    openConfirmRemoveWalletModal();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${background}`}
              >
                <div className={`${textColor}`}>
                  <div className=" text-center">
                    <Dialog.Title
                      as="h3"
                      className={`text-xl font-semibold leading-6 text-left`}
                    >
                      <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-semibold ">Edit Wallet</h2>

                        <button
                          onClick={closeModal}
                          className="font-semibold text-sm text-price-red"
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Title>

                    <div className="mt-12 space-y-2">
                      <p
                        className={`text-10 font-semibold text-gray-text text-left ml-2 ${textColor}`}
                      >
                        Wallet Name
                      </p>
                      <input
                        type="text"
                        value={walletName}
                        onChange={e => {
                          setWalletName(e.target.value);
                        }}
                        className={`w-full rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-3 border border-solid border-tabs-bg ${background} ${textColor}`}
                      />
                    </div>

                    {wallet.address && (
                      <div className="mt-4 space-y-2">
                        <p
                          className={`text-10 font-semibold text-gray-text text-left ml-2 ${textColor}`}
                        >
                          Wallet Address
                        </p>
                        <div
                          className={`flex items-center text-xs font-semibold pl-4 pr-2 py-3 border border-solid border-tabs-bg rounded-xl text-left ${textColor}`}
                        >
                          <span>{wallet?.address}</span>
                          <DocumentDuplicateIcon
                            className="cursor-pointer ml-auto h-4 w-4"
                            onClick={copyAddress}
                          />
                        </div>
                      </div>
                    )}
                    {wallet.walletType !== 'manual' && (
                      <div className="flex justify-between mt-2">
                        <div>
                          {wallet.isVerified &&
                            (wallet.walletType === 'zapper' ||
                              wallet.walletType === 'solana') && (
                              <div className="flex items-center space-x-1">
                                <InformationCircleIcon
                                  className="text-price-red cursor-pointer"
                                  width="20"
                                  height="20"
                                  onClick={() => setShowPrizeWalletModal(true)}
                                />
                                <div>Prize Wallet</div>
                                <input
                                  type="checkbox"
                                  className={`rounded-sm w-4 h-4 accent-price-green ${
                                    wallet.isPrizeWallet ? '' : 'cursor-pointer'
                                  }`}
                                  checked={wallet.isPrizeWallet}
                                  onChange={() => {
                                    if (!wallet.isPrizeWallet) {
                                      setShowConfirmSetPrizeWalletModal(true);
                                    }
                                  }}
                                />
                              </div>
                            )}
                        </div>
                        <div
                          className="cursor-pointer flex items-center justify-between space-x-2"
                          onClick={onClickSyncWallet}
                        >
                          <p className={textColor}>Synchronise</p>
                          <ArrowPathIcon
                            className={`cursor-pointer ml-auto h-4 w-4`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-center space-x-4  mt-10 text-right">
                  <button
                    type="button"
                    className={`w-60 py-4 px-8 leading-17 font-semibold text-sm rounded-xl bg-primary-red ${buttonText}`}
                    onClick={removeWallet}
                  >
                    Remove Wallet
                  </button>
                  <button
                    type="button"
                    disabled={!valid}
                    className={`w-60 py-4 px-8 transition delay-100 ease-in leading-17 font-semibold text-sm rounded-xl  ${
                      valid
                        ? 'bg-price-green'
                        : `${buttonBackground} ${buttonText}`
                    }`}
                    onClick={() => saveEditedWallet()}
                  >
                    <div>Save</div>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <PrizeWalletInfoModal
            isOpen={showPrizeWalletModal}
            closeModal={() => {
              setShowPrizeWalletModal(false);
              closeModal();
            }}
          />
          <ConfirmSetPrizeWalletModal
            isOpen={showConfirmSetPrizeWalletModal}
            closeModal={() => {
              setShowConfirmSetPrizeWalletModal(false);
              closeModal();
            }}
            wallet={wallet}
          />
        </div>
      </Dialog>
    </Transition.Root>
  );
};
