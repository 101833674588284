import { axiosAssetDashApi } from './api';

export const postGenerateCoupon = async (dealId: string) => {
  return await axiosAssetDashApi.post(
    `api/api_v4/deals/user_deals/${dealId}/generate_coupon`,
    { dealId },
  );
};

export const getDeals = async (dealId: string, page: number) => {
  let url = 'api/api_v4/deals/user_deals?';
  if (dealId) {
    url += `tag_ids=${dealId}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  return await axiosAssetDashApi.get(url);
};

export const getPopularDeals = async (
  category: string,
  orderKey: string,
  topTier: boolean,
) => {
  let apiUrl = `api/api_v4/deals/user_deals?`;
  if (topTier) {
    apiUrl += `top_tier=${topTier}`;
  } else if (category) {
    apiUrl += `tag_ids=${category}`;
  } else if (orderKey) {
    apiUrl += `order_key=${orderKey}`;
  }
  return await axiosAssetDashApi.get(apiUrl);
};

export const getRaffleDeals = async (category: string) => {
  return await axiosAssetDashApi.get(
    `api/api_v4/deals/user_deals?category=${category}`,
  );
};

export const getDealDetails = async (dealId: string) => {
  return await axiosAssetDashApi.get(`api/api_v4/deals/user_deals/${dealId}`);
};

export const fetchCompetition = async () => {
  return await axiosAssetDashApi.get(`api/api_v4/deals/deals_waitlist_assets`);
};

export const getDealsTag = async () => {
  return await axiosAssetDashApi.get('api/api_v4/deals/store_tags');
};

export const getCommunityPastWaitlist = async () => {
  return await axiosAssetDashApi.get(
    'api/api_v4/deals/deals_waitlist_asset_past',
  );
};

export const getDealAssets = async () => {
  return await axiosAssetDashApi.get('api/api_v4/deals/deals_assets');
};

export const getMemberCenterData = async () => {
  return await axiosAssetDashApi.get(`api/api_v4/users/member-center`);
};

export const getWeeklyRaffles = async () => {
  return await axiosAssetDashApi.get(
    `api/api_v3/raffles/current_weekly_raffle`,
  );
};

export const postFortuneCookie = async () => {
  return await axiosAssetDashApi.post(`api/api_v4/raffles/open_fortune_cookie`);
};

export const getFortuneCookie = async () => {
  return await axiosAssetDashApi.get(
    `api/api_v4/raffles/current_fortune_cookie`,
  );
};

export const getGreatestGiveaway = async () => {
  return await axiosAssetDashApi.get(
    `/api/api_v3/raffles/current_greatest_giveaway`,
  );
};

export const postClaimRaffleTickets = async () => {
  return await axiosAssetDashApi.post(
    `api/api_v3/raffles/claim_raffle_tickets`,
  );
};

export const getWinningTickets = async () => {
  return await axiosAssetDashApi.get(`api/api_v3/raffles/winning_tickets`);
};

export const getMyOrders = async () => {
  return await axiosAssetDashApi.get(`web/deals/user_purchases`);
};

export const getYourPrizes = async () => {
  return await axiosAssetDashApi.get(`web/raffles/user_prizes`);
};

export const postClaimPrize = async (id: string) => {
  return await axiosAssetDashApi.post('web/raffles/claim_user_prize', {
    user_prize_id: id,
  });
};

export const getRaffleCenterData = async () => {
  return await axiosAssetDashApi.get(`api/api_v4/users/raffle-center`);
};

export const getPastPrizes = async () => {
  return await axiosAssetDashApi.get(`api/api_v3/raffles/past_raffles`);
};

export const getPastPrizesDetails = async (id: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v3/raffles/past_raffle?raffle_id=${id}`,
  );
};

export const getDealsCenter = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/deals-center`);
};

export const getMyRewards = async () => {
  return await axiosAssetDashApi.get(`api/api_v3/users/my-rewards`);
};

export const getSyncCenterData = async () => {
  return await axiosAssetDashApi.get(`api/api_v4/users/sync-center`);
};

export const postPortfolioSync = async () => {
  return await axiosAssetDashApi.post(
    `api/api_v3/users/trigger-portfolio-sync`,
  );
};

export const getClaimedTickets = async () => {
  return await axiosAssetDashApi.get(`api/api_v4/deals/user_coupons`);
};
