import React, { useEffect, useState, useMemo } from 'react';
import { OptionsTab } from '@/components/v2/common/Tab/OptionsTab';
import TokenRewardTab from './tabs/TokenRewardTab';
import WithdrawalTab from './tabs/WithdrawalTab';
import { useSwapCenter } from '@/hooks/useSwapCenter';

export const TokenRewardContainer: React.FC = () => {
  const { load, swapCenterData, isLoading } = useSwapCenter();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTab, setSelectedTab] = useState('rewards');
  const modalTabs: { [key: string]: string } = {
    rewards: 'Rewards',
    withdrawals: 'Withdrawals',
  };

  const sortedRewards = useMemo(() => {
    if (!swapCenterData?.swap_center.swap_rewards) return [];
    return [...swapCenterData.swap_center.swap_rewards].sort((a, b) => {
      const percentageA =
        a.current_amount && a.withdrawal_threshold
          ? (a.current_amount * 100) / a.withdrawal_threshold
          : 0;
      const percentageB =
        b.current_amount && b.withdrawal_threshold
          ? (b.current_amount * 100) / b.withdrawal_threshold
          : 0;
      return percentageB - percentageA;
    });
  }, [swapCenterData?.swap_center.swap_rewards]);

  const sortedWithdrawals = useMemo(() => {
    return swapCenterData?.swap_center.swap_reward_withdrawals || [];
  }, [swapCenterData?.swap_center.swap_reward_withdrawals]);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <div className="py-2 sm:py-5 md:px-6 flex items-center justify-center">
        <OptionsTab
          tabs={modalTabs}
          activeTab={selectedTab}
          onClick={(key: string) => setSelectedTab(key)}
        />
      </div>
      <div className={`${selectedTab === 'rewards' ? '' : 'hidden'}`}>
        <TokenRewardTab rewards={sortedRewards} isLoading={isLoading} />
      </div>
      <div className={`${selectedTab === 'withdrawals' ? '' : 'hidden'}`}>
        <WithdrawalTab withdrawals={sortedWithdrawals} isLoading={isLoading} />
      </div>
    </div>
  );
};
