import React from 'react';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { CardCenter } from '@/features/cards/models/CardCenter';

interface ElementsStatsCardProps {
  cardCenter: CardCenter | undefined;
}

const ElementsStatsCard: React.FC<ElementsStatsCardProps> = ({
  cardCenter,
}) => {
  if (!cardCenter) {
    return <SkeletonLoader height={150} />;
  }

  return (
    <div className="card p-6 rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-element-text-light dark:text-element-text-dark">
        My Status
      </h2>
      <div className="text-lg mb-2 flex justify-between">
        <span className="text-element-text-light dark:text-element-text-dark">
          Rewards Multiplier:
        </span>
        <span className="text-price-green">{cardCenter.totalMultiplier}x</span>
      </div>
      <div className="text-lg mb-2 flex justify-between">
        <span className="text-element-text-light dark:text-element-text-dark">
          Total Elements:
        </span>
        <span className="text-element-text-light dark:text-element-text-dark">
          {cardCenter.totalElementCards}
        </span>
      </div>
      <div className="text-lg mb-2 flex justify-between">
        <span className="text-primary-red">Listed Elements:</span>
        <span className="text-primary-red">
          {cardCenter.listedElementCards}
        </span>
      </div>
    </div>
  );
};

export default ElementsStatsCard;
