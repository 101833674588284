// vendor libraries
import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { Toggle } from '../../components/common/Toggle';

// hooks
import { useNotificationSettings } from '../../hooks/useNotificationSettings';

import { NotificationSettings } from '../../features/settings/models/NotificationSettings';

export default function NotificationSettingsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { settings, fetch, update } = useNotificationSettings();
  const [localSettings, setLocalSettings] = useState<NotificationSettings>();

  useEffect(() => {
    const getSettings = async () => {
      await fetch();
    };

    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set initial value for localSettings after settings is fetched
    if (settings && !localSettings) {
      setLocalSettings(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const updateSettings = async (setting: string, value: boolean) => {
    // Update local state immediately for responsive UI
    const newSettings = localSettings!.copyWith({
      ...localSettings!,
      [setting]: value,
    });
    setLocalSettings(newSettings);

    await update(newSettings);
  };

  return (
    <>
      <SuperSEO title={`AssetDash | Notifications`} lang="en_IE" />
      <div className="flex rounded-xl justify-center items-center text-lg pb-2">
        <button
          className="progressier-install-button border border-solid border-border-bg dark:border-primary-dark rounded-xl text-price-green p-2"
          data-icons="true"
          data-install="Install the app"
          data-installed="Launch the app"
        ></button>
      </div>
      <div className="flex rounded-xl justify-center items-center text-lg">
        <button
          className="progressier-subscribe-button border border-solid border-border-bg dark:border-primary-dark rounded-xl text-price-green p-2"
          data-icons="true"
          data-eligible="Subscribe to Notifications"
          data-subscribed="Subscribed to Notifications"
          data-blocked="Notifications Blocked"
        ></button>
      </div>
      <h1 className="text-32 leading-none text-center mt-8 mb-8">
        Notifications
      </h1>
      <div className="flex justify-center">
        <div className="flex flex-col space-y-4 w-full md:w-1/2 px-8">
          <div className="text-2xl text-accent pb-4 text-center">
            Portfolio Updates
          </div>
          {localSettings && (
            <>
              <div className="flex rounded-xl justify-between items-center text-lg">
                <div>Morning push</div>
                <Toggle
                  id="morning-push"
                  handleToggle={() =>
                    updateSettings(
                      'portfolioUpdateMorning',
                      !localSettings!.portfolioUpdateMorning,
                    )
                  }
                  isChecked={localSettings!.portfolioUpdateMorning}
                />
              </div>
              <div className="flex rounded-xl justify-between items-center text-lg">
                <div>Evening push</div>
                <Toggle
                  id="evening-push"
                  handleToggle={() =>
                    updateSettings(
                      'portfolioUpdateEvening',
                      !localSettings!.portfolioUpdateEvening,
                    )
                  }
                  isChecked={localSettings!.portfolioUpdateEvening}
                />
              </div>
              <div className="flex rounded-xl justify-between items-center text-lg">
                <div>Top Gainer</div>
                <Toggle
                  id="top-gainer"
                  handleToggle={() =>
                    updateSettings(
                      'portfolioUpdateTopGainer',
                      !localSettings!.portfolioUpdateTopGainer,
                    )
                  }
                  isChecked={localSettings!.portfolioUpdateTopGainer}
                />
              </div>
              <div className="flex rounded-xl justify-between items-center text-lg">
                <div>NFTs</div>
                <Toggle
                  id="update-nft"
                  handleToggle={() =>
                    updateSettings(
                      'portfolioUpdateNFT',
                      !localSettings!.portfolioUpdateNFT,
                    )
                  }
                  isChecked={localSettings!.portfolioUpdateNFT}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
