interface SaveButtonProps {
  onClick: () => void;
  valid: boolean;
  isLoading: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  onClick,
  valid = true,
  isLoading = false,
}) => {
  return (
    <button
      className={`w-45.75 py-4 px-8 transition delay-100 ease-in leading-17 font-semibold text-sm rounded-xl text-primary-dark ${
        valid && !isLoading
          ? 'bg-price-green cursor-pointer'
          : 'bg-white dark:bg-tabs-bg'
      }`}
      disabled={!valid || isLoading}
      onClick={onClick}
    >
      {isLoading ? <div className="loader w-full"></div> : <div>Save</div>}
    </button>
  );
};
