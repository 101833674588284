import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useThemeState } from '@/recoil/theme';

export default function PastGiveawayModal({
  open,
  setOpen,
  pastResults,
  getPastResultDate,
}) {
  const navigate = useNavigate();
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';
  const rowBg = theme === 'light' ? 'bg-primary-gray' : 'bg-black';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${background} relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6`}
              >
                <div>
                  <div className="">
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon
                        height={24}
                        width={24}
                        className="text-price-red"
                      />
                    </span>
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl font-bold mb-4 ${textColor}`}
                    >
                      Past Giveaways
                    </Dialog.Title>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4 max-h-145 overflow-scroll scroller">
                      {pastResults.map(pastResult => (
                        <div
                          className={`${rowBg} cursor-pointer rounded-2xl p-4 md:p-6 flex flex-col md:flex-row gap-2`}
                          key={pastResult.id}
                          onClick={() =>
                            navigate(`/giveaways/${pastResult.id}`)
                          }
                        >
                          <div className="space-y-4 flex-1">
                            <p
                              className={`text-xl md:text-2xl font-bold ${textColor} leading-6 md:leading-9`}
                            >
                              {pastResult.title}
                            </p>
                            <p className="text-gray-text text-lg md:text-xl">
                              Date: {getPastResultDate(pastResult.date)}
                            </p>
                          </div>
                          <div className="flex-shrink-0 m-auto md:m-0">
                            <img
                              src={pastResult.image_url}
                              className="h-40 md:h-60 w-full object-contain"
                              alt="past results"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
