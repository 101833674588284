import { AppColors } from '../../../core/theming/colors';

export enum HoldingTypeGroup {
  stocks,
  options,
  cash,
  crypto,
  defi,
  nft,
  commodity,
  privateEquity,
  realEstate,
  collectibles,
  other,
}

export class HoldingTypeGroupInfo {
  static apiValue: { [key in HoldingTypeGroup]: string } = {
    [HoldingTypeGroup.stocks]: 'stocks',
    [HoldingTypeGroup.options]: 'options',
    [HoldingTypeGroup.cash]: 'cash',
    [HoldingTypeGroup.crypto]: 'crypto',
    [HoldingTypeGroup.defi]: 'defi',
    [HoldingTypeGroup.nft]: 'nft',
    [HoldingTypeGroup.commodity]: 'commodity',
    [HoldingTypeGroup.privateEquity]: 'private_equity',
    [HoldingTypeGroup.realEstate]: 'real_estate',
    [HoldingTypeGroup.collectibles]: 'collectibles',
    [HoldingTypeGroup.other]: 'other',
  };

  static holdingTypeGroupByApiValue(apiValue: string): HoldingTypeGroup | null {
    for (const [key, value] of Object.entries(HoldingTypeGroupInfo.apiValue)) {
      if (value === apiValue) {
        return key as unknown as HoldingTypeGroup;
      }
    }
    return null;
  }

  static title: { [key in HoldingTypeGroup]: string } = {
    [HoldingTypeGroup.stocks]: 'Stocks',
    [HoldingTypeGroup.options]: 'Options',
    [HoldingTypeGroup.cash]: 'Cash',
    [HoldingTypeGroup.crypto]: 'Crypto',
    [HoldingTypeGroup.defi]: 'DeFi',
    [HoldingTypeGroup.nft]: 'NFT',
    [HoldingTypeGroup.commodity]: 'Commodity',
    [HoldingTypeGroup.privateEquity]: 'Private Equity',
    [HoldingTypeGroup.realEstate]: 'Real Estate',
    [HoldingTypeGroup.collectibles]: 'Collectibles',
    [HoldingTypeGroup.other]: 'Other',
  };

  static color: { [key in HoldingTypeGroup]: Color } = {
    [HoldingTypeGroup.stocks]: AppColors.portfolioEquity(),
    [HoldingTypeGroup.options]: AppColors.portfolioDerivative(),
    [HoldingTypeGroup.cash]: AppColors.portfolioCurrency(),
    [HoldingTypeGroup.crypto]: AppColors.portfolioCryptoToken(),
    [HoldingTypeGroup.defi]: AppColors.portfolioCryptoProtocol(),
    [HoldingTypeGroup.nft]: AppColors.portfolioNft(),
    [HoldingTypeGroup.commodity]: AppColors.portfolioMetals(),
    [HoldingTypeGroup.privateEquity]: AppColors.portfolioPrivateEquity(),
    [HoldingTypeGroup.realEstate]: AppColors.portfolioRealEstate(),
    [HoldingTypeGroup.collectibles]: AppColors.portfolioCollectible(),
    [HoldingTypeGroup.other]: AppColors.portfolioOther(),
  };
}
