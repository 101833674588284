export class Wallet {
  id?: string;
  name?: string;
  address?: string;
  totalValue?: number;
  portfolioAllocation?: number;
  walletType?: string;
  logoUrl?: string;
  lastSyncTimestamp?: string;
  status?: string;
  calculationStatus?: string;
  readyStatus?: string;
  syncStatus?: string;
  providerId?: string;
  isVerified?: boolean;
  isPrizeWallet?: boolean;
  dealsEligible?: string;
  dealsVerified?: string;

  constructor(data?: {
    id?: string;
    name?: string;
    address?: string;
    totalValue?: number;
    portfolioAllocation?: number;
    walletType?: string;
    logoUrl?: string;
    lastSyncTimestamp?: string;
    status?: string;
    calculationStatus?: string;
    readyStatus?: string;
    syncStatus?: string;
    providerId?: string;
    isVerified?: boolean;
    isPrizeWallet?: boolean;
    dealsEligible?: string;
    dealsVerified?: string;
  }) {
    this.id = data?.id;
    this.name = data?.name;
    this.address = data?.address;
    this.totalValue = data?.totalValue;
    this.portfolioAllocation = data?.portfolioAllocation;
    this.walletType = data?.walletType;
    this.logoUrl = data?.logoUrl;
    this.lastSyncTimestamp = data?.lastSyncTimestamp;
    this.status = data?.status;
    this.calculationStatus = data?.calculationStatus;
    this.readyStatus = data?.readyStatus;
    this.syncStatus = data?.syncStatus;
    this.providerId = data?.providerId;
    this.isVerified = data?.isVerified;
    this.isPrizeWallet = data?.isPrizeWallet;
    this.dealsEligible = data?.dealsEligible;
    this.dealsVerified = data?.dealsVerified;
  }

  static fromJSON(json: any): Wallet {
    return new Wallet({
      id: json['id'],
      name: json['name'],
      address: json['address'],
      totalValue: json['total_value'],
      portfolioAllocation: json['portfolio_allocation'],
      walletType: json['wallet_type'],
      logoUrl: json['logo_url'],
      lastSyncTimestamp: json['last_sync_timestamp'],
      status: json['status'],
      calculationStatus: json['calculation_status'],
      readyStatus: json['ready_status'],
      syncStatus: json['sync_status'],
      providerId: json['provider_id'],
      isVerified: json['is_verified'],
      isPrizeWallet: json['is_prize_wallet'],
      dealsEligible: json['deals_eligible_status'],
      dealsVerified: json['deals_verified_status'],
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      address: this.address,
      totalValue: this.totalValue,
      portfolioAllocation: this.portfolioAllocation,
      walletType: this.walletType,
      logoUrl: this.logoUrl,
      lastSyncTimestamp: this.lastSyncTimestamp,
      status: this.status,
      calculationStatus: this.calculationStatus,
      readyStatus: this.readyStatus,
      syncStatus: this.syncStatus,
      providerId: this.providerId,
      isVerified: this.isVerified,
      isPrizeWallet: this.isPrizeWallet,
      dealsEligible: this.dealsEligible,
      dealsVerified: this.dealsVerified,
    };
  }

  copyWith(data: {
    id?: string;
    name?: string;
    address?: string;
    totalValue?: number;
    portfolioAllocation?: number;
    walletType?: string;
    logoUrl?: string;
    lastSyncTimestamp?: string;
    status?: string;
    calculationStatus?: string;
    readyStatus?: string;
    syncStatus?: string;
    providerId?: string;
    isVerified?: boolean;
    isPrizeWallet?: boolean;
    dealsEligible?: string;
    dealsVerified?: string;
  }): Wallet {
    return new Wallet({
      id: data.id || this.id,
      name: data.name || this.name,
      address: data.address || this.address,
      totalValue: data.totalValue || this.totalValue,
      portfolioAllocation: data.portfolioAllocation || this.portfolioAllocation,
      walletType: data.walletType || this.walletType,
      logoUrl: data.logoUrl || this.logoUrl,
      lastSyncTimestamp: data.lastSyncTimestamp || this.lastSyncTimestamp,
      status: data.status || this.status,
      calculationStatus: data.calculationStatus || this.calculationStatus,
      readyStatus: data.readyStatus || this.readyStatus,
      syncStatus: data.syncStatus || this.syncStatus,
      providerId: data.providerId || this.providerId,
      isVerified: data.isVerified || this.isVerified,
      isPrizeWallet: data.isPrizeWallet || this.isPrizeWallet,
      dealsEligible: data.dealsEligible || this.dealsEligible,
      dealsVerified: data.dealsVerified || this.dealsVerified,
    });
  }

  getAddressDisplay(): string {
    if (this.address && this.address.length > 10) {
      return `${this.address.substring(0, 6)}...${this.address.substring(
        this.address.length - 4,
      )}`;
    } else {
      return this.address || '';
    }
  }
}
