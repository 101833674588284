export class NFTListing {
  timestamp?: string;
  nftUrl?: string;
  nftName?: string;
  nftTokenAddress?: string;
  nftChain?: string;
  nftChainLogo?: string;
  marketplace?: string;
  marketplaceLogo?: string;
  activityType?: string;
  price?: number;
  sellerAddress?: string;

  constructor(data?: Partial<NFTListing>) {
    Object.assign(this, data);
  }

  static fromJSON(json: any): NFTListing {
    return new NFTListing({
      timestamp: json['timestamp'],
      nftUrl: json['nft_url'],
      nftName: json['nft_name'],
      nftTokenAddress: json['nft_token_address'],
      nftChain: json['nft_chain'],
      nftChainLogo: json['nft_chain_logo'],
      marketplace: json['marketplace'],
      marketplaceLogo: json['marketplace_logo'],
      activityType: json['activity_type'],
      price: json['price'],
      sellerAddress: json['seller_address'],
    });
  }

  toJSON(): any {
    return {
      timestamp: this.timestamp,
      nft_url: this.nftUrl,
      nft_name: this.nftName,
      nft_token_address: this.nftTokenAddress,
      nft_chain: this.nftChain,
      nft_chain_logo: this.nftChainLogo,
      marketplace: this.marketplace,
      marketplace_logo: this.marketplaceLogo,
      activity_type: this.activityType,
      price: this.price,
      seller_address: this.sellerAddress,
    };
  }

  copyWith(data: Partial<NFTListing>): NFTListing {
    return new NFTListing({ ...this, ...data });
  }
}
