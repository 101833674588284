import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { toastError } from '../api/utils';

import auctionsApi from '../api/auctions';

import {
  Auction,
  AuctionsResponse,
} from '../features/market/auctions/models/Auctions';

import {
  AuctionResponse,
  MyAuctionUserPrize,
  MyAuctionBidLeaderboard,
  AuctionMyDetailsResponse,
} from '../features/market/auctions/models/AuctionDetail';

import {
  AuctionBid,
  AuctionBidsResponse,
} from '../features/market/auctions/models/AuctionBids';
import {
  AuctionBidLeaderboardsResponse,
  AuctionBidLeaderboard,
} from '../features/market/auctions/models/AuctionBidLeaderboards';
import {
  AuctionUserPrize,
  AuctionUserPrizesResponse,
} from '../features/market/auctions/models/AuctionUserPrizes';
import { AuctionMyBidsResponse } from '../features/market/auctions/models/AuctionMyBids';
import { AuctionMyPrizesResponse } from '../features/market/auctions/models/AuctionMyPrizes';

interface AuctionsContextProps {
  auctions: Auction[];
  auction?: Auction;
  auctionMyDetails?: AuctionMyDetailsResponse;
  auctionBids: AuctionBid[];
  auctionBidLeaderboards: AuctionBidLeaderboard[];
  auctionUserPrizes: AuctionUserPrize[];
  auctionMyBids: MyAuctionBidLeaderboard[];
  auctionMyPrizes?: MyAuctionUserPrize[];

  load: () => Promise<void>;
  getAuctions: () => Promise<void>;
  clearAuctionDetail: () => void;
  loadAuction: (auctionId: string, checkCache?: boolean) => Promise<void>;
  getAuction: (auctionId: string, checkCache?: boolean) => Promise<void>;
  getAuctionMyDetails: (auctionId: string) => Promise<void>;
  getAuctionBids: (auctionId: string) => Promise<void>;
  getAuctionBidLeaderboards: (auctionId: string) => Promise<void>;
  getAuctionUserPrizes: (auctionId: string) => Promise<void>;
  placeAuctionBid: (auctionId: string, amount: number) => Promise<void>;
  getAuctionMyBids: () => Promise<void>;
  getAuctionMyPrizes: () => Promise<void>;

  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const AuctionsContext = createContext<AuctionsContextProps | undefined>(
  undefined,
);

export const useAuctions = (): AuctionsContextProps => {
  const context = useContext(AuctionsContext);
  if (!context) {
    throw new Error('useAuctions must be used within a AuctionsProvider');
  }
  return context;
};

export const AuctionsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [auction, setAuction] = useState<Auction>();
  const [auctionMyDetails, setAuctionMyDetails] =
    useState<AuctionMyDetailsResponse>();
  const [auctionBids, setAuctionBids] = useState<AuctionBid[]>([]);
  const [auctionBidLeaderboards, setAuctionBidLeaderboards] = useState<
    AuctionBidLeaderboard[]
  >([]);
  const [auctionUserPrizes, setAuctionUserPrizes] = useState<
    AuctionUserPrize[]
  >([]);
  const [auctionMyBids, setAuctionMyBids] = useState<MyAuctionBidLeaderboard[]>(
    [],
  );
  const [auctionMyPrizes, setAuctionMyPrizes] = useState<MyAuctionUserPrize[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    try {
      await getAuctions();
      await getAuctionMyBids();
    } catch (error) {
      toastError(error);
    }
  };

  const getAuctions = async () => {
    try {
      const response = await auctionsApi.getAuctions();
      const auctionsResponse = AuctionsResponse.fromJson(response.data);
      setAuctions(auctionsResponse.auctions || []);
    } catch (error) {
      toastError(error);
    }
  };

  const clearAuctionDetail = () => {
    setAuction(undefined);
    setAuctionMyDetails(undefined);
    setAuctionBids([]);
    setAuctionBidLeaderboards([]);
  };

  const loadAuction = async (auctionId: string, checkCache: boolean = true) => {
    await getAuction(auctionId, checkCache);
    await getAuctionMyDetails(auctionId);
    await getAuctionBids(auctionId);
    await getAuctionBidLeaderboards(auctionId);
    await getAuctionUserPrizes(auctionId);
  };

  const getAuction = async (auctionId: string, checkCache: boolean = true) => {
    try {
      const response = await auctionsApi.getAuction(auctionId, checkCache);
      const auctionResponse = AuctionResponse.fromJson(response.data);
      setAuction(auctionResponse.auction);
    } catch (error) {
      toastError(error);
    }
  };

  const getAuctionMyDetails = async (auctionId: string) => {
    try {
      const response = await auctionsApi.getAuctionMyDetails(auctionId);
      const auctionMyDetailsResponse = AuctionMyDetailsResponse.fromJson(
        response.data,
      );
      setAuctionMyDetails(auctionMyDetailsResponse);
    } catch (error) {
      toastError(error);
    }
  };

  const getAuctionBids = async (auctionId: string) => {
    try {
      const response = await auctionsApi.getAuctionBids(auctionId);
      const auctionBidsResponse = AuctionBidsResponse.fromJson(response.data);
      setAuctionBids(auctionBidsResponse.auctionBids || []);
    } catch (error) {
      toastError(error);
    }
  };

  const getAuctionBidLeaderboards = async (auctionId: string) => {
    try {
      const response = await auctionsApi.getAuctionBidLeaderboards(auctionId);
      const auctionBidLeaderboardsResponse =
        AuctionBidLeaderboardsResponse.fromJson(response.data);
      setAuctionBidLeaderboards(
        auctionBidLeaderboardsResponse.auctionBidLeaderboards || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const getAuctionUserPrizes = async (auctionId: string) => {
    try {
      const response = await auctionsApi.getAuctionUserPrizes(auctionId);
      const auctionUserPrizesResponse = AuctionUserPrizesResponse.fromJson(
        response.data,
      );
      setAuctionUserPrizes(auctionUserPrizesResponse.auctionUserPrizes || []);
    } catch (error) {
      toastError(error);
    }
  };

  const placeAuctionBid = async (auctionId: string, amount: number) => {
    try {
      await auctionsApi.placeAuctionBid(auctionId, amount);
      await loadAuction(auctionId);
    } catch (error) {
      toastError(error);
      throw error;
    }
  };

  const getAuctionMyBids = async () => {
    try {
      const response = await auctionsApi.getAuctionMyBids();
      const auctionMyBidsResponse = AuctionMyBidsResponse.fromJson(
        response.data,
      );
      setAuctionMyBids(auctionMyBidsResponse.auctionMyBids || []);
    } catch (error) {
      toastError(error);
    }
  };

  const getAuctionMyPrizes = async () => {
    try {
      const response = await auctionsApi.getAuctionMyPrizes();
      const auctionMyPrizesResponse = AuctionMyPrizesResponse.fromJson(
        response.data,
      );
      setAuctionMyPrizes(auctionMyPrizesResponse.auctionMyPrizes || []);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <AuctionsContext.Provider
      value={{
        // methods
        load,
        getAuctions,
        clearAuctionDetail,
        loadAuction,
        getAuction,
        getAuctionMyDetails,
        getAuctionBids,
        getAuctionBidLeaderboards,
        getAuctionUserPrizes,
        placeAuctionBid,
        getAuctionMyBids,
        getAuctionMyPrizes,

        // values
        auctions,
        auction,
        auctionMyDetails,
        auctionBids,
        auctionBidLeaderboards,
        auctionUserPrizes,
        auctionMyBids,
        auctionMyPrizes,

        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuctionsContext.Provider>
  );
};
