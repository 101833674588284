// vendor libraries
import { useEffect, useState, ChangeEvent } from 'react';
import { toast } from 'react-hot-toast';

// hooks
import { useAuth } from '../../hooks/useAuth';

// utils
import {
  getEmailValidationMessage,
  getPasswordValidationMessage,
} from '../../utils/validators';
import { DEFAULT_ERROR_MESSAGE } from '../../features/core/constants/constants';
// api
import { editCurrentUser } from '../../api/user';

// components
import { PasswordField } from '../common/Field/PasswordField';

export const AccountSettingsForm = () => {
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [userFormValues, setUserFormValues] = useState({
    email: '',
    password: '',
  });

  const { user, setUser } = useAuth();
  const { email, password } = userFormValues;

  const isDisabled = !email || !password || !!passwordError || !!emailError;

  useEffect(() => {
    if (user?.email) {
      setUserFormValues({ email: user.email, password: '' });
    }
  }, [user]);

  useEffect(() => {
    if (emailError) setEmailError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const handleChange = ({
    target: { value, id },
  }: ChangeEvent<HTMLInputElement>) => {
    setUserFormValues({ ...userFormValues, [id]: value });
  };

  const validateEmail = (val: string) => {
    const validationResult = getEmailValidationMessage(val);
    setEmailError(validationResult);
  };

  const validatePassword = (val: string) => {
    const validationResult = getPasswordValidationMessage(val, false);
    setPasswordError(validationResult);
  };

  const isValid = () => {
    validateEmail(email);
    validatePassword(password);
    return passwordError === '' && emailError === '';
  };

  const handleSubmit = async () => {
    if (isValid()) {
      try {
        await editCurrentUser({
          email: email,
          password: password,
        });
        setUser({ ...user, email: email });
        toast.success('Account updated successfully');
      } catch (error) {
        toast.error(
          (error as any)?.response?.data?.detail ||
            (error as any)?.response?.data?.errors[0]?.msg ||
            DEFAULT_ERROR_MESSAGE,
        );
      }
    }
  };

  return (
    <div className="login-box">
      <div>
        <div className="space-y-6">
          <div>
            <div className="text-left">E-mail</div>
            <input
              id="email"
              type="email"
              value={email}
              className="input-box"
              onChange={handleChange}
              onBlur={event => validateEmail(event.target.value)}
            />
            <div className="text-sm text-primary-red h-5 mt-2">
              {emailError}
            </div>
          </div>
          <div>
            <div className="text-left">Password</div>
            <PasswordField
              id="password"
              value={password}
              onChange={handleChange}
              onBlur={event => validatePassword(event.target.value)}
            />
            <div className="text-sm text-primary-red h-5 mt-2">
              {passwordError}
            </div>
          </div>
        </div>
        <button
          className="mt-4 solid h-10p bg-price-green w-full rounded-sm disabled:bg-gray-text dark:disabled:bg-tabs-bg dark:disabled:text-primary-dark disabled:text-primary-dark disabled:cursor-auto"
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Save
        </button>
      </div>
    </div>
  );
};
