import { useEffect } from 'react';
import { useAuctions } from '@/hooks/useAuctions';

import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { AuctionCard } from './AuctionCard';

export const AuctionsTab = () => {
  useEffect(() => {
    if (auctions.length === 0) {
      setIsLoading(true);
    }
    load();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { load, auctions, isLoading, setIsLoading } = useAuctions();

  return (
    <div>
      {isLoading ? (
        <SkeletonLoader height={300} />
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {auctions?.map(auction => <AuctionCard auction={auction} />)}
        </div>
      )}
    </div>
  );
};
