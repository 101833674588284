// vendor libraries
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// hooks
import { useGames } from '../../../hooks/useGames';

// components
import { ArcadeGameCard } from './ArcadeGameCard';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

export const ArcadeGamesTagContainer: React.FC = () => {
  const {
    loadArcadeGames,
    loadArcadeGameTags,

    // values
    arcadeGamesList,
    arcadeGameTags,
  } = useGames();
  const { tagId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadArcadeGameTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async (tag: string) => {
      setIsLoading(true);
      try {
        await loadArcadeGames(tag);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    if (tagId && arcadeGameTags.map(t => t.id).includes(tagId)) {
      fetchData(tagId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId, arcadeGameTags]);
  const tag = arcadeGameTags.find(t => t.id === tagId);
  return (
    <div className="px-6 py-4 space-y-4">
      <h1 className="text-3xl">{tag && tag.name}</h1>
      {isLoading ? (
        <SkeletonLoader height={400} />
      ) : (
        <>
          <div className="flex overflow-x-auto flex-row space-x-4 items-start">
            {arcadeGamesList.map(game => (
              <ArcadeGameCard key={game.id} arcadeGame={game} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
