import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useDeals } from '@/hooks/useDeals';

// components
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { DealsRow } from './DealsRow';
import { DealSearchBar } from './DealSearchBar';

export const DealsTab: React.FC = () => {
  const {
    popularDeals,
    vantaDeals,
    premiumDeals,
    giveawayDeals,
    latestDeals,
    userAllDeals,
    dealsTags,
    dealsLoading,
    loadUserDeals,
    loadUserCoupons,
    loadAllUserDeals,
    setDealsLoading,
  } = useDeals();

  useEffect(() => {
    loadDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDeals = async () => {
    if (userAllDeals.length === 0) {
      setDealsLoading(true);
    }
    try {
      await loadUserDeals();
      await loadUserCoupons();
      await loadAllUserDeals();
    } catch (error) {}
    setDealsLoading(false);
  };

  const navigate = useNavigate();

  const navigateToDealDetails = (tagId: string) => {
    const params = new URLSearchParams(window.location.search);
    navigate(`/deals/tag/${tagId}?tab=${params.get('tab')}`);
  };

  return (
    <div className="container mx-auto px-4">
      {dealsLoading ? (
        <SkeletonLoader height={600} />
      ) : (
        <div className="space-y-4">
          <div className="flex items-start flex-wrap">
            {dealsTags.map((tag: any) => (
              <div
                key={tag.id}
                className="h-15 cursor-pointer text-xs m-2 py-2 px-4 border-1 bg-opacity-10 border-black dark:border-gray-text rounded-full whitespace-nowrap"
                onClick={() => navigateToDealDetails(tag.id)}
              >
                <div className="text-black dark:text-gray-text text-sm">
                  {tag.name}
                </div>
              </div>
            ))}
          </div>
          <DealSearchBar />
          <div className="space-y-4">
            <DealsRow
              deals={popularDeals}
              title="Popular Deals"
              dealCategory="popular"
            />
            <DealsRow
              deals={vantaDeals}
              title="Vanta Drops"
              dealCategory="vanta"
            />
            <DealsRow
              deals={premiumDeals}
              title="Gold & Platinum Only"
              dealCategory="premium"
            />
            <DealsRow
              deals={giveawayDeals}
              title="Giveaways"
              dealCategory="giveaways"
            />
            <DealsRow
              deals={latestDeals}
              title="Latest Deals"
              dealCategory="latest"
            />
            <DealsRow
              deals={userAllDeals}
              title="All Deals"
              dealCategory="all"
            />
          </div>
        </div>
      )}
    </div>
  );
};
