import { useEffect } from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  TooltipProps,
} from 'recharts';

import { AppColors } from '@/features/core/theming/colors';

export interface SeriesCardLineChartDatapoint {
  value: number;
  date: Date;
}

type SeriesCardLineChartProps = {
  isPriceGain: boolean;
  data: SeriesCardLineChartDatapoint[];
  maxHeight?: number;
  handleActiveDatapoint: (value: any, active: boolean) => void;
};

export const SeriesCardLineChart: React.FC<SeriesCardLineChartProps> = ({
  isPriceGain,
  data,
  maxHeight,
  handleActiveDatapoint,
}) => {
  const ActiveDatapoint: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
  }) => {
    useEffect(() => {
      if (active && payload) {
        handleActiveDatapoint(payload[0], active);
      } else {
        handleActiveDatapoint({}, false);
      }
    }, [active, payload]);
    return null;
  };

  const chartColor = isPriceGain ? AppColors.positive() : AppColors.negative();

  return (
    <ResponsiveContainer
      id="chart-container"
      width="100%"
      height="76"
      aspect={1}
      maxHeight={maxHeight ? maxHeight : 68}
    >
      <AreaChart
        data={data}
        margin={{
          top: 6,
          right: 6,
          left: 6,
          bottom: 6,
        }}
      >
        <YAxis hide domain={['dataMin', 'dataMax']} />
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor={chartColor} stopOpacity={0.7} />
            <stop offset="100%" stopColor={chartColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Tooltip
          content={<ActiveDatapoint />}
          cursor={{
            stroke: '#F5AC6E',
            strokeWidth: '2px',
            strokeLinecap: 'round',
          }}
        />
        <Area
          dataKey="value"
          stroke={chartColor}
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#color)"
          isAnimationActive
          activeDot={{
            fill: '#F5AC6E',
            strokeWidth: '0px',
            r: 3,
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
