import moment from 'moment';

import { CoinDistributionJson } from '../../../api/coin';

class CoinDistributionsResponse {
  coinDistributions?: CoinDistribution[];

  constructor(coinDistributions?: CoinDistribution[]) {
    this.coinDistributions = coinDistributions;
  }

  static fromJson(json: {
    coin_distributions: CoinDistributionJson[];
  }): CoinDistributionsResponse {
    const coinDistributions: CoinDistribution[] = [];

    if (json.coin_distributions !== null) {
      for (const v of json.coin_distributions) {
        coinDistributions.push(CoinDistribution.fromJson(v));
      }
    }

    return new CoinDistributionsResponse(coinDistributions);
  }

  toJson(): { coin_distributions?: CoinDistributionJson[] } {
    if (this.coinDistributions) {
      return {
        coin_distributions: this.coinDistributions.map(v => v.toJson()),
      };
    }

    return {};
  }
}

class CoinDistribution {
  constructor(
    public created: Date,
    public distributionType: string,
    public distributionDescription: string,
    public coinsMultiplier: number,
    public coinsMultiplierOriginal: number,
    public coinsOriginal: number,
    public coinsTotal: number,
    public unlockDate: Date,
    public lockStatus: string,
  ) {}

  static fromJson(json: CoinDistributionJson): CoinDistribution {
    return new CoinDistribution(
      new Date(json.created),
      json.distribution_type,
      json.distribution_description,
      json.coins_multiplier,
      json.coins_multiplier_original ?? json.coins_multiplier,
      json.coins_original,
      json.coins_total,
      new Date(json.unlock_date),
      json.lock_status,
    );
  }

  toJson(): CoinDistributionJson {
    return {
      created: this.created.toISOString(),
      distribution_type: this.distributionType,
      distribution_description: this.distributionDescription,
      coins_multiplier: this.coinsMultiplier,
      coins_multiplier_original: this.coinsMultiplierOriginal,
      coins_original: this.coinsOriginal,
      coins_total: this.coinsTotal,
      unlock_date: this.unlockDate.toISOString(),
      lock_status: this.lockStatus,
    };
  }

  getCreatedDay(): string {
    return moment(this.created).format('MMM DD, yyyy');
  }

  getUnlockDay(): string {
    return moment(this.unlockDate).format('MMM DD, yyyy');
  }

  isLocked(): boolean {
    return this.lockStatus !== 'unlocked';
  }

  getLockDateDisplay(): string {
    const numDays = Math.round(
      (this.unlockDate.getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24),
    );
    if (this.lockStatus === 'unlocked') return 'Unlocked';
    if (numDays === 0) return 'Today';
    if (numDays === 1) return `${numDays} day`;
    return `${numDays} days`;
  }
}

export { CoinDistribution, CoinDistributionsResponse };
