/* eslint-disable @typescript-eslint/no-useless-constructor */
import { CryptoDetail } from './CryptoDetail';
import { NFTDetail } from './NFTDetail';

export class DeFiDetail {
  totalValueLockedUsd: number;
  marketCapUsd: number;

  constructor(data: any) {
    this.totalValueLockedUsd = data.total_value_locked_usd;
    this.marketCapUsd = data.market_cap_usd;
  }

  static fromJSON(data: any): DeFiDetail {
    return new DeFiDetail(data);
  }
}

export class StocksDetail {
  averageVolume: number;
  open: number;
  high: number;
  low: number;
  close: number;
  week52High: number;
  week52Low: number;

  constructor(data: any) {
    this.averageVolume = data.average_volume;
    this.open = data.open;
    this.high = data.high;
    this.low = data.low;
    this.close = data.close;
    this.week52High = data.week_52_high;
    this.week52Low = data.week_52_low;
  }

  static fromJSON(data: any): StocksDetail {
    return new StocksDetail(data);
  }
}

export class OptionsDetail {
  totalValueLockedUsd: number;
  marketCapUsd: number;

  constructor(data: any) {
    this.totalValueLockedUsd = data.total_value_locked_usd;
    this.marketCapUsd = data.market_cap_usd;
  }

  static fromJSON(data: any): OptionsDetail {
    return new OptionsDetail(data);
  }
}

export class CashDetail {
  constructor(data: any) {}

  static fromJSON(data: any): CashDetail {
    return new CashDetail(data);
  }
}

export class CommodityDetail {
  constructor(data: any) {}

  static fromJSON(data: any): CommodityDetail {
    return new CommodityDetail(data);
  }
}

export class PrivateEquityDetail {
  constructor(data: any) {}

  static fromJSON(data: any): PrivateEquityDetail {
    return new PrivateEquityDetail(data);
  }
}

export class RealEstateDetail {
  constructor(data: any) {}

  static fromJSON(data: any): RealEstateDetail {
    return new RealEstateDetail(data);
  }
}

export class CollectiblesDetail {
  constructor(data: any) {}

  static fromJSON(data: any): CollectiblesDetail {
    return new CollectiblesDetail(data);
  }
}

export class OtherDetail {
  constructor(data: any) {}

  static fromJSON(data: any): OtherDetail {
    return new OtherDetail(data);
  }
}

export type AssetDetails =
  | CryptoDetail
  | NFTDetail
  | DeFiDetail
  | StocksDetail
  | OptionsDetail
  | CashDetail
  | CommodityDetail
  | PrivateEquityDetail
  | RealEstateDetail
  | CollectiblesDetail
  | OtherDetail;

export const parseCryptoDetail = (data: any): CryptoDetail => {
  return CryptoDetail.fromJSON(data);
};

export const parseNFTDetail = (data: any): NFTDetail => {
  return NFTDetail.fromJSON(data);
};

export const parseDeFiDetail = (data: any): DeFiDetail => {
  return DeFiDetail.fromJSON(data);
};

export const parseStocksDetail = (data: any): StocksDetail => {
  return StocksDetail.fromJSON(data);
};

export const parseOptionsDetail = (data: any): OptionsDetail => {
  return OptionsDetail.fromJSON(data);
};

export const parseCashDetail = (data: any): CashDetail => {
  return CashDetail.fromJSON(data);
};

export const parseCommodityDetail = (data: any): CommodityDetail => {
  return CommodityDetail.fromJSON(data);
};

export const parsePrivateEquityDetail = (data: any): PrivateEquityDetail => {
  return PrivateEquityDetail.fromJSON(data);
};

export const parseRealEstateDetail = (data: any): RealEstateDetail => {
  return RealEstateDetail.fromJSON(data);
};

export const parseCollectiblesDetail = (data: any): CollectiblesDetail => {
  return CollectiblesDetail.fromJSON(data);
};

export const parseOtherDetail = (data: any): OtherDetail => {
  return OtherDetail.fromJSON(data);
};

export const parseAssetDetails = (
  data: any,
  assetType: string,
): AssetDetails => {
  if (assetType === 'crypto') {
    return parseCryptoDetail(data.crypto_details);
  } else if (assetType === 'nft') {
    return parseNFTDetail(data.nft_details);
  } else if (data.defi_details) {
    return parseDeFiDetail(data.defi_details);
  } else if (data.stocks_details) {
    return parseStocksDetail(data.stocks_details);
  } else if (data.options_details) {
    return parseOptionsDetail(data.options_details);
  } else if (data.cash_details) {
    return parseCashDetail(data.cash_details);
  } else if (data.commodity_details) {
    return parseCommodityDetail(data.commodity_details);
  } else if (data.private_equity_details) {
    return parsePrivateEquityDetail(data.private_equity_details);
  } else if (data.real_estate_details) {
    return parseRealEstateDetail(data.real_estate_details);
  } else if (data.collectibles_details) {
    return parseCollectiblesDetail(data.collectibles_details);
  } else if (data.other_details) {
    return parseOtherDetail(data.other_details);
  } else {
    throw new Error('Unsupported asset type');
  }
};
