// vendor libraries
import { useState } from 'react';

import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// components
import MembershipCarousel from './MembershipCarousel';
import { LearnMoreModal } from './LearnMoreModal';

export const MembershipTier = ({ memberCenterData }) => {
  const [showFaq, setShowFaq] = useState(false);
  const { membership_tiers, member_status } = memberCenterData || '';

  return (
    <>
      <div className="card py-4 px-6 rounded-12 space-y-6">
        <div className="flex justify-between items-center">
          <p className="text-22 font-semibold leading-22">Membership Tiers</p>
          <div
            onClick={() => {
              setShowFaq(true);
            }}
            className="order-1 box-border leading-18 border-1 cursor-pointer solid border-border-box py-1 px-3 rounded-20 bg-my-box text-price-green"
          >
            Learn More
          </div>
        </div>
        <p className="text-xl text-gray-text items-stretch">
          Your membership tier is based on your verified investments.
        </p>
        <div className="py-7 px-4">
          {memberCenterData ? (
            <MembershipCarousel
              tiers={membership_tiers}
              memberStatus={member_status}
            />
          ) : (
            <SkeletonLoader height={40} className="rounded-lg" />
          )}
        </div>
      </div>
      {showFaq && (
        <LearnMoreModal
          open={showFaq}
          setOpen={setShowFaq}
          faqs={memberCenterData?.faq}
        />
      )}
    </>
  );
};
