// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useNavigate } from 'react-router-dom';
import { BoltIcon } from '@heroicons/react/24/outline';

// hooks
import { usePortfolio } from '../../hooks/usePortfolio';

// components
import { PortfolioHoldingsSeriesCard } from '@/components/v2/portfolio/holdings/PortfolioHoldingsSeriesCard';
import { NFTCard } from '@/components/v2/nft/NFTCard';
import { ArrowButton } from '@/components/v2/common/Button/ArrowButton';

export default function PortfolioNFTGalleryPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const navigate = useNavigate();
  const { nftCollections } = usePortfolio();

  const handleClickCollectionViewAll = (assetId: string) => {
    navigate(`/assets/${assetId}`);
  };

  return (
    <>
      <SuperSEO title="AssetDash | NFT Gallery" lang="en_IE" />
      <div className="mx-auto main-content">
        <div className="flex justify-end pb-4">
          <div
            className="h-15 cursor-pointer flex items-center space-x-1 px-2 py-1 text-xs justify-center border-1 bg-opacity-10 bg-boosted border-boosted text-boosted rounded-full"
            onClick={() => navigate('/portfolio/nft-activity')}
          >
            <BoltIcon width="17.5" height="17.5" />
            <span className="pr-2  text-sm">Activity</span>
          </div>
        </div>
        <div className="space-y-10">
          <PortfolioHoldingsSeriesCard holdingTypeGroup="nft" />

          {nftCollections.map(nftCollection => {
            return (
              <div key={nftCollection.id} className="card h-full flex flex-col">
                <div className="mx-5 py-4 px-4 md:px-6 flex justify-between items-center">
                  <span className="text-tabs-bg dark:text-white font-semibold text-xl leading-card-title flex items-center space-x-2">
                    <div>{nftCollection.name}</div>
                    <div className="text-price-green text-xs leading-17 bg-primary-gray dark:bg-tabs-bg w-max px-4p py-3p rounded-2xl mt-1">
                      {nftCollection.chain}
                    </div>
                  </span>

                  <ArrowButton
                    onClick={() =>
                      handleClickCollectionViewAll(nftCollection.asset!.id!)
                    }
                    direction="right"
                  >
                    View all
                  </ArrowButton>
                </div>
                <div className="flex overflow-x-auto flex-row space-x-4 px-8 py-4 items-start">
                  {nftCollection.walletNFTItems.slice(0, 7).map(item => (
                    <NFTCard key={item.id} item={item} />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
