// TokenRewardTab.tsx
import React from 'react';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { formatNumber } from '@/utils/number';
import ProgressBar from '@/components/v2/common/ProgressBarV2/ProgressBarV2';
import { CheckIcon } from '@heroicons/react/24/solid';

interface TokenRewardTabProps {
  rewards: Array<any>;
  isLoading: boolean;
}

const TokenRewardTab: React.FC<TokenRewardTabProps> = ({
  rewards,
  isLoading,
}) => {
  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : (
        <div className="mt-4 space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
          {rewards.length === 0 ? (
            <p className="text-center text-gray-400">No Token Rewards</p>
          ) : (
            rewards.map(reward => (
              <div
                key={reward.asset_id}
                className="flex items-center justify-between p-2"
              >
                <div className="flex items-center">
                  <img
                    src={reward?.asset?.logo_url}
                    alt={reward?.asset?.name}
                    className="w-9 h-9 rounded-md"
                  />
                  <div className="ml-4">
                    <p className="text-sm">{reward?.asset?.name}</p>
                  </div>
                </div>
                <div className="w-full max-w-[180px] md:max-w-[300px] relative ml-5">
                  <ProgressBar
                    percentage={
                      reward.current_amount <= 0
                        ? 0
                        : (reward.current_amount * 100) /
                          reward.withdrawal_threshold
                    }
                  />
                  <div className="absolute right-4 -top-5">
                    {reward.withdrawal_threshold === reward.current_amount ? (
                      <div className="flex flex-row items-center gap-x-1 text-primary">
                        <div className="text-sm text-primary">
                          {formatNumber(reward.total_amount)}
                        </div>
                        <CheckIcon className="w-6 h-6 text-primary" />
                      </div>
                    ) : (
                      <div className="flex flex-row items-center">
                        <div className="text-sm font-bold">
                          {`${formatNumber(
                            reward.current_amount,
                          )} / ${formatNumber(reward.withdrawal_threshold)}`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default TokenRewardTab;
