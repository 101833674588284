import { ArrowIcon } from '../Icon/ArrowIcon';

interface ArrowButtonProps {
  children?: React.ReactNode;
  direction?: 'right' | 'left' | 'up' | 'down';
  onClick?: () => void;
  iconStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  bigSize?: boolean;
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  children,
  direction = 'right',
  onClick,
  iconStyle,
  buttonStyle = { color: '#F5AC6E' },
  bigSize,
}) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      className="flex items-center font-semibold text-xs text-white p-0 cursor-pointer leading-15"
      onClick={handleClick}
      style={buttonStyle}
    >
      {children && children}
      <span style={{ display: 'flex', marginLeft: '4px', ...iconStyle }}>
        <ArrowIcon
          direction={direction}
          bigSize={bigSize}
          color={iconStyle?.color || '#F5AC6E'}
        />
      </span>
    </button>
  );
};
