import { Giveaway } from '@/api/games';

// hooks
import { useMember } from '@/hooks/useMember';
import { usePortfolio } from '@/hooks/usePortfolio';

interface GiveawayEligibilityCardProps {
  giveaway: Giveaway;
}

export const GiveawayEligibilityCard: React.FC<
  GiveawayEligibilityCardProps
> = ({ giveaway }) => {
  const { memberCenterData } = useMember();
  const member_status = memberCenterData?.member_status;
  const { verifiedAssetIds } = usePortfolio();
  const memberPartnerIds = new Set(
    memberCenterData?.partner_organizations?.map((m: any) => m.partner_id),
  );

  let isEligible = false;
  if (
    (giveaway?.eligible_vanta === true && member_status === 'vanta') ||
    (giveaway?.eligible_platinum === true && member_status === 'platinum') ||
    (giveaway?.eligible_gold === true && member_status === 'gold') ||
    (giveaway?.eligible_silver === true && member_status === 'silver') ||
    (giveaway?.eligible_bronze === true && member_status === 'bronze')
  ) {
    isEligible = true;
  }

  if (!isEligible && giveaway?.eligible_assets != null) {
    const eligibleGiveaway = giveaway.eligible_assets.find(eligibleAsset =>
      verifiedAssetIds.includes(eligibleAsset.id),
    );
    if (eligibleGiveaway) {
      isEligible = true;
    }
  }

  // Check eligible partners
  if (!isEligible && giveaway?.eligible_partner_organizations != null) {
    const eligiblePartner = giveaway.eligible_partner_organizations.find(
      eligiblePartner => memberPartnerIds.has(eligiblePartner.partner_id),
    );
    if (eligiblePartner) {
      isEligible = true;
    }
  }

  return (
    <div className="space-y-2">
      <p className="text-left text-2xl">Eligibility</p>
      <div className="dark:bg-deals-card-top bg-white py-4 px-8 rounded-xl space-y-4">
        {isEligible ? (
          <div className="border p-1 rounded-2xl font-semibold border-primary text-primary">
            You are eligible for this giveaway
          </div>
        ) : (
          <div className="border p-1 rounded-xl font-semibold border-negative text-negative">
            You are not eligible for this giveaway
          </div>
        )}
        {['vanta', 'platinum', 'gold', 'silver', 'bronze'].map(tier => {
          const eligible_tier = `eligible_${tier}` as
            | 'eligible_vanta'
            | 'eligible_platinum'
            | 'eligible_gold'
            | 'eligible_silver'
            | 'eligible_bronze';

          if (!giveaway[eligible_tier]) {
            return null;
          }
          return (
            <div
              key={tier}
              className="flex justify-between dark:text-gray-300 items-center"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={`https://storage.googleapis.com/assetdash-prod-images/member_center/logo_${tier}.png`}
                  alt={`${tier} logo`}
                  className="w-6 h-3"
                />
                <div className="font-semibold capitalize">{tier}</div>
              </div>
              {member_status === tier && (
                <img
                  src="/assets/icons/check-circle.png"
                  alt="check-circle"
                  className="w-4 h-4"
                />
              )}
            </div>
          );
        })}
        {giveaway.eligible_assets?.map(eligible_asset => {
          return (
            <div className="flex justify-between text-gray-300">
              <div className="flex items-center space-x-2 ">
                <img
                  src={eligible_asset.logo_url}
                  alt={eligible_asset.name}
                  className="w-6 h-3"
                />
                <div className="font-semibold capitalize">
                  {eligible_asset.name}
                </div>
              </div>
              {verifiedAssetIds.includes(eligible_asset.id) && (
                <img
                  src="/assets/icons/check-circle.png"
                  alt={`check-circle`}
                  className="w-6 h-6"
                />
              )}
            </div>
          );
        })}
        {giveaway.eligible_partner_organizations?.map(eligible_partner => {
          return (
            <div className="flex justify-between text-gray-300">
              <div className="flex items-center space-x-2 ">
                <img
                  src={eligible_partner.logo_url}
                  alt={eligible_partner.name}
                  className="w-6 h-3"
                />
                <div className="font-semibold capitalize">
                  {eligible_partner.name}
                </div>
              </div>
              {memberPartnerIds.has(eligible_partner.partner_id) && (
                <img
                  src="/assets/icons/check-circle.png"
                  alt={`check-circle`}
                  className="w-6 h-6"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
