interface AuctionInstructionsCardProps {
  instructions: string[];
}

export const AuctionInstructionsCard: React.FC<
  AuctionInstructionsCardProps
> = ({ instructions }) => {
  return (
    <div className="space-y-4 bg-purple-gradient dark:bg-dark-purple-gradient mt-8 py-4 px-8 rounded-xl relative text-black dark:text-gray-300">
      <img
        src="/assets/icons/giveaway_instructions.png"
        alt="instructions"
        className="h-16 w-16 p-2 rounded-full mx-auto absolute -top-8 left-1/2 transform -translate-x-1/2 bg-deals-card-top"
      />
      <p className="text-center text-2xl font-semibold">Instructions</p>
      <div className="space-y-2 pb-4">
        {instructions.map((instruction, index) => {
          return (
            <div key={instruction} className="flex items-center space-x-2">
              <p className="min-w-6 text-black bg-white rounded-full">
                {index + 1}
              </p>
              <p className="text-left">{instruction}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
