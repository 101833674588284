export class LearnCoursesResponse {
  courses?: LearnCourse[];

  constructor(courses?: LearnCourse[]) {
    this.courses = courses;
  }

  static fromJson(json: any): LearnCoursesResponse {
    const courses = json['courses'] ? json['courses'].map((v: any) => LearnCourse.fromJson(v)) : [];
    return new LearnCoursesResponse(courses);
  }

  toJson(): any {
    return {
      courses: this.courses?.map(v => v.toJson()),
    };
  }
}

export class LearnCourse {
  id?: string;
  created?: string;
  updated?: string;
  organizationId?: string;
  partnerName?: string;
  partnerLogoUrl?: string;
  title?: string;
  imageUrl?: string;
  bannerImageUrl?: string;
  isBoosted?: boolean;
  status?: string;
  steps?: Step[];
  questions?: Question[];
  reward?: Reward;
  description?: string;
  cta?: CTA;
  isStandardUserAllowed?: boolean;
  numViews?: number;
  isLocked?: boolean;
  weeklyCompletionPercent?: number;
  weeklyUnlockTime?: string;

  constructor({
    id,
    created,
    updated,
    organizationId,
    partnerName,
    partnerLogoUrl,
    title,
    imageUrl,
    bannerImageUrl,
    status,
    steps,
    questions,
    reward,
    description,
    isBoosted,
    isStandardUserAllowed,
    numViews,
    cta,
  }: {
    id?: string,
    created?: string,
    updated?: string,
    organizationId?: string,
    partnerName?: string,
    partnerLogoUrl?: string,
    title?: string,
    imageUrl?: string,
    bannerImageUrl?: string,
    status?: string,
    steps?: Step[],
    questions?: Question[],
    reward?: Reward,
    description?: string,
    isBoosted?: boolean,
    isStandardUserAllowed?: boolean,
    numViews?: number,
    cta?: CTA,
  }) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.organizationId = organizationId;
    this.partnerName = partnerName;
    this.partnerLogoUrl = partnerLogoUrl;
    this.title = title;
    this.imageUrl = imageUrl;
    this.bannerImageUrl = bannerImageUrl;
    this.status = status;
    this.steps = steps;
    this.questions = questions;
    this.reward = reward;
    this.description = description;
    this.isBoosted = isBoosted;
    this.isStandardUserAllowed = isStandardUserAllowed;
    this.numViews = numViews;
    this.cta = cta;
  }

  static fromJson(json: any): LearnCourse {
    return new LearnCourse({
      id: json['id'],
      created: json['created'],
      updated: json['updated'],
      organizationId: json['organization_id'],
      partnerName: json['partner_name'],
      partnerLogoUrl: json['partner_logo_url'],
      title: json['title'],
      imageUrl: json['image_url'],
      bannerImageUrl: json['banner_image_url'],
      status: json['status'],
      description: json['description'],
      isBoosted: json['is_boosted'],
      isStandardUserAllowed: json['is_standard_user_allowed'],
      numViews: json['num_views'],
      steps: json['steps'] ? json['steps'].map((v: any) => Step.fromJson(v)) : [],
      questions: json['questions'] ? json['questions'].map((v: any) => Question.fromJson(v)) : [],
      reward: json['reward'] ? Reward.fromJson(json['reward']) : undefined,
      cta: json['cta'] ? CTA.fromJson(json['cta']) : undefined,
    });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    data['id'] = this.id;
    data['created'] = this.created;
    data['updated'] = this.updated;
    data['organization_id'] = this.organizationId;
    data['partner_name'] = this.partnerName;
    data['partner_logo_url'] = this.partnerLogoUrl;
    data['title'] = this.title;
    data['image_url'] = this.imageUrl;
    data['status'] = this.status;
    if (this.steps != null) {
      data['steps'] = this.steps.map((v) => v.toJson());
    }
    if (this.questions != null) {
      data['questions'] = this.questions.map((v) => v.toJson());
    }
    if (this.reward != null) {
      data['reward'] = this.reward.toJson();
    }
    return data;
  }
}

export class Step {
  id?: string;
  created?: string;
  updated?: string;
  courseId?: string;
  order?: number;
  title?: string;
  text?: string;
  imageUrl?: string;
  duration?: number;

  constructor({
    id,
    created,
    updated,
    courseId,
    order,
    title,
    text,
    imageUrl,
    duration
  }: {
    id?: string,
    created?: string,
    updated?: string,
    courseId?: string,
    order?: number,
    title?: string,
    text?: string,
    imageUrl?: string,
    duration?: number,
  }) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.courseId = courseId;
    this.order = order;
    this.title = title;
    this.text = text;
    this.imageUrl = imageUrl;
    this.duration = duration;
  }

  static fromJson(json: any): Step {
    return new Step({
      id: json['id'],
      created: json['created'],
      updated: json['updated'],
      courseId: json['course_id'],
      order: json['order'],
      title: json['title'],
      text: json['text'],
      imageUrl: json['image_url'],
      duration: json['duration'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created,
      updated: this.updated,
      course_id: this.courseId,
      order: this.order,
      title: this.title,
      text: this.text,
      image_url: this.imageUrl,
      duration: this.duration,
    };
  }
}

export class Question {
  id?: string;
  created?: string;
  updated?: string;
  courseId?: string;
  order?: number;
  question?: string;
  answerA?: string;
  answerB?: string;
  answerC?: string;
  answerD?: string;
  correctAnswer?: string;

  constructor({
    id,
    created,
    updated,
    courseId,
    order,
    question,
    answerA,
    answerB,
    answerC,
    answerD,
    correctAnswer
  }: {
    id?: string,
    created?: string,
    updated?: string,
    courseId?: string,
    order?: number,
    question?: string,
    answerA?: string,
    answerB?: string,
    answerC?: string,
    answerD?: string,
    correctAnswer?: string,
  }) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.courseId = courseId;
    this.order = order;
    this.question = question;
    this.answerA = answerA;
    this.answerB = answerB;
    this.answerC = answerC;
    this.answerD = answerD;
    this.correctAnswer = correctAnswer;
  }

  static fromJson(json: any): Question {
    return new Question({
      id: json['id'],
      created: json['created'],
      updated: json['updated'],
      courseId: json['course_id'],
      order: json['order'],
      question: json['question'],
      answerA: json['answer_a'],
      answerB: json['answer_b'],
      answerC: json['answer_c'],
      answerD: json['answer_d'],
      correctAnswer: json['correct_answer'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created,
      updated: this.updated,
      course_id: this.courseId,
      order: this.order,
      question: this.question,
      answer_a: this.answerA,
      answer_b: this.answerB,
      answer_c: this.answerC,
      answer_d: this.answerD,
      correct_answer: this.correctAnswer,
    };
  }
}

export class Reward {
  id?: string;
  created?: string;
  updated?: string;
  courseId?: string;
  cashBack?: number;
  tickets?: number;
  coins?: number;

  constructor({
    id,
    created,
    updated,
    courseId,
    cashBack,
    tickets,
    coins
  }: {
    id?: string,
    created?: string,
    updated?: string,
    courseId?: string,
    cashBack?: number,
    tickets?: number,
    coins?: number,
  }) {
    this.id = id;
    this.created = created;
    this.updated = updated;
    this.courseId = courseId;
    this.cashBack = cashBack;
    this.tickets = tickets;
    this.coins = coins;
  }

  static fromJson(json: any): Reward {
    return new Reward({
      id: json['id'],
      created: json['created'],
      updated: json['updated'],
      courseId: json['course_id'],
      cashBack: json['cash_back'],
      tickets: json['tickets'],
      coins: json['coins'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      created: this.created,
      updated: this.updated,
      course_id: this.courseId,
      cash_back: this.cashBack,
      tickets: this.tickets,
      coins: this.coins,
    }
  }
}

export class CTA {
  id?: string;
  created?: string;
  updated?: string;
  courseId?: string;
  title?: string;
  text?: string;
  imageUrl?: string;
  ctaUrl?: string;
  ctaCourseId?: string;
  ctaDealId?: string;

  constructor({
      id,
      created,
      updated,
      courseId,
      title,
      text,
      imageUrl,
      ctaUrl,
      ctaCourseId,
      ctaDealId,
  }: {
      id?: string,
      created?: string,
      updated?: string,
      courseId?: string,
      title?: string,
      text?: string,
      imageUrl?: string,
      ctaUrl?: string,
      ctaCourseId?: string,
      ctaDealId?: string,
  }) {
      this.id = id;
      this.created = created;
      this.updated = updated;
      this.courseId = courseId;
      this.title = title;
      this.text = text;
      this.imageUrl = imageUrl;
      this.ctaUrl = ctaUrl;
      this.ctaCourseId = ctaCourseId;
      this.ctaDealId = ctaDealId;
  }

  static fromJson(json: any): CTA {
      return new CTA({
          id: json['id'],
          created: json['created'],
          updated: json['updated'],
          courseId: json['course_id'],
          title: json['title'],
          text: json['text'],
          imageUrl: json['image_url'],
          ctaUrl: json['cta_url'],
          ctaCourseId: json['cta_course_id'],
          ctaDealId: json['cta_deal_id'],
      });
  }
}
