export const SORT_DESC = ':desc';

export const SORT_ASC = ':asc';

export const ASSET_TYPE_TO_DISPLAY_NAME: { [key: string]: string } = {
  equity: 'Equity',
  derivative: 'Derivative',
  etf: 'ETF',
  mutual_fund: 'Mutual Fund',
  currency: 'Currency',
  nft: 'NFT',
  crypto_token: 'Crypto Token',
  crypto_protocol: 'Crypto Platform',
  stocks: 'Stocks',
  commodity: 'Commodity',
  metals: 'Metals',
  real_estate: 'Real Estate',
  private_equity: 'Private Equity',
  other: 'Other',
  collectibles: 'Collectibles',
  crypto: 'Crypto',
  defi: 'DeFi',
  cash: 'Cash',
  options: 'Options',
  all: 'Total',
  staked: 'Staked',
};
