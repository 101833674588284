// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { CoinCenterContainer } from '../components/coin/CoinCenterContainer';

export default function CoinCenterPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SuperSEO title={`AssetDash | Asset Details`} lang="en_IE" />
      <CoinCenterContainer />
    </div>
  );
}
