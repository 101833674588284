import React from 'react';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';
import { formatNumber, formatPriceNumber } from '@/utils/number';
import { useNavigate } from 'react-router-dom';

interface WalletListItemProps {
  holding: WalletHolding;
  color: string;
  percentage: number;
  address: string;
}

const WalletListItem: React.FC<WalletListItemProps> = ({
  holding,
  color,
  percentage,
  address,
}) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`/wallets/${holding?.userWalletId}`)}
      className="flex items-center justify-between mb-4 p-3 rounded-lg card hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
    >
      <div className="flex items-center">
        <div
          className="w-3 h-3 rounded-full mr-3"
          style={{ backgroundColor: color }}
        />
        <div>
          <p className="text-opacity-80">{percentage.toFixed(2)}%</p>
          <p className="text-gray-400 text-sm truncate">{address}</p>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <p>
          {`${formatNumber(holding.quantity!)} `}
          {holding.asset?.ticker}
        </p>
        <p className="text-gray-400 text-sm">
          ${`${formatPriceNumber(holding?.value!)}`}
        </p>
      </div>
    </button>
  );
};

export default WalletListItem;
