import { axiosAssetDashApi } from './api';

const getUserExpeditionCenter = async () => {
  return await axiosAssetDashApi.get('/api/api_v4/users/expedition-center');
};

const getExpeditionsBanner = async () => {
  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/dashboard-banners',
  );
};

const getAssetDashExpeditionTags = async () => {
  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/assetdash/tags/list',
  );
};

const getAssetDashExpeditions = async (tag?: string) => {
  const params = {
    ...(tag && { tag_ids: tag }),
  };

  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/assetdash/expeditions/list',
    { params },
  );
};

const getCompletedAssetDashExpeditions = async (tag?: string) => {
  const params = {
    ...(tag && { tag_ids: tag }),
  };

  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/assetdash/expeditions/list-completed',
    { params },
  );
};

const getAssetDashExpeditionDetail = async (assetdashExpeditionId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/expeditions/assetdash/expeditions/${assetdashExpeditionId}`,
  );
};

const getPartnerExpeditionTags = async () => {
  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/partners/tags/list',
  );
};

const getPartnerExpeditions = async (tag?: string) => {
  const params = {
    ...(tag && { tag_ids: tag }),
  };

  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/partners/expeditions/list',
    { params },
  );
};

const getCompletedPartnerExpeditions = async (tag?: string) => {
  const params = {
    ...(tag && { tag_ids: tag }),
  };

  return await axiosAssetDashApi.get(
    '/api/api_v4/expeditions/partners/expeditions/list-completed',
    { params },
  );
};

const getPartnerExpeditionDetail = async (partnerExpeditionId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/expeditions/partners/expeditions/${partnerExpeditionId}`,
  );
};

const launchPartnerExpedition = async (partnerExpeditionId: string) => {
  return await axiosAssetDashApi.post(
    `/api/api_v4/expeditions/partners/expeditions/${partnerExpeditionId}/launch_expedition`,
  );
};

const expeditionsApi = {
  getUserExpeditionCenter,
  getExpeditionsBanner,
  getAssetDashExpeditionTags,
  getAssetDashExpeditions,
  getCompletedAssetDashExpeditions,
  getAssetDashExpeditionDetail,
  getPartnerExpeditionTags,
  getPartnerExpeditions,
  getCompletedPartnerExpeditions,
  getPartnerExpeditionDetail,
  launchPartnerExpedition,
};

export default expeditionsApi;
