type JsonType = { [key: string]: any };

export class AssetSearchResults {
  results?: AssetSearchResult[];

  constructor(results?: AssetSearchResult[]) {
    this.results = results;
  }

  static fromJSON(json: JsonType) {
    const assetSearchResults = new AssetSearchResults();
    if (json['results'] != null) {
      assetSearchResults.results = json['results'].map((v: JsonType) =>
        AssetSearchResult.fromJSON(v),
      );
    }
    return assetSearchResults;
  }

  toJSON() {
    const data: JsonType = {};
    if (this.results != null) {
      data['results'] = this.results.map(v => v.toJSON());
    }
    return data;
  }
}

export class AssetSearchResult {
  id?: string;
  ticker?: string;
  type?: string;
  typeGroup?: string;
  name?: string;
  network?: string;
  logoUrl?: string;
  marketCap?: number;
  tags?: string[];

  constructor(
    id?: string,
    ticker?: string,
    type?: string,
    typeGroup?: string,
    name?: string,
    network?: string,
    logoUrl?: string,
    marketCap?: number,
    tags?: string[],
  ) {
    this.id = id;
    this.ticker = ticker;
    this.type = type;
    this.typeGroup = typeGroup;
    this.name = name;
    this.network = network;
    this.logoUrl = logoUrl;
    this.marketCap = marketCap;
    this.tags = tags;
  }

  static fromJSON(json: JsonType) {
    return new AssetSearchResult(
      json['id'],
      json['ticker'],
      json['type'],
      json['type_group'],
      json['name'],
      json['network'],
      json['logo_url'],
      json['market_cap'],
      json['tags'] as string[],
    );
  }

  toJSON() {
    const data: JsonType = {};
    data['id'] = this.id;
    data['ticker'] = this.ticker;
    data['type'] = this.type;
    data['type_group'] = this.typeGroup;
    data['name'] = this.name;
    data['network'] = this.network;
    data['logo_url'] = this.logoUrl;
    data['market_cap'] = this.marketCap;
    data['tags'] = this.tags;
    return data;
  }
}
