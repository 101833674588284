export const NavGiftIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="gift 9">
      <path
        id="Vector"
        d="M23.0672 13.6698V25.0613H4.84082V13.6698"
        stroke="currentColor"
        stroke-width="1.56226"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M25.3431 7.97339H2.56006V13.6691H25.3431V7.97339Z"
        stroke="currentColor"
        stroke-width="1.56226"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M13.9529 23.8051V7.97339"
        stroke="currentColor"
        stroke-width="2.92925"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M13.9516 7.97468H8.82542C8.07011 7.97468 7.34574 7.67464 6.81166 7.14056C6.27758 6.60648 5.97754 5.88211 5.97754 5.12681C5.97754 4.3715 6.27758 3.64714 6.81166 3.11305C7.34574 2.57897 8.07011 2.27893 8.82542 2.27893C12.8124 2.27893 13.9516 7.97468 13.9516 7.97468Z"
        stroke="currentColor"
        stroke-width="1.56226"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M13.9529 7.97468H19.0791C19.8344 7.97468 20.5587 7.67464 21.0928 7.14056C21.6269 6.60648 21.9269 5.88211 21.9269 5.12681C21.9269 4.3715 21.6269 3.64714 21.0928 3.11305C20.5587 2.57897 19.8344 2.27893 19.0791 2.27893C15.092 2.27893 13.9529 7.97468 13.9529 7.97468Z"
        stroke="currentColor"
        stroke-width="1.56226"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
