class CardCenterResponse {
  cardCenter?: CardCenter;

  constructor(cardCenter?: CardCenter) {
    this.cardCenter = cardCenter;
  }

  static fromJson(json: { [key: string]: any }): CardCenterResponse {
    return new CardCenterResponse(
      json['card_center']
        ? CardCenter.fromJson(json['card_center'])
        : undefined,
    );
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.cardCenter) {
      data['card_center'] = this.cardCenter.toJson();
    }
    return data;
  }
}

class CardCenter {
  totalMultiplier?: number;
  totalVantaHands?: number;
  totalVantaCards?: number;
  listedVantaCards?: number;
  totalElementCards?: number;
  listedElementCards?: number;
  maxMultiplier?: number;
  isDiamondHands?: boolean;
  stakedVantaCards?: number;
  stakedEternalVantaCards?: number;
  stakedVantaDailyCoins?: number;

  constructor({
    totalMultiplier = 1.0,
    totalVantaHands = 0,
    totalVantaCards = 0,
    listedVantaCards = 0,
    totalElementCards = 0,
    listedElementCards = 0,
    maxMultiplier = 1.0,
    isDiamondHands = false,
    stakedVantaCards = 0,
    stakedEternalVantaCards = 0,
    stakedVantaDailyCoins = 0,
  }: {
    totalMultiplier?: number;
    totalVantaHands?: number;
    totalVantaCards?: number;
    listedVantaCards?: number;
    totalElementCards?: number;
    listedElementCards?: number;
    maxMultiplier?: number;
    isDiamondHands?: boolean;
    stakedVantaCards?: number;
    stakedEternalVantaCards?: number;
    stakedVantaDailyCoins?: number;
  }) {
    this.totalMultiplier = totalMultiplier;
    this.totalVantaHands = totalVantaHands;
    this.totalVantaCards = totalVantaCards;
    this.listedVantaCards = listedVantaCards;
    this.totalElementCards = totalElementCards;
    this.listedElementCards = listedElementCards;
    this.maxMultiplier = maxMultiplier;
    this.isDiamondHands = isDiamondHands;
    this.stakedVantaCards = stakedVantaCards;
    this.stakedEternalVantaCards = stakedEternalVantaCards;
    this.stakedVantaDailyCoins = stakedVantaDailyCoins;
  }

  static fromJson(json: { [key: string]: any }): CardCenter {
    return new CardCenter({
      totalMultiplier: json['total_multiplier'] ?? 1.0,
      totalVantaHands: json['total_vanta_hands'] ?? 0,
      totalVantaCards: json['total_vanta_cards'] ?? 0,
      listedVantaCards: json['listed_vanta_cards'] ?? 0,
      totalElementCards: json['total_element_cards'] ?? 0,
      listedElementCards: json['listed_element_cards'] ?? 0,
      maxMultiplier: json['max_multiplier'] ?? 1.0,
      isDiamondHands: json['is_diamond_hands'] ?? false,
      stakedVantaCards: json['staked_vanta_cards'] ?? 0,
      stakedEternalVantaCards: json['staked_eternal_vanta_cards'] ?? 0,
      stakedVantaDailyCoins: json['staked_vanta_daily_coins'] ?? 0,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      total_multiplier: this.totalMultiplier,
      total_vanta_hands: this.totalVantaHands,
      total_vanta_cards: this.totalVantaCards,
      listed_vanta_cards: this.listedVantaCards,
      total_element_cards: this.totalElementCards,
      listed_element_cards: this.listedElementCards,
      max_multiplier: this.maxMultiplier,
      is_diamond_hands: this.isDiamondHands,
      staked_vanta_cards: this.stakedVantaCards,
      staked_eternal_vanta_cards: this.stakedEternalVantaCards,
      staked_vanta_daily_coins: this.stakedVantaDailyCoins,
    };
  }
}

export { CardCenter, CardCenterResponse };
