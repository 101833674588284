import { axiosAssetDashApi } from './api';
import { AxiosResponse } from 'axios';

export enum GIVEAWAY_TYPE {
  SUPER_GIVEAWAY = 'super_giveaway',
}

export enum GIVEAWAY_STATUS {
  CREATED = 'created',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  NOT_YET_LIVE = 'not_live_yet',
}

export enum GIVEAWAY_PRIZE_TYPE {
  DIGITAL_GOOD = 'digital_good',
  ASSETDASH_COINS = 'assetdash_coins',
}

export enum GIVEAWAY_PRIZE_CHAIN {
  ETH = 'ETH',
  SOL = 'SOL',
  FLOW = 'FLOW',
  NEAR = 'NEAR',
  MATIC = 'MATIC',
  BITCOIN = 'BITCOIN',
  BSC = 'BSC',
  AVAX = 'AVAX',
  ARB = 'ARB',
}

export interface GameDashboardBanner {
  image_url: string;
  page: string;
  page_id?: string | null;
}

export const getGamesDashboardBanners = (): Promise<{
  data: {
    banners: GameDashboardBanner[];
  };
}> => {
  return axiosAssetDashApi.get('api/api_v4/games/dashboard-banners');
};

export interface GamesPartnersTag {
  id: string;
  name?: string | null;
}

export const getGamesPartnersTagsList = (): Promise<{
  data: {
    tags: GamesPartnersTag[];
  };
}> => {
  return axiosAssetDashApi.get('api/api_v4/games/partners/tags/list');
};

export interface PartnerGame {
  id: string;
  name: string;
  cost_coins: number;
  status?: PARTNER_GAME_STATUS | null;
  display_priority?: string | null;
  image_url?: string | null;
  description?: string | null;
  instructions?: string[] | null;
  game_url?: string | null;
  tags: GamesPartnersTag[];
  instructions_link: string;
  terms_link: string;
}

export enum PARTNER_GAME_STATUS {
  CREATED = 'created',
  NOT_YET_LIVE = 'not_yet_live',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const getGamesPartnersGamesList = (
  tag?: string,
): Promise<AxiosResponse<{ partner_games: PartnerGame[] }>> => {
  const params = {
    ...(tag && { tag_ids: tag }),
  };
  return axiosAssetDashApi.get('api/api_v4/games/partners/games/list', {
    params,
  });
};

export interface PartnerGamePass {
  id: string;
  start_time: string;
  end_time: string;
  partner_game_id: string;
}

export const getGamesPartnersGame = (
  gameListingId: string,
): Promise<
  AxiosResponse<{
    partner_game: PartnerGame;
    partner_game_store: PartnerGameStore;
    partner_game_pass?: PartnerGamePass;
  }>
> => {
  return axiosAssetDashApi.get(
    `api/api_v4/games/partners/games/${gameListingId}`,
  );
};

export interface PartnerGameStore {
  banner_url: string;
  description: string;
  discord_url: string | null;
  id: string;
  image_url: string;
  name: string;
  twitter_url: string | null;
  website_url: string;
}

export const getGamesPartnerGameStoresList = (): Promise<
  AxiosResponse<{
    partner_game_stores: PartnerGameStore[];
  }>
> => {
  return axiosAssetDashApi.get(`api/api_v4/games/partners/stores/list`);
};

export const getGamesPartnersStore = (
  gameStoreId: string,
): Promise<
  AxiosResponse<{
    partner_game_store: PartnerGameStore;
    partner_games: PartnerGame[];
  }>
> => {
  return axiosAssetDashApi.get(
    `api/api_v4/games/partners/store/${gameStoreId}`,
  );
};

export const postGamesBuyPartnerGamePass = (
  partnerGameId: string,
): Promise<AxiosResponse<{ partner_game_pass: PartnerGamePass }>> => {
  return axiosAssetDashApi.post('/api/api_v4/games/partners/games/buy_pass', {
    partner_game_id: partnerGameId,
  });
};

export interface Giveaway {
  id: string;
  start_time: Date;
  end_time: Date;
  title: string;
  description: string;
  instructions?: string[];
  image_url?: string;
  type: GIVEAWAY_TYPE;
  status: GIVEAWAY_STATUS;
  entry_cost_coins: number;
  entry_limit?: number;

  eligible_vanta: boolean;
  eligible_platinum: boolean;
  eligible_gold: boolean;
  eligible_silver: boolean;
  eligible_bronze: boolean;

  total_passes: number;
  prizes: GiveawayPrize[];
  eligible_assets: GiveawayEligibleAsset[];
  eligible_partner_organizations: GiveawayEligiblePartner[];

  instructions_link: string;
  terms_link: string;
}

export interface GiveawayEligibleAsset {
  id: string;
  name?: string;
  logo_url?: string;
}

export interface GiveawayEligiblePartner {
  id: string;
  partner_id: string;
  name?: string;
  logo_url?: string;
}

export interface GiveawayPrize {
  id: string;
  prize_type: string;
  prize_chain?: string;
  title: string;
  description: string;
  image_url: string;
  num_winners: number;
  value: number;
}

export const getGiveaways = (): Promise<
  AxiosResponse<{
    giveaways: Giveaway[];
  }>
> => {
  return axiosAssetDashApi.get(`/api/api_v4/games/giveaways/list`);
};

export interface GiveawayPass {
  id: string;
  user_id: string;
  giveaway_id: string;
  code: string;
  winner: boolean;
}

export interface MyGiveawayUserPrize {
  id: string;
  user_id: string;
  giveaway_id: string;
  giveaway_prize_id: string;
  prize_title?: string;
  prize_image_url?: string;
  prize_link?: string;
  pass_code?: string;
  delivered_wallet_address?: string;
  delivered: boolean;
}

export const getGiveawayDetails = (
  giveawayId: string,
): Promise<
  AxiosResponse<{
    giveaway: Giveaway;
    giveaway_passes: GiveawayPass[];
    giveaway_user_prizes: MyGiveawayUserPrize[];
  }>
> => {
  return axiosAssetDashApi.get(`/api/api_v4/games/giveaways/${giveawayId}`);
};

export const getGiveawayMyPrizes = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/games/giveaways/my_prizes`);
};
export interface GiveawayPassPurchase {
  public_user_id: string;
  created: string;
  num_passes: number;
  num_coins: number;
}

export const getGiveawayPassPurchases = (
  giveawayId: string,
): Promise<
  AxiosResponse<{
    giveaway_pass_purchases: GiveawayPassPurchase[];
  }>
> => {
  return axiosAssetDashApi.get(
    `/api/api_v4/games/giveaways/${giveawayId}/pass_purchases`,
  );
};

export interface GiveawayPassLeaderboard {
  public_user_id: string;
  created: string;
  total_passes: number;
  total_coins: number;
}

export const getGiveawayPassLeaderboards = (
  giveawayId: string,
): Promise<
  AxiosResponse<{
    giveaway_pass_leaderboards: GiveawayPassLeaderboard[];
  }>
> => {
  return axiosAssetDashApi.get(
    `/api/api_v4/games/giveaways/${giveawayId}/pass_leaderboards`,
  );
};

export interface GiveawayUserPrize {
  public_user_id: string;
  prize_image_url: string;
  prize_title: number;
  pass_code: number;
}

export const getGiveawayUserPrizes = (
  giveawayId: string,
): Promise<
  AxiosResponse<{
    giveaway_user_prizes: GiveawayUserPrize[];
  }>
> => {
  return axiosAssetDashApi.get(
    `/api/api_v4/games/giveaways/${giveawayId}/user_prizes`,
  );
};

export const postBuyGiveawayPasses = (
  giveawayId: string,
  amount: number,
): Promise<AxiosResponse<{ partner_game_pass: PartnerGamePass }>> => {
  return axiosAssetDashApi.post('/api/api_v4/games/giveaways/buy_pass', {
    giveaway_id: giveawayId,
    amount: amount,
  });
};

export interface ArcadeGameTag {
  id: string;
  name: string;
}

export const getArcadeGameTags = (): Promise<
  AxiosResponse<{
    tags: ArcadeGameTag[];
  }>
> => {
  return axiosAssetDashApi.get(`/api/api_v4/games/arcade/tags/list`);
};

export interface ArcadeGame {
  id: string;
  game_id: string;
  name: string;
  status: string;
  description: string;
  image_url: string;
  game_url: string;
  cost_coins: number;
  instructions: string[];
  scoring_instructions: string[];
  tags: ArcadeGameTag[];
  instructions_link: string;
  terms_link: string;
}

export const getArcadeGames = (
  tag?: string,
): Promise<AxiosResponse<{ arcade_games: ArcadeGame[] }>> => {
  const params = {
    ...(tag && { tag_ids: tag }),
  };
  return axiosAssetDashApi.get('/api/api_v4/games/arcade/games/list', {
    params,
  });
};

export const getArcadeGameDetail = (
  arcadeGameId: string,
): Promise<
  AxiosResponse<{
    arcade_game: ArcadeGame;
  }>
> => {
  return axiosAssetDashApi.get(
    `/api/api_v4/games/arcade/games/${arcadeGameId}`,
  );
};
