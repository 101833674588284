import {
  InformationCircleIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';

// models
import { Auction } from '@/features/market/auctions/models/Auctions';
import { MyAuctionBidLeaderboard } from '@/features/market/auctions/models/AuctionDetail';
import { AuctionBidLeaderboard } from '@/features/market/auctions/models/AuctionBidLeaderboards';
import { CoinCenter } from '@/features/coins/models/CoinCenter';

import { AuctionStatusCard } from '../cards/AuctionStatusCard';
import { AuctionPrizesCard } from '../cards/AuctionPrizesCard';
import { AuctionEligibilityCard } from '../cards/AuctionEligibilityCard';
import { AuctionPlaceBidCard } from '../cards/AuctionPlaceBidCard';
import { AuctionInfoCard } from '../cards/AuctionInfoCard';
import { AuctionInstructionsCard } from '../cards/AuctionInstructionsCard';
import { AuctionCountdownCard } from '../cards/AuctionCountdownCard';

interface AuctionDetailsTabProps {
  auction: Auction;
  myAuctionBidLeaderboard?: MyAuctionBidLeaderboard;
  auctionBidLeaderboards: AuctionBidLeaderboard[];
  coinCenter: CoinCenter;
}

export const AuctionDetailsTab: React.FC<AuctionDetailsTabProps> = ({
  auction,
  myAuctionBidLeaderboard,
  auctionBidLeaderboards,
  coinCenter,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-0 md:gap-8 px-4">
      <div className={`md:hidden font-semibold text-left text-3xl`}>
        {auction.title}
      </div>
      <div className="md:col-span-2 lg:col-span-2 bg-white p-3 dark:bg-deals-card-top rounded-13 h-max">
        <img
          src={auction.imageUrl}
          className="w-full h-96 object-contain mx-auto"
          alt="logo"
        />
      </div>
      <div className="text-center md:col-span-2 lg:col-span-3 space-y-4">
        <div className={`hidden md:block font-semibold text-left text-3xl`}>
          {auction.title}
        </div>
        <AuctionStatusCard auction={auction} />
        <div
          className={`grid 
                  'grid-cols-1'
                } gap-6 my-8`}
        >
          <div className="text-left space-y-2">
            <div className="flex justify-between">
              <p className="text-xl font-bold">Description</p>
              <div className="flex space-x-2">
                <InformationCircleIcon
                  className="cursor-pointer text-accent"
                  height={24}
                  width={24}
                  onClick={() => window.open(auction.instructionsLink)}
                />
                <ListBulletIcon
                  className="cursor-pointer text-accent"
                  height={24}
                  width={24}
                  onClick={() => window.open(auction.termsLink)}
                />
              </div>
            </div>
            <p className="text-element-text-light dark:text-element-text-dark">
              {auction.description}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 my-8">
          <AuctionPrizesCard prizes={auction.prizes} />
        </div>
        <div className="grid grid-cols-1 gap-6 my-8">
          <AuctionEligibilityCard auction={auction} />
        </div>
        <div className="grid grid-cols-1 gap-6 my-8">
          <AuctionPlaceBidCard
            auction={auction}
            myAuctionBidLeaderboard={myAuctionBidLeaderboard}
            topAuctionBidLeaderboard={auctionBidLeaderboards[0]}
            coinCenter={coinCenter}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 my-8 text-gray-300">
          <AuctionInfoCard
            auction={auction}
            auctionBidLeaderboard={myAuctionBidLeaderboard}
          />
        </div>

        {auction.instructions && auction.instructions.length > 0 && (
          <div className="grid gap-6 my-8 ">
            <AuctionInstructionsCard instructions={auction.instructions} />
          </div>
        )}

        <div className="grid gap-6 my-8 ">
          <AuctionCountdownCard auction={auction} />
        </div>
      </div>
    </div>
  );
};
