import axios from 'axios';
import { handleConfigError, setBearerToken } from '../utils/api';

export const axiosAssetDashApi = axios.create({
  baseURL: process.env.REACT_APP_ASSETDASH_API,
});

axiosAssetDashApi.interceptors.request.use(setBearerToken, handleConfigError);

// prevent caching api calls
axiosAssetDashApi.interceptors.request.use(
  config => {
    config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
