export class DealsResponse {
  deals?: Deal[];

  constructor({ deals }: { deals?: Deal[] }) {
    this.deals = deals;
  }

  static fromJson(json: any): DealsResponse {
    const deals = json['deals'] ? json['deals'].map((v: any) => Deal.fromJson(v)) : undefined;
    return new DealsResponse({ deals });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.deals) data['deals'] = this.deals.map((v) => v.toJson());
    return data;
  }
}

export class DealDetailResponse {
  deal?: Deal;
  userCoupon?: UserCoupon;
  relatedDeals?: Deal[];

  constructor({ deal, userCoupon, relatedDeals }: { deal?: Deal, userCoupon?: UserCoupon, relatedDeals?: Deal[] }) {
    this.deal = deal;
    this.userCoupon = userCoupon;
    this.relatedDeals = relatedDeals;
  }

  static fromJson(json: any): DealDetailResponse {
    const deal = json['deal'] ? Deal.fromJson(json['deal']) : undefined;
    const userCoupon = json['user_coupon'] ? UserCoupon.fromJson(json['user_coupon']) : undefined;
    const relatedDeals = json['related_deals'] ? json['related_deals'].map((v: any) => Deal.fromJson(v)) : undefined;

    return new DealDetailResponse({ deal, userCoupon, relatedDeals });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.deal) data['deal'] = this.deal.toJson();
    if (this.userCoupon) data['user_coupon'] = this.userCoupon.toJson();
    if (this.relatedDeals) data['related_deals'] = this.relatedDeals.map((deal) => deal.toJson());

    return data;
  }
}

export class Deal {
  id?: string;
  status?: string;
  store?: Store;
  name?: string;
  description?: string;
  websiteUrl?: string;
  twitterUrl?: string;
  discordUrl?: string;
  startDate?: string;
  endDate?: string;
  data?: Data;
  customChip?: string;
  imageUrl?: string;
  dealText?: string;
  customButtonText?: string;
  customCouponText?: string;
  customWebsiteText?: string;
  customLabelText?: string;
  customWebsiteUrl?: string;
  numCoupons?: number;
  numCouponsClaimed?: number;
  percentCouponsClaimed?: number;
  soldOut?: boolean;
  instructions?: string[];
  cashBackTicket?: number;
  cashBackPercentage?: number;
  special?: string;
  cashBackPercentTiers?: CashBackPercentTiers;
  cashBackTicketsTiers?: CashBackTicketsTiers;
  isTopTier?: boolean;
  isVanta?: boolean;
  costCoins?: number;
  nftChain?: string;
  startTime?: Date;

  constructor(args: {
    id?: string,
    status?: string,
    store?: Store,
    name?: string,
    description?: string,
    websiteUrl?: string,
    twitterUrl?: string,
    discordUrl?: string,
    startDate?: string,
    endDate?: string,
    data?: Data,
    customChip?: string,
    imageUrl?: string,
    dealText?: string,
    customButtonText?: string,
    customCouponText?: string,
    customWebsiteText?: string,
    customLabelText?: string,
    customWebsiteUrl?: string,
    numCoupons?: number,
    numCouponsClaimed?: number,
    percentCouponsClaimed?: number,
    soldOut?: boolean,
    instructions?: string[],
    cashBackTicket?: number,
    cashBackPercentage?: number,
    special?: string,
    cashBackPercentTiers?: CashBackPercentTiers,
    cashBackTicketsTiers?: CashBackTicketsTiers,
    isTopTier?: boolean,
    isVanta?: boolean,
    costCoins?: number,
    nftChain?: string,
    startTime?: Date
  }) {
    Object.assign(this, args);
  }

  static fromJson(json: any): Deal {
    return new Deal({
      id: json['id'],
      status: json['status'],
      store: json['store'] ? Store.fromJson(json['store']) : undefined,
      name: json['name'],
      description: json['description'],
      websiteUrl: json['website_url'],
      twitterUrl: json['twitter_url'],
      discordUrl: json['discord_url'],
      startDate: json['start_date'],
      endDate: json['end_date'],
      data: json['data'] ? Data.fromJson(json['data']) : undefined,
      customChip: json['custom_chip'],
      imageUrl: json['image_url'],
      dealText: json['deal_text'],
      customButtonText: json['custom_button_text'],
      customCouponText: json['custom_coupon_text'],
      customWebsiteText: json['custom_website_text'],
      customLabelText: json['custom_label_text'],
      customWebsiteUrl: json['custom_website_url'],
      numCoupons: json['num_coupons'],
      numCouponsClaimed: json['num_coupons_claimed'],
      percentCouponsClaimed: json['percent_coupons_claimed'],
      soldOut: json['sold_out'],
      cashBackTicket: json['cash_back_tickets'],
      special: json['specials'],
      instructions: json['instructions'] ? json['instructions'] as string[] : undefined,
      cashBackPercentage: json['cash_back_percent'],
      isTopTier: json['is_top_tier'],
      isVanta: json['is_vanta'],
      costCoins: json['cost_coins'],
      nftChain: json['nft_chain'],
      startTime: json['start_time'] ? new Date(json['start_time'] + 'Z') : undefined,
      cashBackPercentTiers: json['cash_back_percent_tiers'] ? CashBackPercentTiers.fromJson(json['cash_back_percent_tiers']) : undefined,
      cashBackTicketsTiers: json['cash_back_tickets_tiers'] ? CashBackTicketsTiers.fromJson(json['cash_back_tickets_tiers']) : undefined
    });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    data['id'] = this.id;
    if (this.store) data['store'] = this.store.toJson();
    data['name'] = this.name;
    data['description'] = this.description;
    data['start_date'] = this.startDate;
    data['end_date'] = this.endDate;
    return data;
  }

  // Placeholder methods for getGradient, showDealCountdown, etc.
}

class CashBackPercentTiers {
  bronze?: number;
  silver?: number;
  gold?: number;
  platinum?: number;

  constructor({ bronze, silver, gold, platinum }: { bronze?: number, silver?: number, gold?: number, platinum?: number }) {
    this.bronze = bronze;
    this.silver = silver;
    this.gold = gold;
    this.platinum = platinum;
  }

  static fromJson(json: any): CashBackPercentTiers {
    return new CashBackPercentTiers({
      bronze: json['bronze'],
      silver: json['silver'],
      gold: json['gold'],
      platinum: json['platinum']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      bronze: this.bronze,
      silver: this.silver,
      gold: this.gold,
      platinum: this.platinum
    };
  }
}

class CashBackTicketsTiers {
  bronze?: number;
  silver?: number;
  gold?: number;
  platinum?: number;

  constructor({ bronze, silver, gold, platinum }: { bronze?: number, silver?: number, gold?: number, platinum?: number }) {
    this.bronze = bronze;
    this.silver = silver;
    this.gold = gold;
    this.platinum = platinum;
  }

  static fromJson(json: any): CashBackTicketsTiers {
    return new CashBackTicketsTiers({
      bronze: json['bronze'],
      silver: json['silver'],
      gold: json['gold'],
      platinum: json['platinum']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      bronze: this.bronze,
      silver: this.silver,
      gold: this.gold,
      platinum: this.platinum
    };
  }
}

class Store {
  id?: string;
  name?: string;
  storeUrl?: string;
  imageUrl?: string;
  tags?: Tags[];

  constructor({ id, name, storeUrl, imageUrl, tags }: { id?: string, name?: string, storeUrl?: string, imageUrl?: string, tags?: Tags[] }) {
    this.id = id;
    this.name = name;
    this.storeUrl = storeUrl;
    this.imageUrl = imageUrl;
    this.tags = tags;
  }

  static fromJson(json: any): Store {
    return new Store({
      id: json['id'],
      name: json['name'],
      storeUrl: json['store_url'],
      imageUrl: json['image_url'],
      tags: json['tags'] ? json['tags'].map((v: any) => Tags.fromJson(v)) : undefined
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      name: this.name,
      storeUrl: this.storeUrl,
      imageUrl: this.imageUrl,
      tags: this.tags ? this.tags.map((v) => v.toJson()) : undefined
    };
  }
}

class Data {
  amount?: number;
  appliesTo?: string;
  type?: string;

  constructor({ amount, appliesTo, type }: { amount?: number, appliesTo?: string, type?: string }) {
    this.amount = amount;
    this.appliesTo = appliesTo;
    this.type = type;
  }

  static fromJson(json: any): Data {
    return new Data({
      amount: json['amount'],
      appliesTo: json['applies_to'],
      type: json['type']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      amount: this.amount,
      appliesTo: this.appliesTo,
      type: this.type
    };
  }
}

class Tags {
  id?: string;
  name?: string;

  constructor({ id, name }: { id?: string, name?: string }) {
    this.id = id;
    this.name = name;
  }

  static fromJson(json: any): Tags {
    return new Tags({
      id: json['id'],
      name: json['name']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      name: this.name
    };
  }
}

export class UserCoupon {
  id?: string;
  storeId?: string;
  dealId?: string;
  couponId?: string;
  couponCode?: string;
  store?: Store;
  deal?: Deal;
  affliateLink?: string;

  constructor({ id, storeId, dealId, couponId, couponCode, store, deal, affliateLink }: { id?: string, storeId?: string, dealId?: string, couponId?: string, couponCode?: string, store?: Store, deal?: Deal, affliateLink?: string }) {
    this.id = id;
    this.storeId = storeId;
    this.dealId = dealId;
    this.couponId = couponId;
    this.couponCode = couponCode;
    this.store = store;
    this.deal = deal;
    this.affliateLink = affliateLink;
  }

  static fromJson(json: any): UserCoupon {
    return new UserCoupon({
      id: json['id'],
      storeId: json['store_id'],
      dealId: json['deal_id'],
      couponId: json['coupon_id'],
      couponCode: json['coupon_code'],
      affliateLink: json['affiliate_link'],
      store: json['store'] ? Store.fromJson(json['store']) : undefined,
      deal: json['deal'] ? Deal.fromJson(json['deal']) : undefined
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      storeId: this.storeId,
      dealId: this.dealId,
      couponId: this.couponId,
      couponCode: this.couponCode,
      affliateLink: this.affliateLink,
      store: this.store ? this.store.toJson() : undefined,
      deal: this.deal ? this.deal.toJson() : undefined
    };
  }
}
