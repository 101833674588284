// vendor libraries
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

// components
import { SelectAssetType } from './SelectAssetType';
import { PrivateEquityView } from './PrivateEquityView';
import { SearchListView } from './SearchListView';
import { SaveButton } from '../../../common/Button/SaveButton';

// apis
import { addManualAsset, editManualAsset } from '@/api/wallets';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { AssetSearchResult } from '@/features/assets/models/AssetSearchResults';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

interface ManualAssetFormProps {
  walletId: string;
  assetType: string;
  holding: WalletHolding | null;
  closeForm: () => void;
}

export const ManualAssetForm: React.FC<ManualAssetFormProps> = ({
  walletId,
  assetType,
  holding,
  closeForm,
}) => {
  const { load } = usePortfolio();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedAsset, setSelectedAsset] = useState<AssetSearchResult | null>(
    null,
  );
  const [quantity, setQuantity] = useState<number | ''>(
    holding?.quantity || '',
  );
  const [value, setValue] = useState<number | ''>(holding?.value || '');
  const [name, setName] = useState(holding?.manualAsset?.name || '');
  const [numberOfShares, setNumberOfShares] = useState<number | ''>(
    holding?.quantity || '',
  );
  const [pricePerShare, setPricePerShare] = useState<number | ''>(
    holding?.price || '',
  );
  const [percentOwnership, setPercentOwnership] = useState<number | ''>(
    holding?.percentOwnership || '',
  );
  const [companyEvaluation, setCompanyEvaluation] = useState<number | ''>(
    holding?.companyValuation || '',
  );
  const [selectedEquity, setSelectedEquity] = useState(
    holding?.percentOwnership ? 'percent_ownership' : 'number_of_shares',
  );

  const resetData = () => {
    setSelectedAsset(null);
    setQuantity('');
    setValue('');
    setName('');
    setNumberOfShares('');
    setPricePerShare('');
    setPercentOwnership('');
    setCompanyEvaluation('');
    setSelectedEquity('number_of_shares');
  };

  const updateManualHolding = async () => {
    if (valid) {
      let payload;

      if (assetType === 'staked' || assetType === 'metals') {
        let type = assetType;
        if (assetType === 'staked') {
          type = 'nft';
        }
        payload = {
          manualWalletId: walletId,
          quantity: quantity!,
          assetId: selectedAsset?.id!,
          assetType: type!,
        };
      } else if (
        assetType === 'stocks' ||
        assetType === 'nft' ||
        assetType === 'crypto'
      ) {
        payload = {
          manualWalletId: walletId,
          quantity: quantity!,
          name: selectedAsset?.name,
          assetId: selectedAsset?.id,
          assetType: selectedAsset?.type!,
        };
      } else if (assetType === 'private_equity') {
        if (selectedEquity === 'number_of_shares') {
          payload = {
            manualWalletId: walletId,
            name: name,
            assetType: assetType!,
            quantity: numberOfShares!,
            price: pricePerShare!,
          };
        } else {
          payload = {
            manualWalletId: walletId,
            name: name,
            assetType: assetType!,
            percentOwnership: percentOwnership!,
            companyValuation: companyEvaluation!,
          };
        }
      } else {
        payload = {
          manualWalletId: walletId,
          value: value!,
          assetType: assetType!,
          name: name,
        };
      }

      try {
        setIsLoading(true);
        if (!holding) {
          await addManualAsset(payload);
        } else if (holding) {
          await editManualAsset({ holdingId: holding.id!, ...payload });
        }
        toast.success(`Holding ${holding ? 'updated' : 'created'}`);
        await load({ checkCache: false });
      } catch (err) {
        toast.error(
          (err as any)?.response?.data?.detail || 'Something went wrong',
        );
      }
      resetData();
      closeForm();
      setIsLoading(false);
    }
  };

  const cancel = () => {
    closeForm();
    resetData();
  };

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (assetType === 'staked' || assetType === 'metals') {
      return setValid(
        quantity! > 0 && (selectedAsset !== null || holding !== null),
      );
    } else if (assetType === 'private_equity') {
      if (selectedEquity === 'number_of_shares') {
        setValid(name !== '' && numberOfShares! > 0 && pricePerShare! > 0);
      } else {
        setValid(
          name !== '' &&
            percentOwnership !== null &&
            companyEvaluation !== null &&
            percentOwnership > 0 &&
            percentOwnership <= 100 &&
            companyEvaluation > 0,
        );
      }
    } else if (['stocks', 'nft', 'crypto'].includes(assetType)) {
      return setValid(
        (selectedAsset !== null || holding !== null) && quantity! > 0,
      );
    } else {
      return setValid(value! > 0 && name !== '');
    }
  }, [
    assetType,
    selectedAsset,
    quantity,
    value,
    name,
    numberOfShares,
    pricePerShare,
    percentOwnership,
    companyEvaluation,
    holding,
    selectedEquity,
  ]);

  return (
    <div className="flex flex-col md:flex-row justify-between h-full">
      <div className="py-6 px-4 h-max space-y-4 w-full md:w-93.5 rounded-card-box bg-white dark:bg-deals-card-top">
        <div className=" space-y-4">
          {['crypto', 'stocks', 'nft'].includes(assetType) && (
            <SearchListView
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              assetType={assetType}
              quantity={quantity!}
              setQuantity={setQuantity}
              holding={holding}
            />
          )}
          {assetType === 'private_equity' && (
            <PrivateEquityView
              equityName={name}
              setEquityName={setName}
              numberOfShares={numberOfShares}
              setNumberOfShares={setNumberOfShares}
              pricePerShare={pricePerShare}
              setPricePerShare={setPricePerShare}
              percentOwnership={percentOwnership}
              setPercentOwnership={setPercentOwnership}
              companyEvaluation={companyEvaluation}
              setCompanyEvaluation={setCompanyEvaluation}
              selectedEquity={selectedEquity}
              setSelectedEquity={setSelectedEquity}
            />
          )}
          {['staked', 'metals'].includes(assetType) && (
            <SelectAssetType
              assetType={assetType}
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              quantity={quantity}
              setQuantity={setQuantity}
              holding={holding}
            />
          )}
          {['real_estate', 'collectibles', 'other'].includes(assetType) && (
            <div className="space-y-6 ">
              <div className="space-y-2">
                <div>Name</div>
                <input
                  type="text"
                  className="h-16 w-full rounded-xl px-4 bg-primary-gray dark:bg-deals-card"
                  placeholder="Enter Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>

              {!['real_estate', 'collectibles', 'other'].includes(assetType) ? (
                <div className="space-y-2">
                  <div>Quantity</div>
                  <input
                    type="number"
                    className="h-16 w-full rounded-xl px-4 bg-primary-gray dark:bg-deals-card"
                    placeholder="Enter Quantity"
                    value={quantity}
                    onChange={e =>
                      setQuantity(
                        e.target.value ? parseFloat(e.target.value) : '',
                      )
                    }
                  />
                </div>
              ) : (
                <div className="space-y-2">
                  <div>Value</div>
                  <input
                    type="number"
                    className="h-16 w-full rounded-xl px-4 bg-primary-gray dark:bg-deals-card"
                    placeholder="Enter Value"
                    value={value}
                    onChange={e =>
                      setValue(e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="relative md:absolute right-0 md:right-20 bottom-0 md:bottom-10 mt-4 md:mt-0">
        <div className="flex flex-col md:flex-row md:space-x-2 space-y-4 md:space-y-0">
          <button
            className="w-45.75 bg-white dark:bg-primary-dark py-4 px-8 leading-17 font-semibold text-sm rounded-xl text-price-green"
            onClick={cancel}
          >
            Cancel
          </button>
          <SaveButton
            valid={valid}
            onClick={updateManualHolding}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
