import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { toastError } from '../api/utils';

import expeditionsApi from '../api/expeditions';

import {
  AssetDashExpedition,
  AssetDashExpeditionsResponse,
} from '../features/expeditions/models/AssetDashExpeditions';
import { AssetDashExpeditionDetailResponse } from '../features/expeditions/models/AssetDashExpeditionDetail';
import {
  AssetDashExpeditionTag,
  AssetDashExpeditionTagsResponse,
} from '../features/expeditions/models/AssetDashExpeditionTag';
import {
  ExpeditionCenter,
  ExpeditionCenterResponse,
} from '../features/expeditions/models/ExpeditionCenter';
import {
  PartnerExpedition,
  PartnerExpeditionsResponse,
} from '../features/expeditions/models/PartnerExpeditions';
import { PartnerExpeditionDetailResponse } from '../features/expeditions/models/PartnerExpeditionDetail';
import {
  PartnerExpeditionTag,
  PartnerExpeditionTagsResponse,
} from '../features/expeditions/models/PartnerExpeditionTag';
import {
  DashboardBanner,
  BannersResponse,
} from '../features/core/models/Banner';

interface ExpeditionsContextProps {
  // Center
  expeditionCenter?: ExpeditionCenter;

  // Banners
  banners: DashboardBanner[];

  // AssetDash Expeditions
  assetdashExpeditionTags: AssetDashExpeditionTag[];
  assetdashExpeditions: AssetDashExpedition[];
  completedAssetDashExpeditions: AssetDashExpedition[];
  assetdashExpeditionDetail?: AssetDashExpeditionDetailResponse;
  assetdashTagExpeditions?: AssetDashExpedition[];

  // Partner Expeditions
  partnerExpeditionTags: PartnerExpeditionTag[];
  partnerExpeditions: PartnerExpedition[];
  completedPartnerExpeditions: PartnerExpedition[];
  partnerExpeditionDetail?: PartnerExpeditionDetailResponse;
  partnerTagExpeditions?: PartnerExpedition[];

  // methods
  load: (showLoading?: boolean) => Promise<void>;
  getExpeditionCenter: () => Promise<void>;
  getExpeditionsBanners: () => Promise<void>;
  getAssetDashExpeditionTags: () => Promise<void>;
  getAssetDashExpeditions: () => Promise<void>;
  getCompletedAssetDashExpeditions: (
    assetdashExpeditionTagId: string,
  ) => Promise<void>;
  clearAssetDashTagExpeditions: () => void;
  getAssetDashTagExpeditions: (
    assetdashExpeditionTagId: string,
  ) => Promise<void>;
  clearAssetDashExpeditionDetail: () => void;
  getAssetDashExpeditionDetail: (
    assetdashExpeditionId: string,
  ) => Promise<void>;
  getPartnerExpeditionTags: () => Promise<void>;
  getPartnerExpeditions: () => Promise<void>;
  getCompletedPartnerExpeditions: () => Promise<void>;
  clearPartnerTagExpeditions: () => void;
  getPartnerTagExpeditions: (partnerExpeditionTagId: string) => Promise<void>;
  clearPartnerExpeditionDetail: () => void;
  getPartnerExpeditionDetail: (partnerExpeditionId: string) => Promise<void>;
  launchPartnerExpedition: (partnerExpeditionId: string) => Promise<void>;

  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const ExpeditionsContext = createContext<
  ExpeditionsContextProps | undefined
>(undefined);

export const useExpeditions = (): ExpeditionsContextProps => {
  const context = useContext(ExpeditionsContext);
  if (!context) {
    throw new Error('useExpeditions must be used within a ExpeditionsProvider');
  }
  return context;
};

export const ExpeditionsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Center
  const [expeditionCenter, setExpeditionCenter] = useState<ExpeditionCenter>();

  // Banners
  const [banners, setBanners] = useState<DashboardBanner[]>([]);

  // AssetDash Expeditions
  const [assetdashExpeditionTags, setAssetdashExpeditionTags] = useState<
    AssetDashExpeditionTag[]
  >([]);
  const [assetdashExpeditions, setAssetdashExpeditions] = useState<
    AssetDashExpedition[]
  >([]);
  const [completedAssetDashExpeditions, setCompletedAssetDashExpeditions] =
    useState<AssetDashExpedition[]>([]);
  const [assetdashExpeditionDetail, setAssetdashExpeditionDetail] =
    useState<AssetDashExpeditionDetailResponse>();
  const [assetdashTagExpeditions, setAssetdashTagExpeditions] = useState<
    AssetDashExpedition[]
  >([]);

  // Partner Expeditions
  const [partnerExpeditionTags, setPartnerExpeditionTags] = useState<
    PartnerExpeditionTag[]
  >([]);
  const [partnerExpeditions, setPartnerExpeditions] = useState<
    PartnerExpedition[]
  >([]);
  const [completedPartnerExpeditions, setCompletedPartnerExpeditions] =
    useState<PartnerExpedition[]>([]);
  const [partnerExpeditionDetail, setPartnerExpeditionDetail] =
    useState<PartnerExpeditionDetailResponse>();
  const [partnerTagExpeditions, setPartnerTagExpeditions] = useState<
    PartnerExpedition[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);

  const load = async (showLoading = true) => {
    try {
      if (showLoading) {
        setIsLoading(true);
      }
      await getExpeditionCenter();
      await getExpeditionsBanners();
      await getAssetDashExpeditionTags();
      await getAssetDashExpeditions();
      await getCompletedAssetDashExpeditions();
      await getPartnerExpeditionTags();
      await getPartnerExpeditions();
      await getCompletedPartnerExpeditions();
    } catch (error) {
      toastError(error);
    } finally {
      if (showLoading) {
        setIsLoading(false);
      }
    }
  };

  const getExpeditionCenter = async () => {
    try {
      const response = await expeditionsApi.getUserExpeditionCenter();
      const expeditionCenterResponse = ExpeditionCenterResponse.fromJson(
        response.data,
      );
      setExpeditionCenter(expeditionCenterResponse.expeditionCenter!);
    } catch (error) {
      toastError(error);
    }
  };

  const getExpeditionsBanners = async () => {
    try {
      const response = await expeditionsApi.getExpeditionsBanner();
      const bannersResponse = BannersResponse.fromJson(response.data);
      setBanners(bannersResponse.banners!);
    } catch (error) {
      toastError(error);
    }
  };

  // AssetDash
  const getAssetDashExpeditionTags = async () => {
    try {
      const response = await expeditionsApi.getAssetDashExpeditionTags();
      const assetDashExpeditionTagsResponse =
        AssetDashExpeditionTagsResponse.fromJson(response.data);
      setAssetdashExpeditionTags(
        assetDashExpeditionTagsResponse.expeditionTags || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const getAssetDashExpeditions = async () => {
    try {
      const response = await expeditionsApi.getAssetDashExpeditions();
      const assetDashExpeditionsResponse =
        AssetDashExpeditionsResponse.fromJson(response.data);
      setAssetdashExpeditions(
        assetDashExpeditionsResponse.assetdashExpeditions || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const getCompletedAssetDashExpeditions = async () => {
    try {
      const response = await expeditionsApi.getCompletedAssetDashExpeditions();
      const assetDashExpeditionsResponse =
        AssetDashExpeditionsResponse.fromJson(response.data);
      setCompletedAssetDashExpeditions(
        assetDashExpeditionsResponse.assetdashExpeditions || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const clearAssetDashTagExpeditions = () => {
    setAssetdashTagExpeditions([]);
  };

  const getAssetDashTagExpeditions = async (
    assetdashExpeditionTagId: string,
  ) => {
    try {
      const response = await expeditionsApi.getAssetDashExpeditions(
        assetdashExpeditionTagId,
      );
      const assetDashExpeditionsResponse =
        AssetDashExpeditionsResponse.fromJson(response.data);
      setAssetdashTagExpeditions(
        assetDashExpeditionsResponse.assetdashExpeditions || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const clearAssetDashExpeditionDetail = () => {
    setAssetdashExpeditionDetail(undefined);
  };

  const getAssetDashExpeditionDetail = async (
    assetdashExpeditionId: string,
  ) => {
    try {
      const response = await expeditionsApi.getAssetDashExpeditionDetail(
        assetdashExpeditionId,
      );
      const assetDashExpeditionDetailResponse =
        AssetDashExpeditionDetailResponse.fromJson(response.data);
      setAssetdashExpeditionDetail(assetDashExpeditionDetailResponse);
    } catch (error) {
      toastError(error);
    }
  };

  // Partners
  const getPartnerExpeditionTags = async () => {
    try {
      const response = await expeditionsApi.getPartnerExpeditionTags();
      const partnerExpeditionTagsResponse =
        PartnerExpeditionTagsResponse.fromJson(response.data);
      setPartnerExpeditionTags(
        partnerExpeditionTagsResponse.expeditionTags || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const getPartnerExpeditions = async () => {
    try {
      const response = await expeditionsApi.getPartnerExpeditions();
      const partnerExpeditionsResponse = PartnerExpeditionsResponse.fromJson(
        response.data,
      );
      setPartnerExpeditions(
        partnerExpeditionsResponse.partnerExpeditions || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const getCompletedPartnerExpeditions = async () => {
    try {
      const response = await expeditionsApi.getCompletedPartnerExpeditions();
      const partnerExpeditionsResponse = PartnerExpeditionsResponse.fromJson(
        response.data,
      );
      setCompletedPartnerExpeditions(
        partnerExpeditionsResponse.partnerExpeditions || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const clearPartnerTagExpeditions = () => {
    setPartnerTagExpeditions([]);
  };

  const getPartnerTagExpeditions = async (partnerExpeditionTagId: string) => {
    try {
      const response = await expeditionsApi.getPartnerExpeditions(
        partnerExpeditionTagId,
      );
      const partnerExpeditionsResponse = PartnerExpeditionsResponse.fromJson(
        response.data,
      );
      setPartnerTagExpeditions(
        partnerExpeditionsResponse.partnerExpeditions || [],
      );
    } catch (error) {
      toastError(error);
    }
  };

  const clearPartnerExpeditionDetail = () => {
    setPartnerExpeditionDetail(undefined);
  };

  const getPartnerExpeditionDetail = async (partnerExpeditionId: string) => {
    try {
      const response =
        await expeditionsApi.getPartnerExpeditionDetail(partnerExpeditionId);
      const partnerExpeditionDetailResponse =
        PartnerExpeditionDetailResponse.fromJson(response.data);
      setPartnerExpeditionDetail(partnerExpeditionDetailResponse);
    } catch (error) {
      toastError(error);
    }
  };

  const launchPartnerExpedition = async (partnerExpeditionId: string) => {
    try {
      await expeditionsApi.launchPartnerExpedition(partnerExpeditionId);
      await getPartnerExpeditionDetail(partnerExpeditionId);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <ExpeditionsContext.Provider
      value={{
        // values
        expeditionCenter,

        // Banners
        banners,

        // AssetDash Expeditions
        assetdashExpeditionTags,
        assetdashExpeditions,
        completedAssetDashExpeditions,
        assetdashExpeditionDetail,
        assetdashTagExpeditions,

        // Partner Expeditions
        partnerExpeditionTags,
        partnerExpeditions,
        completedPartnerExpeditions,
        partnerExpeditionDetail,
        partnerTagExpeditions,

        // methods
        load,
        getExpeditionCenter,
        getExpeditionsBanners,
        getAssetDashExpeditionTags,
        getAssetDashExpeditions,
        getCompletedAssetDashExpeditions,
        clearAssetDashTagExpeditions,
        getAssetDashTagExpeditions,
        clearAssetDashExpeditionDetail,
        getAssetDashExpeditionDetail,
        getPartnerExpeditionTags,
        getPartnerExpeditions,
        getCompletedPartnerExpeditions,
        clearPartnerTagExpeditions,
        getPartnerTagExpeditions,
        clearPartnerExpeditionDetail,
        getPartnerExpeditionDetail,
        launchPartnerExpedition,

        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </ExpeditionsContext.Provider>
  );
};
