import { useEffect, useState } from 'react';
import { SuperSEO } from 'react-super-seo';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ConfettiExplosion from 'react-confetti-explosion';
// api
import { generateUserDealCoupon } from '../../api/deals';

// hooks
import { useDeals } from '@/hooks/useDeals';
import { useMember } from '@/hooks/useMember';
import { usePortfolio } from '@/hooks/usePortfolio';

// components
import { DealProgressBar } from '../../components/market/deals/DealProgressBar';
import { DealsRow } from '../../components/market/deals/DealsRow';
import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';
import { ConnectInvestmentsModal } from '../../components/common/Modal/ConnectInvestmentsModal';

export default function DealDetailsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const [connectInvestmentsModalOpen, setConnectInvestmentsModalOpen] =
    useState(false);
  const { memberCenterData } = useMember();
  const member_status = memberCenterData?.member_status;

  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { loadUserDealDetails, userDealDetail, userDealDetailsLoading } =
    useDeals();
  const [isExploding, setIsExploding] = useState(false);
  const [userCoupon, setUserCoupon] = useState<any>(null);

  const cashbackCategories = ['bronze', 'silver', 'gold', 'platinum'];

  const { verifiedAssetIds } = usePortfolio();

  useEffect(() => {
    if (id) {
      loadUserDealDetails(id);
      setUserCoupon(null);
      setIsExploding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async () => {
    if (member_status === 'standard') {
      setConnectInvestmentsModalOpen(true);
      return;
    }
    setIsSubmitLoading(true);
    try {
      const response = await generateUserDealCoupon(id!);
      const coupons = response.data;
      if (coupons.coupon != null) {
        setUserCoupon(coupons.coupon);
        toast.success('Deal claimed!');
        setIsExploding(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || 'Something went wrong');
    }
    setIsSubmitLoading(false);
  };

  let { deal, related_deals, user_coupon } = userDealDetail || {};

  if (!user_coupon && userCoupon) {
    user_coupon = {
      affiliate_link: userCoupon.affiliate_link,
      id: userCoupon.coupon_id,
      coupon_code: userCoupon.coupon_code,
      store: userCoupon.store,
    };
  }

  const {
    name,
    description,
    specials,
    store,
    data,
    deal_text,
    instructions,
    coins_cost,
    custom_button_text,
    custom_chip,
    custom_coupon_text,
    custom_label_text,
    custom_website_text,
    custom_website_url,
    percent_coupons_claimed,
    sold_out,
    cash_back_percent,
    cash_back_tickets,
    cash_back_tickets_tiers,
    cash_back_percent_tiers,
    nft_chain,
  } = deal || {};

  const memberPartnerIds = new Set(
    memberCenterData?.partner_organizations.map((m: any) => m.partner_id),
  );

  const isOldDeal =
    deal?.eligible_vanta == null &&
    deal?.eligible_platinum == null &&
    deal?.eligible_gold == null &&
    deal?.eligible_silver == null &&
    deal?.eligible_bronze == null;

  let isEligible = false;

  // Check deprecated eligibility
  if (deal?.is_vanta && member_status === 'vanta') {
    isEligible = true;
  } else if (
    deal?.is_top_tier &&
    ['vanta', 'platinum', 'gold'].includes(member_status)
  ) {
    isEligible = true;
  }

  if (isOldDeal) {
    if (deal?.is_vanta && deal?.is_top_tier == null) {
      isEligible = true;
    }
  }

  // Check eligibility
  if (
    (deal?.eligible_vanta === true && member_status === 'vanta') ||
    (deal?.eligible_platinum === true && member_status === 'platinum') ||
    (deal?.eligible_gold === true && member_status === 'gold') ||
    (deal?.eligible_silver === true && member_status === 'silver') ||
    (deal?.eligible_bronze === true && member_status === 'bronze')
  ) {
    isEligible = true;
  }
  if (!isEligible && deal?.eligible_assets != null) {
    const eligibleDeal = deal.eligible_assets.find(eligibleAsset =>
      verifiedAssetIds.includes(eligibleAsset.id),
    );
    if (eligibleDeal) {
      isEligible = true;
    }
  }

  // Check eligible partners
  if (!isEligible && deal?.eligible_partner_organizations != null) {
    const eligiblePartner = deal.eligible_partner_organizations.find(
      eligiblePartner => memberPartnerIds.has(eligiblePartner.partner_id),
    );
    if (eligiblePartner) {
      isEligible = true;
    }
  }

  const isValidUrl = (string: string): boolean => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const buttonText = sold_out
    ? 'Sold Out'
    : custom_button_text
    ? custom_button_text
    : 'Get Deal';
  const couponText = user_coupon?.affiliate_link
    ? 'Your Link'
    : user_coupon?.coupon_code;
  const labelText = custom_label_text ? custom_label_text : name;
  const websiteText = custom_website_text
    ? custom_website_text
    : 'Store Website';
  const websiteUrl = custom_website_url ? custom_website_url : store?.store_url;

  const products = data?.products;

  const image = deal?.image_url
    ? deal?.image_url
    : deal?.data && deal?.data.products
    ? deal?.data.products[0].image_src
    : store?.image_url ||
      'https://storage.googleapis.com/assetdash-prod-images/deals/gift.png';

  const copyCode = () => {
    navigator.clipboard.writeText(
      user_coupon?.affiliate_link
        ? user_coupon?.affiliate_link
        : user_coupon?.coupon_code,
    );
    setIsCopied(true);

    toast.success('Copied to clipboard!');

    let url = user_coupon?.affiliate_link
      ? user_coupon?.affiliate_link
      : websiteUrl;
    if (isValidUrl(user_coupon?.coupon_code)) {
      url = user_coupon?.coupon_code;
    }

    if (url) {
      setTimeout(() => {
        window.open(url, '_blank');
      }, 2000);
    }
  };

  const upperText = user_coupon?.affiliate_link
    ? 'Buy Now'
    : custom_coupon_text
    ? custom_coupon_text
    : 'Your Coupon Code';

  return (
    <>
      <SuperSEO title={`AssetDash | Deal Details`} lang="en_IE" />
      <div className="main-content mx-auto space-y-6">
        {!userDealDetailsLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-8 mt-8 px-4">
            <div className="md:col-span-2 lg:col-span-2 bg-white p-3 dark:bg-deals-card rounded-13 h-max">
              <img
                src={image}
                className="rounded-13 w-full h-auto mx-auto"
                alt="logo"
              />
            </div>
            <div className="text-center md:col-span-2 lg:col-span-3">
              <div className="flex flex-col lg:flex-row space-x-2 items-start md:items-center">
                <div className={`font-semibold text-left text-xl`}>
                  {labelText}
                </div>
                <div className="mb-3 mt-2 text-left space-x-2 whitespace-nowrap overflow-auto">
                  {deal_text && (
                    <span
                      className={`inline-flex items-center px-4 py-2 bg-white dark:bg-gray-700 rounded-full text-sm text-price-green`}
                    >
                      {deal_text}
                    </span>
                  )}
                  {data && (
                    <span
                      className={`inline-flex items-center px-4 py-2 rounded-full text-sm text-accent bg-white dark:bg-gray-700`}
                    >
                      {data?.applies_to === 'all_products'
                        ? 'All Products'
                        : 'Select Product'}
                    </span>
                  )}
                  {(coins_cost || custom_chip) && (
                    <span
                      className={`inline-flex items-center px-4 py-2 border bg-price-green bg-opacity-40 text-price-green border-price-green rounded-full text-sm`}
                    >
                      {coins_cost ? `${coins_cost} Coins` : custom_chip}
                    </span>
                  )}
                  {cash_back_tickets && (
                    <span
                      className={`inline-flex items-center px-4 py-2 bg-white dark:bg-gray-700 rounded-full text-sm text-price-green mr-2`}
                    >
                      {cash_back_tickets} Tickets
                    </span>
                  )}
                  {nft_chain && (
                    <span
                      className={`inline-flex items-center px-4 py-2 bg-white dark:bg-gray-700 rounded-full text-sm text-price-green mr-2`}
                    >
                      {nft_chain}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <h3
                  className={`text-2xl leading-3 capitalize text-black dark:text-gray-text`}
                >
                  {store?.name}
                </h3>
                {websiteText && websiteUrl && (
                  <a
                    href={websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-accent underline text-sm font-medium"
                  >
                    {websiteText}
                  </a>
                )}
              </div>
              <div
                className={`grid ${
                  specials ? 'grid-cols-2' : 'grid-cols-1'
                } gap-6 my-8`}
              >
                {description && (
                  <div className="text-left space-y-2">
                    <p className="text-xl font-bold">About Product</p>
                    <p className="text-gray-text">{description}</p>
                  </div>
                )}
                {specials && (
                  <div className="text-left space-y-2">
                    <p className="text-xl font-bold">Specials</p>
                    <p className="text-gray-text">{specials}</p>
                  </div>
                )}
              </div>

              {products && (
                <div>
                  <p className={`text-left mt-5`}>Products</p>
                  <div className="whitespace-nowrap overflow-auto h-208">
                    {products.map(product => {
                      return (
                        <a
                          key={product.id}
                          className={`flex rounded-13 p-2 mb-2 bg-white dark:bg-gray-700`}
                          href={product.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="flex space-x-8">
                            <img
                              src={product?.image_src}
                              alt="logo"
                              style={{ height: '70px' }}
                              className="rounded-13 mr-2 object-scale-down"
                            />
                            <div
                              className={`whitespace-normal items-center justify-center my-auto text-left`}
                            >
                              {product?.title}
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="pt-8">
                <DealProgressBar
                  percent={percent_coupons_claimed}
                  color={
                    deal?.is_vanta
                      ? 'vanta-color'
                      : deal?.is_top_tier
                      ? 'top-tier-color'
                      : 'price-green'
                  }
                />
              </div>

              {instructions && instructions.length > 0 && (
                <div className="mt-2 ">
                  <div>
                    <div className="space-y-4">
                      <p className="text-left text-3xl">Instructions</p>
                      <div className="space-y-2">
                        {instructions &&
                          instructions.length > 0 &&
                          instructions.map((instruction, index) => {
                            return (
                              <div
                                key={instruction}
                                className="flex items-center space-x-2"
                              >
                                <p className="min-w-6 text-black bg-price-green rounded-full">
                                  {index + 1}
                                </p>
                                <p className="text-left">{instruction}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-2 ">
                <div className="dark:bg-deals-card-top bg-white py-4 px-8 rounded-xl space-y-4">
                  {isEligible ? (
                    <div className="border p-1 rounded-2xl font-semibold border-primary text-primary">
                      You are eligible for this deal
                    </div>
                  ) : (
                    <div className="border p-1 rounded-xl font-semibold border-negative text-negative">
                      You are not eligible for this deal
                    </div>
                  )}
                  {['vanta', 'platinum', 'gold', 'silver', 'bronze'].map(
                    tier => {
                      const eligible_tier = `eligible_${tier}` as
                        | 'eligible_vanta'
                        | 'eligible_platinum'
                        | 'eligible_gold'
                        | 'eligible_silver'
                        | 'eligible_bronze';

                      const isEligible =
                        deal?.[eligible_tier] === true ||
                        (isOldDeal &&
                          (tier === 'platinum' ||
                            tier === 'gold' ||
                            tier === 'silver' ||
                            tier === 'bronze') &&
                          !deal?.is_vanta &&
                          !deal?.is_top_tier);

                      if (!isEligible) {
                        return null;
                      }
                      return (
                        <div
                          key={tier}
                          className="flex justify-between dark:text-gray-300 items-center"
                        >
                          <div className="flex items-center space-x-2">
                            <img
                              src={`https://storage.googleapis.com/assetdash-prod-images/member_center/logo_${tier}.png`}
                              alt={`${tier} logo`}
                              className="w-6 h-3"
                            />
                            <div className="font-semibold capitalize">
                              {tier}
                            </div>
                          </div>
                          {member_status === tier && (
                            <img
                              src="/assets/icons/check-circle.png"
                              alt="check-circle"
                              className="w-4 h-4"
                            />
                          )}
                        </div>
                      );
                    },
                  )}
                  {deal?.eligible_assets?.map(eligible_asset => {
                    return (
                      <div className="flex justify-between text-gray-300">
                        <div className="flex items-center space-x-2 ">
                          <img
                            src={eligible_asset.logo_url}
                            alt={eligible_asset.name}
                            className="w-6 h-3"
                          />
                          <div className="font-semibold capitalize">
                            {eligible_asset.name}
                          </div>
                        </div>
                        {verifiedAssetIds.includes(eligible_asset.id) && (
                          <img
                            src="/assets/icons/check-circle.png"
                            alt={`check-circle`}
                            className="w-6 h-6"
                          />
                        )}
                      </div>
                    );
                  })}
                  {deal?.eligible_partner_organizations?.map(
                    eligible_partner => {
                      return (
                        <div className="flex justify-between text-gray-300">
                          <div className="flex items-center space-x-2 ">
                            <img
                              src={eligible_partner.logo_url}
                              alt={eligible_partner.name}
                              className="w-6 h-3"
                            />
                            <div className="font-semibold capitalize">
                              {eligible_partner.name}
                            </div>
                          </div>
                          {memberPartnerIds.has(
                            eligible_partner.partner_id,
                          ) && (
                            <img
                              src="/assets/icons/check-circle.png"
                              alt={`check-circle`}
                              className="w-6 h-6"
                            />
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              </div>

              <div className="mt-16 sm:flex justify-center items-center text-right">
                {/* <div
                  className={`animate-pulse flex rounded-xl`}
                  style={{ width: '140px', height: '45px' }}
                ></div> */}
                {!user_coupon && (
                  <button
                    type="button"
                    className={`inline-flex ${
                      isSubmitLoading
                        ? 'bg-white dark:bg-tabs-bg'
                        : isCopied
                        ? 'bg-primary-gray dark:bg-gray-500'
                        : deal?.sold_out
                        ? 'bg-gray-200 dark:bg-dark text-negative'
                        : deal?.is_top_tier
                        ? 'bg-top-tier-color text-white'
                        : deal?.is_vanta
                        ? 'bg-vanta-color text-black'
                        : 'bg-price-green text-black'
                    } justify-center rounded-xl border border-transparent shadow-sm py-3 px-10 sm:ml-3 sm:w-auto sm:text-sm leading-17 font-semibold text-sm`}
                    disabled={sold_out || isSubmitLoading}
                    onClick={() => (couponText ? copyCode() : handleSubmit())}
                  >
                    {isSubmitLoading ? (
                      <div className="loader w-full"></div>
                    ) : (
                      <div>{buttonText}</div>
                    )}
                  </button>
                )}

                {isExploding && (
                  <ConfettiExplosion
                    duration={5000}
                    height={3000}
                    width={3000}
                  />
                )}

                {user_coupon && (
                  <div className="inline-block">
                    <div
                      className={`text-left bg-white dark:bg-gray-700 px-8 py-2 rounded-13`}
                      onClick={() => {
                        copyCode();
                      }}
                    >
                      <div>
                        <p className="text-center text-sm font-bold">
                          {upperText}
                        </p>
                        <div className="flex text-accent items-center text-2xl cursor-pointer">
                          <p className=" add-overflow-dot max-w-60">
                            {couponText}{' '}
                          </p>
                          <span className="ml-2 ">
                            <div className="relative h-4 w-4">
                              {!isCopied && (
                                <Copy
                                  css={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    strokeDasharray: 50,
                                    strokeDashoffset: isCopied ? -50 : 0,
                                    transition: 'all 300ms ease-in-out',
                                  }}
                                />
                              )}
                              {isCopied && (
                                <Check
                                  css={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    strokeDasharray: 50,
                                    strokeDashoffset: isCopied ? 0 : -50,
                                    transition: 'all 300ms ease-in-out',
                                  }}
                                />
                              )}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {cash_back_percent && (
              <div className="md:col-span-4 lg:col-span-5 text-left space-y-4 bg-white dark:bg-deals-card p-4 rounded-xl my-8">
                <p className="text-3xl font-bold text-price-green">
                  Cash Back By Tiers
                </p>
                {cashbackCategories?.map(item => {
                  return (
                    <div className="space-y-7">
                      <div className="flex space-x-4 items-center">
                        <img
                          src={`/assets/images/${item}_member.png`}
                          className="w-12 h-7"
                          alt={item}
                        />
                        <p className="font-semibold text-lg whitespace-wrap md:whitespace-nowrap">
                          <span className="capitalize">{item}</span> Member gets{' '}
                          <span className="text-price-green font-bold">
                            {cash_back_percent_tiers
                              ? cash_back_percent_tiers[item] ??
                                cash_back_percent
                              : cash_back_percent}
                            %
                          </span>{' '}
                          cash back and{' '}
                          <span className="text-price-green font-bold">
                            {cash_back_tickets_tiers
                              ? cash_back_tickets_tiers[item] ??
                                cash_back_tickets
                              : cash_back_tickets}
                          </span>{' '}
                          tickets
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {related_deals && related_deals.length > 0 && (
              <div className="md:col-span-4 lg:col-span-5">
                <DealsRow deals={related_deals} title="Related Deals" />
              </div>
            )}
          </div>
        ) : (
          <SkeletonLoader height={800} />
        )}
      </div>
      <ConnectInvestmentsModal
        isOpen={connectInvestmentsModalOpen}
        closeModal={() => setConnectInvestmentsModalOpen(false)}
        title="Verify to access exclusive deals and drops"
        description="You need to be Bronze Tier or higher to access this feature. AssetDash recommends having a verified wallet of $150 or higher to become Bronze Tier. Tap continue below to get started."
        imageLocation="/assets/images/connect_investment/gift.png"
      />
    </>
  );

  function Copy(props) {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.99944 9.71373H3.42801C3.12491 9.71373 2.83422 9.59332 2.61989 9.37899C2.40556 9.16467 2.28516 8.87398 2.28516 8.57087V3.42801C2.28516 3.12491 2.40556 2.83422 2.61989 2.61989C2.83422 2.40556 3.12491 2.28516 3.42801 2.28516H8.57087C8.87398 2.28516 9.16467 2.40556 9.37899 2.61989C9.59332 2.83422 9.71373 3.12491 9.71373 3.42801V3.99944M7.42801 6.28516H12.5709C13.2021 6.28516 13.7137 6.79683 13.7137 7.42801V12.5709C13.7137 13.2021 13.2021 13.7137 12.5709 13.7137H7.42801C6.79683 13.7137 6.28516 13.2021 6.28516 12.5709V7.42801C6.28516 6.79683 6.79683 6.28516 7.42801 6.28516Z"
          stroke="#F5AC6E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function Check(props) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <path d="M13.25 4.75L6 12L2.75 8.75" />
      </svg>
    );
  }
}
