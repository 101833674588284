import { axiosAssetDashApi } from './api';

export interface GetCryptoProvidersParams {
  name?: string;
  page: number;
  limit: number;
}

export const getCryptoProviders = async (params: GetCryptoProvidersParams) => {
  return await axiosAssetDashApi.get('/api/api_v3/providers/', {
    params,
  });
};
