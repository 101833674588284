export class ExpeditionCenter {
  availableAssetDashExpeditions?: number | null;
  completedAssetDashExpeditions?: number | null;
  availableAssetDashExpeditionCoins?: number | null;
  earnedAssetDashExpeditionCoins?: number | null;
  availablePartnerExpeditions?: number | null;
  completedPartnerExpeditions?: number | null;
  availablePartnerExpeditionCoins?: number | null;
  earnedPartnerExpeditionCoins?: number | null;

  constructor({
    availableAssetDashExpeditions,
    completedAssetDashExpeditions,
    availableAssetDashExpeditionCoins,
    earnedAssetDashExpeditionCoins,
    availablePartnerExpeditions,
    completedPartnerExpeditions,
    availablePartnerExpeditionCoins,
    earnedPartnerExpeditionCoins,
  }: {
    availableAssetDashExpeditions?: number | null;
    completedAssetDashExpeditions?: number | null;
    availableAssetDashExpeditionCoins?: number | null;
    earnedAssetDashExpeditionCoins?: number | null;
    availablePartnerExpeditions?: number | null;
    completedPartnerExpeditions?: number | null;
    availablePartnerExpeditionCoins?: number | null;
    earnedPartnerExpeditionCoins?: number | null;
  }) {
    this.availableAssetDashExpeditions = availableAssetDashExpeditions ?? null;
    this.completedAssetDashExpeditions = completedAssetDashExpeditions ?? null;
    this.availableAssetDashExpeditionCoins =
      availableAssetDashExpeditionCoins ?? null;
    this.earnedAssetDashExpeditionCoins =
      earnedAssetDashExpeditionCoins ?? null;
    this.availablePartnerExpeditions = availablePartnerExpeditions ?? null;
    this.completedPartnerExpeditions = completedPartnerExpeditions ?? null;
    this.availablePartnerExpeditionCoins =
      availablePartnerExpeditionCoins ?? null;
    this.earnedPartnerExpeditionCoins = earnedPartnerExpeditionCoins ?? null;
  }

  static fromJson(json: any): ExpeditionCenter {
    return new ExpeditionCenter({
      availableAssetDashExpeditions: json['available_assetdash_expeditions'],
      completedAssetDashExpeditions: json['completed_assetdash_expeditions'],
      availableAssetDashExpeditionCoins:
        json['available_assetdash_expedition_coins'],
      earnedAssetDashExpeditionCoins: json['earned_assetdash_expedition_coins'],
      availablePartnerExpeditions: json['available_partner_expeditions'],
      completedPartnerExpeditions: json['completed_partner_expeditions'],
      availablePartnerExpeditionCoins:
        json['available_partner_expedition_coins'],
      earnedPartnerExpeditionCoins: json['earned_partner_expedition_coins'],
    });
  }

  toJson(): any {
    return {
      available_assetdash_expeditions: this.availableAssetDashExpeditions,
      completed_assetdash_expeditions: this.completedAssetDashExpeditions,
      available_assetdash_expedition_coins:
        this.availableAssetDashExpeditionCoins,
      earned_assetdash_expedition_coins: this.earnedAssetDashExpeditionCoins,
      available_partner_expeditions: this.availablePartnerExpeditions,
      completed_partner_expeditions: this.completedPartnerExpeditions,
      available_partner_expedition_coins: this.availablePartnerExpeditionCoins,
      earned_partner_expedition_coins: this.earnedPartnerExpeditionCoins,
    };
  }
}

export class ExpeditionCenterResponse {
  expeditionCenter?: ExpeditionCenter | null;

  constructor({
    expeditionCenter,
  }: {
    expeditionCenter?: ExpeditionCenter | null;
  }) {
    this.expeditionCenter = expeditionCenter;
  }

  static fromJson(json: any): ExpeditionCenterResponse {
    return new ExpeditionCenterResponse({
      expeditionCenter: json['expedition_center']
        ? ExpeditionCenter.fromJson(json['expedition_center'])
        : null,
    });
  }

  toJson(): any {
    return {
      expedition_center: this.expeditionCenter
        ? this.expeditionCenter.toJson()
        : null,
    };
  }
}
