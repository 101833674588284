import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// components
import { ImageTag } from '../../common/Image/ImageTag';

export const CouponCard: React.FC = ({ coupon }) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const { store, deal, coupon_code, affiliate_link } = coupon || {};

  const couponText = affiliate_link ? 'Copy Link' : coupon_code;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const image = deal?.image_url
    ? deal?.image_url
    : deal?.data && deal?.data.products
    ? deal?.data.products[0].image_src
    : store?.image_url ||
      'https://storage.googleapis.com/assetdash-prod-images/deals/gift.png';

  const websiteUrl = affiliate_link
    ? affiliate_link
    : deal?.custom_website_url
    ? deal?.custom_website_url
    : store?.store_url;

  const copyCode = (event: React.MouseEvent) => {
    event.stopPropagation();

    navigator.clipboard.writeText(
      affiliate_link ? affiliate_link : coupon_code,
    );
    toast.success(`${affiliate_link ? 'Link' : 'Coupon code'} copied!`);
    setIsCopied(true);

    if (coupon?.affiliate_link || websiteUrl) {
      setTimeout(() => {
        window.open(
          coupon?.affiliate_link ? coupon?.affiliate_link : websiteUrl,
          '_blank',
        );
      }, 2000);
    }
  };

  return (
    <div
      onClick={() => {
        navigate(`/deals/${deal.id}`);
      }}
      className={`cursor-pointer h-92 w-44 border solid p-2 space-y-2 rounded-card-box`}
    >
      <div className="rounded-card-box">
        <ImageTag url={image} alt="logo" />
      </div>
      <div>
        <div className="text-xs leading-17 text-ellipsis whitespace-nowrap overflow-hidden text-gray-text">
          {deal.store.name}
        </div>
        <div className="text-sm leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {deal.name}
        </div>
      </div>
      <div>
        <div className="text-xs leading-17 text-ellipsis whitespace-nowrap overflow-hidden text-gray-text">
          {deal.custom_label_text ? deal.custom_label_text : 'Deal'}
        </div>
        <div className="text-sm leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {deal.deal_text}
        </div>
      </div>
      <div>
        <div className="text-xs leading-17 text-ellipsis whitespace-nowrap overflow-hidden text-gray-text">
          Coupon
        </div>
        <div
          className="text-sm leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden"
          onClick={event => {
            copyCode(event);
          }}
        >
          <div>
            <div className="flex text-accent items-center cursor-pointer">
              <p className=" add-overflow-dot max-w-60">{couponText} </p>
              <span className="ml-2 ">
                <div className="relative h-4 w-4">
                  {!isCopied && (
                    <Copy
                      css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        strokeDasharray: 50,
                        strokeDashoffset: isCopied ? -50 : 0,
                        transition: 'all 300ms ease-in-out',
                      }}
                    />
                  )}
                  {isCopied && (
                    <Check
                      css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        strokeDasharray: 50,
                        strokeDashoffset: isCopied ? 0 : -50,
                        transition: 'all 300ms ease-in-out',
                      }}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Copy(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99944 9.71373H3.42801C3.12491 9.71373 2.83422 9.59332 2.61989 9.37899C2.40556 9.16467 2.28516 8.87398 2.28516 8.57087V3.42801C2.28516 3.12491 2.40556 2.83422 2.61989 2.61989C2.83422 2.40556 3.12491 2.28516 3.42801 2.28516H8.57087C8.87398 2.28516 9.16467 2.40556 9.37899 2.61989C9.59332 2.83422 9.71373 3.12491 9.71373 3.42801V3.99944M7.42801 6.28516H12.5709C13.2021 6.28516 13.7137 6.79683 13.7137 7.42801V12.5709C13.7137 13.2021 13.2021 13.7137 12.5709 13.7137H7.42801C6.79683 13.7137 6.28516 13.2021 6.28516 12.5709V7.42801C6.28516 6.79683 6.79683 6.28516 7.42801 6.28516Z"
        stroke="#F5AC6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Check(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
}
