// vendor libraries
import { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';

// components
import { WalletHoldingsList } from './WalletHoldingsList';
import { WalletDetailsCard } from './WalletDetailsCard';
import { ManualAssetForm } from './manual_asset_form/ManualAssetForm';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

export const WalletDetailsContainer = () => {
  const { walletId } = useParams();
  const { walletsMap, isLoading } = usePortfolio();
  const [wallet, setWallet] = useState<Wallet>();

  useEffect(() => {
    if (walletId && walletsMap && walletsMap.has(walletId)) {
      setWallet(walletsMap.get(walletId));
    }
  }, [walletId, walletsMap]);

  const [manualAssetType, setManualAssetType] = useState<string | null>(null);
  const [selectedManualHolding, setSelectedManualHolding] =
    useState<WalletHolding | null>(null);
  const [action, setAction] = useState(null);

  const handleManualAssetSelect = (
    assetType: string,
    holding?: WalletHolding,
  ) => {
    if (wallet?.walletType === 'manual') {
      holding && setSelectedManualHolding(holding);
      setManualAssetType(assetType);
      setAction(action);
    }
  };

  if (!wallet || isLoading) {
    return (
      <>
        <SkeletonLoader height={200} />
        <SkeletonLoader className="mt-10" height={360} />
      </>
    );
  }

  return (
    <>
      {manualAssetType ? (
        <div className="h-screen col-span-1 overflow-scroll space-y-4">
          <ManualAssetForm
            walletId={walletId!}
            assetType={manualAssetType}
            closeForm={() => {
              setManualAssetType(null);
              setSelectedManualHolding(null);
            }}
            holding={selectedManualHolding}
          />
        </div>
      ) : (
        <div className="lg:h-screen col-span-2 overflow-scroll scroller rounded-card-box">
          <WalletDetailsCard
            selectedWallet={wallet}
            handleManualAssetSelect={handleManualAssetSelect}
          />
          <div className="pt-10">
            <WalletHoldingsList
              handleManualAssetSelect={handleManualAssetSelect}
            />
          </div>
        </div>
      )}
    </>
  );
};
