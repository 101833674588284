// vendor libraries
import { useState, useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';

const faqs = [
  {
    title:
      'How long does it take to display holdings from a new account or wallet?',
    value:
      'It normally takes a few minutes to display holdings once you connect an account or wallet. Please check the “Link Assets” page for the status on all of your accounts and wallets. If you notice any issues with your holdings, please email support@assetdash.com.',
  },
  {
    title: 'How often do my holdings and portfolio net worth update?',
    value:
      'Prices are updated every few seconds, holdings are updated every hour except equities. Equity holdings update once a day due to limitations with the traditional market system.',
  },
  {
    title: 'What is the Discover page?',
    value:
      'The Discover page consists of anonymous aggregated data from verified accounts connected to Asset Dash. Users with connected accounts or wallets can access the Discover page for unique insights into what the overall community is buying or selling. Our goal is for the Discover page to become a valuable source of alpha for Asset Dash users.',
  },
  {
    title: 'Will Asset Dash be free forever?',
    value:
      'Yes, the plan is to offer a free version of Asset Dash forever. In the future, we plan to release premium features and services for a fee.',
  },
  {
    title: 'Is Asset Dash private?',
    value:
      'Yes, user privacy is a top priority for us. We also welcome anons! Our system only has “read-only” holdings access to the accounts you connect and does not have access to any personal information.',
  },
  {
    title: 'What do the “dots” next to my linked accounts and wallets mean?',
    value:
      'The dot next to your account or wallet signals the status of the connection. A green dot means the connection is working and live, a yellow dot means the connection is loading, a red dot means the connection is not working. If you have a red dot, please try connecting the account again, if the issue persists email support@assetdash.com.',
  },
  {
    title: 'Data Update Frequencies?',
    value:
      'Stocks: Stock holdings are updated once per day. Stock prices are updated every 15 minutes.\n\nCrypto Holdings: Cryptocurrency and token holdings are updated once per day. Cryptocurrency and token prices are updated every 15 minutes.\n\nNFT Holdings: NFT holdings are updated once per day for Ethereum and a few times per day for Solana. NFT floor prices are updated once per day for Ethereum and a few times per day for Solana. \n\nDeFi Holdings: DeFi holdings (funds currently in DeFi pools) are updated once per day. DeFi prices (prices of holdings in DeFi pools aka “total value in pool”) are updated once per day. \n\nBanks: Bank balances are updated once per day. \n\nPortfolio Calculations: Portfolios are recalculated every 10 minutes',
  },
  {
    title: 'Do you have a support email?',
    value:
      'Yes, support@assetdash.com. We will get back to you as soon as possible!',
  },
];

export default function FAQPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [expandedSection, setExpandedSection] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    if (expandedSection === index) {
      setExpandedSection(null);
    } else {
      setExpandedSection(index);
    }
  };

  return (
    <>
      <SuperSEO title={`AssetDash | FAQ`} lang="en_IE" />
      <h1 className="text-32 leading-none text-center mt-8 mb-8">FAQ</h1>
      <div className="main-content mx-auto">
        <div className="divide-y divide-gray-text dark:divide-gray-600 py-5 h-full">
          {faqs.map((faq, index) => (
            <div key={index}>
              <div
                className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card py-4"
                onClick={() => toggleSection(index)}
              >
                <div className="flex items-center space-x-2">
                  <div>{faq.title}</div>
                </div>
                {expandedSection === index ? (
                  <ChevronUpIcon
                    width="17.5"
                    height="17.5"
                    className="text-primary-orange mr-2"
                  />
                ) : (
                  <ChevronDownIcon
                    width="17.5"
                    height="17.5"
                    className="text-primary-orange mr-2"
                  />
                )}
              </div>
              <div
                className={`overflow-hidden transition-max-height duration-100 ${
                  expandedSection === index ? 'max-h-[500px]' : 'max-h-0'
                }`}
              >
                <div className="mx-2 p-4 text-gray-700 dark:text-gray-300 bg-white dark:bg-deals-card rounded-lg">
                  <ReactMarkdown>{faq.value}</ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
