// vendor libraries
import { InformationCircleIcon } from '@heroicons/react/24/outline';

// components
import { Tooltip } from '../common/Tooltip/Tooltip';
import { SvgIcon } from '../common/Icon/SvgIcon';

// api
import { generateSocialOauthURL } from '@/api/matrica';

export const SocialLink = ({ platform, value }) => {
  const getMatricaVerifyURL = () => {
    return generateSocialOauthURL(platform)
      .then(res => {
        if (res.status === 200 && res.data) {
          const oauthURL = res.data.oauth_url;
          window.open(oauthURL, '_blank');
        } else {
          throw new Error('Error, please try again.');
        }
      })
      .catch(err => {
        toast.error('Error, please try again.');
      });
  };

  return (
    <div className="card py-4 px-6 justify-center space-y-6">
      <div className="flex space-x-2 items-center">
        <img
          src={`/assets/images/${platform}.png`}
          alt="sort_by"
          className="h-10"
        />
        <div className="font-bold text-22 leading-22 capitalize">
          {platform}
        </div>
        <Tooltip
          text={
            'Connect your Matrica account to automatically add and verify your social media accounts on AssetDash.'
          }
          position="bottom"
        >
          <InformationCircleIcon
            className="text-price-red"
            width="20"
            height="20"
          />
        </Tooltip>
      </div>
      {value ? (
        <div className="space-y-5 mb-5 text-2xl font-semibold text-price-green text-center h-20">
          {value}
        </div>
      ) : (
        <>
          <div className="space-y-5 mb-5 text-gray-text text-center">
            Link account and get 2,000 AssetDash Coins
          </div>
          <div className="space-y-5 flex justify-center">
            <button onClick={getMatricaVerifyURL}>
              <SvgIcon iconFileName="matrica_button" size={{ width: 220 }} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
