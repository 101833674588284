// vendor libraries
import { useState, useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { toast } from 'react-hot-toast';

// hooks
import { useAuth } from '../../hooks/useAuth';

// api
import { editCurrentUser } from '../../api/user';

export default function InviteCodePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { user, setUser } = useAuth();

  const [inviteCode, setInviteCode] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (user.signup_invite_code) {
      setInviteCode(user.signup_invite_code);
    }
  }, [user]);

  const handleSubmit = async () => {
    if (!inviteCode) {
      setMessage('Invite code is required');
      return;
    }

    try {
      const resp = await editCurrentUser({
        signup_invite_code: inviteCode,
      });
      if (resp.data.signup_invite_code) {
        setUser(resp.data);
        toast.success('Invite code set');
      } else {
        toast.error('Invite code not found');
      }
      setMessage('');
    } catch (error) {
      toast.error(error?.message || 'something went wrong');
    }
  };

  return (
    <>
      <SuperSEO title={`AssetDash | Invite Code`} lang="en_IE" />
      <h1 className="text-32 leading-none text-center mt-8 mb-8">
        Invite Code
      </h1>
      <div className="main-content mx-auto">
        {user && (
          <div className="flex flex-col items-center space-y-4">
            <input
              type="text"
              className="w-1/2 bg-gray-200 dark:bg-deals-card-top rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
              value={inviteCode}
              onChange={e => setInviteCode(e.target.value)}
              disabled={user.signup_invite_code}
            />
            <div className="text-sm text-primary-red h-4 mt-1">{message}</div>
            <button
              className={`flex items-center justify-center mt-4 solid py-6 w-1/2 bg-price-green mx-auto rounded-xl font-semibold text-xl leading-3.5 text-black
              ${
                user.signup_invite_code ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={user.signup_invite_code}
              onClick={handleSubmit}
            >
              <div>Set Invite Code</div>
            </button>
          </div>
        )}
      </div>
    </>
  );
}
