// vendor libraries
import { useNavigate } from 'react-router-dom';

// components
import { ArrowButton } from '../../common/Button/ArrowButton';
import { DealCard } from './DealCard';

export const DealsRow = ({ title, deals, dealCategory }) => {
  const navigate = useNavigate();

  return (
    <div>
      {deals.length > 0 && (
        <div className="bg-white dark:bg-deals-card-top rounded-card-box h-full flex flex-col pt-4">
          <div className="flex justify-between items-center">
            <div className="text-center text-2xl font-semibold px-6 md:px-6">
              {title}
            </div>
            {dealCategory && (
              <ArrowButton
                onClick={() => navigate(`/deals/category/${dealCategory}`)}
                direction="right"
              >
                View all
              </ArrowButton>
            )}
          </div>
          <div className="flex overflow-x-auto flex-row space-x-4 px-6 py-4 items-start h-96">
            {deals.map(deal => (
              <DealCard key={deal.id} deal={deal} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
