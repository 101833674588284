import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

// constants
import { ASSET_TYPE_TO_DISPLAY_NAME } from '@constants';

// api
import { getAssetSearchResults } from '@/api/assets';

// models
import {
  AssetSearchResult,
  AssetSearchResults,
} from '@/features/assets/models/AssetSearchResults';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

interface SearchListViewProps {
  selectedAsset: AssetSearchResult | null;
  setSelectedAsset: (asset: AssetSearchResult | null) => void;
  assetType: string;
  quantity: number;
  setQuantity: (quantity: number | '') => void;
  holding: WalletHolding | null;
}

export const SearchListView: React.FC<SearchListViewProps> = ({
  selectedAsset,
  setSelectedAsset,
  assetType,
  quantity,
  setQuantity,
  holding,
}) => {
  const [searchResults, setSearchResults] = useState<AssetSearchResults>();
  const [searchItem, setSearchItem] = useState('');
  const [dbValue, saveToDb] = useState('');
  const ref = useRef(null);

  const debouncedSave = useRef(
    debounce(nextValue => {
      setSearchItem(nextValue);
    }, 1000),
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = event.target;
    saveToDb(nextValue);
    debouncedSave(nextValue);
  };

  useEffect(() => {
    if (holding) {
      return;
    }
    if (searchItem) {
      getAssetSearchResults(searchItem)
        .then(res => {
          setSearchResults(AssetSearchResults.fromJSON(res.data));
        })
        .catch(err => {});
    } else {
      getAssetSearchResults(assetType)
        .then(res => {
          setSearchResults(AssetSearchResults.fromJSON(res.data));
        })
        .catch(err => {});
    }
  }, [searchItem, assetType, holding]);

  return (
    <div>
      <div className="space-y-4">
        <div className="flex items-center  w-full h-14 bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none">
          {holding?.asset?.name || selectedAsset?.name}
        </div>
        {!holding && (
          <>
            <div ref={ref}>
              <label htmlFor="name" className="text-sm text-gray-600">
                Search
              </label>
              <input
                type="text"
                id="name"
                className="w-full bg-primary-gray dark:bg-deals-card rounded-12 font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 py-22 border-none"
                placeholder={`Search your ${ASSET_TYPE_TO_DISPLAY_NAME[assetType]} here`}
                value={dbValue}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-2">
              {searchResults &&
                searchResults.results?.map(searchResult => {
                  return (
                    <div
                      key={searchResult.id}
                      onClick={() => {
                        setSelectedAsset(searchResult);
                      }}
                      className={`flex space-x-4 items-center
                    ${
                      selectedAsset?.id === searchResult.id
                        ? 'bg-gray-text dark:bg-blue-dark p-4 rounded-xl'
                        : 'bg-primary-gray dark:bg-deals-card p-4 rounded-xl hover:bg-gray-text dark:hover:bg-blue-dark cursor-pointer'
                    }`}
                    >
                      <img
                        src={searchResult.logoUrl}
                        className="h-8 w-8 rounded-full object-contain"
                        alt="logo"
                      />
                      <div className="">{searchResult.name}</div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        <div>
          <input
            className="h-16 w-full rounded-xl border-1 border-solid px-4 bg-primary-gray dark:bg-deals-card-top"
            placeholder="Enter the quantity"
            type="number"
            min="0"
            value={quantity}
            onChange={e => {
              setQuantity(e.target.value ? parseFloat(e.target.value) : '');
            }}
          />
        </div>
      </div>
    </div>
  );
};
