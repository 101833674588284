import { axiosAssetDashApi } from './api';

export const getPartnerRewards = async () => {
  return await axiosAssetDashApi.get(`/api/api_v5/partner_rewards/list`);
};

export const getPartnerRewardDetail = async (partnerRewardId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/rewards/partners/rewards/${partnerRewardId}`,
  );
};
