import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { MarketContainer } from '@/components/v2/market/MarketContainer';

export default function MarketPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | Market`} lang="en_IE" />
      <div className="main-content mx-auto space-y-6">
        <MarketContainer />
      </div>
    </>
  );
}
