// vendor libraries
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

// recoil
import { useCurrencyVisibleState } from '../../../recoil/currencyVisible';

// components
import { IconButtonWrapper } from './IconButtonWrapper';

export const CurrencyVisibilityButton = () => {
  const [currencyVisible, setCurrencyVisible] = useCurrencyVisibleState();

  return (
    <IconButtonWrapper
      onClick={() => {
        setCurrencyVisible(!currencyVisible);
      }}
    >
      {!currencyVisible ? (
        <EyeIcon width="12.57" height="12.57" className=" text-price-green" />
      ) : (
        <EyeSlashIcon
          width="12.57"
          height="12.57"
          className=" text-price-green"
        />
      )}
    </IconButtonWrapper>
  );
};
