export type Color = string;


export class AppColors {
  static isLightMode: boolean = localStorage.getItem('theme') === 'light';

  static primary(): Color {
    return '#21CE99';
  }
  static accent(): Color {
    return '#F5AC6E';
  }
  static positive(): Color {
    return '#00C287';
  }
  static negative(): Color {
    return '#EA394E';
  }

  static text(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? 'black' : 'white';
  }

  static fadeText(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#49494D' : '#A8A8A8';
  }

  static elementText(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#A8A8A8' : '#D8D8D8';
  }

  static background(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#F2F2F2' : '#1C1E29';
  }

  static containerBackground(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? 'white' : '#262C3A';
  }

  static elementBackground(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#F2F2F2' : '#49494D';
  }

  static elementBackground2(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#F2F2F2' : '#1A1A1B';
  }

  static activeElementBackground(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#D8D8D8' : '#49494D';
  }

  static whiteBlack(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? 'white' : 'black';
  }

  static portfolioEquity(): Color {
    return '#21CE99';
  }
  static portfolioDerivative(): Color {
    return '#FFDC5D';
  }
  static portfolioEtf(): Color {
    return '#95A1C0';
  }
  static portfolioMutualFund(): Color {
    return '#F9A671';
  }
  static portfolioCurrency(): Color {
    return '#F3705A';
  }
  static portfolioNft(): Color {
    return '#BFC4CC';
  }
  static portfolioCryptoToken(): Color {
    return '#60B7FF';
  }
  static portfolioCryptoProtocol(): Color {
    return '#5B3F7B';
  }
  static portfolioMetals(): Color {
    return 'teal';
  }
  static portfolioPrivateEquity(): Color {
    return 'amber';
  }
  static portfolioCollectible(): Color {
    return 'green';
  }
  static portfolioRealEstate(): Color {
    return 'blue';
  }
  static portfolioOther(): Color {
    return 'gray';
  }

  static boosted(): Color {
    return '#7B61FF';
  }
  static allocation(): Color {
    return '#60B7FF';
  }
  static secondContainerColor(): Color {
    return AppColors.isLightMode ? '#F2F2F2' : '#F3B3E56';
  }

  static textSnackBar(): Color {
    return 'white';
  }
  static negativeSnackbar(): Color {
    return '#E8444D';
  }
  static bottomNavSelected(): Color {
    return '#62C696';
  }
  static bottomNavUnselected(): Color {
    return '#95A1C0';
  }
  static appBarIcon(): Color {
    return AppColors.accent();
  }
  static link(): Color {
    return '#55ACEE';
  }
  static inputIcon(): Color {
    return '#999999';
  }

  static custom(lightModeColor: Color, darkModeColor: Color): Color {
    return AppColors.isLightMode ? lightModeColor : darkModeColor;
  }

  static get buttonDefault(): ButtonColors {
    return new ButtonColors(AppColors.primary(), 'white');
  }

  static get buttonRed(): ButtonColors {
    return new ButtonColors(
      AppColors.negative(),
      'white',
      AppColors.negative(),
    );
  }

  static disabledButton(): Color {
    return AppColors.isLightMode ? 'rgba(73, 73, 77, 0.1)' : '#49494D';
  }

  static border(): Color {
    return '#D8D8D8';
  }
  static divider(lightMode?: boolean): Color {
    return lightMode ?? AppColors.isLightMode ? '#D8D8D8' : '#49494D';
  }

  static editTextLine(): Color {
    return '#DFDFDF';
  }

  static percentageChartColorList(): Color[] {
    return [
      AppColors.primary(),
      AppColors.accent(),
      AppColors.portfolioCurrency(),
      AppColors.portfolioCryptoToken(),
      '#9B63DB',
    ];
  }

  static getVantaGradient(): Color[] {
    return ['#838383', '#ffffff', '#828282'];
  }

  static vantaGrey(): Color {
    return '#838383';
  }
}

export class ButtonColors {
  buttonColor: Color;
  borderColor?: Color;
  textColor: Color;

  constructor(buttonColor: Color, textColor: Color, borderColor?: Color) {
    this.buttonColor = buttonColor;
    this.borderColor = borderColor;
    this.textColor = textColor;
  }
}
