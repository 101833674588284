// vendor libraries
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import QRCode from 'react-qr-code';

// recoil
import { useThemeState } from '@/recoil/theme.ts';

interface AddressModalProps {
  isOpen: boolean;
  closeModal: () => void;
  address: string;
}

export const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  closeModal,
  address,
}) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const buttonText = theme === 'light' ? 'text-black' : 'text-white';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    closeModal();
    setTimeout(() => {
      toast.success('Address copied to clipboard');
    }, 500);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-2xl sm:w-full sm:p-6 ${background}`}
              >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-10">
                  <div className="col-span-2  text-center">
                    <Dialog.Title
                      as="h3"
                      className={`text-xl font-semibold leading-6 text-left ${textColor}`}
                    >
                      Receive Crypto
                    </Dialog.Title>
                    <div className="mt-6p">
                      <p
                        className={`text-left text-xs font-semibold ${textColor}`}
                      >
                        Scan this QR-code or copy wallet address from the input
                        below
                      </p>
                    </div>

                    <div className="mt-12 space-y-2">
                      <p
                        className={`text-10 font-semibold text-gray-text text-left ml-2 ${textColor}`}
                      >
                        Address
                      </p>
                      <div
                        className={`flex items-center text-xs font-semibold pl-4 pr-2 py-3 border border-solid border-tabs-bg rounded-xl text-left ${textColor}`}
                      >
                        <span className="break-all">{address}</span>
                        <DocumentDuplicateIcon
                          className="cursor-pointer ml-auto h-4 w-4"
                          onClick={copyAddress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-auto ml-auto">
                    <QRCode
                      value={address || ''}
                      size={128}
                      style={{
                        scale: 2,
                        width: 128,
                      }}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
                <div className="mt-10 text-right">
                  <button
                    type="button"
                    className={` py-4 px-8 leading-17 font-semibold text-sm rounded-xl bg-primary-red ${buttonText}`}
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
