import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

import { getPartnerRewards, getPartnerRewardDetail } from '../api/rewards';

import {
  PartnerReward,
  PartnerRewardsResponse,
} from '../features/rewards/models/PartnerRewards';
import { PartnerRewardDetailResponse } from '../features/rewards/models/PartnerRewardDetail';

interface RewardsContextProps {
  load: () => Promise<void>;
  loadPartnerRewards: () => Promise<void>;
  loadPartnerRewardDetail: (partnerRewardId: string) => Promise<void>;
  clearPartnerRewardDetail: () => void;

  partnerRewards: PartnerReward[];
  partnerRewardDetail?: PartnerRewardDetailResponse;

  isLoading: boolean;
}

export const RewardsContext = createContext<RewardsContextProps | undefined>(
  undefined,
);

export const useRewards = (): RewardsContextProps => {
  const context = useContext(RewardsContext);
  if (!context) {
    throw new Error('useRewards must be used within a RewardsProvider');
  }
  return context;
};

export const RewardsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [partnerRewards, setPartnerRewards] = useState<PartnerReward[]>([]);
  const [partnerRewardDetail, setPartnerRewardDetail] =
    useState<PartnerRewardDetailResponse>();
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    await loadPartnerRewards();
  };

  const loadPartnerRewards = async () => {
    setIsLoading(true);
    try {
      const response = await getPartnerRewards();
      const partnerRewardsResponse = PartnerRewardsResponse.fromJson(
        response.data,
      );
      setPartnerRewards(partnerRewardsResponse.partnerRewards || []);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearPartnerRewardDetail = () => {
    setPartnerRewardDetail(undefined);
  };

  const loadPartnerRewardDetail = async (partnerRewardId: string) => {
    setIsLoading(true);
    try {
      const response = await getPartnerRewardDetail(partnerRewardId);
      const partnerRewardDetailResponse = PartnerRewardDetailResponse.fromJson(
        response.data,
      );
      setPartnerRewardDetail(partnerRewardDetailResponse);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RewardsContext.Provider
      value={{
        // methods
        load,
        loadPartnerRewards,
        loadPartnerRewardDetail,
        clearPartnerRewardDetail,

        // values
        partnerRewards,
        partnerRewardDetail,

        isLoading,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
};
