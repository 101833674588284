import React from 'react';
import { Color } from '../../../features/core/theming/colors';

interface SvgIconProps {
  iconFileName?: string;
  size?: { width: number; height?: number };
  round?: boolean;
  folder?: string;
  subFolder?: string;
  color?: Color;
  fullPath?: string;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  iconFileName,
  size = { width: 24, height: 24 },
  round = false,
  folder = 'svg',
  subFolder,
  color,
  fullPath,
}) => {
  if (!fullPath) {
    fullPath = subFolder
      ? `/assets/${folder}/${subFolder}/${iconFileName}.svg`
      : `/assets/${folder}/${iconFileName}.svg`;
  }

  const commonProps = {
    width: size.width,
    height: size.height,
    style: { color },
  };

  return (
    <img
      src={fullPath}
      alt={iconFileName}
      {...commonProps}
      className={round ? 'rounded-full' : ''}
    />
  );
};

export default SvgIcon;
