import { useEffect } from 'react';
import { useAuctions } from '@/hooks/useAuctions';

import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { AuctionMyBidCard } from './AuctionMyBidCard';

export const MyBidsTab = () => {
  useEffect(() => {
    getAuctionMyBids();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getAuctionMyBids, auctionMyBids, isLoading } = useAuctions();

  return (
    <div>
      {isLoading ? (
        <SkeletonLoader height={300} />
      ) : auctionMyBids.length === 0 ? (
        <div className="flex flex-col justify-center items-center">
          <img
            className="w-full md:w-1/2"
            src="/assets/images/empty_deals.png"
            alt="empty bids"
          />
          <div className="font-bold text-md sm:text-2xl text-center -mt-12">
            You haven't bid on any open auctions yet.
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {auctionMyBids?.map(auctionMyBid => (
            <AuctionMyBidCard
              key={auctionMyBid.id}
              auctionMyBid={auctionMyBid}
            />
          ))}
        </div>
      )}
    </div>
  );
};
