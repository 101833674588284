import { useState } from 'react';

// components
import { GiveawayConfirmBuyPassesModal } from './GiveawayConfirmBuyPassesModal';

// hooks
import { useGames } from '@/hooks/useGames';

// api
import { Giveaway } from '@/api/games';

interface GiveawayBuyPassesCardProps {
  giveaway: Giveaway;
}

export const GiveawayBuyPassesCard: React.FC<GiveawayBuyPassesCardProps> = ({
  giveaway,
}) => {
  const { buyGiveawayPasses, isGiveawayOpen } = useGames();
  const [coinsAmount, setCoinsAmount] = useState(0);
  const [passesAmount, setPassesAmount] = useState(0);
  const [customPassesAmount, setCustomPassesAmount] = useState('');
  const [confirmBuyPassesModalOpen, setConfirmBuyPassesModalOpen] =
    useState(false);

  const addPasses = (amount: number) => {
    setPassesAmount(passesAmount + amount);
    setCoinsAmount(coinsAmount + giveaway.entry_cost_coins * amount);
  };

  const clearPasses = () => {
    setPassesAmount(0);
    setCoinsAmount(0);
    setCustomPassesAmount('');
  };

  const handleBuyPasses = async () => {
    const numPasses = passesAmount;
    clearPasses();
    await buyGiveawayPasses(giveaway.id, numPasses);
  };

  const handleCustomPassesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setCustomPassesAmount(e.target.value);
      setPassesAmount(value);
      setCoinsAmount(giveaway.entry_cost_coins * value);
    } else {
      setCustomPassesAmount('');
      setPassesAmount(0);
      setCoinsAmount(0);
    }
  };

  const handleMaxPasses = () => {
    const maxPasses = 100;
    setPassesAmount(maxPasses);
    setCoinsAmount(giveaway.entry_cost_coins * maxPasses);
  };

  return (
    <>
      <div className="space-y-2">
        <p className="text-left text-2xl">Buy Passes</p>
        <div className="bg-white dark:bg-deals-card-top py-4 px-8 rounded-xl space-y-4 dark:text-gray-300">
          <div className="space-y-1">
            <div className="flex justify-between">
              <div className="font-bold">Amount</div>
            </div>
            <div className="flex justify-between bg-gray-300 dark:bg-black p-6 rounded-xl">
              <div className="flex space-x-1 items-center">
                <div className="mt-1 min-w-6">
                  <img
                    className="rounded-full"
                    src="/assets/icons/coin.png"
                    alt="Coin"
                    height={20}
                    width={20}
                  />
                </div>
                <div className="font-semibold">AssetDash Coins</div>
              </div>
              <div>{coinsAmount}</div>
            </div>
          </div>
          <div className="space-y-1">
            <div className="flex justify-between">
              <div className="font-semibold">Passes</div>
              <div>{giveaway.entry_cost_coins} Coins = 1 Pass</div>
            </div>
            <div className="flex justify-between bg-gray-300 dark:bg-black p-6 rounded-xl">
              <div className="flex space-x-1 items-center">
                <div className="mt-1 min-w-6">
                  <img
                    className="rounded-full"
                    src="/assets/icons/coin.png"
                    alt="Coin"
                    height={20}
                    width={20}
                  />
                </div>
                <div className="font-semibold">Super Passes</div>
              </div>
              <div>{passesAmount}</div>
            </div>
          </div>
          <div className="flex flex-wrap justify-evenly text-xs space-y-1">
            {[10, 20, 50, 100].map(amount => {
              return (
                <div
                  key={amount}
                  className="cursor-pointer whitespace-nowrap px-2 py-2 border-1 bg-opacity-10 bg-price-green border-price-green rounded-3xl"
                  onClick={() => {
                    if (isGiveawayOpen(giveaway)) addPasses(amount);
                  }}
                >
                  {amount} Passes
                </div>
              );
            })}
            <input
              type="number"
              value={customPassesAmount}
              onChange={handleCustomPassesChange}
              className="max-w-28 whitespace-nowrap px-2 py-2 border-1 bg-opacity-10 bg-price-green border-price-green rounded-3xl"
              placeholder="Custom Amount"
            />
            <div
              className="cursor-pointer whitespace-nowrap px-2 py-2 border-1 bg-opacity-10 bg-price-green border-price-green rounded-3xl"
              onClick={handleMaxPasses}
            >
              Max
            </div>
          </div>
          <div className="flex justify-evenly space-x-4 pt-5 text-right">
            <button
              type="button"
              className={`whitespace-nowrap font-bold w-60 py-4 px-8 leading-17 text-md rounded-xl bg-gray-300 dark:bg-tabs-bg text-price-green`}
              onClick={clearPasses}
            >
              Clear
            </button>
            <button
              type="button"
              disabled={coinsAmount === 0 || !isGiveawayOpen(giveaway)}
              className={`whitespace-nowrap font-bold w-60 py-4 px-8 transition delay-100 ease-in leading-17 text-md rounded-xl text-black ${
                coinsAmount > 0 && isGiveawayOpen(giveaway)
                  ? 'bg-price-green'
                  : `bg-gray-200 dark:bg-tabs-bg`
              }`}
              onClick={() => setConfirmBuyPassesModalOpen(true)}
            >
              {isGiveawayOpen(giveaway) ? 'Buy Passes' : 'Closed'}
            </button>
          </div>
        </div>
      </div>
      <GiveawayConfirmBuyPassesModal
        isOpen={confirmBuyPassesModalOpen}
        closeModal={() => setConfirmBuyPassesModalOpen(false)}
        coins={coinsAmount}
        passes={passesAmount}
        onSubmit={handleBuyPasses}
      />
    </>
  );
};
