import { Auction } from '@/features/market/auctions/models/Auctions';
import { MyAuctionBidLeaderboard } from '@/features/market/auctions/models/AuctionDetail';

import { formatNumber } from '@/utils/number';

interface AuctionInfoCardProps {
  auction: Auction;
  auctionBidLeaderboard?: MyAuctionBidLeaderboard;
}

export const AuctionInfoCard: React.FC<AuctionInfoCardProps> = ({
  auction,
  auctionBidLeaderboard,
}) => {
  return (
    <div className="space-y-4 bg-blue-gradient dark:bg-dark-blue-gradient text-black dark:text-gray-300 mt-8 py-4 px-8 rounded-xl relative">
      <img
        src="/assets/icons/giveaway_zap.png"
        alt="zap"
        className="h-16 w-16 p-2 rounded-full mx-auto absolute -top-8 left-1/2 transform -translate-x-1/2 bg-deals-card-top"
      />
      <p className="text-center text-2xl font-semibold">{auction.title}</p>
      <div className="space-y-2 pb-4">
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img
              src="/assets/icons/trophy.png"
              alt="trophy"
              className="h-4 w-4"
            />
            <p className="font-semibold">Total Bids</p>
          </div>
          <p className="text-left">
            {formatNumber(auction.totalBids!, 0)} bids
          </p>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img src="/assets/icons/coin.png" alt="coin" className="h-4 w-4" />
            <p className="font-semibold">Your bid</p>
          </div>
          <p className="text-left">
            {formatNumber(auctionBidLeaderboard?.numCoins || 0, 0)}
          </p>
        </div>
      </div>
    </div>
  );
};
