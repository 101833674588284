import { createContext, useContext, useState } from 'react';

import { getMemberCenterData } from '../api/members';

interface MemberCenterData {
  [key: string]: any;
}

interface MemberContextValue {
  memberCenterData: MemberCenterData | null;
  setMemberCenterData: React.Dispatch<
    React.SetStateAction<MemberCenterData | null>
  >;
  fetchMemberCenterData: () => Promise<void>;
  isMemberCenterLoading: boolean;
  setIsMemberCenterLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MemberContext = createContext({});

export function useMember(): MemberContextValue {
  return useContext(MemberContext) as MemberContextValue;
}

interface MemberProviderProps {
  children: React.ReactNode;
}

export const MemberProvider: React.FC<MemberProviderProps> = ({ children }) => {
  const [memberCenterData, setMemberCenterData] =
    useState<MemberCenterData | null>(null);
  const [isMemberCenterLoading, setIsMemberCenterLoading] = useState(false);

  const fetchMemberCenterData = async () => {
    try {
      setIsMemberCenterLoading(true);
      const resp = await getMemberCenterData();
      if (resp.status === 200) {
        setMemberCenterData(resp.data.member_center);
      }
      setIsMemberCenterLoading(false);
    } catch (err) {
      setIsMemberCenterLoading(false);
    }
  };

  return (
    <MemberContext.Provider
      value={{
        memberCenterData,
        setMemberCenterData,
        fetchMemberCenterData,
        isMemberCenterLoading,
        setIsMemberCenterLoading,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};
