/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useThemeState } from '../../../recoil/theme';

export const WithdrawModal = ({ open, setOpen }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-2xl sm:w-full sm:p-6 ${background}`}
              >
                <div className="">
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className={`flex justify-between text-3xl font-semibold leading-6 text-left ${textColor}`}
                    >
                      <p>Withdraw</p>
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <XMarkIcon
                          height={24}
                          width={24}
                          className="text-price-red"
                        />
                      </p>
                    </Dialog.Title>
                    <div className="mt-6p">
                      <p
                        className={`text-left text-2xl font-semibold ${textColor}`}
                      >
                        The minimum withdrawal amount is $250. Keep collecting
                        cash back and withdraw once you pass the threshold! Cash
                        back totals and orders update every 24 hours.
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
