import { useEffect, useState } from 'react';

// components
import { PortfolioHoldingsPieChart } from './PortfolioHoldingsPieChart';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { HoldingCategory } from '@/features/portfolio/holdings/models/HoldingCategory';

// enums
import { HoldingTypeGroupInfo } from '@/features/portfolio/holdings/enums/HoldingTypeGroup';

export type PieChartData = {
  value: number;
  color: string;
  title: string;
  currencyValue: number;
};

type PortfolioHoldingsPieChartContainerProps = {
  holdingCategories: HoldingCategory[];
};

export const PortfolioHoldingsPieChartContainer: React.FC<
  PortfolioHoldingsPieChartContainerProps
> = ({ holdingCategories }) => {
  const { convertUSDCurrency } = useAuth();
  const { totalPortfolioValue } = usePortfolio();

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [data, setData] = useState<PieChartData[]>([]);

  useEffect(() => {
    setData(getPieChartData(holdingCategories));
  }, [holdingCategories]);

  const handleClick = (index: number) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    } else {
      setActiveIndex(null);
    }
  };

  return (
    <div className="relative h-full flex items-center justify-center md:justify-between">
      <div className="relative">
        <div className="text-center font-normal text-2xl leading-7 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            style={{
              color: activeIndex === null ? '' : data[activeIndex].color,
            }}
          >
            {activeIndex === null ? 'Total' : data[activeIndex].title}
          </div>
          <div>
            {activeIndex === null
              ? convertUSDCurrency(totalPortfolioValue)
              : convertUSDCurrency(data[activeIndex].currencyValue)}
          </div>
        </div>
        <PortfolioHoldingsPieChart
          data={data}
          activeIndex={activeIndex}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export const getPieChartData = (
  holdingCategories: HoldingCategory[],
): PieChartData[] => {
  holdingCategories = holdingCategories.sort(
    (a, b) => b.allocation - a.allocation,
  );
  const data = holdingCategories.map(holdingCategory => {
    return {
      value: holdingCategory.allocation,
      color: HoldingTypeGroupInfo.color[holdingCategory.type],
      title: HoldingTypeGroupInfo.title[holdingCategory.type],
      currencyValue: holdingCategory.value,
    };
  });

  return data;
};
