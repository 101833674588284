// vendor libraries
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';

// models
import {
  CoinCenterResponse,
  CoinCenter,
} from '../../features/coins/models/CoinCenter';

// api
import { getUserCoinCenter } from '../../api/coin';

export const CoinStats: React.FC = () => {
  const [coinCenter, setCoinCenter] = useState<CoinCenter>();

  useEffect(() => {
    fetchCoinCenter();
  }, []);

  const fetchCoinCenter = async () => {
    try {
      const response = await getUserCoinCenter();
      setCoinCenter(CoinCenterResponse.fromJson(response.data).coinCenter);
    } catch (error) {}
  };

  return (
    <div className="flex space-x-6 md:justify-center overflow-x-auto whitespace-nowrap">
      <div className="h-20 w-32 flex flex-col p-2 items-center space-x-1 text-md justify-center px-4 py-1 border-1 bg-opacity-10 bg-price-green border-price-green text-price-green rounded-lg">
        <CountUp duration={2} end={coinCenter?.totalCoins || 0} />
        <div>Total Coins</div>
      </div>
      <div className="h-20 w-32 flex flex-col p-2 items-center space-x-1 text-md justify-center px-4 py-1 border-1  bg-opacity-10 bg-boosted border-boosted text-boosted rounded-lg">
        <CountUp duration={2} end={coinCenter?.liquidCoins || 0} />
        <div>Liquid Coins</div>
      </div>
      <div className="h-20 w-32 flex flex-col p-2 items-center space-x-1 text-md justify-center px-4 py-1 border-1 bg-opacity-10 bg-portfolio-currency border-portfolio-currency text-portfolio-currency rounded-lg">
        <CountUp duration={2} end={coinCenter?.lockedCoins || 0} />
        <div>Locked Coins</div>
      </div>
      <div className="h-20 w-32 flex flex-col p-2 items-center space-x-1 text-md justify-center px-4 py-1 border-1 bg-accent bg-opacity-10 border-accent text-accent rounded-lg">
        <CountUp duration={2} end={coinCenter?.bidCoins || 0} />
        <div>Bid Coins</div>
      </div>
      <div className="h-20 w-32 flex flex-col p-2 items-center space-x-1 text-md justify-center px-4 py-1 border-1  bg-opacity-10 bg-negative border-negative text-negative rounded-lg">
        <CountUp duration={2} end={coinCenter?.spentCoins || 0} />
        <div>Spent Coins</div>
      </div>
    </div>
  );
};
