// vendor libraries
import { useState } from 'react';

// components
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import { NFTEditPriceModal } from './NFTEditPriceModal';
import { NFTItemDetails } from '@/features/portfolio/models/NFTItemDetails';
import { useAuth } from '@/hooks/useAuth';

interface NFTItemPriceCardProps {
  nftItemDetails?: NFTItemDetails;
  walletNFTItem?: WalletNFTItem;
}
export const NFTItemPriceCard: React.FC<NFTItemPriceCardProps> = ({
  nftItemDetails,
  walletNFTItem,
}) => {
  const { convertUSDCurrency } = useAuth();
  const { isLoading } = usePortfolio();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {nftItemDetails && !isLoading ? (
        <div className="w-full space-y-2">
          <div className="card p-2">
            <span className="text-sm">Collection Floor Price</span>
            <div className="space-x-3 flex items-center">
              {/* <div className="w-8 h-8 rounded-full bg-dark dark:bg-white"></div> */}
              {/*TBR*/}
              <span className="font-bold text-xl">
                {nftItemDetails?.floorPriceNative} {nftItemDetails?.nftChain}
              </span>
              <span className="rounded-md text-xs text-light-green bg-light-green/20 px-1 py-px">
                ~{convertUSDCurrency(nftItemDetails?.floorPriceUsd)}
              </span>
            </div>
          </div>
          <div className="card p-2">
            <span className="text-xl">Rarity rank</span>
            <div className="font-bold text-xl">
              {nftItemDetails?.rarityRank}
            </div>
          </div>
          <div className="card p-2">
            <span className="text-xl">Last Sold</span>
            <div className="font-bold text-xl">
              {nftItemDetails?.lastSaleNative ?? 'N/A'}{' '}
              {nftItemDetails?.nftChain}
            </div>
          </div>
        </div>
      ) : (
        <SkeletonLoader height={200} />
      )}
      <NFTEditPriceModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        walletNFTItem={walletNFTItem}
        defaultTab="manualPrice"
      />
    </>
  );
};
