// vendor libraries
import React, { useState } from 'react';
import Slider from 'react-slick';

// components
import { NFTCard } from './NFTCard';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// recoil
import { useThemeState } from '@/recoil/theme';

// utils
import { isMobile } from '@/utils/device';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

interface NFTItemsSliderProps {
  items: WalletNFTItem[];
  large?: boolean;
}

export const NFTItemsSlider: React.FC<NFTItemsSliderProps> = ({
  items,
  large,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [theme] = useThemeState();

  const scrollLength: number = isMobile() ? 2 : 3;
  const sliderStep: number = currentIndex / scrollLength;

  const getStyledDots = (dots: React.ReactNode[]): JSX.Element => {
    return (
      <ul
        style={{
          display: 'contents',
          marginTop: '20px',
        }}
      >
        {dots &&
          dots.map((dot, index) => {
            if (React.isValidElement(dot)) {
              return (
                <li key={index} className="inline-flex align-middle">
                  {dot.props.children}
                </li>
              );
            }
            return null;
          })}
      </ul>
    );
  };

  const styledDot = (index: number): JSX.Element => {
    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          background:
            index === sliderStep
              ? theme === 'light'
                ? '#302f2f'
                : '#ffffff'
              : theme === 'light'
                ? '#D8D8D8'
                : '#49494D',
          borderRadius: '15px',
          marginLeft: '8px',
          cursor: 'pointer',
        }}
      />
    );
  };

  const setIndexes = (next: number) => {
    setCurrentIndex(next);
  };

  return (
    <div className="px-0 md:px-3 py-4 flex justify-center items-center grow bg-white dark:bg-dark-bg rounded-card-box">
      {(items.length > 0 && (
        <>
          <div className="nft-slider">
            <Slider
              className="text-center space-y-4"
              autoplay={true}
              swipe={true}
              slidesToShow={
                isMobile()
                  ? 2
                  : items.length >= 3
                    ? large
                      ? 5
                      : 4
                    : items.length
              }
              slidesToScroll={scrollLength}
              arrows={false}
              dots={true}
              infinite={true}
              speed={800}
              customPaging={index => styledDot(index)}
              beforeChange={(_prev, next) => setIndexes(next)}
              appendDots={dots => getStyledDots(dots)}
              centerPadding={isMobile() ? '35px' : '60px'}
            >
              {items.length > 0 ? (
                items.map(item => {
                  return (
                    <div>
                      <div key={item.id} className="w-40 appear-front px-2">
                        <NFTCard item={item} showChainTag />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex-grow">
                  <SkeletonLoader height="100%" />
                </div>
              )}
            </Slider>
          </div>
        </>
      )) || (
        <div className="flex-grow">
          <SkeletonLoader height="100%" />
        </div>
      )}
    </div>
  );
};
