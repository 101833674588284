export class CustomNFTPrice {
  id?: string;
  userId?: string;
  nftItemId?: string;
  floorMultiplier?: number;
  manualPriceUsd?: number;
  priceEstimateService?: string;
  priceUsd?: number;
  costBasisUsd?: number;
  costBasisNative?: number;
  returnUsd?: number;
  pnlUsd?: number;

  constructor(data?: Partial<CustomNFTPrice>) {
    Object.assign(this, data);
  }

  static fromJSON(json: any): CustomNFTPrice {
    return new CustomNFTPrice({
      id: json['id'],
      userId: json['user_id'],
      nftItemId: json['nft_item_id'],
      floorMultiplier: json['floor_multiplier'],
      manualPriceUsd: json['manual_price_usd'],
      priceEstimateService: json['price_estimate_service'],
      priceUsd: json['price_usd'],
      costBasisUsd: json['cost_basis_usd'],
      costBasisNative: json['cost_basis_native'],
      returnUsd: json['return_usd'],
      pnlUsd: json['pnl_usd'],
    });
  }

  toJSON(): any {
    return {
      id: this.id,
      user_id: this.userId,
      nft_item_id: this.nftItemId,
      floor_multiplier: this.floorMultiplier,
      manual_price_usd: this.manualPriceUsd,
      price_estimate_service: this.priceEstimateService,
      price_usd: this.priceUsd,
      cost_basis_usd: this.costBasisUsd,
      cost_basis_native: this.costBasisNative,
      return_usd: this.returnUsd,
      pnl_usd: this.pnlUsd,
    };
  }
}
