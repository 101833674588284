export const hexToRGBA = (hex: string, alpha = 1) => {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Check if it's a shorthand notation (e.g., #f00)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
