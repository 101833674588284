// api
import { GiveawayPrize } from '@/api/games';

interface GiveawayPrizesCardProps {
  prizes: GiveawayPrize[];
}

export const GiveawayPrizesCard: React.FC<GiveawayPrizesCardProps> = ({
  prizes,
}) => {
  return (
    <div className="text-left space-y-2 overflow-x-auto">
      <p className="text-2xl">Prizes</p>
      <div className="flex overflow-x-auto whitespace-nowrap space-x-4">
        {prizes.map(prize => {
          return (
            <div key={prize.id} className="inline-block relative">
              <div className="relative h-36 flex items-center justify-center bg-gray-100 dark:bg-dark-background overflow-hidden">
                <img
                  src={prize.image_url}
                  alt={prize.title}
                  className="min-w-36 w-36 min-h-36 h-36 object-cover rounded-2xl"
                />
                <div className="absolute text-gray-text bottom-0 w-full h-1/6  bg-deals-card bg-opacity-70 flex items-center px-4 rounded-b-2xl">
                  <p className="text-sm overflow-hidden text-ellipsis font-semibold">
                    {prize.title}
                  </p>
                </div>
              </div>
              {prize.prize_chain && (
                <div className="absolute top-0 left-0 text-sm text-gray-text border border-gray-text bg-deals-card bg-opacity-70 px-2 m-2 rounded-xl">
                  {prize.prize_chain}
                </div>
              )}
              <div className="absolute top-0 right-0 text-sm border text-gray-text border-gray-text bg-deals-card bg-opacity-70 px-2 m-2 rounded-xl">
                {prize.num_winners}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
