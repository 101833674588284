import { atom } from 'recoil';
import { useRecoilState } from 'recoil';

import { localStorageEffect } from './effects/localStorageEffect';

const currencyVisibleState = atom({
  key: 'currencyVisible',
  default: localStorage.getItem('currencyVisible') || true,
  effects: [localStorageEffect('currencyVisible')],
});

const useCurrencyVisibleState = () => useRecoilState(currencyVisibleState);

export { useCurrencyVisibleState };
