import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRewards } from '@/hooks/useRewards';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';

export const PartnerRewardsCard: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, loadPartnerRewards, partnerRewards } = useRewards();

  useEffect(() => {
    const fetchData = async () => {
      await loadPartnerRewards();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`card flex-1 flex-col p-4`}>
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold text-gray-900 dark:text-gray-100">
          💧 My Airdrops & Points
        </h2>
        <button
          onClick={() => navigate('/rewards/partner')}
          className="text-primary font-bold"
        >
          View All
        </button>
      </div>
      <p className="text-sm mt-2 text-gray-700 dark:text-gray-300">
        Track all of your points and airdrop allocations on partner platforms.
      </p>
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : partnerRewards.length === 0 ? (
        <div className="flex justify-center mt-4 text-gray-700 dark:text-gray-300">
          No Airdrops or Points
        </div>
      ) : (
        <div className="mt-4 space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
          {partnerRewards.slice(0, 5).map((reward, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors duration-200 cursor-pointer"
              onClick={() => {
                navigate(`/rewards/${reward.id}`);
              }}
            >
              <div className="flex items-center">
                <img
                  src={reward.imageUrl}
                  className="w-8 h-8 bg-gray-600 rounded-md"
                  alt=""
                />
                <div className="ml-4">
                  <p className="text-sm text-gray-900 dark:text-gray-100">
                    {reward.name}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {reward.type}
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {reward.rewardUnits ?? 0}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
