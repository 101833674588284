export class PartnerGame {
  id: string;
  name: string;
  status: string;
  description: string;
  imageUrl: string;
  gameUrl: string;
  coinsCost: number;
  instructions: string[];
  instructionsLink: string;
  termsLink: string;

  constructor({
    id,
    name,
    status,
    description,
    imageUrl,
    gameUrl,
    coinsCost,
    instructions,
    instructionsLink,
    termsLink
  }: {
    id: string,
    name: string,
    status: string,
    description: string,
    imageUrl: string,
    gameUrl: string,
    coinsCost: number,
    instructions: string[],
    instructionsLink: string,
    termsLink: string
  }) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.description = description;
    this.imageUrl = imageUrl;
    this.gameUrl = gameUrl;
    this.coinsCost = coinsCost;
    this.instructions = instructions;
    this.instructionsLink = instructionsLink;
    this.termsLink = termsLink;
  }

  static fromJson(json: any): PartnerGame {
    return new PartnerGame({
      id: json['id'],
      name: json['name'],
      status: json['status'],
      description: json['description'],
      imageUrl: json['image_url'],
      gameUrl: json['game_url'],
      coinsCost: json['cost_coins'],
      instructions: json['instructions'] || [],
      instructionsLink: json['instructions_link'],
      termsLink: json['terms_link']
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      description: this.description,
      image_url: this.imageUrl,
      game_url: this.gameUrl,
      cost_coins: this.coinsCost,
      instructions: this.instructions,
      instructions_link: this.instructionsLink,
      terms_link: this.termsLink
    };
  }
}

export class PartnerGamesResponse {
  partnerGames?: PartnerGame[];

  constructor({ partnerGames }: { partnerGames?: PartnerGame[] }) {
    this.partnerGames = partnerGames;
  }

  static fromJson(json: any): PartnerGamesResponse {
    const partnerGames = json['partner_games'] ? json['partner_games'].map((v: any) => PartnerGame.fromJson(v)) : undefined;
    return new PartnerGamesResponse({ partnerGames });
  }

  toJson(): { [key: string]: any } {
    const data: { [key: string]: any } = {};
    if (this.partnerGames) data['partner_games'] = this.partnerGames.map((game) => game.toJson());
    return data;
  }
}
