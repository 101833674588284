import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// apis
import { postResetPassword } from '../../api/auth';

// utils
import { getPasswordValidationMessage } from '../../utils/validators';

// hooks
import { useAuth } from '../../hooks/useAuth';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const ResetPasswordForm = ({
  reset_password_token,
}: {
  reset_password_token: string;
}) => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const navigate = useNavigate();
  const successStatus = 'Password reset successfully!';
  const errorStatus = 'Invalid Password';

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resultStatus, setResultStatus] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const validatePassword = (val: string) => {
    const validationResult = getPasswordValidationMessage(val);
    setPasswordError(validationResult);
    if (confirmPassword) {
      if (val === confirmPassword) {
        setPasswordMismatch(false);
      } else {
        setPasswordMismatch(true);
      }
    }
  };

  const validatePasswordMatch = (val: string) => {
    if (val === password) {
      setPasswordMismatch(false);
    } else {
      setPasswordMismatch(true);
    }
  };

  const submit = () => {
    if (passwordError === '' && !passwordMismatch) {
      postResetPassword({ password, reset_password_token })
        .then(res => {
          if (res.status === 200) {
            setResultStatus(successStatus);
            setTimeout(() => {
              navigate('/login');
            }, 2000);
          } else {
            setResultStatus(errorStatus);
          }
        })
        .catch(err => {
          setResultStatus(err?.response?.data?.detail);
        });
    }
  };

  return (
    <div className="login-box">
      <div className="justify-center">
        <div className="space-y-2">
          <div className="space-y-2">
            <div>Password</div>
            <input
              id="password"
              type="password"
              value={password}
              className="input-box"
              onChange={e => {
                validatePassword(e.target.value);
                setPassword(e.target.value);
              }}
            />
            <div className="text-sm text-primary-red h-5 mt-1">
              {passwordError}
            </div>
          </div>
          <div className="space-y-2">
            <div>Confirm Password</div>
            <input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              className="input-box"
              onChange={e => {
                validatePasswordMatch(e.target.value);
                setConfirmPassword(e.target.value);
              }}
            />
            <div className="text-sm text-primary-red h-5 mt-1">
              {passwordMismatch && 'Passwords do not match'}
            </div>
          </div>
        </div>
        <div
          className={
            (resultStatus === successStatus
              ? 'text-price-green'
              : 'text-primary-red') + ' text-sm h-10p mt-4'
          }
        >
          {resultStatus}
        </div>
        <button
          className={classNames(
            passwordError === '' && !passwordMismatch
              ? 'bg-price-green'
              : 'bg-gray-text',
            'mt-2 solid h-10p w-full rounded-lg',
          )}
          onClick={submit}
        >
          Submit
        </button>
        <div className="flex space-x-2 justify-end mt-4 text-tabs-bg dark:text-gray-text">
          <div className="font-bold">
            <button onClick={() => navigate('/login')}>Log In</button>
          </div>
        </div>
      </div>
    </div>
  );
};
