import { NftItemTrait } from './NFTItem';

export class NFTItemDetails {
  name?: string;
  nftThumbnailUrl?: string;
  nftUrl?: string;
  description?: string;
  nftChain?: string;
  lastSaleNative?: number;
  lastSaleUsd?: number;
  lastSaleTimestamp?: string;
  rarityRank?: number;
  floorPriceNative?: number;
  floorPriceUsd?: number;
  traits?: NftItemTrait[];

  constructor(data?: {
    name?: string;
    nftThumbnailUrl?: string;
    nftUrl?: string;
    description?: string;
    nftChain?: string;
    lastSaleNative?: number;
    lastSaleUsd?: number;
    lastSaleTimestamp?: string;
    rarityRank?: number;
    floorPriceNative?: number;
    floorPriceUsd?: number;
    traits?: NftItemTrait[];
  }) {
    this.name = data?.name;
    this.nftThumbnailUrl = data?.nftThumbnailUrl;
    this.nftUrl = data?.nftUrl;
    this.description = data?.description;
    this.nftChain = data?.nftChain;
    this.lastSaleNative = data?.lastSaleNative;
    this.lastSaleUsd = data?.lastSaleUsd;
    this.lastSaleTimestamp = data?.lastSaleTimestamp;
    this.rarityRank = data?.rarityRank;
    this.floorPriceNative = data?.floorPriceNative;
    this.floorPriceUsd = data?.floorPriceUsd;
    this.traits = data?.traits;
  }

  static fromJSON(json: any): NFTItemDetails {
    return new NFTItemDetails({
      name: json['name'],
      nftThumbnailUrl: json['nft_thumbnail_url'],
      nftUrl: json['nft_url'],
      description: json['description'],
      nftChain: json['nft_chain'],
      lastSaleNative: json['last_sale_native'],
      lastSaleUsd: json['last_sale_usd'],
      lastSaleTimestamp: json['last_sale_timestamp'],
      rarityRank: json['rarity_rank'],
      floorPriceNative: json['floor_price_native'],
      floorPriceUsd: json['floor_price_usd'],
      traits: json['traits']
        ? json['traits'].map((v: any) => NftItemTrait.fromJSON(v))
        : undefined,
    });
  }

  toJSON(): any {
    const data: any = {};
    data['name'] = this.name;
    data['nft_thumbnail_url'] = this.nftThumbnailUrl;
    data['nft_url'] = this.nftUrl;
    data['description'] = this.description;
    data['nft_chain'] = this.nftChain;
    data['last_sale_native'] = this.lastSaleNative;
    data['last_sale_usd'] = this.lastSaleUsd;
    data['last_sale_timestamp'] = this.lastSaleTimestamp;
    data['rarity_rank'] = this.rarityRank;
    data['floor_price_native'] = this.floorPriceNative;
    data['floor_price_usd'] = this.floorPriceUsd;
    if (this.traits) {
      data['traits'] = this.traits.map(v => v.toJSON());
    }
    return data;
  }
}
