export interface BannersResponse {
  banners?: DashboardBanner[];
}

export interface DashboardBanner {
  imageUrl?: string;
  page?: string;
  pageId?: string;
}

export class BannersResponse {
  banners?: DashboardBanner[];

  constructor(data?: BannersResponse) {
    if (data) {
      this.banners = data.banners?.map(banner => new DashboardBanner(banner));
    }
  }

  static fromJson(json: any): BannersResponse {
    const bannersResponse = new BannersResponse();
    if (json['banners']) {
      bannersResponse.banners = json['banners'].map((v: any) =>
        DashboardBanner.fromJson(v),
      );
    }
    return bannersResponse;
  }

  toJson(): any {
    const data: any = {};
    if (this.banners) {
      data['banners'] = this.banners.map(v => v.toJson());
    }
    return data;
  }
}

export class DashboardBanner {
  imageUrl?: string;
  page?: string;
  pageId?: string;

  constructor(data?: DashboardBanner) {
    if (data) {
      this.imageUrl = data.imageUrl;
      this.page = data.page;
      this.pageId = data.pageId;
    }
  }

  static fromJson(json: any): DashboardBanner {
    const dashboardBanner = new DashboardBanner();
    dashboardBanner.imageUrl = json['image_url'];
    dashboardBanner.page = json['page'];
    dashboardBanner.pageId = json['page_id'];
    return dashboardBanner;
  }

  toJson(): any {
    const data: any = {};
    data['image_url'] = this.imageUrl;
    data['page'] = this.page;
    data['page_id'] = this.pageId;
    return data;
  }
}
