import { useEffect } from 'react';
import { usePortfolio } from '@/hooks/usePortfolio';

import { SeriesCardContainer } from '../../common/SeriesCard/SeriesCardContainer';

import { HoldingTypeGroupInfo } from '@/features/portfolio/holdings/enums/HoldingTypeGroup';

interface PortfolioHoldingsSeriesCardProps {
  holdingTypeGroup: string;
}

export const PortfolioHoldingsSeriesCard: React.FC<
  PortfolioHoldingsSeriesCardProps
> = ({ holdingTypeGroup }) => {
  const {
    portfolioHoldingsChartDataMap,
    fetchPortfolioSeriesChart,
    isPortfolioSeriesChartFetching,
    isLoading,
  } = usePortfolio();

  const type = HoldingTypeGroupInfo.holdingTypeGroupByApiValue(
    holdingTypeGroup!,
  );

  useEffect(() => {
    if (
      holdingTypeGroup &&
      !portfolioHoldingsChartDataMap.has(holdingTypeGroup)
    ) {
      fetchPortfolioSeriesChart(holdingTypeGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingTypeGroup]);

  return (
    <div className="w-full">
      <SeriesCardContainer
        title={
          holdingTypeGroup === 'all'
            ? 'Net Worth'
            : HoldingTypeGroupInfo.title[type!]
        }
        holdingChartDataMap={portfolioHoldingsChartDataMap.get(
          holdingTypeGroup,
        )}
        isLoading={isPortfolioSeriesChartFetching || isLoading}
        logo={
          holdingTypeGroup !== 'all'
            ? `https://storage.googleapis.com/assetdash-prod-images/asset_types/${holdingTypeGroup}.png`
            : undefined
        }
      />
    </div>
  );
};
