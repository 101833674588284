import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useDealsLegacy } from '@/hooks/useDealsLegacy';
import DashDropClaimModalStandard from '../market/deals/DashDropClaimModalStandard';
import { Tooltip } from '../common/Tooltip/Tooltip';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useThemeState } from '@/recoil/theme';

export default function RaffleTicketModal({
  open,
  setOpen,
  raffleTickets,
  message,
}) {
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const { claimRaffleTickets, fetchWeeklyRaffles, memberCenterData } =
    useDealsLegacy();
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';
  const rowBg = theme === 'light' ? 'bg-primary-gray' : 'bg-black';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${background} relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6`}
              >
                <div>
                  <div className="text-center">
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon
                        height={24}
                        width={24}
                        className="text-price-red"
                      />
                    </span>
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl font-bold mb-4 ${textColor}`}
                    >
                      Current Tickets
                    </Dialog.Title>
                    <div className="grid grid-cols-1 gap-4 max-h-145 overflow-scroll scroller">
                      {raffleTickets.map((raffle_ticket, index) => {
                        return (
                          <div key={raffle_ticket?.id}>
                            <div
                              className={`p-4 flex justify-between items-center rounded-2xl  space-y-2 text-left ${rowBg}`}
                            >
                              <div className="flex flex-col-reverse md:flex-row space-x-0 md:space-x-4 items-center">
                                <div>
                                  <img
                                    src={raffle_ticket?.image_url}
                                    alt="raffle"
                                    className="w-max"
                                    style={{ height: '70px' }}
                                  />
                                </div>
                                <div className="flex space-x-2 items-center">
                                  <p
                                    className={`${textColor} text-sm md:text-xl capitalize`}
                                  >
                                    {raffle_ticket?.title}
                                  </p>
                                  {raffle_ticket?.info && (
                                    <Tooltip
                                      text={raffle_ticket?.info}
                                      position="bottom"
                                    >
                                      <InformationCircleIcon
                                        className={`h-4 w-4 ${textColor}`}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                              <button
                                onClick={async () => {
                                  if (raffle_ticket.claimed) {
                                    navigator.clipboard.writeText(
                                      raffle_ticket.ticket_code,
                                    );
                                    toast.success(
                                      'Giveaway ticket copied successfully',
                                    );
                                  } else {
                                    if (
                                      memberCenterData?.member_status ===
                                      'standard'
                                    ) {
                                      setOpenClaimModal(true);
                                    } else {
                                      await claimRaffleTickets();
                                      await fetchWeeklyRaffles();
                                    }
                                  }
                                }}
                                className={`border-2 w-max ${textColor} border-price-green px-4 md:px-8 h-max py-2 rounded-8 min-w-36 md:min-w-42`}
                              >
                                {raffle_ticket.claimed
                                  ? raffle_ticket.ticket_code
                                  : 'Claim'}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <DashDropClaimModalStandard
            open={openClaimModal}
            setOpen={setOpenClaimModal}
          />
        </div>
      </Dialog>
    </Transition.Root>
  );
}
