import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import { ExpeditionsContainer } from '../../components/expeditions/ExpeditionsContainer';

export default function ExpeditionsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title={`AssetDash | Expeditions`} lang="en_IE" />
      <div className="main-learn-content mx-auto space-y-6">
        <ExpeditionsContainer />
      </div>
    </>
  );
}
