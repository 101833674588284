import { useNavigate } from 'react-router-dom';

import { ArcadeGame } from '../../../api/games';

interface ArcadeGameCardProps {
  arcadeGame: ArcadeGame;
}

export const ArcadeGameCard: React.FC<ArcadeGameCardProps> = ({
  arcadeGame,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/games/arcade/${arcadeGame.id}`);
      }}
      className={`cursor-pointer h-96 w-56 p-4 flex flex-col justify-between rounded-card-box bg-white dark:bg-deals-card-top`}
    >
      <div className="md:col-span-2 lg:col-span-2 bg-white p-1 mb-2 dark:bg-deals-card rounded-13 h-max">
        <img
          src={arcadeGame.image_url!}
          className="min-w-42 h-42 object-contain mx-auto"
          alt="logo"
        />
      </div>
      <div className="space-y-1">
        <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {arcadeGame.name}
        </div>
        <div className="text-xs text-gray-text h-16 card-description-multiline-truncate">
          {arcadeGame.description}
        </div>
      </div>
      <div className="space-y-1 flex justify-center">
        <div className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full">
          <div className="pl-2">
            <img
              className="rounded-full"
              src="/assets/icons/coin.png"
              alt="Coin"
              height={24}
              width={24}
            />
          </div>
          <span className="pr-2 text-sm">Play Now</span>
        </div>
      </div>
    </div>
  );
};
