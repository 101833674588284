import { axiosAssetDashApi } from './api';

const getAuctions = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/market/auctions/list`);
};

const getAuction = async (auctionId: string, checkCache = true) => {
  const params = {
    check_cache: checkCache,
  };

  return await axiosAssetDashApi.get(
    `/api/api_v4/market/auctions/${auctionId}/details`,
    { params },
  );
};

const getAuctionMyDetails = async (auctionId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/market/auctions/${auctionId}/my_details`,
  );
};

const getAuctionBids = async (auctionId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/market/auctions/${auctionId}/bids`,
  );
};

const getAuctionBidLeaderboards = async (auctionId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/market/auctions/${auctionId}/bid_leaderboards`,
  );
};

const getAuctionUserPrizes = async (auctionId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/market/auctions/${auctionId}/user_prizes`,
  );
};

const placeAuctionBid = (auctionId: string, amount: number) => {
  return axiosAssetDashApi.post(`/api/api_v4/market/auctions/place_bid`, {
    auction_id: auctionId,
    amount: amount,
  });
};

const getAuctionMyBids = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/market/auctions/my_bids`);
};

const getAuctionMyPrizes = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/market/auctions/my_prizes`);
};

const auctionsApi = {
  getAuctions,
  getAuction,
  getAuctionMyDetails,
  getAuctionBids,
  getAuctionBidLeaderboards,
  getAuctionUserPrizes,
  placeAuctionBid,
  getAuctionMyBids,
  getAuctionMyPrizes,
};

export default auctionsApi;
