import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import {
  PlusIcon,
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
} from '@heroicons/react/24/solid';

import { useThemeState } from '@/recoil/theme';

export const LearnMoreModal = ({ open, setOpen, faqs }) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-member-center-bg';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';
  const answerColor = theme === 'light' ? 'text-black' : 'text-gray-text';
  const iconColor = theme === 'light' ? 'black' : 'white';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-700"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-scroll scroller h-135.25 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all  sm:w-full lg:max-w-2xl sm:my-8 sm:max-w-lg sm:p-6 ${background}`}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rotate-45 hover:text-gray-500 focus:outline-none "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <PlusIcon className="text-price-red h-6 w-6" />
                  </button>
                </div>
                <div className=" space-y-2">
                  {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div> */}
                  <div className="mt-0 text-center sm:mt-0">
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl font-bold ${textColor}`}
                    >
                      FAQ
                    </Dialog.Title>
                  </div>

                  <dl className="mt-6 space-y-6 divide-y-2 divide-member-center-card">
                    {faqs?.map(faq => (
                      <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                          <>
                            <dt className="text-lg">
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                                <span
                                  className={`font-medium ${textColor} text-lg md:text-2xl`}
                                >
                                  {faq.question}
                                </span>
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <ChevronDoubleUpIcon
                                      height={12}
                                      width={12}
                                      color={iconColor}
                                      style={{ transition: 'transform .4s' }}
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChevronDoubleDownIcon
                                      height={12}
                                      width={12}
                                      color={iconColor}
                                      style={{ transition: 'transform .4s' }}
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className={`text-base ${answerColor}`}>
                                {faq.answer}
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </dl>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
