import { useState } from 'react';
import { SkeletonLoader } from '../Skeleton/SkeletonLoader';

// hooks
import { useAuth } from '@/hooks/useAuth';

// components
import { SeriesCardLineChart } from './SeriesCardLineChart';
import { SeriesCardTabBar } from './SeriesCardTabBar';
import { PortfolioChartData } from '@/features/portfolio/worth/models/CategoryWorthModel';
import { ComponentPlaceholder } from '../../common/Placeholder/ComponentPlaceholder';

// interfaces
import { SeriesCardLineChartDatapoint } from './SeriesCardLineChart';

// utils
import { formatNumber } from '@/utils/number';

type SeriesCardContainerProps = {
  holdingChartDataMap?: Map<number, PortfolioChartData>;
  isLoading: boolean;
  title: string;
  logo?: string;
};

export const SeriesCardContainer: React.FC<SeriesCardContainerProps> = ({
  holdingChartDataMap,
  isLoading,
  logo,
  title,
}) => {
  const [activeValue, setActiveValue] = useState(null);
  const [activeDate, setActiveDate] = useState<string | null>(null);
  const [selected, setSelected] = useState('1');

  const { convertUSDCurrency } = useAuth();

  const { datapoints, priceGain, priceGainPercent, value } = holdingChartDataMap
    ? holdingChartDataMap.get(Number(selected))!
    : ({} as PortfolioChartData);

  let data: SeriesCardLineChartDatapoint[] = [];
  if (datapoints) {
    data = datapoints.map(datapoint => {
      return {
        date: datapoint.timestamp!,
        value: datapoint.value,
      };
    });
  }

  const onTabClick = (key: string) => {
    setSelected(key);
  };

  const getFormattedDate = (date: Date) => {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const handleActiveDatapoint = (datapoint: any, active: boolean) => {
    if (active && datapoint.payload) {
      const formattedDate = getFormattedDate(datapoint.payload.date);
      setActiveDate(formattedDate);
      setActiveValue(datapoint.payload.value);
    } else {
      setActiveValue(null);
      setActiveDate(null);
    }
  };

  const subValue = activeValue !== null ? activeValue : priceGain;
  const subValueColor = activeValue
    ? 'text-primary-orange'
    : priceGain > 0
      ? 'text-price-green'
      : 'text-primary-red';

  return (
    <div
      className={`card pt-4 pb-6 px-4 md:px-6 rounded-card-box max-w-full w-full flex`}
    >
      {isLoading || data.length > 0 ? (
        <div className="md:flex-row md:grow flex w-inherit">
          <div className="md:min-w-170625 flex flex-col w-full">
            <div className="flex space-x-2 items-center capitalize text-tabs-bg dark:text-white font-semibold text-lg leading-card-title w-full">
              {logo && (
                <img src={logo} alt="logo" className="w-6 h-6 rounded-full" />
              )}
              <div>{title}</div>
            </div>
            <div>
              <div className="text-8 leading-asset-value mt-1 text-black dark:text-white grow-0 mr-4">
                {!isLoading ? (
                  <div>
                    <div className="text-22 leading-asset-value whitespace-nowrap">
                      {convertUSDCurrency(value)}
                    </div>
                    <div className="flex flex-row w-full justify-between space-x-2">
                      <div className="flex items-center">
                        <div
                          className={`${subValueColor} font-semibold text-base leading-28 whitespace-nowrap`}
                        >
                          {convertUSDCurrency(subValue)}
                        </div>
                      </div>
                      {activeDate ? (
                        <div className="text-sm items-center my-auto ml-auto min-w-43">
                          {activeDate}
                        </div>
                      ) : (
                        <div className={`${subValueColor} text-base ml-45`}>
                          {formatNumber(priceGainPercent)}%
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <SkeletonLoader count={2} />
                )}
              </div>
              <div className="mt-8 w-full">
                <div className="flex flex-col justify-between md:items-stretch -mt-2">
                  {!isLoading && data && data.length > 0 ? (
                    <SeriesCardLineChart
                      data={data}
                      isPriceGain={priceGain >= 0}
                      handleActiveDatapoint={handleActiveDatapoint}
                    />
                  ) : (
                    <SkeletonLoader height={64} />
                  )}
                  <SeriesCardTabBar
                    selected={selected}
                    handleClick={onTabClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <ComponentPlaceholder />
        </div>
      )}
    </div>
  );
};
