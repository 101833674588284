import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemeState } from '@/recoil/theme';

export default function DashDropClaimModalStandard({ open, setOpen }) {
  const navigate = useNavigate();
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-member-center-bg';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-700"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-scroll scroller h-max rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all  sm:w-full lg:max-w-2xl sm:my-8 sm:max-w-lg sm:p-6 ${background}`}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rotate-45 hover:text-gray-500 focus:outline-none "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <PlusIcon className="text-price-red h-6 w-6" />
                  </button>
                </div>
                <div className="mt-2 md:mt-4 lg:col-span-2 lg:mt-0 py-2 md:py-4">
                  <div className="space-y-4">
                    <p
                      className={`${textColor} text-3xl font-bold text-center`}
                    >
                      Verify to access your DashDrop Giveaway giveaways
                    </p>
                    <p className={`${textColor} text-md`}>
                      You need to be Bronze Tier or higher to access this
                      feature. AssetDash recommends having a verified wallet of
                      $150 or higher to become Bronze Tier. Tap continue below
                      to get started.
                    </p>
                    <img
                      src="/assets/images/standard-claim-ticket.png"
                      className="w-full object-contain h-full"
                      alt="Claim Ticket"
                    />
                    <div>
                      <button
                        className="mt-4 bg-price-green text-black py-2 w-full rounded-xl"
                        onClick={() => navigate('/wallets')}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
