import { createContext, useContext, useState } from 'react';

interface ConfettiContextProps {
  showConfetti: boolean;
  setShowConfetti: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GamesContext = createContext<ConfettiContextProps | undefined>(
  undefined,
);

export const ConfettiContext = createContext({} as any);

export function useConfetti() {
  return useContext(ConfettiContext);
}

export const ConfettiProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [showConfetti, setShowConfetti] = useState(false);

  return (
    <ConfettiContext.Provider
      value={{
        showConfetti,
        setShowConfetti,
      }}
    >
      {children}
    </ConfettiContext.Provider>
  );
};
