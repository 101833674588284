import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

import {
  getUserCoinCenter,
  CoinCenterResponse,
  CoinDistributionsResponse,
  getCoinDistributions,
  CoinSpendingsResponse,
  getCoinSpendings,
} from '../api/coin';

interface CoinCenterContextProps {
  load: () => Promise<void>;
  loadUserCoinCenter: () => Promise<void>;
  loadCoinDistributions: (
    sort_by: string,
    filter_by: string,
    page: number,
  ) => Promise<void>;
  loadCoinSpendings: () => Promise<void>;
  clearCoinData: () => void;

  userCoinCenter?: CoinCenterResponse;
  coinDistributions: CoinDistributionsResponse['coin_distributions'];
  coinSpendings: CoinSpendingsResponse['coin_spendings'];

  isLoading: boolean;
}

export const CoinCenterContext = createContext<
  CoinCenterContextProps | undefined
>(undefined);

export const useCoinCenter = (): CoinCenterContextProps => {
  const context = useContext(CoinCenterContext);
  if (!context) {
    throw new Error('useCoinCenter must be used within a CoinCenterProvider');
  }
  return context;
};

export const CoinCenterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userCoinCenter, setUserCoinCenter] = useState<CoinCenterResponse>();
  const [coinDistributions, setCoinDistributions] = useState<
    CoinDistributionsResponse['coin_distributions']
  >([]);
  const [coinSpendings, setCoinSpendings] = useState<
    CoinSpendingsResponse['coin_spendings']
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    await Promise.all([
      loadUserCoinCenter(),
      loadCoinDistributions('created', 'all', 1),
      loadCoinSpendings(),
    ]);
  };

  const loadUserCoinCenter = async () => {
    setIsLoading(true);
    try {
      const response = await getUserCoinCenter();
      setUserCoinCenter(response.data);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadCoinDistributions = async (
    sort_by: string,
    filter_by: string,
    page: number,
  ) => {
    setIsLoading(true);
    try {
      const response = await getCoinDistributions(sort_by, filter_by, page);
      setCoinDistributions(response.data.coin_distributions || []);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadCoinSpendings = async () => {
    setIsLoading(true);
    try {
      const response = await getCoinSpendings();
      setCoinSpendings(response.data.coin_spendings || []);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearCoinData = () => {
    setUserCoinCenter(undefined);
    setCoinDistributions([]);
    setCoinSpendings([]);
  };

  return (
    <CoinCenterContext.Provider
      value={{
        // methods
        load,
        loadUserCoinCenter,
        loadCoinDistributions,
        loadCoinSpendings,
        clearCoinData,

        // values
        userCoinCenter,
        coinDistributions,
        coinSpendings,

        isLoading,
      }}
    >
      {children}
    </CoinCenterContext.Provider>
  );
};
