import { CoinRewardCard } from './CoinRewardCard';
import { PartnerRewardsCard } from './PartnerRewardsCard';
import { TokenRewardCard } from './TokenRewardCard';

export const RewardsContainer: React.FC = () => {
  return (
    <div>
      <div className="text-2xl leading-none mb-4">Rewards</div>
      <div className="flex flex-col lg:flex-row gap-6 text-white">
        <div className="flex flex-col flex-1 gap-6">
          <CoinRewardCard />
          <PartnerRewardsCard />
        </div>
        <TokenRewardCard />
      </div>
    </div>
  );
};
