import { axiosAssetDashApi } from './api';

export const getMatricaOauthURL = () => {
  return axiosAssetDashApi.get(`matrica/v1/generate_oauth_url`);
};

export const generateSocialOauthURL = (platform: string) => {
  const params = {
    platform: platform,
  };
  return axiosAssetDashApi.get(`/api/api_v4/users/generate-social-oauth-link`, {
    params,
  });
};
