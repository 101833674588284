import { ArrowIcon } from '../common/Icon/ArrowIcon';

export default function Onboard1({ handleOnboard }) {
  return (
    <div>
      <div className="pt-8 px-4">
        <div
          className="onboard-box anim-slide-down bg-cover py-4 px-2 height-new-onboard"
          style={{
            backgroundImage:
              "url('https://storage.googleapis.com/assetdash-prod-images/web_app/onboarding1.png')",
          }}
        >
          <div className="h-full grid my-auto">
            <div className="mt-8 text-2xl md:text-10x leading-6 md:leading-10 font-bold text-center text-white">
              Track your portfolio like a pro
            </div>
            <img
              src="https://storage.googleapis.com/assetdash-prod-images/web_app/onboard1center.png"
              alt="Welcome"
              className="text-center justify-center mx-auto my-8 w-1/2 h-auto"
            />
            <div className="px-4 md:px-30 text-center text-xl md:text-22 font-semibold order-2 items-stretch leading-6 md:leading-7 mb-10 text-white">
              Track your entire portfolio from memecoins to stocks to DeFi to
              crypto to NFTs in a single dashboard.
            </div>
            <button
              className="bg-price-green max-h-16 h-12 hover:bg-hover-price-green order-3 relative bottom-1 text-black w-full rounded-xl mt-4"
              onClick={handleOnboard}
            >
              <div className="flex items-center text-center justify-center font-semibold space-x-4 text-sm text-tabs-bg hover:text-black">
                <p>Continue</p>
                <ArrowIcon
                  direction="right"
                  iconStyle={{ color: '#49494d', fontWeight: '600' }}
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
