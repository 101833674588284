import { PortfolioChartDatapoint } from '../../portfolio/worth/models/CategoryWorthModel';

export class AssetPriceChartSeries {
  all?: PortfolioChartDatapoints;
  day1Items?: PortfolioChartDatapoints;
  week1Items?: PortfolioChartDatapoints;
  month1Items?: PortfolioChartDatapoints;
  month3Items?: PortfolioChartDatapoints;
  year1Items?: PortfolioChartDatapoints;

  constructor({
    all,
    day1Items,
    week1Items,
    month1Items,
    month3Items,
    year1Items,
  }: {
    all?: PortfolioChartDatapoints;
    day1Items?: PortfolioChartDatapoints;
    week1Items?: PortfolioChartDatapoints;
    month1Items?: PortfolioChartDatapoints;
    month3Items?: PortfolioChartDatapoints;
    year1Items?: PortfolioChartDatapoints;
  }) {
    this.all = all;
    this.day1Items = day1Items;
    this.week1Items = week1Items;
    this.month1Items = month1Items;
    this.month3Items = month3Items;
    this.year1Items = year1Items;
  }

  static fromJSON(json: any): AssetPriceChartSeries {
    return new AssetPriceChartSeries({
      all: json['all']
        ? PortfolioChartDatapoints.fromJSON(json['all'])
        : undefined,
      day1Items: json['day_1']
        ? PortfolioChartDatapoints.fromJSON(json['day_1'])
        : undefined,
      week1Items: json['week_1']
        ? PortfolioChartDatapoints.fromJSON(json['week_1'])
        : undefined,
      month1Items: json['month_1']
        ? PortfolioChartDatapoints.fromJSON(json['month_1'])
        : undefined,
      month3Items: json['month_3']
        ? PortfolioChartDatapoints.fromJSON(json['month_3'])
        : undefined,
      year1Items: json['year_1']
        ? PortfolioChartDatapoints.fromJSON(json['year_1'])
        : undefined,
    });
  }
}

export class PortfolioChartDatapoints {
  points: PortfolioChartDatapoint[];

  constructor(points: PortfolioChartDatapoint[] = []) {
    this.points = points;
  }

  get maxvalue(): number {
    if (this.points.length === 0) {
      return 0;
    }

    if (this.points.length === 1) {
      return this.points[0].value;
    }

    return this.points.reduce((previousValue, element) =>
      previousValue.value > element.value ? previousValue : element,
    ).value;
  }

  get minvalue(): number {
    if (this.points.length === 0) {
      return 0;
    }

    if (this.points.length === 1) {
      return this.points[0].value;
    }

    return this.points.reduce((previousValue, element) =>
      previousValue.value < element.value ? previousValue : element,
    ).value;
  }

  static fromJSON(json: any): PortfolioChartDatapoints {
    return new PortfolioChartDatapoints(
      ((json['chart'] as any[]) || []).map(point =>
        PortfolioChartDatapoint.fromJSON(point),
      ),
    );
  }
}
