export const Toggle = ({ handleToggle, isChecked, id }) => {
  return (
    <div className="form-switch">
      <input
        type="checkbox"
        id={`switch-${id}`}
        className="hidden"
        checked={isChecked}
        onChange={handleToggle}
      />
      <label htmlFor={`switch-${id}`} className="slider"></label>
    </div>
  );
};
