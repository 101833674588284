// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import {
  InformationCircleIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';

// hooks
import { useGames } from '../../../hooks/useGames';

// components
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { ConfirmBuyPassModal } from './ConfirmBuyPassModal';
import { Countdown } from '../../common/Countdown/Countdown';

import {
  PartnerGame,
  // PartnerGameStore,
  PartnerGamePass,
} from '../../../api/games';

export const GameDetailsContainer = () => {
  const { gameId } = useParams();
  const {
    loadGamesPartnersGame,
    gamesPartnersGameDetails,
    postGamesBuyPartnerGamePass,
  } = useGames();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [partnerGame, setPartnerGame] = useState<PartnerGame | undefined>();
  // const [partnerGameStore, setPartnerGameStore] = useState<PartnerGameStore | undefined>();
  const [partnerGamePass, setPartnerGamePass] = useState<
    PartnerGamePass | undefined
  >();

  useEffect(() => {
    if (gameId) {
      loadGame(gameId);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  const loadGame = async (gameId: string) => {
    try {
      await loadGamesPartnersGame(gameId);
    } catch (err) {
      navigate('/games');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (gamesPartnersGameDetails) {
      setPartnerGame(gamesPartnersGameDetails.partner_game);
      // setPartnerGameStore(gamesPartnersGameDetails.partner_game_store);
      setPartnerGamePass(gamesPartnersGameDetails.partner_game_pass);
    }
  }, [gamesPartnersGameDetails]);

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      const resp = await postGamesBuyPartnerGamePass(gameId!);
      setPartnerGamePass(resp.data.partner_game_pass);
      setIsExploding(true);
      toast.success('Game pass purchased');
    } catch (err) {
      toast.error(
        (err as any)?.response?.data?.detail || 'Something went wrong',
      );
    }
    setIsSubmitLoading(false);
  };

  return (
    <div className="mx-auto main-content grid grid-cols-1 gap-y-6 md:gap-y-0 md:gap-6">
      {!isLoading && partnerGame ? (
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-8 mt-8 px-4">
          <div className="md:col-span-2 lg:col-span-2 bg-white p-3 dark:bg-deals-card rounded-13 h-max">
            <img
              src={partnerGame.image_url!}
              className="w-full h-96 object-contain mx-auto"
              alt="logo"
            />
          </div>
          <div className="text-center md:col-span-2 lg:col-span-3 space-y-4">
            <div className="flex flex-row space-x-2 justify-between items-center">
              <div className={`font-semibold text-left text-3xl`}>
                {partnerGame.name}
              </div>
            </div>
            <div className="flex space-x-2 mb-2">
              {partnerGame.tags?.map(tag => (
                <div
                  key={tag.id}
                  className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full"
                >
                  <span className="px-2 text-sm">{tag.name}</span>
                </div>
              ))}
              <div className="inline-flex items-center space-x-1 text-xs px-1 py-1 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full">
                <div className="pl-2">
                  <img
                    className="rounded-full"
                    src="/assets/icons/coin.png"
                    alt="Coin"
                    height={24}
                    width={24}
                  />
                </div>
                <span className="pr-2 text-sm">
                  {partnerGame.cost_coins} Coins
                </span>
              </div>
            </div>
            <div
              className={`grid 
                  'grid-cols-1'
                } gap-6 my-8`}
            >
              <div className="text-left space-y-2">
                <div className="flex justify-between">
                  <p className="text-xl font-bold">Description</p>
                  <div className="flex space-x-2">
                    <InformationCircleIcon
                      className="cursor-pointer"
                      height={24}
                      width={24}
                      onClick={() => window.open(partnerGame.instructions_link)}
                    />
                    <ListBulletIcon
                      className="cursor-pointer"
                      height={24}
                      width={24}
                      onClick={() => window.open(partnerGame.terms_link)}
                    />
                  </div>
                </div>
                <p>{partnerGame.description}</p>
              </div>
            </div>

            {partnerGame.instructions &&
              partnerGame.instructions.length > 0 && (
                <div className="mt-2 bg-white dark:bg-deals-card-top p-4 rounded-xl">
                  <div>
                    <div className="space-y-4">
                      <p className="text-left text-xl font-bold">
                        Instructions
                      </p>
                      <div className="space-y-2">
                        {partnerGame.instructions &&
                          partnerGame.instructions.length > 0 &&
                          partnerGame.instructions.map((instruction, index) => {
                            return (
                              <div
                                key={instruction}
                                className="flex items-center space-x-2"
                              >
                                <p className="min-w-6 text-black bg-price-green rounded-full">
                                  {index + 1}
                                </p>
                                <p className="text-left">{instruction}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div
              className={`grid 
                  'grid-cols-1'
                } gap-6 my-8`}
            >
              {/* <div className="text-left space-y-2 flex flex-col items-center md:items-start bg-gradient-to-b from-price-green to-deals-card-top  p-4 rounded-xl"> */}
              <div className="text-left space-y-2 flex flex-col items-center md:items-start bg-green-gradient  p-4 rounded-xl">
                <p className="text-xl font-bold">Game Pass</p>
                <div className="flex space-x-1">
                  <div>Your Game Pass is eligible for</div>
                  <span className="text-price-green font-semibold">
                    {partnerGamePass ? (
                      <Countdown
                        targetTime={moment(partnerGamePass.end_time + 'Z')}
                      />
                    ) : (
                      <div>24 hours.</div>
                    )}
                  </span>
                </div>
              </div>
            </div>

            {!partnerGamePass && (
              <button
                type="button"
                className={`flex justify-center w-full md:justify-start ${
                  isSubmitLoading
                    ? 'bg-white dark:bg-tabs-bg'
                    : 'bg-price-green text-black'
                }  rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                disabled={isSubmitLoading}
                onClick={() => setIsModalOpen(true)}
              >
                {isSubmitLoading ? (
                  <div className="loader w-full"></div>
                ) : (
                  <div>Buy Game Pass</div>
                )}
              </button>
            )}

            {isExploding && (
              <ConfettiExplosion duration={5000} height={3000} width={3000} />
            )}

            {partnerGamePass && (
              <div className="space-y-2">
                <button
                  type="button"
                  className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                  onClick={() => {
                    window.open(partnerGame.game_url!);
                  }}
                >
                  <div className="text-black text-2xl ">Launch Game</div>
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
      <ConfirmBuyPassModal
        isOpen={isModalOpen}
        onSubmit={handleSubmit}
        closeModal={() => setIsModalOpen(false)}
        coinCost={partnerGame?.cost_coins!}
        gameName={partnerGame?.name!}
      />
    </div>
  );
};
