import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// api
import { postForgotPassword } from '../../api/auth';

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();

  const successStatus =
    'Reset Link is sent to this email address. If an account exists, please use that link to reset your password';
  const errorStatus = 'Invalid email address';

  const [email, setEmail] = useState('');
  const [resultStatus, setResultStatus] = useState('');

  const updateEmail = (value: string) => {
    setEmail(value);
  };

  const sendLink = () => {
    if (email) {
      postForgotPassword(email)
        .then(res => {
          if (res.status === 200) {
            setResultStatus(successStatus);
            toast.success(res.data.msg);
          } else {
            setResultStatus(errorStatus);
          }
        })
        .catch(e => {
          const error = e.response.data?.detail || 'Could not validate email';
          setResultStatus(error);
        });
    }
  };

  return (
    <div className="login-box">
      <div className="justify-center">
        <div className="space-y-2">
          <div className="space-y-2">
            <div>E-mail</div>
            <input
              id="email"
              type="email"
              value={email}
              className="input-box"
              onChange={e => updateEmail(e.target.value)}
            />
          </div>
        </div>
        <div
          className={
            (resultStatus === successStatus
              ? 'text-price-green'
              : 'text-primary-red') + ' text-sm h-10p mt-4'
          }
        >
          {resultStatus}
        </div>
        <button
          className="mt-2 solid h-10p bg-price-green w-full rounded-lg"
          onClick={sendLink}
        >
          Send Link
        </button>
        <div className="flex space-x-2 justify-end mt-4 text-tabs-bg dark:text-gray-text">
          <button className="font-bold" onClick={() => navigate('/login')}>
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};
