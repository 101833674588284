import { useNavigate } from 'react-router-dom';

// hooks
import { useAuth } from '../../hooks/useAuth';
import { usePortfolio } from '../../hooks/usePortfolio';

// models
import { WalletNFTItem } from '../../features/portfolio/models/WalletNFTItem';
import { SvgIcon } from '../common/Icon/SvgIcon';
import { AppColors } from '../../features/core/theming/colors';

// utils
import { formatPriceNumber } from '../../utils/number';

// recoil
import { useCurrencyVisibleState } from '../../recoil/currencyVisible';

// components
import { ImageWrapper } from '../common/Image/ImageWrapper';

export const NFTCard: React.FC<{
  item: WalletNFTItem;
  showChainTag?: boolean;
}> = ({ item, showChainTag = false }) => {
  const navigate = useNavigate();
  const { convertUSDCurrency } = useAuth();
  const { customNFTPricesMap } = usePortfolio();
  const [currencyVisible] = useCurrencyVisibleState();

  const handleClick = () => {
    navigate(`/nfts/${item?.nftItemId}`);
  };

  return (
    <div
      onClick={handleClick}
      className="min-w-39.5 h-299 space-x-4 cursor-pointer"
    >
      <div className="border solid border-border-bg dark:border-tabs-bg rounded-card-box">
        <div className="rounded-card-box">
          <ImageWrapper
            verified={item.verified!}
            url={item?.nftItem?.getThumbnailUrl()!}
            alt="nft"
          />
        </div>
        <div className="p-4 pt-12">
          <div className="text-sm text-left leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {item?.nftItem?.name}
          </div>
          <div className="flex space-x-1">
            {showChainTag && (
              <div className="text-price-green text-10 leading-17 bg-primary-gray dark:bg-tabs-bg w-max px-4p py-3p rounded-2xl mt-1">
                {item?.chain}
              </div>
            )}
            {item?.nftItem?.rarityRank && (
              <div className="text-primary-orange text-10 leading-17 bg-primary-gray dark:bg-tabs-bg w-max px-4p py-3p rounded-2xl mt-1">
                Rank #{item?.nftItem?.rarityRank}
              </div>
            )}
          </div>
          <div className="text-sm leading-21 text-right text-price-green mt-7 flex justify-end">
            <SvgIcon
              fullPath={`https://storage.googleapis.com/assetdash-prod-images/mobile_app/nft_chains/${item.chain}.svg`}
              color={AppColors.primary()}
              size={{ height: 14, width: 14 }}
            />
            {currencyVisible ? formatPriceNumber(item?.priceNative!) : '--.--'}
          </div>
          <div className="text-lg leading-21 text-right text-price-green">
            {customNFTPricesMap.has(item?.assetId!)
              ? convertUSDCurrency(
                  customNFTPricesMap.get(item?.assetId!)?.priceUsd!,
                )
              : convertUSDCurrency(item?.priceUsd!)}
          </div>
        </div>
      </div>
    </div>
  );
};
