// vendor libraries
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

//
import { GamesContainer } from '@/components/v2/games/GamesContainer';

export default function GamesCenterPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="main-content overflow-hidden">
      <SuperSEO title={`AssetDash | Game Center`} lang="en_IE" />
      <GamesContainer />
    </div>
  );
}
