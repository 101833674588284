// vendor libraries
import { useState, useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import ReactMarkdown from 'react-markdown';

// api
import { getTermsOfService } from '../api/terms';

export default function TermsOfServicePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [terms, setTerms] = useState<any>({});

  useEffect(() => {
    fetchTermsOfService();
  }, []);

  const fetchTermsOfService = async () => {
    const response = await getTermsOfService();
    setTerms(response.data);
  };

  return (
    <>
      <SuperSEO title={`AssetDash | Terms Of Service`} lang="en_IE" />
      <div className="main-content mx-auto">
        <h1 className="text-32 leading-none text-center mt-8 mb-8">
          Terms of Service
        </h1>
        <div className="divide-y divide-gray-text dark:divide-gray-600 py-5 h-full">
          <ReactMarkdown>{terms?.text}</ReactMarkdown>
        </div>
      </div>
    </>
  );
}
