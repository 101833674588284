import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { useRewards } from '../../hooks/useRewards';
import { usePortfolio } from '../../hooks/usePortfolio';

import { SkeletonLoader } from '../../components/common/Skeleton/SkeletonLoader';

import {
  PartnerRewardUserDetail,
  PartnerRewardUserWallet,
} from '../../features/rewards/models/PartnerRewardDetail';
import { Wallet } from '../../features/portfolio/models/Wallet';

import { formatNumber } from '../../../src/utils/number';

export const RewardDetailsContainer: React.FC = () => {
  const { loadPartnerRewardDetail, partnerRewardDetail, isLoading } =
    useRewards();
  const { walletsMap } = usePortfolio();

  const { partnerRewardId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (partnerRewardId && walletsMap.size > 0) {
      loadPartnerReward(partnerRewardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerRewardId, walletsMap]);

  const loadPartnerReward = async (partnerRewardId: string) => {
    try {
      await loadPartnerRewardDetail(partnerRewardId);
    } catch (err) {
      navigate('/rewards');
    }
  };

  return (
    <div className="text-element-text-light dark:text-element-text-dark">
      {isLoading || !partnerRewardDetail?.partnerReward ? (
        <SkeletonLoader height={300} />
      ) : (
        <>
          {/* For small screens */}
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-8 px-4">
            <div className="md:hidden flex items-center space-x-4 px-4">
              <div className="flex-shrink-0 w-1/5">
                <img
                  src={partnerRewardDetail.partnerReward.imageUrl!}
                  className="w-full h-24 object-contain"
                  alt="logo"
                />
              </div>
              <div className="space-y-2">
                <div className="font-semibold text-3xl">
                  {partnerRewardDetail.partnerReward.name}
                </div>
              </div>
            </div>

            {/* Hide image, title, tag, and created on small screens since the code above handles that */}
            <div className="hidden md:flex md:col-span-1 lg:col-span-2 bg-white p-3 dark:bg-deals-card rounded-13 h-max">
              <img
                src={partnerRewardDetail.partnerReward.imageUrl!}
                className="w-full h-96 object-contain mx-auto"
                alt="logo"
              />
            </div>
            <div className="text-center md:col-span-3 lg:col-span-3 space-y-2">
              <div className="hidden md:flex flex-row space-x-2 justify-between items-center">
                <div className={`font-semibold text-left text-3xl truncate`}>
                  {partnerRewardDetail.partnerReward.name}
                </div>
              </div>
              <div className="font-semibold text-lg text-left px-2">
                Description
              </div>
              <div className="text-left px-2">
                {partnerRewardDetail.partnerReward.description}
              </div>
              {partnerRewardDetail.partnerReward.rewardUrl && (
                <a
                  className="space-y-2"
                  href={partnerRewardDetail.partnerReward.rewardUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-2 px-10 sm:w-auto leading-17 font-semibold text-sm md:m-2`}
                  >
                    <div className="text-black text-2xl ">View Website</div>
                  </button>
                </a>
              )}

              {partnerRewardDetail.partnerReward.partnerExpeditionId && (
                <div className="space-y-2">
                  <button
                    type="button"
                    className={`flex justify-center w-full md:justify-start bg-price-green
                   rounded-xl border border-transparent shadow-sm py-3 px-10 sm:w-auto  leading-17 font-semibold text-sm`}
                    onClick={() => {
                      navigate(
                        `/learns/${
                          partnerRewardDetail.partnerReward!.partnerExpeditionId
                        }`,
                      );
                    }}
                  >
                    <div className="text-black text-2xl ">Expedition</div>
                  </button>
                </div>
              )}

              <div className="font-semibold text-lg text-left px-2">
                My Rewards
              </div>
              {partnerRewardDetail?.partnerRewardUser ? (
                <PartnerRewardUserRow
                  partnerRewardUser={partnerRewardDetail.partnerRewardUser}
                />
              ) : (
                <div>You do not have any rewards</div>
              )}

              {partnerRewardDetail?.partnerRewardUser && (
                <>
                  <div className="font-semibold text-lg text-left px-2">
                    My Wallets
                  </div>
                  {partnerRewardDetail?.partnerRewardUser.partnerRewardUserWallets.map(
                    partnerUserWallet => (
                      <PartnerRewardUserWalletRow
                        key={partnerUserWallet.id}
                        partnerUserWallet={partnerUserWallet}
                        wallet={walletsMap.get(partnerUserWallet.userWalletId!)}
                      />
                    ),
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const PartnerRewardUserRow = ({
  partnerRewardUser,
}: {
  partnerRewardUser: PartnerRewardUserDetail;
}) => {
  return (
    <div className="bg-white dark:bg-deals-card-top p-2 rounded-xl space-y-2">
      {partnerRewardUser.rewardUnit && (
        <div className="flex justify-between items-center space-y-1 flex-row">
          <div className="flex items-center space-x-2">
            <div className="min-w-6">
              {partnerRewardUser.rewardUnitLogoUrl && (
                <img
                  className="rounded-full"
                  src={partnerRewardUser.rewardUnitLogoUrl}
                  alt="Reward Unit Logo"
                  height={48}
                  width={48}
                />
              )}
            </div>
            <div className="font-semibold text-gray-600 dark:text-gray-300 text-lg">
              {partnerRewardUser.rewardUnit}
            </div>
          </div>
          <div className="pr-1">
            {partnerRewardUser.rewardUnits
              ? formatNumber(partnerRewardUser.rewardUnits, 0)
              : 0}
          </div>
        </div>
      )}
      {partnerRewardUser?.rewardData?.rows.map((row, index) => (
        <div className="flex justify-between">
          <div>{row.label}</div>
          <div>
            {row.type === 'bool' ? (
              row.value === 'true' ? (
                <CheckCircleIcon className="h-6 w-6 text-price-green" />
              ) : (
                <XCircleIcon className="text-negative h-6 w-6" />
              )
            ) : row.type === 'number' ? (
              <div className="font-semibold pr-1">
                {formatNumber(Number(row.value), 0)}
              </div>
            ) : (
              <div className="font-semibold pr-1">{row.value}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const PartnerRewardUserWalletRow = ({
  partnerUserWallet,
  wallet,
}: {
  partnerUserWallet: PartnerRewardUserWallet;
  wallet?: Wallet;
}) => {
  return (
    <div className="bg-white dark:bg-deals-card-top p-2 rounded-xl space-y-2">
      <div className="flex items-center space-x-2 justify-between">
        <div className="min-w-6">
          {partnerUserWallet.rewardUnitLogoUrl && (
            <img
              className="rounded-full"
              src={partnerUserWallet.rewardUnitLogoUrl}
              alt="Reward Unit Logo"
              height={48}
              width={48}
            />
          )}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-300 text-lg">
            <div className="font-semibold truncate max-w-140">
              {wallet?.name}
            </div>
            <div className="text-sm truncate text-fade-text-light dark:text-fade-text-dark">
              {wallet?.getAddressDisplay()}
            </div>
          </div>
          {partnerUserWallet.rewardUnit && (
            <div className="flex justify-between">
              <div>{partnerUserWallet.rewardUnit}</div>
              <div>{formatNumber(partnerUserWallet.rewardUnits!, 0)}</div>
            </div>
          )}
          {partnerUserWallet.rewardData?.rows &&
            partnerUserWallet.rewardData?.rows.map(row => (
              <div className="flex justify-between">
                <div>{row.label}</div>
                <div>
                  {row.type === 'bool' ? (
                    row.value === 'true' ? (
                      <CheckCircleIcon className="h-6 w-6 text-price-green" />
                    ) : (
                      <XCircleIcon className="text-negative h-6 w-6" />
                    )
                  ) : row.type === 'number' ? (
                    <div className="font-semibold pr-1">
                      {formatNumber(Number(row.value), 0)}
                    </div>
                  ) : (
                    <div className="font-semibold pr-1">{row.value}</div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
