import React from 'react';

import { PortfolioHoldingCategoryListItem } from './PortfolioHoldingCategoryListItem';

// models
import { HoldingCategory } from '@/features/portfolio/holdings/models/HoldingCategory';

interface PortfolioHoldingCategoryListProps {
  holdingCategories: HoldingCategory[];
}

export const PortfolioHoldingCategoryList: React.FC<
  PortfolioHoldingCategoryListProps
> = ({ holdingCategories }) => {
  return (
    <ul className="flex flex-col h-full overflow-auto overflow-y-scroll box-border md:max-h-340  md:max-w-[580px] w-full space-y-3 scrollbar scroller">
      {holdingCategories?.map((holdingCategory, index) => (
        <li key={index} className="appear-front-right">
          <PortfolioHoldingCategoryListItem holdingCategory={holdingCategory} />
        </li>
      ))}
    </ul>
  );
};
