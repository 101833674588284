// vendor libraries
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import { BoltIcon } from '@heroicons/react/24/outline';

// enums
import { HoldingTypeGroupInfo } from '../../features/portfolio/holdings/enums/HoldingTypeGroup';

// components
import { PortfolioHoldingsSeriesCard } from '@/components/v2/portfolio/holdings/PortfolioHoldingsSeriesCard';
import { PortfolioHoldingsTable } from '@/components/v2/portfolio/holdings/PortfolioHoldingsTable';

export default function PortfolioHoldingsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { holdingTypeGroup } = useParams();
  const navigate = useNavigate();

  const type = HoldingTypeGroupInfo.holdingTypeGroupByApiValue(
    holdingTypeGroup!,
  );

  useEffect(() => {
    if (!type && holdingTypeGroup !== 'all') {
      navigate('/portfolio');
    }
  }, [type, navigate, holdingTypeGroup]);

  return (
    <>
      <SuperSEO
        title={`AssetDash | ${
          HoldingTypeGroupInfo.title[type!]
            ? HoldingTypeGroupInfo.title[type!]
            : ''
        } Portfolio`}
        lang="en_IE"
      />
      <div className="mx-auto main-content">
        {holdingTypeGroup === 'nft' && (
          <div className="flex justify-end pb-4">
            <div
              className="h-15 cursor-pointer flex items-center space-x-1 px-2 py-1 text-xs justify-center border-1 bg-opacity-10 bg-boosted border-boosted text-boosted rounded-full"
              onClick={() => navigate('/portfolio/nft-activity')}
            >
              <BoltIcon width="17.5" height="17.5" />
              <span className="pr-2  text-sm">Activity</span>
            </div>
          </div>
        )}
        <div className="space-y-10">
          <PortfolioHoldingsSeriesCard holdingTypeGroup={holdingTypeGroup!} />
          <PortfolioHoldingsTable holdingTypeGroup={holdingTypeGroup!} />
        </div>
      </div>
    </>
  );
}
