import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { AssetLogo } from '../../common/Logo/AssetLogo';
import { useNavigate } from 'react-router-dom';
import { getDealsSearchResults } from '@/api/deals';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const DealSearchBar = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [groupedResults, setGroupedResults] = useState([]);
  const [dbValue, saveToDb] = useState(''); // would be an API call normally
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  // highlight-starts
  const debouncedSave = useRef(
    debounce(nextValue => saveToDb(nextValue), 1000),
  ).current;
  // highlight-ends

  const handleChange = event => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
    debouncedSave(nextValue);
  };

  const groupResults = results => {
    const grouped = {};
    results.forEach(result => {
      const { type } = result;
      if (!grouped[type]) {
        grouped[type] = [];
      }
      grouped[type].push(result);
    });
    const res = Object.keys(grouped).map(group => ({
      group,
      results: grouped[group],
    }));

    setGroupedResults(res);

    analytics.track('search_entry', {
      search_string: value,
      count: res.length,
      platform: 'web',
    });
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      setActive(false);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    fetchSearchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbValue]);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      fetchSearchData();
    }
  };

  const fetchSearchData = () => {
    if (dbValue)
      getDealsSearchResults(dbValue).then(res => {
        groupResults(res.data.results);
        setActive(true);
      });
    setActive(false);
  };

  return (
    <div className="w-full">
      <div ref={ref} className="relative w-full m-auto flex justify-center">
        <input
          className="w-full bg-white dark:bg-deals-card-top outline-none rounded-3xl font-normal text-base leading-5 dark:placeholder-white placeholder-opacity-30 dark:placeholder-opacity-30 px-4 pl-16 py-22 border-price-green border-solid border-1"
          placeholder="Search deals..."
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <div
          className="absolute left-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
          onClick={fetchSearchData}
        >
          <MagnifyingGlassIcon
            height={24}
            width={24}
            className="text-price-green"
          />
        </div>
        <div
          className="absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
          onClick={() => {
            setValue('');
            setActive(false);
          }}
        >
          <XMarkIcon height={24} width={24} className="text-price-red" />
        </div>

        {active && groupedResults.length > 0 ? (
          <ul
            className="rounded-3xl p-9 z-10 list-none  absolute top-full inset-x-0 max-h-145 overflow-scroll scroller
            bg-white dark:bg-deals-card-top border border-gray-text"
          >
            {groupedResults.map((result, index) => (
              <div key={index}>
                {result &&
                  result.results.map(item => {
                    return (
                      <li
                        className="flex items-center justify-between space-x-4 cursor-pointer py-6 px-3.5  rounded-lg bg-white dark:bg-deals-card hover:bg-primary-gray dark:hover:bg-blue-dark z-10 mt-2 leading-4 last:mb-4 transition delay-75 ease-in"
                        key={item.id}
                        onClick={() => {
                          analytics.track('search_deal_click', {
                            asset_id: item.id,
                            search_string: value,
                            platform: 'web',
                          });
                          navigate(`/deals/${item.id}`);
                        }}
                      >
                        <div className="flex space-x-4 md:space-x-8 items-center">
                          <div className="h-6 w-6">
                            <AssetLogo
                              height={20}
                              width={20}
                              type={item.type}
                              logoUrl={item.image_url}
                            />
                          </div>
                          {/* <img src={result.logo_url} className="h-4 w-4 rounded-full" /> */}
                          <div className="cursor-pointer text-lg text-black dark:text-border-bg">
                            {item?.name}
                          </div>
                        </div>
                        <div>{item.display_text}</div>
                      </li>
                    );
                  })}
              </div>
            ))}
          </ul>
        ) : (
          active &&
          groupedResults.length === 0 && (
            <ul
              className=" rounded-3xl p-9 z-10 list-none overflow-hidden absolute top-full inset-x-0 min-h-100px
            bg-white dark:bg-search-box"
            >
              <li>No results found</li>
            </ul>
          )
        )}
      </div>
    </div>
  );
};
