import React, { useEffect, useState } from 'react';
import { usePortfolio } from '@/hooks/usePortfolio';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';
import { WalletAssetPieChart } from './assetCard/WalletAssetPieChart';
import WalletListItem from './assetCard/WalletListItem';
import { getShortenedAddress } from '@/utils/address';
import { Holding } from '@/features/portfolio/models/Holding';
import { useAuth } from '@/hooks/useAuth';

interface AssetHoldingsGraphCardProps {
  holding: Holding;
  walletHoldings?: WalletHolding[];
}

export type AssetWalletPieChartData = {
  value: number;
  color: string;
};

export const AssetHoldingsGraphCard: React.FC<AssetHoldingsGraphCardProps> = ({
  walletHoldings,
  holding,
}) => {
  const { convertUSDCurrency } = useAuth();
  const { walletsMap } = usePortfolio();
  const [assetData, setAssetData] = useState<AssetWalletPieChartData[]>([]);
  const [sortedWalletHoldings, setSortedWalletHoldings] = useState<
    WalletHolding[]
  >([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const walletColors = [
    '#21ce99',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
  ];

  useEffect(() => {
    const calculateAssetData = (): AssetWalletPieChartData[] => {
      if (!walletHoldings) return [];
      const totalValue = walletHoldings.reduce(
        (acc, curr) => acc + (curr.value ?? 0),
        0,
      );

      const data = walletHoldings.map((walletHolding, index) => ({
        value: ((walletHolding.value ?? 0) / totalValue) * 100,
        color: walletColors[index % walletColors.length],
      }));

      const sortedHoldings = walletHoldings
        .map((holding, index) => ({
          ...holding,
          percentage: data[index].value,
        }))
        .sort((a, b) => b.percentage - a.percentage);

      setSortedWalletHoldings(sortedHoldings);

      return data;
    };

    setAssetData(calculateAssetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletHoldings, walletsMap]);

  const handleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="h-auto md:h-[420px] py-5 px-4 md:px-6 space-y-4 flex flex-col items-center">
      <div className="grow w-full flex flex-col items-center space-y-4 md:flex-row justify-between md:space-x-4">
        {walletHoldings && (
          <div className="relative">
            <div className="text-center font-normal text-2xl leading-7 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div
                style={{
                  color:
                    activeIndex === null ? '' : assetData[activeIndex].color,
                }}
              >
                {activeIndex === null ? 'Total' : ''}
              </div>
              <div
                style={{
                  color:
                    activeIndex === null ? '' : assetData[activeIndex].color,
                }}
              >
                {activeIndex === null
                  ? convertUSDCurrency(holding.amount)
                  : convertUSDCurrency(walletHoldings[activeIndex].value)}
              </div>
            </div>
            <WalletAssetPieChart
              data={assetData}
              activeIndex={activeIndex}
              handleClick={handleClick}
            />
          </div>
        )}
        <div className="flex flex-col w-full h-auto md:max-h-72 overflow-y-auto grow">
          {assetData?.length > 0 &&
            sortedWalletHoldings.map((holding, index) => {
              const walletAddress = holding?.userWalletId
                ? walletsMap.get(holding?.userWalletId)?.address || 'No address'
                : 'No address';
              return (
                <WalletListItem
                  key={holding.userWalletId}
                  holding={holding}
                  color={assetData[index].color}
                  percentage={assetData[index].value}
                  address={getShortenedAddress(walletAddress)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
