import { useEffect, useState } from 'react';
import { useLearn } from '../../hooks/useLearn';

export default function AnswerQuestion({ id, questions }) {
  const { submitAnswer, activeQuestion } = useLearn();
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [err, setErr] = useState('');

  const handleSelectAnswer = e => {
    setSelectedAnswer(e.target.value);
  };

  const isValid = () => {
    if (selectedAnswer) {
      return true;
    }
    setErr('Please select the answer!');
    return false;
  };

  useEffect(() => {
    if (selectedAnswer) {
      setErr('');
    }
  }, [selectedAnswer]);

  return (
    <div className="h-full">
      <div>
        <p className="font-bold border-b-1 border-solid border-black w-max mb-2">
          Questions:{' '}
          <span>
            {activeQuestion + 1}/{questions.length}
          </span>
        </p>
      </div>
      {questions.map((question, index) => {
        return (
          index === activeQuestion && (
            <div
              key={question.id}
              className="space-y-8 h-full flex flex-col justify-between py-2"
            >
              <div>
                <p className="text-3xl font-bold">{question.question}</p>
                <div className="mt-8 lg:mt-20 space-y-4 text-xl">
                  <div className="flex space-x-2">
                    <input
                      type="radio"
                      id={question.answer_a}
                      name="answers"
                      value="a"
                      onChange={handleSelectAnswer}
                    />
                      <label for={question.answer_a}>{question.answer_a}</label>
                  </div>
                  <div className="flex space-x-2">
                    <input
                      type="radio"
                      id={question.answer_b}
                      name="answers"
                      value="b"
                      onChange={handleSelectAnswer}
                    />
                      <label for={question.answer_b}>{question.answer_b}</label>
                  </div>
                  {question.answer_c && (
                    <div className="flex space-x-2">
                      <input
                        type="radio"
                        id={question.answer_c}
                        name="answers"
                        value="c"
                        onChange={handleSelectAnswer}
                      />
                       {' '}
                      <label for={question.answer_c}>{question.answer_c}</label>
                    </div>
                  )}
                  {question.answer_d && (
                    <div className="flex space-x-2">
                      <input
                        type="radio"
                        id={question.answer_d}
                        name="answers"
                        value="d"
                        onChange={handleSelectAnswer}
                      />
                       {' '}
                      <label for={question.answer_d}>{question.answer_d}</label>
                    </div>
                  )}
                </div>
                <p className="text-sm mt-4 ml-8 text-primary-red">{err}</p>
              </div>
              <div>
                <button
                  className="bg-price-green py-3 w-full text-black rounded-8 font-semibold mb-2"
                  onClick={async () => {
                    const resp = isValid();
                    if (resp) {
                      if (questions.length - 1 >= index) {
                        const payload = {
                          course_id: id,
                          course_question_id: question.id,
                          answer: selectedAnswer,
                        };
                        await submitAnswer(payload);
                      }
                    }
                  }}
                >
                  Submit Answer
                </button>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}
