export const AssetLogo = ({ logoUrl, type, width, height }) => {
  if (logoUrl) {
    return (
      <div
        className="border solid border-gray-text rounded-full overflow-hidden flex items-center justify-center"
        style={{ height: height + 4, width: width + 4 }}
      >
        <img
          src={logoUrl}
          alt="logo"
          className="rounded-full object-scale-down"
          style={{ height: height, width: width }}
        />
      </div>
    );
  } else {
    let logoUrl =
      'https://storage.googleapis.com/assetdash-prod-images/asset_types/';
    const formattedType = type?.toLowerCase();

    switch (formattedType) {
      case 'cash':
        logoUrl += 'cash.png';
        break;
      case 'collectibles':
        logoUrl += 'collectibles.png';
        break;
      case 'crypto':
        logoUrl += 'crypto.png';
        break;
      case 'private_equity':
        logoUrl += 'private_equity.png';
        break;
      case 'commodity':
        logoUrl += 'commodity.png';
        break;
      case 'real_estate':
        logoUrl += 'real_estate.png';
        break;
      case 'nft':
        logoUrl += 'nft.png';
        break;
      case 'defi':
        logoUrl += 'defi.png';
        break;
      case 'stock':
        logoUrl += 'stocks.png';
        break;
      case 'options':
        logoUrl += 'options.png';
        break;
      default:
        logoUrl += 'other.png';
    }

    return (
      <div
        className="border solid border-gray-text rounded-full overflow-hidden flex items-center justify-center"
        style={{ height: height + 4, width: width + 4 }}
      >
        <img
          src={logoUrl}
          alt="logo"
          className="rounded-full object-scale-down"
          style={{ height: height, width: width }}
        />
      </div>
    );
  }
};
