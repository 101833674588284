import { ASSET_TYPE_TO_DISPLAY_NAME } from '@constants';

interface ManualHoldingDropdownItemProps {
  assetType: string;
  handleClick: (assetType: string) => void;
}

export const ManualHoldingDropdownItem: React.FC<
  ManualHoldingDropdownItemProps
> = ({ assetType, handleClick }) => {
  const handleTheClick = () => {
    handleClick(assetType);
  };
  return (
    <div
      className="cursor-pointer flex justify-between px-4 py-2 hover:bg-white-opacity"
      onClick={handleTheClick}
    >
      <div className="capitalize text-xs font-semibold">
        {ASSET_TYPE_TO_DISPLAY_NAME[assetType]}
      </div>
    </div>
  );
};
