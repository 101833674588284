import moment from 'moment';

import { AppColors } from '../../../core/theming/colors';

interface AuctionPrizeJson {
  id: string;
  prize_type: string;
  prize_chain?: string;
  title: string;
  description: string;
  image_url: string;
  num_winners: number;
  value: number;
}

export class AuctionPrize {
  id: string;
  prizeType: string;
  prizeChain?: string;
  title: string;
  description: string;
  imageUrl: string;
  numWinners: number;
  value: number;

  constructor({
    id,
    prizeType,
    prizeChain,
    title,
    description,
    imageUrl,
    numWinners,
    value,
  }: {
    id: string;
    prizeType: string;
    prizeChain?: string;
    title: string;
    description: string;
    imageUrl: string;
    numWinners: number;
    value: number;
  }) {
    this.id = id;
    this.prizeType = prizeType;
    this.prizeChain = prizeChain;
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.numWinners = numWinners;
    this.value = value;
  }

  static fromJson(json: AuctionPrizeJson): AuctionPrize {
    return new AuctionPrize({
      id: json['id'],
      prizeType: json['prize_type'],
      prizeChain: json['prize_chain'],
      title: json['title'],
      description: json['description'],
      imageUrl: json['image_url'],
      numWinners: json['num_winners'],
      value: json['value'],
    });
  }

  toJson(): AuctionPrizeJson {
    return {
      id: this.id,
      prize_type: this.prizeType,
      prize_chain: this.prizeChain,
      title: this.title,
      description: this.description,
      image_url: this.imageUrl,
      num_winners: this.numWinners,
      value: this.value,
    };
  }
}

interface AuctionEligibleAssetJson {
  id: string;
  name: string;
  logo_url: string;
}

export class AuctionEligibleAsset {
  id: string;
  name: string;
  logoUrl: string;

  constructor({
    id,
    name,
    logoUrl,
  }: {
    id: string;
    name: string;
    logoUrl: string;
  }) {
    this.id = id;
    this.name = name;
    this.logoUrl = logoUrl;
  }

  static fromJson(json: AuctionEligibleAssetJson): AuctionEligibleAsset {
    return new AuctionEligibleAsset({
      id: json['id'],
      name: json['name'],
      logoUrl: json['logo_url'],
    });
  }

  toJson(): AuctionEligibleAssetJson {
    return {
      id: this.id,
      name: this.name,
      logo_url: this.logoUrl,
    };
  }
}

interface AuctionEligiblePartnerJson {
  id: string;
  partner_id: string;
  name: string;
  logo_url: string;
}

export class AuctionEligiblePartner {
  id: string;
  partnerId: string;
  name: string;
  logoUrl: string;

  constructor({
    id,
    name,
    partnerId,
    logoUrl,
  }: {
    id: string;
    name: string;
    partnerId: string;
    logoUrl: string;
  }) {
    this.id = id;
    this.partnerId = partnerId;
    this.name = name;
    this.logoUrl = logoUrl;
  }

  static fromJson(json: AuctionEligiblePartnerJson): AuctionEligiblePartner {
    return new AuctionEligiblePartner({
      id: json['id'],
      partnerId: json['partner_id'],
      name: json['name'],
      logoUrl: json['logo_url'],
    });
  }

  toJson(): AuctionEligiblePartnerJson {
    return {
      id: this.id,
      partner_id: this.partnerId,
      name: this.name,
      logo_url: this.logoUrl,
    };
  }
}

export interface AuctionJson {
  id: string;
  start_open_time: string;
  start_locked_time: string;
  end_time: string;
  status: string;
  type: string;
  title: string;
  description: string;
  instructions: string[];
  image_url: string;
  instructions_link: string;
  terms_link: string;
  total_bids?: number;
  eligible_vanta: boolean;
  eligible_platinum: boolean;
  eligible_gold: boolean;
  eligible_silver: boolean;
  eligible_bronze: boolean;
  prizes: AuctionPrizeJson[];
  eligible_assets: AuctionEligibleAssetJson[];
  eligible_partner_organizations: AuctionEligiblePartnerJson[];
}

export class Auction {
  id: string;
  startOpenTime: Date;
  startLockedTime: Date;
  endTime: Date;
  status: string;
  type: string;
  title: string;
  description: string;
  instructions: string[];
  imageUrl: string;
  instructionsLink: string;
  termsLink: string;
  totalBids?: number;
  eligibleVanta: boolean;
  eligiblePlatinum: boolean;
  eligibleGold: boolean;
  eligibleSilver: boolean;
  eligibleBronze: boolean;
  prizes: AuctionPrize[];
  eligibleAssets: AuctionEligibleAsset[];
  eligiblePartners: AuctionEligiblePartner[];

  constructor({
    id,
    startOpenTime,
    startLockedTime,
    endTime,
    status,
    type,
    title,
    description,
    instructions,
    imageUrl,
    instructionsLink,
    termsLink,
    totalBids,
    eligibleVanta,
    eligiblePlatinum,
    eligibleGold,
    eligibleSilver,
    eligibleBronze,
    prizes,
    eligibleAssets,
    eligiblePartners,
  }: {
    id: string;
    startOpenTime: Date;
    startLockedTime: Date;
    endTime: Date;
    status: string;
    type: string;
    title: string;
    description: string;
    instructions: string[];
    imageUrl: string;
    instructionsLink: string;
    termsLink: string;
    totalBids?: number;
    eligibleVanta: boolean;
    eligiblePlatinum: boolean;
    eligibleGold: boolean;
    eligibleSilver: boolean;
    eligibleBronze: boolean;
    prizes: AuctionPrize[];
    eligibleAssets: AuctionEligibleAsset[];
    eligiblePartners: AuctionEligiblePartner[];
  }) {
    this.id = id;
    this.startOpenTime = startOpenTime;
    this.startLockedTime = startLockedTime;
    this.endTime = endTime;
    this.status = status;
    this.type = type;
    this.title = title;
    this.description = description;
    this.instructions = instructions;
    this.imageUrl = imageUrl;
    this.instructionsLink = instructionsLink;
    this.termsLink = termsLink;
    this.totalBids = totalBids;
    this.eligibleVanta = eligibleVanta;
    this.eligiblePlatinum = eligiblePlatinum;
    this.eligibleGold = eligibleGold;
    this.eligibleSilver = eligibleSilver;
    this.eligibleBronze = eligibleBronze;
    this.prizes = prizes;
    this.eligibleAssets = eligibleAssets;
    this.eligiblePartners = eligiblePartners;
  }

  static fromJson(json: AuctionJson): Auction {
    const prizes = json['prizes']
      ? json['prizes'].map((v: any) => AuctionPrize.fromJson(v))
      : [];
    const eligibleAssets = json['eligible_assets']
      ? json['eligible_assets'].map((v: any) =>
          AuctionEligibleAsset.fromJson(v),
        )
      : [];
    const eligiblePartners = json['eligible_partner_organizations']
      ? json['eligible_partner_organizations'].map((v: any) =>
          AuctionEligiblePartner.fromJson(v),
        )
      : [];

    return new Auction({
      id: json['id'],
      startOpenTime: new Date(json['start_open_time'] + 'Z'),
      startLockedTime: new Date(json['start_locked_time'] + 'Z'),
      endTime: new Date(json['end_time'] + 'Z'),
      type: json['type'],
      status: json['status'],
      title: json['title'],
      description: json['description'],
      instructions: json['instructions'] || [],
      imageUrl: json['image_url'],
      instructionsLink: json['instructions_link'],
      termsLink: json['terms_link'],
      totalBids: json['total_bids'],
      eligibleVanta: json['eligible_vanta'],
      eligiblePlatinum: json['eligible_platinum'],
      eligibleGold: json['eligible_gold'],
      eligibleSilver: json['eligible_silver'],
      eligibleBronze: json['eligible_bronze'],
      prizes: prizes,
      eligibleAssets: eligibleAssets,
      eligiblePartners: eligiblePartners,
    });
  }

  toJson(): AuctionJson {
    return {
      id: this.id,
      start_open_time: this.startOpenTime.toISOString(),
      start_locked_time: this.startLockedTime.toISOString(),
      end_time: this.endTime.toISOString(),
      status: this.status,
      type: this.type,
      title: this.title,
      description: this.description,
      instructions: this.instructions,
      image_url: this.imageUrl,
      instructions_link: this.instructionsLink,
      terms_link: this.termsLink,
      total_bids: this.totalBids,
      eligible_vanta: this.eligibleVanta,
      eligible_platinum: this.eligiblePlatinum,
      eligible_gold: this.eligibleGold,
      eligible_silver: this.eligibleSilver,
      eligible_bronze: this.eligibleBronze,
      prizes: this.prizes.map(prize => prize.toJson()),
      eligible_assets: this.eligibleAssets.map(asset => asset.toJson()),
      eligible_partner_organizations: this.eligiblePartners.map(partner =>
        partner.toJson(),
      ),
    };
  }

  isOpenPhase(): boolean {
    return (
      moment(this.startOpenTime + 'Z').isBefore(moment()) &&
      moment(this.startLockedTime + 'Z').isAfter(moment())
    );
  }

  isLockedPhase(): boolean {
    return (
      moment(this.startLockedTime + 'Z').isBefore(moment()) &&
      moment(this.endTime + 'Z').isAfter(moment())
    );
  }

  isCompleted(): boolean {
    return moment(this.endTime + 'Z').isBefore(moment());
  }

  isNotLiveYet(): boolean {
    return moment(this.startOpenTime + 'Z').isAfter(moment());
  }

  getStatusColor() {
    if (this.isLockedPhase()) {
      return AppColors.primary();
    } else if (this.isOpenPhase()) {
      return AppColors.primary();
    } else if (this.isNotLiveYet()) {
      return AppColors.accent();
    } else {
      return AppColors.fadeText();
    }
  }
}

interface AuctionsResponseJson {
  auctions?: AuctionJson[];
}

export class AuctionsResponse {
  auctions?: Auction[];

  constructor({ auctions }: { auctions?: Auction[] }) {
    this.auctions = auctions;
  }

  static fromJson(json: AuctionsResponseJson): AuctionsResponse {
    const auctions = json['auctions']
      ? json['auctions'].map((v: any) => Auction.fromJson(v))
      : undefined;
    return new AuctionsResponse({ auctions });
  }

  toJson(): AuctionsResponseJson {
    return {
      auctions: this.auctions?.map(auction => auction.toJson()),
    };
  }
}
