import { Asset } from './Asset';
import { CustomNFTPrice } from './CustomNFTPrice';
import { HiddenAssets } from './HiddenAsset';
import { ManualAsset } from './ManualAsset';
import { NFTCollection } from './NFTCollection';
import { NFTItem } from './NFTItem';
import { Wallet } from './Wallet';
import { WalletHolding } from './WalletHolding';
import { WalletNFTItem } from './WalletNFTItem';

export class PortfolioSync {
  wallets?: Wallet[];
  walletHoldings?: WalletHolding[];
  assets?: Asset[];
  manualAssets?: ManualAsset[];
  walletNfts?: WalletNFTItem[];
  collections?: NFTCollection[];
  hiddenAssets?: HiddenAssets[];
  customNftPrices?: CustomNFTPrice[];
  nftItems?: NFTItem[];
  elementsAsset?: Asset;
  constructor(
    wallets?: Wallet[],
    walletHoldings?: WalletHolding[],
    assets?: Asset[],
    manualAssets?: ManualAsset[],
    walletNfts?: WalletNFTItem[],
    collections?: NFTCollection[],
    hiddenAssets?: HiddenAssets[],
    customNftPrices?: CustomNFTPrice[],
    nftItems?: NFTItem[],
    elementsAsset?: Asset,
  ) {
    this.wallets = wallets;
    this.walletHoldings = walletHoldings;
    this.assets = assets;
    this.manualAssets = manualAssets;
    this.walletNfts = walletNfts;
    this.collections = collections;
    this.hiddenAssets = hiddenAssets;
    this.customNftPrices = customNftPrices;
    this.nftItems = nftItems;
    this.elementsAsset = elementsAsset;
  }

  static fromJSON(json: any): PortfolioSync {
    return new PortfolioSync(
      json['wallets']?.map((v: any) => Wallet.fromJSON(v)),
      json['wallet_holdings']?.map((v: any) => WalletHolding.fromJSON(v)),
      json['assets']?.map((v: any) => Asset.fromJSON(v)),
      json['manual_assets']?.map((v: any) => ManualAsset.fromJSON(v)),
      json['wallet_nfts']?.map((v: any) => WalletNFTItem.fromJSON(v)),
      json['collections']?.map((v: any) => NFTCollection.fromJSON(v)),
      json['hidden_assets']?.map((v: any) => HiddenAssets.fromJSON(v)),
      json['custom_nft_prices']?.map((v: any) => CustomNFTPrice.fromJSON(v)),
      json['nft_items']?.map((v: any) => NFTItem.fromJSON(v)),
      Asset.fromJSON(json['elements_asset']),
    );
  }

  toJSON(): any {
    const data: any = {};

    if (this.wallets) {
      data['wallets'] = this.wallets.map(v => v.toJSON());
    }
    if (this.walletHoldings) {
      data['wallet_holdings'] = this.walletHoldings.map(v => v.toJSON());
    }
    if (this.assets) {
      data['assets'] = this.assets.map(v => v.toJSON());
    }
    if (this.manualAssets) {
      data['manual_assets'] = this.manualAssets.map(v => v.toJSON());
    }
    if (this.walletNfts) {
      data['wallet_nfts'] = this.walletNfts.map(v => v.toJSON());
    }
    if (this.collections) {
      data['collections'] = this.collections.map(v => v.toJSON());
    }
    if (this.hiddenAssets) {
      data['hidden_assets'] = this.hiddenAssets.map(v => v.toJSON());
    }
    if (this.customNftPrices) {
      data['custom_nft_prices'] = this.customNftPrices.map(v => v.toJSON());
    }
    if (this.nftItems) {
      data['nft_items'] = this.nftItems.map(v => v.toJSON());
    }

    return data;
  }
}
