export const ImageTag: React.FC<{ url: string; alt: string }> = ({
  url,
  alt,
}) => {
  if (
    url?.includes('webm') ||
    url?.includes('mp4') ||
    url?.match(
      /(?:https?:\/\/(?:www\.)?)?vimeo.com\/(?:channels\/|groups\/([^]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/,
    )
  ) {
    return (
      <video
        src={url}
        className="min-h-158 h-158 min-w-158 w-full rounded-card-box -m-1 object-cover"
      />
    );
  } else
    return (
      <img
        src={url}
        alt={alt}
        className="min-h-158 h-158 min-w-158 w-full rounded-card-box -m-1 object-cover"
      />
    );
};
