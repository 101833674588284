import { ReactNode, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTokenState } from '../../recoil/token';

import { useAuth } from '../../hooks/useAuth';
interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { fetchUserProfile } = useAuth();
  const [token, setToken] = useTokenState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    if (tokenParam) {
      searchParams.delete('token');
      handleAuth(tokenParam);
      navigate(location.pathname + '?' + searchParams.toString(), {
        replace: true,
      });
      return;
    }
    if (!token) {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/login?redirectUrl=${window.location.href}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuth = async (token: string) => {
    localStorage.setItem('token', token);
    setToken(token);
    fetchUserProfile();
  };

  return children;
};
