import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwapCenter } from '@/hooks/useSwapCenter';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { formatNumber } from '@/utils/number';
import ProgressBar from '../common/ProgressBarV2/ProgressBarV2';
import { CheckIcon } from '@heroicons/react/24/solid';

export const TokenRewardCard: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, load, swapCenterData } = useSwapCenter();

  useEffect(() => {
    const fetchData = async () => {
      await load();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedRewards = useMemo(() => {
    if (!swapCenterData?.swap_center.swap_rewards) return [];

    return [...swapCenterData.swap_center.swap_rewards].sort((a, b) => {
      const percentageA =
        a.current_amount && a.withdrawal_threshold
          ? (a.current_amount * 100) / a.withdrawal_threshold
          : 0;
      const percentageB =
        b.current_amount && b.withdrawal_threshold
          ? (b.current_amount * 100) / b.withdrawal_threshold
          : 0;

      return percentageB - percentageA;
    });
  }, [swapCenterData?.swap_center.swap_rewards]);

  return (
    <div className={`card flex-1 flex-col p-4`}>
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-bold text-gray-900 dark:text-gray-100">
              🏆 My Token Rewards
            </h2>
            <button
              onClick={() => navigate('/rewards/token')}
              className="text-primary font-bold"
            >
              View All
            </button>
          </div>
          <p className="text-sm mt-2 text-gray-700 dark:text-gray-300">
            Token Rewards are liquid tokens you earn while using AssetDash
            products like SuperSwap.
          </p>
          <div className="mt-4 space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
            {sortedRewards.length === 0 ? (
              <p className="text-center text-gray-400 dark:text-gray-500">
                No Token Rewards
              </p>
            ) : (
              sortedRewards.map((reward, index) => (
                <div
                  key={reward.asset_id}
                  className="flex items-center justify-between p-2"
                >
                  <div className="flex items-center">
                    <img
                      src={reward?.asset?.logo_url}
                      alt={reward?.asset?.name}
                      className="w-9 h-9 rounded-md"
                    />
                    <div className="ml-4">
                      <p className="text-sm text-gray-900 dark:text-gray-100">
                        {reward?.asset?.name}
                      </p>
                    </div>
                  </div>
                  <div className="w-full max-w-[180px] md:max-w-[300px] relative ml-5">
                    <ProgressBar
                      percentage={
                        reward.current_amount <= 0
                          ? 0
                          : (reward.current_amount * 100) /
                            reward.withdrawal_threshold
                      }
                    />
                    <div className="absolute right-4 -top-5">
                      {reward.withdrawal_threshold === reward.current_amount ? (
                        <div className="flex flex-row items-center gap-x-1 text-primary">
                          <div className="text-sm text-primary">
                            {formatNumber(reward.total_amount)}
                          </div>
                          <CheckIcon className="w-6 h-6 text-primary" />
                        </div>
                      ) : (
                        <div className="flex flex-row items-center">
                          <div className="text-sm font-bold text-gray-900 dark:text-gray-100">
                            {`${formatNumber(reward.current_amount)} / ${formatNumber(reward.withdrawal_threshold)}`}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};
