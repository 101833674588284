import { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { Auction } from '@/features/market/auctions/models/Auctions';
import { InfoModal } from '@/components/v2/common/Modal/InfoModal';

// hooks
import { useMember } from '@/hooks/useMember';
import { usePortfolio } from '@/hooks/usePortfolio';

interface AuctionEligibilityCardProps {
  auction: Auction;
}

export const AuctionEligibilityCard: React.FC<AuctionEligibilityCardProps> = ({
  auction,
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { memberCenterData } = useMember();
  const member_status = memberCenterData?.member_status;
  const { verifiedAssetIds } = usePortfolio();
  const memberPartnerIds = new Set(
    memberCenterData?.partner_organizations?.map((m: any) => m.partner_id),
  );

  let isEligible = false;
  if (
    (auction?.eligibleVanta === true && member_status === 'vanta') ||
    (auction?.eligiblePlatinum === true && member_status === 'platinum') ||
    (auction?.eligibleGold === true && member_status === 'gold') ||
    (auction?.eligibleSilver === true && member_status === 'silver') ||
    (auction?.eligibleBronze === true && member_status === 'bronze')
  ) {
    isEligible = true;
  }

  // Check eligible assets
  if (!isEligible && auction?.eligibleAssets != null) {
    const eligibleAsset = auction.eligibleAssets.find(eligibleAsset =>
      verifiedAssetIds.includes(eligibleAsset.id),
    );
    if (eligibleAsset) {
      isEligible = true;
    }
  }

  // Check eligible partners
  if (!isEligible && auction?.eligiblePartners != null) {
    const eligiblePartner = auction.eligiblePartners.find(eligiblePartner =>
      memberPartnerIds.has(eligiblePartner.partnerId),
    );
    if (eligiblePartner) {
      isEligible = true;
    }
  }

  return (
    <>
      <div className="space-y-2">
        <div className="flex space-x-2 items-center">
          <p className="text-left text-2xl">Eligibility</p>
          <InformationCircleIcon
            className="cursor-pointer text-accent"
            height={18}
            width={18}
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <div className="dark:bg-deals-card-top bg-white py-4 px-8 rounded-xl space-y-4">
          {isEligible ? (
            <div className="border p-1 rounded-2xl font-semibold border-primary text-primary">
              You are eligible for this auction
            </div>
          ) : (
            <div className="border p-1 rounded-xl font-semibold border-negative text-negative">
              You are not eligible for this auction
            </div>
          )}
          {['Vanta', 'Platinum', 'Gold', 'Silver', 'Bronze'].map(tier => {
            const eligibleTier = `eligible${tier}` as
              | 'eligibleVanta'
              | 'eligiblePlatinum'
              | 'eligibleGold'
              | 'eligibleSilver'
              | 'eligibleBronze';

            if (!auction[eligibleTier]) {
              return null;
            }
            return (
              <div
                key={tier}
                className="flex justify-between dark:text-gray-300 items-center"
              >
                <div className="flex items-center space-x-2">
                  <img
                    src={`https://storage.googleapis.com/assetdash-prod-images/member_center/logo_${tier.toLowerCase()}.png`}
                    alt={`${tier} logo`}
                    className="w-6 h-3"
                  />
                  <div className="font-semibold capitalize">{tier}</div>
                </div>
                {member_status === tier.toLowerCase() && (
                  <img
                    src="/assets/icons/check-circle.png"
                    alt="check-circle"
                    className="w-4 h-4"
                  />
                )}
              </div>
            );
          })}
          {auction.eligibleAssets?.map(eligibleAsset => {
            return (
              <div className="flex justify-between text-gray-300">
                <div className="flex items-center space-x-2 ">
                  <img
                    src={eligibleAsset.logoUrl}
                    alt={eligibleAsset.name}
                    className="w-6 h-3"
                  />
                  <div className="font-semibold capitalize">
                    {eligibleAsset.name}
                  </div>
                </div>
                {verifiedAssetIds.includes(eligibleAsset.id) && (
                  <img
                    src="/assets/icons/check-circle.png"
                    alt={`check-circle`}
                    className="w-6 h-6"
                  />
                )}
              </div>
            );
          })}
          {auction.eligiblePartners?.map(eligiblePartner => {
            return (
              <div className="flex justify-between text-gray-300">
                <div className="flex items-center space-x-2 ">
                  <img
                    src={eligiblePartner.logoUrl}
                    alt={eligiblePartner.name}
                    className="w-6 h-3"
                  />
                  <div className="font-semibold capitalize">
                    {eligiblePartner.name}
                  </div>
                </div>
                {memberPartnerIds.has(eligiblePartner.partnerId) && (
                  <img
                    src="/assets/icons/check-circle.png"
                    alt={`check-circle`}
                    className="w-6 h-6"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <InfoModal
        isOpen={isInfoModalOpen}
        closeModal={() => setIsInfoModalOpen(false)}
        title="Eligibility"
        description="This section states who is eligible and able to buy bids for this auction. Eligibility is based on AssetDash Tiers and which communities you are a part of."
      />
    </>
  );
};
