import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

import { WalletsContainer } from '@/components/v2/wallets/WalletsContainer';

export default function WalletsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SuperSEO title="AssetDash | Wallets" lang="en_IE" />
      <div className="main-content mx-auto space-y-6">
        <WalletsContainer />
      </div>
    </>
  );
}
