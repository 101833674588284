export class UserWallet {
  id?: string;
  name?: string;
  address?: string;
  totalValue?: number;
  portfolioAllocation?: number;
  status?: string;
  walletType?: string;

  constructor(
    id?: string,
    name?: string,
    address?: string,
    totalValue?: number,
    portfolioAllocation?: number,
    status?: string,
    walletType?: string,
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.totalValue = totalValue;
    this.portfolioAllocation = portfolioAllocation;
    this.status = status;
    this.walletType = walletType;
  }

  static fromJSON(json: any): UserWallet {
    return new UserWallet(
      json['id'],
      json['name'],
      json['address'],
      json['total_value'],
      json['portfolio_allocation'],
      json['status'],
      json['wallet_type'],
    );
  }

  toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      address: this.address,
      total_value: this.totalValue,
      portfolio_allocation: this.portfolioAllocation,
      status: this.status,
      wallet_type: this.walletType,
    };
  }
}
