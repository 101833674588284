import { PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import { ManualHoldingDropdownItem } from './ManualHoldingDropdownItem';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

const holdingEnum: { [key: string]: string } = {
  crypto: 'Crypto',
  stocks: 'Stocks',
  nft: 'NFT',
  staked: 'Staked',
  private_equity: 'Private Equity',
  real_estate: 'Real Estate',
  metals: 'Metals',
  collectibles: 'Collectibles',
  other: 'Other',
};

interface ManualHoldingDropdownProps {
  handleManualAssetSelect: (
    selectedAsset: string,
    holding?: WalletHolding,
  ) => void;
}

export const ManualHoldingDropdown: React.FC<ManualHoldingDropdownProps> = ({
  handleManualAssetSelect,
}) => {
  const [selected, setSelected] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleSingleSelectClick = (assetType: string) => {
    setSelected(assetType);
    setIsOpen(false);
    handleManualAssetSelect(assetType);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      setIsOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const tabKeys = Object.keys(holdingEnum);
  const text = selected ? holdingEnum[selected] : 'New Holding';

  return (
    <div
      className="relative inline-block text-left my-auto w-full md:w-fit"
      ref={ref}
    >
      <div>
        <div
          className="items-center cursor-pointer capitalize inline-flex justify-between w-full rounded-lg  shadow-sm px-6 py-4 min-w-43 bg-primary-gray dark:bg-blue-dark text-sm font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 text-black dark:text-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          {text}
          <PlusIcon
            className="-mr-1 ml-2 h-4 w-4 text-price-red"
            aria-hidden="true"
          />
        </div>
      </div>

      {isOpen && (
        <div>
          <div className="text-sm font-bold origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-blue-dark ring-1 ring-black ring-opacity-5 focus:outline-none text-black dark:text-white">
            <div className="py-1">
              {tabKeys.map((assetType, index) => {
                return (
                  <ManualHoldingDropdownItem
                    key={index}
                    assetType={assetType}
                    handleClick={handleSingleSelectClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
